import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { handleDisplayWithdrawModal, transferFundsToWallet } from '../actions/wallet-actions';
import { getUserWalletPortfolio } from '../actions/wallet-actions';

import { WALLET_ICONS_URL } from '../../../config/constants';
import TradingHelper from '../../../core/helpers/trading-helper';
import GenericModal from '../../shared-components/generic-modal/generic-modal';
import Amount from '../../shared-components/amount/components/amount';

import '../styles/withdraw-modal.scss';

class WithdrawModal extends Component {
  getInitialState = () => {
    return {
      withdrawAmount: '',
      mfaCode: '',
      terminal: { value: '', label: '', balance: 0 },
      amountError: '',
      mfaError: '',
      filteredAccounts: [],
    };
  };

  state = { ...this.getInitialState() };

  componentDidUpdate = prevProps => {
    const { currencies, selectedCurrency, fetchingUserWallet } = this.props;

    if (prevProps.fetchingUserWallet && !fetchingUserWallet) {
      const fiatCurrencies = currencies.fiat && currencies.fiat.length ? currencies.fiat : [];
      this.setState({ filteredAccounts: this.formatAccounts(filter(fiatCurrencies, ['currency', selectedCurrency.symbol])) });
    }
  };

  handleModalOpen = () => {
    const { selectedCurrency, getUserWalletPortfolio } = this.props;

    if (TradingHelper.isFiatCurrency(selectedCurrency.asset_group)) {
      getUserWalletPortfolio();
    }

    this.setState({ ...this.getInitialState() });
  };

  handleTerminalChanged = option => {
    this.setState({ terminal: option });
  };

  handleOnAmountChange = e => {
    const { selectedCurrency } = this.props;

    const enteredValue = e.target.value;
    const decimalPlaces = enteredValue.split('.');
    const maximalAmount = parseFloat(selectedCurrency.free);

    // Don't allow entering invalid numbers or unsupported decimals
    if (isNaN(+enteredValue) || +enteredValue < 0 || (decimalPlaces.length > 1 && decimalPlaces[1].length > selectedCurrency.display_digits)) {
      e.preventDefault();
      return;
    }

    if (!enteredValue.trim().length) {
      this.setState({ withdrawAmount: '', amountError: 'WALLET.AMOUNT_REQUIRED_ERROR' });
    } else if (!maximalAmount || parseFloat(enteredValue) <= maximalAmount) {
      this.setState({ withdrawAmount: enteredValue, amountError: '' });
    } else {
      this.setState({ withdrawAmount: enteredValue, amountError: 'WALLET.AMOUNT_EXCEED_BALANCE_ERROR' });
    }
  };

  handleOnMFAChange = e => {
    const { mfaCode } = this.state;
    const code = e.target.value;

    if (!code) {
      this.setState({ mfaCode: '', mfaError: 'WALLET.MFA_CODE_REQUIRED' });
    } else if (code.length > 6 || isNaN(+code) || +code < 0) {
      this.setState({ mfaCode });
    } else {
      this.setState({ mfaCode: code, mfaError: '' });
    }
  };

  handleMaxClick = () => {
    const { selectedCurrency } = this.props;

    this.setState({ withdrawAmount: selectedCurrency.free, amountError: '' });
  };

  handleWithdrawClick = () => {
    const { selectedCurrency, currencies, transferFundsToWallet } = this.props;
    const { amountError, mfaError, withdrawAmount, mfaCode, terminal } = this.state;

    let terminalId;
    let currencyCode;

    if (TradingHelper.isFiatCurrency(selectedCurrency.asset_group)) {
      const currencyItem = currencies.fiat ? find(currencies.fiat, ['currency', selectedCurrency.symbol]) : null;
      if (currencyItem) {
        currencyCode = currencyItem.currency;
      }
      terminalId = terminal.value;
    } else {
      const currencyItem = find(currencies.crypto, ['currency', selectedCurrency.symbol]);
      if (currencyItem) {
        terminalId = currencyItem.terminal_id;
        currencyCode = currencyItem.currency_code;
      }
    }

    if (terminalId && withdrawAmount && currencyCode && !amountError && !mfaError) {
      transferFundsToWallet(terminalId, parseFloat(withdrawAmount), currencyCode, mfaCode);
    }
  };

  handleModalClose = () => {
    const { handleDisplayWithdrawModal } = this.props;

    handleDisplayWithdrawModal(false);
  };

  formatAccounts = currencies => {
    return currencies.map(currency => ({
      value: currency.terminal_id,
      label: `${currency.login} - ${currency.balance} ${currency.currency}`,
      balance: currency.balance,
    }));
  };

  renderTerminals = currency => {
    const { t } = this.context;
    const { fetchingUserWallet } = this.props;
    const { terminal, filteredAccounts } = this.state;

    if (TradingHelper.isFiatCurrency(currency.asset_group)) {
      return (
        <div>
          <div className="withdraw-modal__label">{t('WALLET.TO_ACCOUNT')}</div>
          <div className="withdraw-modal__select">
            <Select
              className="account-select"
              classNamePrefix="account-select"
              name="terminals"
              options={filteredAccounts}
              value={terminal}
              onChange={this.handleTerminalChanged}
              isSearchable={false}
              isLoading={fetchingUserWallet}
              loadingMessage={() => t('WALLET.LOADING_ACCOUNTS')}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  renderMFAInput = () => {
    const { t } = this.context;
    const { mfaEnabled } = this.props;
    const { mfaCode, mfaError } = this.state;

    return (
      mfaEnabled && (
        <div>
          <div className="withdraw-modal__label">{t('WALLET.YOUR_MULTI_FACTOR_AUTHENTICATION_CODE')}</div>
          <div className="withdraw-modal__input">
            <input
              className={`mfa-input${mfaError ? ' error-input-display' : ''}`}
              type="text"
              value={mfaCode}
              placeholder={t('WALLET.MULTI_FACTOR_AUTHENTICATION_CODE')}
              onChange={this.handleOnMFAChange}
            />
            {mfaError && <span className="error-label-styles">{t(mfaError)}</span>}
          </div>
        </div>
      )
    );
  };

  render = () => {
    const { t } = this.context;
    const { showWithdrawModal, selectedCurrency, mfaEnabled } = this.props;
    const { withdrawAmount, mfaCode, terminal, amountError, mfaError } = this.state;
    const withdrawDisabled =
      !withdrawAmount ||
      !+withdrawAmount ||
      (mfaEnabled && (!mfaCode || mfaCode.length < 6)) ||
      (TradingHelper.isFiatCurrency(selectedCurrency.asset_group) && !terminal.value) ||
      !!amountError ||
      !!mfaError;

    if (isEmpty(selectedCurrency)) {
      return null;
    }

    return (
      <GenericModal className="withdraw-modal" show={showWithdrawModal} onEnter={this.handleModalOpen} onHide={this.handleModalClose}>
        <Modal.Header>
          <div>
            <span>{t('WALLET.WITHDRAW_CURRENCY_TO_WALLET', { currency: selectedCurrency.name })}</span>
          </div>
          <i className="icn icn-simple-remove-big-thin" onClick={this.handleModalClose} />
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="withdraw-modal__amount">
              <span>{t('WALLET.AMOUNT_AVAILABLE')}</span>
              <Amount value={selectedCurrency.free} suffix={` ${selectedCurrency.symbol}`} decimals={selectedCurrency.display_digits} colored />
              {selectedCurrency.estUSDValue >= 0 && <Amount value={selectedCurrency.estUSDValue} prefix="($" suffix=")" colored />}
            </div>
            {this.renderTerminals(selectedCurrency)}
            <div className="withdraw-modal__label">{t('WALLET.WITHDRAWAL_AMOUNT')}</div>
            <div className="withdraw-modal__input">
              <input className={`${amountError && 'error-input-display'}`} type="text" value={withdrawAmount} placeholder={t('WALLET.ENTER_AMOUNT')} onChange={this.handleOnAmountChange} />
              <div>
                <div>{selectedCurrency.symbol}</div>
                <div>
                  <img src={`${WALLET_ICONS_URL}${selectedCurrency.symbol.toLowerCase()}.png`} alt="Wallet Currency" />
                </div>
              </div>
              <Button bsStyle="default" bsSize="large" onClick={this.handleMaxClick}>
                {t('WALLET.MAX')}
              </Button>
              {amountError && <span className="error-label-styles">{t(amountError)}</span>}
            </div>
            {this.renderMFAInput()}
            <div className="withdraw-modal__button">
              <Button bsStyle="primary" disabled={withdrawDisabled} onClick={this.handleWithdrawClick}>
                {t('WALLET.WITHDRAW')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </GenericModal>
    );
  };
}

WithdrawModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showWithdrawModal: state.wallet.showWithdrawModal,
  selectedCurrency: state.wallet.selectedCurrency,
  fetchingUserWallet: state.wallet.fetchingUserWallet,
  currencies: state.wallet.currencies,
  mfaEnabled: state.currentUser.info.mfaEnabled,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayWithdrawModal: data => {
    dispatch(handleDisplayWithdrawModal(data));
  },
  transferFundsToWallet: (terminalId, amount, currencyCode, token) => {
    dispatch(transferFundsToWallet(terminalId, amount, currencyCode, token));
  },
  getUserWalletPortfolio: () => {
    dispatch(getUserWalletPortfolio());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawModal);
