import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';

import { ORDER_SIDE } from '../../../../config/constants';
import MiniLoader from '../../../shared-components/mini-loader/mini-loader';
import RenderIf from '../../../shared-components/render-if/components/render-if';

import '../../styles/buy-sell-button.scss';

class BuySellButton extends Component {
  handleClick = e => {
    const { handleClick } = this.props;

    if (handleClick) {
      handleClick();
    }
  };

  getLabel = () => {
    const { t } = this.context;
    const { type, value, minMargin, symbol, insufficientFunds } = this.props;

    let label = '';

    if (insufficientFunds === true) {
      label = t('OPEN_ORDER.YOU_NEED_AT_LEAST_TO_TRADE', { amount: minMargin });
    } else if (type === ORDER_SIDE.BUY) {
      label = t('OPEN_ORDER.BUY_AMOUNT_SYMBOL', { amount: value, symbol: symbol });
    } else if (type === ORDER_SIDE.SELL) {
      label = t('OPEN_ORDER.SELL_AMOUNT_SYMBOL', { amount: value, symbol: symbol });
    }

    return label;
  };

  render = () => {
    const { type, isProcessing, insufficientFunds } = this.props;

    let btnStyle = type === ORDER_SIDE.BUY ? 'success' : 'danger';
    let className = 'btn-block';
    className += isProcessing ? ' loading' : '';

    const isDisabled = isProcessing || insufficientFunds;

    return (
      <div>
        <Button bsStyle={btnStyle} bsSize="large" className={`buy-sell-button ${className}`} disabled={isDisabled} onClick={!isProcessing ? this.handleClick : null}>
          <RenderIf if={!isProcessing}>{this.getLabel()}</RenderIf>
          <RenderIf if={isProcessing}>
            <MiniLoader whiteLoader />
          </RenderIf>
        </Button>
      </div>
    );
  };
}

BuySellButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BuySellButton;
