export const IN_APP_MESSAGES_ACTIONS = {
  HANDLE_MESSAGE_RECEIVED: 'handleMessageReceived',
  HANDLE_TOGGLE_IN_APP_MESSAGE_MODAL: 'handleToggleInAppMessageModal',
};

export const MESSAGE_OPTIONS = {
  CONTENT: {
    TEXT_ONLY: 'text_only',
    THUMBNAIL_LEFT: 'thumbnail_left',
    THUMBNAIL_RIGHT: 'thumbnail_right',
    MEDIA_HEADER_BODY: 'media_header_body',
    HEADER_BODY_MEDIA: 'header_body_media',
    HEADER_MEDIA_BODY: 'header_media_body',
  },
  DISPLAY_TYPE: {
    MODAL: 'modal',
    BANNER: 'banner',
    FULL_SCREEN: 'full_screen',
  },
  URL_TOKEN: 'naga://',
  // Keys below are not uppercase on purpose, as they should be the same as on backend
  SCREEN_POSITION: {
    top: 'top',
    bottom: 'bottom',
  },
  CONTENT_ORDER: {
    header_media_body: 'hmb',
    media_header_body: 'mhb',
  },
  MEDIA_TYPE: {
    image: 'image',
    video: 'video',
  },
  DEEP_LINK: {
    'fund-account': '/payments/deposits-funds',
    'verify-account': '/verification',
    'upgrade-account': '/register/live-account',
  },
  AUTO_CLOSE_PERIOD: 2000,
  TYPE: {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
  },
};
