import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import withRouter from 'react-router-dom/withRouter';

import GenericModal from '../../generic-modal/generic-modal';
import RenderIf from '../../render-if/components/render-if';
import { toggleInfoModal } from '../actions/info-modal-actions';

import '../styles/info-modal.scss';
import warning from '../styles/images/warning.png';
import success from '../styles/images/success.png';
import error from '../styles/images/error.png';
import info from '../styles/images/info.png';

class InfoModal extends Component {
  constructor(props) {
    super(props);

    this.icon = {
      warning,
      success,
      error,
      info,
    };
  }

  closeModal = () => {
    const { toggleInfoModal, callback, redirectUrl } = this.props;

    if (callback) {
      callback();
    }

    if (redirectUrl) {
      this.props.history.push(redirectUrl);
    }

    toggleInfoModal();
  };

  ctaAction = () => {
    const { toggleInfoModal, ctaCallback } = this.props;

    toggleInfoModal();

    if (ctaCallback) {
      ctaCallback();
    }
  };

  render() {
    const { showInfoModal, message, type, ctaText, translationParameters } = this.props;
    const { t } = this.context;

    return (
      <GenericModal className="info-modal" show={showInfoModal} onHide={this.closeModal}>
        <Modal.Header className={`info-modal-header border-bottom ${type}`} closeButton>
          <Modal.Title className="text-center">
            <img className="warning" src={this.icon[type || 'warning']} alt="Warning" />
          </Modal.Title>
          <p className="modal__text">{t(message, translationParameters)}</p>
        </Modal.Header>
        <RenderIf if={!!ctaText}>
          <Modal.Body>
            <Button onClick={this.ctaAction}>{t(ctaText || '')}</Button>
          </Modal.Body>
        </RenderIf>
      </GenericModal>
    );
  }
}

const mapStateToProps = state => ({
  showInfoModal: state.infoModal.showInfoModal,
  message: state.infoModal.message,
  type: state.infoModal.type,
  callback: state.infoModal.callback,
  redirectUrl: state.infoModal.redirectUrl,
  ctaText: state.infoModal.ctaText,
  ctaCallback: state.infoModal.ctaCallback,
  translationParameters: state.infoModal.translationParameters,
  lang: state.i18nState.lang,
});

const mapDispatchToProps = dispatch => ({
  toggleInfoModal: () => {
    dispatch(toggleInfoModal());
  },
});

InfoModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoModal));
