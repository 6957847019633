import find from 'lodash/find';

import { WALLET_REDUX_ACTIONS } from '../constants/wallet-constants';

const initialState = {
  selectedCurrency: {},
  assets: [],
  currencies: {
    crypto: [],
    fiat: [],
  },
  transferringFunds: false,
  showWithdrawModal: false,
  showDepositModal: false,
  fetchingUserWallet: false,
};

export const wallet = (state = initialState, payload) => {
  switch (payload.type) {
    case WALLET_REDUX_ACTIONS.HANDLE_SELECTED_WALLET_CURRENCY:
      return { ...state, selectedCurrency: payload.data };
    case WALLET_REDUX_ACTIONS.HANDLE_ASSETS_RECEIVED:
      return { ...state, assets: payload.data };
    case WALLET_REDUX_ACTIONS.TRANSFERRING_FUNDS_TO_WALLET:
      return { ...state, transferringFunds: payload.data };
    case WALLET_REDUX_ACTIONS.HANDLE_CURRENCIES_RECEIVED:
      return { ...state, currencies: payload.data };
    case WALLET_REDUX_ACTIONS.HANDLE_FETCHING_USER_WALLET_INFORMATION:
      return { ...state, fetchingUserWallet: payload.data };
    case WALLET_REDUX_ACTIONS.UPDATE_ASSETS_DATA:
      const updatedData = state.assets.map(item => {
        const newAssetData = find(payload.data, f => f.id === item.asset_id);
        if (newAssetData) {
          return { ...item, free: newAssetData.free, locked: newAssetData.locked, total: parseFloat(newAssetData.free) + parseFloat(newAssetData.locked) };
        } else {
          return item;
        }
      });
      return { ...state, assets: updatedData };
    case WALLET_REDUX_ACTIONS.HANDLE_UPDATE_ASSET_BALANCE:
      return {
        ...state,
        assets: state.assets.map(asset =>
          asset.asset_id === payload.data.id
            ? {
                ...asset,
                free: payload.data.free,
                locked: payload.data.locked,
                total: parseFloat(payload.data.free) + parseFloat(payload.data.locked),
              }
            : asset
        ),
      };
    case WALLET_REDUX_ACTIONS.HANDLE_DISPLAY_WITHDRAW_MODAL:
      return { ...state, showWithdrawModal: payload.data };
    case WALLET_REDUX_ACTIONS.HANDLE_DISPLAY_DEPOSIT_MODAL:
      return { ...state, showDepositModal: payload.data };
    default:
      return state;
  }
};
