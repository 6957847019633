import React, { Component } from 'react';

import Amount from '../../../shared-components/amount/components/amount';
import { ORDER_SIDE } from '../../../../config/constants';
import { GRAPH_COLOR } from '../../constants/exchange-constants';

import '../../styles/order-book/order-book-item.scss';

export default class OrderBookItem extends Component {
  constructor(props) {
    super(props);

    this.graphInstance = React.createRef();
  }

  shouldComponentUpdate = nextProps => {
    const { item, maxTotal, isPlaceHolder } = this.props;

    if (isPlaceHolder) {
      return false;
    }

    if (nextProps.item.price !== item.price || nextProps.item.amount !== item.amount || nextProps.maxTotal !== maxTotal) {
      return true;
    }
    return false;
  };

  componentDidMount = () => {
    const { isPlaceHolder } = this.props;

    if (!isPlaceHolder) {
      this.drawGraph();
    }
  };

  componentDidUpdate = prevProps => {
    const { maxTotal, isPlaceHolder } = this.props;

    if (prevProps.maxTotal !== maxTotal && !isPlaceHolder) {
      this.drawGraph();
    }
  };

  calculatePercentage = () => {
    const { maxTotal, item } = this.props;
    const itemTotal = item.price * item.amount;
    return (itemTotal * 100) / maxTotal;
  };

  drawGraph = () => {
    const { type } = this.props;
    const color = type === ORDER_SIDE.BUY ? GRAPH_COLOR.BUY : GRAPH_COLOR.SELL;

    this.graphInstance.current.style.background = `linear-gradient(to left, ${color} ${this.calculatePercentage()}%, transparent 0%)`;
  };

  renderPlaceHolderItem = () => {
    const { type } = this.props;

    return (
      <div className="order-book-item">
        <div className="order-book-item__price" />
        <div className="order-book-item__amount" />
        <div className={`order-book-item__amount ${type.toLowerCase()}`}>-</div>
      </div>
    );
  };

  render = () => {
    const { item, type, onPriceClick, onAmountClick, pair, isPlaceHolder } = this.props;

    if (isPlaceHolder) {
      return this.renderPlaceHolderItem();
    }

    return (
      <div className="order-book-item">
        <div className="order-book-item__graph">
          <div className="order-book-item__graph-line" ref={this.graphInstance} />
        </div>
        <div className={`order-book-item__price ${type.toLowerCase()}`} data-price={item.price} data-type={type} onClick={onPriceClick}>
          <Amount value={item.price} decimals={pair.quote_currency_decimals} />
        </div>
        <div className={`order-book-item__amount`} data-price={item.price} data-amount={item.amount} data-type={type} onClick={onAmountClick}>
          <Amount value={item.amount} decimals={pair.base_currency_decimals} />
        </div>
        <div className={`order-book-item__amount`}>
          <Amount value={item.price * item.amount} decimals={pair.quote_currency_decimals} />
        </div>
      </div>
    );
  };
}
