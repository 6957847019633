import reduce from 'lodash/reduce';
import find from 'lodash/find';
import map from 'lodash/map';

import { handleAssetsReceived, updateAssetsData } from '../../wallet/actions/wallet-actions';
import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';

import { MARKETS } from '../../../config/constants';
import { EXCHANGE_ACTIONS, ORDER_BOOK_ACTIONS } from '../constants/exchange-constants';
import HelperFunctions from '../../../core/helpers/helper-functions';
import ExchangeApi from '../../../api/exchange-api';
import userApi from '../../../api/user-api';
import PortfolioAPI from '../../../api/portfolio-api';
import ordersApi from '../../../api/orders-api';
import UserApi from '../../../api/user-api';
import TradingHelper from '../../../core/helpers/trading-helper';

export const getPairs = () => {
  return dispatch => {
    dispatch(handleFetchingSymbols(true));
    return Promise.all([ExchangeApi.getAssets(), ExchangeApi.getPairs()])
      .then(
        res => {
          if (res.length === 2) {
            const assets = res[0].data.data;
            const pairs = res[1].data.data;
            const pairNames = pairs.map(pair => {
              return pair.name;
            });

            dispatch(handleAssetsReceived(assets));

            //get market prices by pairs and combine them
            ExchangeApi.getMarketPricesData(pairNames).then(
              pricesResponse => {
                let combinedPairs = map(pairs, pair => {
                  const baseAsset = find(assets, f => f.asset_id === pair.base_asset_id) || {};
                  const quoteAsset = find(assets, f => f.asset_id === pair.quote_asset_id) || {};
                  const pairPrice = find(pricesResponse.data.data, f => f.pair_name === pair.name) || {};

                  pair.base_currency = baseAsset.symbol;
                  pair.base_currency_name = baseAsset.name;
                  pair.quote_currency = quoteAsset.symbol;
                  pair.quote_currency_name = quoteAsset.name;
                  pair.display_name = `${baseAsset.symbol}/${quoteAsset.symbol}`;
                  pair.base_currency_decimals = HelperFunctions.countDecimals(+pair.lot_step);
                  pair.quote_currency_decimals = HelperFunctions.countDecimals(+pair.price_step);
                  pair.currentPrice = pairPrice.close_price;
                  pair.openPrice = pairPrice.open_price;
                  pair.dailyHigh = pairPrice.high;
                  pair.dailyLow = pairPrice.low;
                  pair.quoteVolume = pairPrice.quote_volume;
                  pair.baseVolume = pairPrice.base_volume;
                  pair.changePerTime = pairPrice.closed;
                  pair.change24HourValue = pairPrice.change_1d_value;
                  pair.change24HourPercentage = pairPrice.change_1d_percentage;
                  pair.bid = pairPrice.best_bid;
                  pair.ask = pairPrice.best_ask;

                  return pair;
                });

                combinedPairs = reduce(
                  combinedPairs,
                  (result, item) => {
                    if (find(MARKETS, market => market.name === item.quote_currency)) {
                      const { lastDayVolumeInUSD, lastPriceInUSD } = TradingHelper.getPricesInUSD(combinedPairs, item.quote_currency, item.currentPrice, item.quoteVolume);
                      item.lastDayVolumeInUSD = lastDayVolumeInUSD;
                      item.lastPriceInUSD = lastPriceInUSD;
                      result.push(item);
                    }
                    return result;
                  },
                  []
                );

                dispatch(handleSymbolsReceived(combinedPairs));
                dispatch(handleFetchingSymbols(false));
              },
              pricesError => {
                console.error(pricesError);
                dispatch(handleSymbolsReceived([]));
                dispatch(handleFetchingSymbols(false));
              }
            );
          }
        },
        err => {
          console.error(err);
          dispatch(handleSymbolsReceived([]));
          dispatch(handleFetchingSymbols(false));
        }
      )
      .catch(err => {
        dispatch(handleFetchingSymbols(false));
      });
  };
};

export const getOrderBookItems = pair => {
  return dispatch => {
    dispatch(handleGetOrderBookItemsInProgress(true));
    return ExchangeApi.getMarketDepth(pair)
      .then(res => {
        if (res.data && res.data.data) {
          const bids = res.data.data.bids.map(a => {
            return { price: a[0], amount: a[1] };
          });
          const asks = res.data.data.asks.map(a => {
            return { price: a[0], amount: a[1] };
          });
          dispatch(handleGetOrderBookItemsSuccess({ asks, bids }));
        }
        dispatch(handleGetOrderBookItemsInProgress(false));
      })
      .catch(err => {
        dispatch(handleGetOrderBookItemsInProgress(false));
        dispatch(handleGetOrderBookItemsSuccess({}));
      });
  };
};

export const getTradeHistoryItems = data => {
  return dispatch => {
    dispatch(handleGetTradeHistoryItemsInProgress(true));
    return ExchangeApi.getTradeHistory(data)
      .then(res => {
        // dispatch(handleHasMoreTradeHistoryItemsToLoad(res.data.length === data.limit));
        dispatch(handleGetTradeHistoryItemsSuccess(res.data.data));
        dispatch(handleGetTradeHistoryItemsInProgress(false));
      })
      .catch(err => {
        dispatch(handleGetTradeHistoryItemsInProgress(false));
      });
  };
};

export const getUserExchangePortfolio = () => {
  return dispatch => {
    return PortfolioAPI.getExchangePortfolio();
  };
};

export const getUserExchangePortfolioCache = () => {
  let invocationCounter = 0;
  return dispatch => {
    return getExchangePortfolioCache(dispatch, invocationCounter);
  };
};

export const getUserSpecificMQTTTopics = () => {
  return dispatch => {
    return userApi.getUserSpecificMQTTTopics().then(
      res => {
        dispatch(handleUserSpecificMQTTTopics(res.data.data));
      },
      err => {}
    );
  };
};

export const placeOrder = data => {
  return dispatch => {
    return ordersApi.placeOrder(data).then(
      res => {
        console.log('PLACED!');
      },
      err => {
        console.error('ERROR!');
      }
    );
  };
};

const getExchangePortfolioCache = (dispatch, counter) => {
  counter++;
  return PortfolioAPI.getExchangePortfolioCache().then(
    res => {
      dispatch(updateAssetsData(res.data.data));
    },
    err => {
      if (counter < 5) {
        getExchangePortfolioCache(dispatch, counter);
      } else {
        if (err.response.data && err.response.data.request_id) {
          dispatch(toggleInfoModal('EXCHANGE.FAILED_TO_GET_WALLET_INFORMATION', 'error', null, null, null, null, { n: err.response.data.request_id }));
        }
      }
    }
  );
};

export const getUserFees = () => {
  return dispatch => {
    return UserApi.getUserFees().then(res => {
      if (res.data && res.data.data) {
        dispatch(handleUserFeesReceived(res.data.data));
      }
    });
  };
};

export const handleFetchingSymbols = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_FETCHING_SYMBOLS,
  data,
});

export const handleSymbolsReceived = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_SYMBOLS_RECEIVED,
  data,
});

export const handleMarketChanged = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_MARKET_CHANGED,
  data,
});

export const handleSymbolChanged = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_SYMBOL_CHANGED,
  data,
});

export const handleFetchingSymbolInfo = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_FETCHING_SYMBOL_INFO,
  data,
});

export const handleSymbolInfoChanged = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_SYMBOL_INFO_CHANGED,
  data,
});

export const handleExchangeSubscriptionChanged = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_EXCHANGE_SUBSCRIPTION_CHANGED,
  data,
});

export const handleOrderCreateInProgress = () => {
  //TODO: Check how order opening is going to be done on backend (WS or HTTP)
};

export const toggleMarketsModal = data => ({
  type: EXCHANGE_ACTIONS.TOGGLE_MARKETS_MODAL,
  data,
});

export const handleGetOrderBookItemsSuccess = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_ORDER_BOOK_ITEMS_SUCCESS,
  data,
});

export const handleGetOrderBookItemsInProgress = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_GET_ORDER_BOOK_ITEMS_IN_PROGRESS,
  data,
});

export const handleSeletedOrderBookItem = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_SELECTED_ORDER_BOOK_ITEM,
  data,
});

export const handleGetTradeHistoryItemsSuccess = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_GET_TRADE_HISTORY_ITEMS_SUCCESS,
  data,
});

export const handleNewTradeHistoryItemReceived = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_NEW_TRADE_HISTORY_ITEM_RECEIVED,
  data,
});

export const handleGetTradeHistoryItemsInProgress = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_GET_TRADE_HISTROY_ITEMS_IN_PROGRESS,
  data,
});

export const handleHasMoreTradeHistoryItemsToLoad = data => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_HAS_MORE_TRADE_HISTORY_ITEMS_TO_LOAD,
  data,
});

export const handleResetTradeHistoryItems = () => ({
  type: ORDER_BOOK_ACTIONS.HANDLE_RESET_TRADE_HISTORY_ITEMS,
});

export const toggleOrderBookModal = data => ({
  type: EXCHANGE_ACTIONS.TOGGLE_ORDER_BOOK_MODAL,
  data,
});

export const handleUserSpecificMQTTTopics = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_USER_SPECIFIC_MQTT_TOPICS,
  data,
});

export const handleUserFeesReceived = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_USER_FEES_RECEIVED,
  data,
});

export const handleUpdatePairDataFromTicker = data => ({
  type: EXCHANGE_ACTIONS.HANDLE_UPDATE_PAIR_DATA_FROM_TICKER,
  data,
});
