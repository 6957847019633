import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { socialAutoLogin } from '../actions/registration-actions';
import HelperFunctions from '../../../core/helpers/helper-functions';
import AppLoader from '../../shared-components/loader/components/loader';

class SocialAutoLogin extends Component {
  componentDidMount() {
    const { socialAutoLogin } = this.props;
    const id = HelperFunctions.getParameterByName('id');
    const token = HelperFunctions.getParameterByName('token');
    const type = HelperFunctions.getParameterByName('type');

    HelperFunctions.generateFingerprint();

    const data = {
      id: decodeURIComponent(id),
      token: decodeURIComponent(token),
      type: decodeURIComponent(type),
    };

    socialAutoLogin(data);
  }

  componentDidUpdate = props => {
    const { history } = this.props;

    if (props.loggedIn) {
      history.push('/exchange');
    }
  };

  render() {
    const { t } = this.context;

    return (
      <div>
        <AppLoader message={t('LOGIN.LOGGING_IN_PLEASE_WAIT')} />
      </div>
    );
  }
}

SocialAutoLogin.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: state.currentUser.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  socialAutoLogin: data => {
    dispatch(socialAutoLogin(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialAutoLogin));
