import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ORDER_SIDE } from '../../../../config/constants';
import OpenOrder from '../trading/open-order';

import '../../styles/exchange-open-order.scss';

class ExchangeOpenOrder extends Component {
  handleOrdersTabSelected = value => {
    this.setState({ selectedOrdersTab: value });
  };

  render = () => {
    const { isMobile } = this.props;

    if (isMobile) {
      return null;
    }

    return (
      <div className="exchange-open-order">
        <div className="exchange-open-order__buy">
          <OpenOrder orderSide={ORDER_SIDE.BUY} />
        </div>
        <div className="exchange-open-order__sell">
          <OpenOrder orderSide={ORDER_SIDE.SELL} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  isMobile: state.mediaQuery.isMobile,
});

export default connect(mapStateToProps, null)(ExchangeOpenOrder);
