import React from 'react';

class RegistrationHelper {
  static getCountryOption = country => {
    return {
      value: country.code,
      label: (
        <div>
          <img className="country-flag" src={country.icon} alt="Country" />
          {country.name}
        </div>
      ),
      name: country.name,
      code: country.code,
      phoneCode: country.phone_code,
      icon: country.icon,
      isEU: country.is_eu,
    };
  };

  static getCodeOption = country => {
    return {
      value: country.phone_code,
      label: (
        <div>
          <img className="country-flag" src={country.icon} alt="Country" />
          {country.name}
          {country.phone_code}
        </div>
      ),
      name: country.name,
      code: country.code,
      phoneCode: country.phone_code,
      icon: country.icon,
      isEU: country.is_eu,
    };
  };
}

export default RegistrationHelper;
