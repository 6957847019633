import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';
import { handlePhoneNumberConfirmation, handleChangePhoneNumberInProgress } from '../../user-settings/actions/user-settings-actions';
import { handlePhoneNumberVerified } from '../../profile/actions/profile-actions';
import { DB_BOOLEAN } from '../../../config/constants';
import { PHONE_VERIFICATION_ACTIONS } from '../constants/phone-verification-constants';
import registrationAPI from '../../../api/registration-api';
import ErrorHelper from '../../../core/helpers/error-helper';

export const verifyAndSavePhoneNumber = (phoneNumber, sms_code, reject, resolve) => {
  return dispatch => {
    return registrationAPI
      .verifyAndSavePhoneNumber(phoneNumber, sms_code)
      .then(
        response => {
          const phoneVerified = response.data && response.data.success && !response.data.error;

          dispatch(handlePhoneVerification({ isVerified: phoneVerified, phoneNumber }));
          dispatch(handlePhoneNumberVerified(phoneVerified));

          if (!phoneVerified) {
            reject({ sms_code_1: ' ', sms_code_2: ' ', sms_code_3: ' ', sms_code_4: ' ' });
          } else {
            dispatch(handlePhoneNumberConfirmation(DB_BOOLEAN.TRUE, phoneNumber));
            dispatch(handleChangePhoneNumberInProgress(false));
            resolve();
          }
        },
        () => {
          reject({ sms_code_1: ' ', sms_code_2: ' ', sms_code_3: ' ', sms_code_4: ' ' });
        }
      )
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        reject({ sms_code: 'SOMETHING_WENT_WRONG' });
      });
  };
};

export const sendVerificationCode = (phoneNumber, isResend) => {
  return dispatch => {
    dispatch(handleSendVerificationCodeInProgress(true));
    return registrationAPI
      .sendVerificationCode(phoneNumber)
      .then(
        () => {
          dispatch(handleSendVerificationCodeInProgress(false));
          dispatch(handleShowPhoneVerifyWarningModal(false));
          if (isResend) {
            dispatch(handlePhoneVerification({ isVerified: false, phoneNumber }));
            dispatch(toggleInfoModal('VERIFICATION.VERIFICATION_CODE_SENT_TO_YOUR_PHONE_NUMBER', 'success'));
          } else {
            dispatch(handleShowPhoneVerifyModal(true));
          }
        },
        error => {
          dispatch(toggleInfoModal(error.response.data.message, 'error'));
          dispatch(handleSendVerificationCodeInProgress(false));
        }
      )
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        dispatch(handleSendVerificationCodeInProgress(false));
      });
  };
};

export const handleShowPhoneVerifyModal = data => ({
  type: PHONE_VERIFICATION_ACTIONS.HANDLE_SHOW_PHONE_VERIFY_MODAL,
  data,
});

export const handleShowPhoneVerifyWarningModal = data => ({
  type: PHONE_VERIFICATION_ACTIONS.HANDLE_SHOW_PHONE_VERIFY_WARNING_MODAL,
  data,
});

export const handlePhoneVerification = data => ({
  type: PHONE_VERIFICATION_ACTIONS.HANDLE_PHONE_VERIFICATION,
  data,
});

export const handleSendVerificationCodeInProgress = data => ({
  type: PHONE_VERIFICATION_ACTIONS.HANDLE_SEND_VERIFICATION_CODE_IN_PROGRESS,
  data,
});
