import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import withRouter from 'react-router-dom/withRouter';

import { confirmEmail } from '../../registration/actions/registration-actions';

import { PLATFORM, DEFAULT_ROUTE, VARIOUS_IMAGES_URL } from '../../../config/constants';

import HelperFunctions from '../../../core/helpers/helper-functions';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';

import '../styles/confirm-email.scss';

class ConfirmEmail extends Component {
  componentDidMount = () => {
    const { confirmEmail } = this.props;
    const email = decodeURIComponent(HelperFunctions.getParameterByName('email')) || '';
    const userId = decodeURIComponent(HelperFunctions.getParameterByName('user_id')) || '';
    const platform = HelperFunctions.getParameterByName('p') || PLATFORM;

    confirmEmail({ email, userId, platform });
  };

  navigateToApp = () => {
    const { history, loggedIn, isGuest } = this.props;
    const isUserLoggedIn = loggedIn && !isGuest;

    if (isUserLoggedIn) {
      history.push(DEFAULT_ROUTE);
    } else {
      history.push('/login');
    }
  };

  render = () => {
    const { t } = this.context;
    const { loggedIn, isGuest, emailConfirmationInProgress } = this.props;
    const isUserLoggedIn = loggedIn && !isGuest;

    return (
      <div className="confirm-email-wrapper">
        <div className="confirm-email-wrapper__content">
          {emailConfirmationInProgress ? (
            <Fragment>
              <MiniLoader />
              <label>{t('CONFIRM_EMAIL.PLEASE_WAIT')}</label>
            </Fragment>
          ) : (
            <Fragment>
              <img src={`${VARIOUS_IMAGES_URL}envelope-green.svg`} alt="envelope" />
              <span>{t('CONFIRM_EMAIL.VERIFIED')}</span>
              <span>{t('CONFIRM_EMAIL.RETURN_TO_APP')}</span>
              <Button bsStyle="primary" onClick={this.navigateToApp}>
                {isUserLoggedIn ? t('CONFIRM_EMAIL.GET_STARTED') : t('CONFIRM_EMAIL.LOGIN')}
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    );
  };
}

ConfirmEmail.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: state.currentUser.loggedIn,
  emailConfirmationInProgress: state.registration.emailConfirmationInProgress,
});

const mapDispatchToProps = dispatch => ({
  confirmEmail: data => {
    dispatch(confirmEmail(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail));
