import forEach from 'lodash/forEach';

import CookiesWrapper from './cookies-wrapper';
import HelperFunctions from './helper-functions';

export default class CacheHelper {
  static getAuthTokens() {
    const authTokens = CookiesWrapper.getCookie('authTokens');
    return authTokens || {};
  }

  static setAuthTokens(tokens) {
    CookiesWrapper.setCookie('authTokens', tokens);
  }

  static getUserInfo() {
    const userInfo = CookiesWrapper.getCookie('userInfo');
    return userInfo || {};
  }

  static setUserInfo(userInfo) {
    CookiesWrapper.setCookie('userInfo', userInfo);
    localStorage.setItem('lang', userInfo.language);
  }

  static setNagaRedirectFlag() {
    CookiesWrapper.setCookie('nagaRedirect', 1);
  }

  static removeNagaRedirectFlag() {
    CookiesWrapper.removeCookie('nagaRedirect');
  }

  static clearLocalStorageDataByKeys(keys) {
    forEach(keys, key => {
      localStorage.removeItem(key);
    });
  }

  static clearLocalStorageData() {
    const keys = this.getStorageDataKeys();
    forEach(keys, key => {
      localStorage.removeItem(key);
    });
  }

  static clearCachedData() {
    CookiesWrapper.removeCookie('authTokens');
    CookiesWrapper.removeCookie('userInfo');
    this.clearLocalStorageData();
  }

  static getStorageDataKeys() {
    return [];
  }

  static saveRegistrationUrlParams() {
    const registrationUrlParams = {
      p_invited_by_code: HelperFunctions.getParameterByName('refcode') || this.getRegistrationUrlParamsByName('p_invited_by_code'),
      p_tracking_code: HelperFunctions.getParameterByName('trackcode') || this.getRegistrationUrlParamsByName('p_tracking_code'),
      p_etrass_click_id: HelperFunctions.getParameterByName('clickid') || this.getRegistrationUrlParamsByName('p_etrass_click_id'),
      utm_source: HelperFunctions.getParameterByName('utm_source') || this.getRegistrationUrlParamsByName('utm_source'),
      utm_campaign: HelperFunctions.getParameterByName('utm_campaign') || this.getRegistrationUrlParamsByName('utm_campaign'),
      utm_medium: HelperFunctions.getParameterByName('utm_medium') || this.getRegistrationUrlParamsByName('utm_medium'),
      utm_content: HelperFunctions.getParameterByName('utm_content') || this.getRegistrationUrlParamsByName('utm_content'),
      utm_keyword: HelperFunctions.getParameterByName('utm_keyword') || this.getRegistrationUrlParamsByName('utm_keyword'),
      utm_term: HelperFunctions.getParameterByName('utm_term') || this.getRegistrationUrlParamsByName('utm_term'),
      aff: HelperFunctions.getParameterByName('aff') || this.getRegistrationUrlParamsByName('aff'),
      cid: HelperFunctions.getParameterByName('cid') || this.getRegistrationUrlParamsByName('cid'),
      pid: HelperFunctions.getParameterByName('pid') || this.getRegistrationUrlParamsByName('pid'),
      reason: HelperFunctions.getParameterByName('reason') || this.getRegistrationUrlParamsByName('reason'),
      affiliate: HelperFunctions.getParameterByName('affiliate') || this.getRegistrationUrlParamsByName('affiliate'),
      clickid: HelperFunctions.getParameterByName('clickid') || this.getRegistrationUrlParamsByName('clickid'),
    };
    localStorage.setItem('registrationUrlParams', JSON.stringify(registrationUrlParams));
  }

  static getRegistrationUrlParamsByName(param) {
    return JSON.parse(localStorage.getItem('registrationUrlParams')) && JSON.parse(localStorage.getItem('registrationUrlParams'))[param];
  }

  static clearRegistrationUrlParams() {
    return localStorage.removeItem('registrationUrlParams');
  }
}
