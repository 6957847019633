export const NAVIGATION_ITEMS = [
  {
    text: 'NAV.EXCHANGE',
    name: 'exchange',
    path: '/exchange',
  },
  {
    text: 'NAV.WALLET',
    name: 'wallet',
    path: '/wallet',
  },
  {
    text: 'NAV.ORDERS',
    name: 'orders',
    path: '/orders',
  },
  {
    text: 'NAV.GET_NGC',
    name: 'referals',
    path: '/refer-a-friend',
  },
];
