import React, { Component } from 'react';
import { connect } from 'react-redux';

import OpenOrderActions from './orders/open-order-actions';

class ExchangeActions extends Component {
  render = () => {
    const { isMobile, isIphoneX, selectedPair } = this.props;

    if (!isMobile) {
      return null;
    }

    return <OpenOrderActions isIphoneX={isIphoneX} selectedPair={selectedPair} />;
  };
}

const mapStateToProps = state => {
  return {
    isMobile: state.mediaQuery.isMobile,
    selectedPair: state.exchange.selectedPair,
  };
};

export default connect(mapStateToProps, null)(ExchangeActions);
