import { INFO_MODAL_ACTIONS } from '../constants/info-modal-constants';

export const toggleInfoModal = (message, type, callback, redirectUrl, ctaText, ctaCallback, translationParameters) => ({
  type: INFO_MODAL_ACTIONS.TOGGLE_INFO_MODAL,
  data: {
    message,
    type,
    callback,
    redirectUrl,
    ctaText,
    ctaCallback,
    translationParameters,
  },
});
