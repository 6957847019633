import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { showBanner } from '../../in-app-messages/actions/in-app-message-actions';
import { sendInviteLink, getAllInvitedUsers } from '../actions/refer-a-friend-actions';

import { EMAIL_CRITERIA, NAGA_MARKETS_URL, NAGA_GLOBAL_URL } from '../../../config/constants';
import UserHelper from '../../../core/helpers/user-helper';
import ReferredFriendItem from './refered-friend-item';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';

import '../styles/refer-a-friend.scss';

class ReferAFriend extends Component {
  constructor(props) {
    super(props);

    const isNagaMarketsUser = UserHelper.isNagaMarketsUser(props.userInfo);

    this.state = {
      refLink: `${isNagaMarketsUser ? NAGA_MARKETS_URL : NAGA_GLOBAL_URL}?refcode=${props.referralCode}`,
      errorMessage: '',
      email: '',
    };
  }

  componentDidMount = () => {
    const { getAllInvitedUsers } = this.props;

    getAllInvitedUsers();
  };

  componentDidUpdate = prevProps => {
    const { referralCode } = this.props;

    if (isEmpty(prevProps.referralCode) && !isEmpty(referralCode)) {
      this.setState(prevState => ({ refLink: `${prevState.refLink}${referralCode}` }));
    }
  };

  handleCopyInviteLink = () => {
    const { showBanner } = this.props;

    showBanner('REFER_FRIEND.LINK_COPIED');
  };

  handleShareLinkViaSocial = e => {
    const { refLink } = this.state;
    const socialURL = e.currentTarget.dataset.url;

    window.open(`${socialURL}${refLink}`, '_blank');
  };

  handleOnEmailChange = e => {
    this.setState({ email: e.currentTarget.value });
  };

  handleOnEmailKeyPress = e => {
    if (e.key === 'Enter') {
      this.sendAnEmail();
    }
  };

  handleSendEmail = e => {
    this.sendAnEmail();
  };

  sendAnEmail = () => {
    const { sendInviteLink } = this.props;
    const { email } = this.state;

    if (isEmpty(email)) {
      this.setState({ errorMessage: 'REFER_FRIEND.NO_EMAIL' });
    } else if (!EMAIL_CRITERIA.test(email)) {
      this.setState({ errorMessage: 'REFER_FRIEND.INVALID_EMAIL' });
    } else {
      this.setState({ errorMessage: '' });
      sendInviteLink({ email });
    }
  };

  renderStep = (stepNumber, text) => {
    const { t } = this.context;

    return (
      <div>
        <div className="refer-a-friend__step-outer">
          <div className="refer-a-friend__step-inner">{stepNumber}</div>
        </div>
        <span className="refer-a-friend__step-text">{t(text)}</span>
      </div>
    );
  };

  renderInvitedUsers = () => {
    const { t } = this.context;
    const { invitedUsers, getInvitedUsersInProgress, isMobile } = this.props;

    if (getInvitedUsersInProgress) {
      return (
        <div className="refer-a-friend__loader">
          <MiniLoader />
        </div>
      );
    }

    if (isEmpty(invitedUsers)) {
      return <div className="refer-a-friend__no-data">{t('REFER_FRIEND.NO_REFERRED_FRIENDS')}</div>;
    }

    return invitedUsers.map(item => {
      return <ReferredFriendItem item={item} key={item.user_id} isMobile={isMobile} />;
    });
  };

  render = () => {
    const { t } = this.context;
    const { isMobile, referralCode } = this.props;
    const { refLink, errorMessage } = this.state;

    return (
      <div className="refer-a-friend">
        <div className="refer-a-friend__desc-container">
          <div className="refer-a-friend__desc-container__text">
            <div className="refer-a-friend__desc-container__text-title">{t('REFER_FRIEND.EARN_NGC')}</div>
            <div className="refer-a-friend__desc-container__text-desc">{t('REFER_FRIEND.EARN_NGC_DESC')}</div>
            <div className="refer-a-friend__desc-container__text-steps">
              {this.renderStep(1, 'REFER_FRIEND.INVITE_FRIENDS')}
              {this.renderStep(2, 'REFER_FRIEND.TRACK_FRIENDS')}
              {this.renderStep(3, 'REFER_FRIEND.GET_BONUS')}
            </div>
            <div className="refer-a-friend__desc-container__text-disclaimer">
              <div>{t('REFER_FRIEND.BONUS_DESC')}</div>
              <ol>
                <li>{t('REFER_FRIEND.FIRST_BONUS')}</li>
                <li>{t('REFER_FRIEND.SECOND_BONUS')}</li>
              </ol>
            </div>
          </div>
          <div className="refer-a-friend__desc-container__arrow" />
          <div className="refer-a-friend__desc-container__actions">
            <div className="refer-a-friend__desc-container__actions-title">{t('REFER_FRIEND.INVITE_FRIENDS_TITLE')}</div>
            <div className="refer-a-friend__desc-container__actions-invite">
              <span>{t('REFER_FRIEND.SEND_INVITE')}</span>
              <div>
                <FormGroup>
                  <InputGroup>
                    <FormControl type="text" placeholder={t('REFER_FRIEND.EMAIL_ADDRESS')} onChange={this.handleOnEmailChange} onKeyPress={this.handleOnEmailKeyPress} />
                    <InputGroup.Button>
                      <Button bsStyle="success" onClick={this.handleSendEmail}>
                        {t('REFER_FRIEND.SEND_INVITE_BUTTON')}
                      </Button>
                    </InputGroup.Button>
                  </InputGroup>
                  <div className="refer-a-friend__desc-container__actions-invite__email-error">{t(errorMessage)}</div>
                </FormGroup>
              </div>
            </div>
            <div className="refer-a-friend__desc-container__actions-separator">{t('REFER_FRIEND.OR')}</div>
            <div className="refer-a-friend__desc-container__actions-link">
              <span>{t('REFER_FRIEND.SHARE_LINK')}</span>
              <div>
                <FormGroup>
                  <CopyToClipboard text={refLink}>
                    <InputGroup>
                      <FormControl type="text" readOnly value={refLink} />
                      <InputGroup.Button>
                        <Button bsStyle="default" onClick={this.handleCopyInviteLink} disabled={isEmpty(referralCode)}>
                          <i className="icn icn-copy-link-2-thin" />
                          {t('REFER_FRIEND.COPY')}
                        </Button>
                      </InputGroup.Button>
                    </InputGroup>
                  </CopyToClipboard>
                </FormGroup>
              </div>
            </div>
            <div className="refer-a-friend__desc-container__actions-social">
              <Button className="fb" onClick={this.handleShareLinkViaSocial} data-url="https://www.facebook.com/sharer/sharer.php?u=" disabled={isEmpty(referralCode)}>
                <div className="fb__logo" />
                <span className="fb__text">Facebook</span>
              </Button>
              <Button className="twitter" onClick={this.handleShareLinkViaSocial} data-url="https://twitter.com/home?status=" disabled={isEmpty(referralCode)}>
                <div className="twitter__logo" />
                <span className="twitter__text">Twitter</span>
              </Button>
              <Button className="gmail" onClick={this.handleShareLinkViaSocial} data-url="https://mail.google.com/mail/?view=cm&fs=1&su=NAGA Wallet Invitation&body=" disabled={isEmpty(referralCode)}>
                <div className="gmail__logo" />
                <span className="gmail__text">Gmail</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="refer-a-friend__users">
          <div className="refer-a-friend__users-title">{t('REFER_FRIEND.SENT_INVITES_TITLE')}</div>
          <div className="refer-a-friend__users-table">
            <ReferredFriendItem isTitleRow isMobile={isMobile} />
            {this.renderInvitedUsers()}
          </div>
        </div>
      </div>
    );
  };
}

ReferAFriend.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  referralCode: state.referrals.referralCode,
  invitedUsers: state.referrals.users,
  userInfo: state.currentUser.info,
  getInvitedUsersInProgress: state.referrals.getInvitedUsersInProgress,
  isMobile: state.mediaQuery.isMobile,
});

const mapDispatchToProps = dispatch => ({
  showBanner: data => {
    dispatch(showBanner(data));
  },
  sendInviteLink: data => {
    dispatch(sendInviteLink(data));
  },
  getAllInvitedUsers: () => {
    dispatch(getAllInvitedUsers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriend);
