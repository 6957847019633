import each from 'lodash/each';

import { REAL_TIME_SERVICE_ACTIONS } from '../constants/real-time-service-constants';

import MQTTService from '../../../core/helpers/mqtt-service';

export const initializeRealTimeService = authTokens => {
  return dispatch => {
    const callbacks = {
      available: data => dispatch(handleIsRealTimeAvailable(data)),
      connected: data => dispatch(handleIsRealTimeConnected(data)),
    };

    MQTTService.connect(authTokens, callbacks);
  };
};

export const disconnectRealTimeService = () => {
  return dispatch => {
    MQTTService.disconnect(data => dispatch(handleIsRealTimeConnected(data)));
  };
};

export const subscribeToRTService = (topics, forceCallback) => {
  return dispatch => {
    if (topics && !Array.isArray(topics)) {
      topics = [topics];
    }

    const subsRequested = topics.length;
    let subsGranted = 0;

    const callback = () => {
      subsGranted++;

      if (forceCallback && subsRequested === subsGranted) {
        dispatch(handleSubscriptionsFinished(true));
      }
    };

    each(topics, topic => MQTTService.subscribe(topic, callback));
  };
};

export const unsubscribeFromRTService = topics => {
  return dispatch => {
    if (topics && !Array.isArray(topics)) {
      topics = [topics];
    }

    each(topics, topic => MQTTService.unsubscribe(topic));
  };
};

export const bindRTListener = (topic, callback, callbackId = null) => {
  return () => {
    MQTTService.on(topic, callback, callbackId);
  };
};

export const offRTCallback = (topic, callbackId) => {
  return () => {
    MQTTService.off(topic, callbackId);
  };
};

export const handleIsRealTimeAvailable = data => ({
  type: REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_RT_AVAILABLE,
  data,
});

export const handleIsRealTimeConnected = data => ({
  type: REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_RT_CONNECTED,
  data,
});

export const handleSubscriptionsFinished = data => ({
  type: REAL_TIME_SERVICE_ACTIONS.HANDLE_SUBSCRIPTIONS_FINISHED,
  data,
});
