import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import Switch from 'react-router-dom/Switch';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import { ThemeProvider, LightTheme } from '@my-swipestox/components';

import NAGAMQTTService from '../core/helpers/naga-mqtt-service';
import { USER_STATUS_TYPE } from '../config/constants';
import { subscribeToInAppMessages, showBanner } from '../features/in-app-messages/actions/in-app-message-actions';
import { handleAutoLoginMAUrlReceived, getPublicSocketCredentials } from '../features/registration/actions/registration-actions';
import { getUserSpecificMQTTTopics, getUserExchangePortfolioCache, getPairs } from '../features/exchange/actions/exchange-actions';
import { handleUpdateAssetBalance } from '../features/wallet/actions/wallet-actions';
import { handleMediaQueryResult } from '../features/media-query/actions/media-query-actions';
import { handleUserInfoUpdated } from '../features/current-user/actions/current-user-actions';
import { handleDisplayUserStatusNotification } from '../features/profile/actions/profile-actions';
import { initializeRealTimeService, disconnectRealTimeService, subscribeToRTService, unsubscribeFromRTService, bindRTListener } from '../features/real-time-service/actions/real-time-service-actions';
import { initializeNAGARealTimeService } from '../features/real-time-service/actions/naga-real-time-service-actions';

// import FacebookHelper from '../core/helpers/facebook-helper';
// import GoogleHelper from '../core/helpers/google-helper';
import CacheHelper from '../core/helpers/cache-helper';
import MediaQueryHelper from '../core/helpers/media-query-helper';
import ErrorHelper from '../core/helpers/error-helper';
import routes from '../core/route-config';
import RouteWithSubRoutes from '../core/route-with-subroutes';
import Header from '../features/layout/header/components/header';
import MainNavigation from '../features/layout/navigation/components/navigation';
import InfoModal from '../features/shared-components/info-modal/components/info-modal-component';
import Prompt from '../features/shared-components/prompt/components/prompt';
import AppLoader from '../features/shared-components/loader/components/loader';
import BlockedPopupModal from '../features/profile/components/blocked-popups-modal';
import InAppMessage from '../features/in-app-messages/components/in-app-message';
import MomentLocalizationHelper from '../core/helpers/moment-localization-helper';
import ForgotPasswordModal from '../features/registration/components/forgot-password-modal';
import { NAVIGATION_ITEMS } from '../features/layout/navigation/constants/navigation-menu';
import IntercomHelper from './helpers/intercom-helper';
import BrowserNotSupported from '../features/browser-not-supported/components/browser-not-supported';
import NotificationBar from '../features/notification-bar/component/notification-bar';
import { WS_COMMANDS, REAL_TIME_CHANNELS } from '../config/constants';
import { MESSAGE_OPTIONS } from '../features/in-app-messages/constants/in-app-messages-constants';
import {
  requestActiveOrdersData,
  handleActiveOrdersReceived,
  handleRequestActiveOrdersDataInProgress,
  handleOrderCancelledRTUpdate,
  handleNewOrderPlacedRTUpdate,
  handleOrderMatchedRTUpdate,
} from '../features/orders/actions/orders-actions';
import TradingHelper from './helpers/trading-helper';

import traderFavicon from '../features/registration/styles/images/logo-short.png';

import '../core/helpers/prototypes';

import 'react-toastify/dist/ReactToastify.css';
import './../libs/bootstrap/css/bootstrap.scss';
import './styles/app.scss';
import './styles/icons.scss';
import './styles/nucleo.scss';

class App extends Component {
  componentDidMount = () => {
    const { subscribeToInAppMessages, loggedIn, getPublicSocketCredentials, userSpecificMQTTTopics, getUserSpecificMQTTTopics, pairs, getPairs } = this.props;

    CacheHelper.saveRegistrationUrlParams();

    if (isEmpty(pairs)) {
      getPairs();
    }

    if (loggedIn) {
      this.initializeRTService();
      this.initializeNAGARTService();

      //get all private mqtt topics
      if (isEmpty(userSpecificMQTTTopics)) {
        getUserSpecificMQTTTopics();
      }
    } else {
      getPublicSocketCredentials();
    }

    subscribeToInAppMessages();

    MediaQueryHelper.addMediaQueryListeners(result => {
      const { handleMediaQueryResult } = this.props;
      handleMediaQueryResult(result);
    });

    if (MediaQueryHelper.isMobileResolution()) {
      IntercomHelper.hideIntercom();
    }
  };

  componentWillUnmount = () => {
    const { unsubscribeFromRTService } = this.props;

    MediaQueryHelper.removeMediaQueryListeners();
    this.handlePrivateUserTopicsSubscribe(unsubscribeFromRTService);
  };

  componentDidUpdate = prevProps => {
    const { t } = this.context;
    const {
      loggedIn,
      userInfo,
      authTokens,
      userSpecificMQTTTopics,
      getUserSpecificMQTTTopics,
      subscribeToRTService,
      isConnected,
      isNAGARTConnected,
      unsubscribeFromRTService,
      getUserExchangePortfolioCache,
      requestActiveOrdersData,
      autoLoginMAUrl,
      autoLoginWindow,
      autoLoginURLTarget,
      handleAutoLoginMAUrlReceived,
      getPairs,
      getPublicSocketCredentials,
      subscriptionsFinished,
      location,
    } = this.props;
    const tokenLoginPath = '/auth/token';

    if (loggedIn && !prevProps.loggedIn) {
      this.initializeIntercom();
      //get all private mqtt topics
      if (isEmpty(userSpecificMQTTTopics)) {
        getUserSpecificMQTTTopics();
      }
      this.initializeNAGARTService();
    }

    if (!loggedIn && prevProps.loggedIn) {
      IntercomHelper.logout();
      this.handlePrivateUserTopicsSubscribe(unsubscribeFromRTService);
      getPairs();
      getPublicSocketCredentials();
    }

    if (userInfo && (!prevProps.userInfo || !MomentLocalizationHelper.isInitialized || prevProps.userInfo.language !== userInfo.language)) {
      MomentLocalizationHelper.setLocale(userInfo.language, t);
    }

    if (!prevProps.autoLoginMAUrl && autoLoginMAUrl) {
      if (!autoLoginURLTarget || autoLoginURLTarget === '_blank') {
        autoLoginWindow.location.href = autoLoginMAUrl;
      } else {
        window.open(autoLoginMAUrl, autoLoginURLTarget);
      }
      handleAutoLoginMAUrlReceived(null, null);
    }

    //after tokens are changes, (re)connect to MQTT
    if (
      (!loggedIn && !isEmpty(prevProps.authTokens) && !isEmpty(authTokens) && authTokens.socketSecret && prevProps.authTokens.socketSecret !== authTokens.socketSecret) ||
      (isEmpty(prevProps.authTokens) && !isEmpty(authTokens) && authTokens.socketSecret && location.pathname !== tokenLoginPath) // prevent connecting to MQTT as guest user if autologin is in progress
    ) {
      this.initializeRTService();
    }

    //once all private topics are available, subscribe to them all and start listening
    if ((isEmpty(prevProps.userSpecificMQTTTopics) && !isEmpty(userSpecificMQTTTopics) && isConnected) || (!isEmpty(userSpecificMQTTTopics) && isConnected && !prevProps.isConnected)) {
      this.handlePrivateUserTopicsSubscribe(topics => {
        subscribeToRTService(topics, true);
      });
      this.bindToOrdersRTUpdates();
      getUserExchangePortfolioCache();
    }

    if (isNAGARTConnected && !prevProps.isNAGARTConnected) {
      this.subscribeAndListenToUserChanges();
    }

    if (!prevProps.subscriptionsFinished && subscriptionsFinished) {
      requestActiveOrdersData();
    }
  };

  initializeIntercom = () => {
    const { userInfo } = this.props;

    if (!isEmpty(userInfo)) {
      IntercomHelper.initialize(userInfo);
    }
  };

  initializeRTService = () => {
    const { authTokens, initializeRealTimeService, disconnectRealTimeService } = this.props;

    //disconnect from expired session
    disconnectRealTimeService();

    initializeRealTimeService(authTokens);
  };

  initializeNAGARTService = () => {
    const { authTokens, initializeNAGARealTimeService } = this.props;

    initializeNAGARealTimeService(authTokens);
  };

  renderRoutes = () => {
    return routes.map((route, index) => <RouteWithSubRoutes key={index} {...route} />);
  };

  handlePrivateUserTopicsSubscribe = callback => {
    const { userSpecificMQTTTopics } = this.props;

    const topics = userSpecificMQTTTopics.map(item => item.topic);

    callback(topics);
  };

  bindToOrdersRTUpdates = () => {
    const { t } = this.context;
    const {
      bindRTListener,
      userInfo,
      handleActiveOrdersReceived,
      handleRequestActiveOrdersDataInProgress,
      handleNewOrderPlacedRTUpdate,
      handleOrderCancelledRTUpdate,
      handleOrderMatchedRTUpdate,
      handleUpdateAssetBalance,
      showBanner,
    } = this.props;

    bindRTListener(`exchange/wallet/${userInfo.id}`, message => {
      const payload = JSON.parse(message);

      handleUpdateAssetBalance(payload);
    });

    bindRTListener(`exchange/fund/${userInfo.id}`, message => {
      const payload = JSON.parse(message);

      showBanner(t('WALLET.TRANSFER_SUCCESSFUL', { asset: payload.asset_symbol, balance: payload.position_free }));
    });

    bindRTListener(`exchange/${userInfo.id}/${WS_COMMANDS.GET_ORDER_BY_PAIR_OR_ALL}`, message => {
      const payload = JSON.parse(message);
      handleActiveOrdersReceived(payload.orders);
      handleRequestActiveOrdersDataInProgress(false);
    });

    bindRTListener(`exchange/${userInfo.id}/${WS_COMMANDS.CANCEL_ORDER_BY_UUID}`, message => {
      const payload = JSON.parse(message);
      const additionalPairData = TradingHelper.getAdditionalPairData(payload.pair_id);
      handleOrderCancelledRTUpdate(payload);
      showBanner(t('ORDERS.ORDER_CANCELED', { value: additionalPairData.pairCompleteName }));
    });

    bindRTListener(`exchange/order/${userInfo.id}/match`, message => {
      const payload = JSON.parse(message);

      handleOrderMatchedRTUpdate({
        pair_id: payload.pair_id,
        order_id: payload.order_id,
        side: payload.side,
        price: payload.price,
        timestamp: payload.timestamp,
        pair_name: payload.pair_name,
        amount: payload.trade_amount,
      });
      showBanner(t('ORDERS.ORDER_MATCHED', { pair: payload.pair_name }));
    });

    bindRTListener(`exchange/order/${userInfo.id}/place`, message => {
      const payload = JSON.parse(message);
      const additionalPairData = TradingHelper.getAdditionalPairData(payload.pair_id);

      handleNewOrderPlacedRTUpdate({
        order_id: payload.order_id,
        pair_id: payload.pair_id,
        timestamp: payload.timestamp,
        price: payload.price,
        initial_amount: payload.initial_amount,
        current_amount: payload.placed_amount,
        pair: payload.pair,
        side: payload.side,
        partial_filled: payload.partial_fill,
        msb: payload.msb,
        lsb: payload.lsb,
      });

      showBanner(t('ORDERS.ORDER_PLACED', { pair: additionalPairData.pairCompleteName }));
    });

    bindRTListener(`exchange/${userInfo.id}/${WS_COMMANDS.PLACE_ORDER}`, message => {
      const payload = JSON.parse(message);

      if (payload.code) {
        showBanner(t(ErrorHelper.handleNotificationErrorCode(payload.code)), null, MESSAGE_OPTIONS.TYPE.ERROR);
      }
    });
  };

  handlePublicDataUpdates = (callback, pairName) => {
    const topics = [`${REAL_TIME_CHANNELS.ORDER_PLACED}/${pairName}`, `${REAL_TIME_CHANNELS.ORDER_CANCELLED}/${pairName}`, `${REAL_TIME_CHANNELS.LEVEL2}/${pairName}`];

    callback(topics);
  };

  subscribeAndListenToUserChanges = () => {
    const { userInfo, handleUserInfoUpdated, handleDisplayUserStatusNotification } = this.props;

    NAGAMQTTService.subscribe(`user_changes/${userInfo.id}`);

    NAGAMQTTService.on(`user_changes/${userInfo.id}`, payload => {
      try {
        const data = JSON.parse(payload);
        const userStatus = userInfo.status === USER_STATUS_TYPE.NEW && data.user_bo_status === USER_STATUS_TYPE.DEMO ? userInfo.status : data.user_bo_status || userInfo.status;

        handleDisplayUserStatusNotification(userStatus);
        handleUserInfoUpdated({ ...userInfo, status: userStatus });
      } catch (err) {
        console.log(err);
      }
    });
  };

  getLayoutClasses = () => {
    let currentRoute;

    each(routes, route => {
      // Stop iteration in case route config is found
      if (this.isCurrentRoute(route)) {
        currentRoute = route;
        return false;
      }

      // Handle subroutes
      if (route.routes) {
        each(route.routes, subroute => {
          if (this.isCurrentRoute(subroute)) {
            currentRoute = route;
            return false;
          }
        });

        if (currentRoute) {
          return false;
        }
      }
    });

    return (currentRoute && currentRoute.layoutClasses) || '';
  };

  isCurrentRoute = route => {
    if (route.path === window.location.pathname) {
      return true;
    }

    // Handle routes with dynamic parameters
    const colonPosition = route.path ? route.path.indexOf(':') : -1;
    if (colonPosition > -1 && window.location.pathname.indexOf(route.path.slice(0, colonPosition - 1)) === 0) {
      return true;
    }

    return false;
  };

  render = () => {
    const layoutClasses = this.getLayoutClasses();

    return (
      <ThemeProvider theme={LightTheme}>
        <div className={`app-layout ${layoutClasses}`}>
          <Favicon url={traderFavicon} />
          <div className="app-layout__header">
            <Header />
            <MainNavigation items={NAVIGATION_ITEMS} />
            <AppLoader />
            <InfoModal />
            <Prompt />
          </div>
          <div className="app-layout__body">
            <NotificationBar />
            <div className="app-content">
              <Switch>{this.renderRoutes()}</Switch>
            </div>
          </div>
          <BlockedPopupModal />
          <ToastContainer autoClose={false} closeOnClick={false} />
          <InAppMessage />
          <ForgotPasswordModal />
          <BrowserNotSupported />
        </div>
      </ThemeProvider>
    );
  };
}

App.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  authTokens: state.currentUser.authTokens,
  loggedIn: state.currentUser.loggedIn,
  isConnected: state.realTimeService.isConnected,
  isNAGARTConnected: state.nagaRealTimeService.isConnected,
  subscriptionsFinished: state.realTimeService.subscriptionsFinished,
  userSpecificMQTTTopics: state.exchange.userSpecificMQTTTopics,
  userInfo: state.currentUser.info,
  pairs: state.exchange.pairs,
  selectedPair: state.exchange.selectedPair,
  autoLoginMAUrl: state.registration.autoLoginMAUrl,
  autoLoginWindow: state.registration.autoLoginWindow,
  autoLoginURLTarget: state.registration.autoLoginURLTarget,
});

const mapDispatchToProps = dispatch => ({
  subscribeToInAppMessages: () => {
    dispatch(subscribeToInAppMessages());
  },
  handleMediaQueryResult: data => {
    dispatch(handleMediaQueryResult(data));
  },
  handleUserInfoUpdated: data => {
    dispatch(handleUserInfoUpdated(data));
  },
  initializeRealTimeService: data => {
    dispatch(initializeRealTimeService(data));
  },
  disconnectRealTimeService: () => {
    dispatch(disconnectRealTimeService());
  },
  initializeNAGARealTimeService: data => {
    dispatch(initializeNAGARealTimeService(data));
  },
  getPublicSocketCredentials: () => {
    dispatch(getPublicSocketCredentials());
  },
  getUserSpecificMQTTTopics: () => {
    dispatch(getUserSpecificMQTTTopics());
  },
  subscribeToRTService: (topics, forceCallback) => {
    dispatch(subscribeToRTService(topics, forceCallback));
  },
  unsubscribeFromRTService: topics => {
    dispatch(unsubscribeFromRTService(topics));
  },
  getUserExchangePortfolioCache: () => {
    dispatch(getUserExchangePortfolioCache());
  },
  handleUpdateAssetBalance: data => {
    dispatch(handleUpdateAssetBalance(data));
  },
  handleDisplayUserStatusNotification: data => {
    dispatch(handleDisplayUserStatusNotification(data));
  },
  bindRTListener: (topic, callback) => {
    dispatch(bindRTListener(topic, callback));
  },
  requestActiveOrdersData: () => {
    dispatch(requestActiveOrdersData());
  },
  handleActiveOrdersReceived: data => {
    dispatch(handleActiveOrdersReceived(data));
  },
  handleRequestActiveOrdersDataInProgress: data => {
    dispatch(handleRequestActiveOrdersDataInProgress(data));
  },
  getPairs: () => {
    dispatch(getPairs());
  },
  handleOrderCancelledRTUpdate: data => {
    dispatch(handleOrderCancelledRTUpdate(data));
  },
  handleNewOrderPlacedRTUpdate: data => {
    dispatch(handleNewOrderPlacedRTUpdate(data));
  },
  handleOrderMatchedRTUpdate: data => {
    dispatch(handleOrderMatchedRTUpdate(data));
  },
  showBanner: (message, title, type) => {
    dispatch(showBanner(message, title, type));
  },
  handleAutoLoginMAUrlReceived: (url, windowInstance) => {
    dispatch(handleAutoLoginMAUrlReceived(url, windowInstance));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
