import { IN_APP_MESSAGES_ACTIONS, MESSAGE_OPTIONS } from '../constants/in-app-messages-constants';

const InAppMessageState = {
  messageObj: {
    message: null,
    title: null,
    type: MESSAGE_OPTIONS.TYPE.SUCCESS,
    additional_data: {
      position: MESSAGE_OPTIONS.SCREEN_POSITION.top,
      displayType: MESSAGE_OPTIONS.DISPLAY_TYPE.BANNER,
      interactiveAccept: null,
      interactiveReject: null,
      interactive: false,
      media: null,
      alignment: MESSAGE_OPTIONS.CONTENT.TEXT_ONLY,
      deep_links: {
        web: null,
      },
      autoClose: false,
    },
  },
  showMessage: false,
};

export function inAppMessage(state = InAppMessageState, action) {
  switch (action.type) {
    case IN_APP_MESSAGES_ACTIONS.HANDLE_MESSAGE_RECEIVED: {
      return { ...state, messageObj: action.message, showMessage: true };
    }
    case IN_APP_MESSAGES_ACTIONS.HANDLE_TOGGLE_IN_APP_MESSAGE_MODAL: {
      return { ...state, showMessage: false };
    }
    default:
      return state;
  }
}
