import StoreInstance from '../../index';

import { PHOTO_PROFILE_URL, PLATFORM, MAIN_LANGUAGES } from '../../config/constants';

class UserHelper {
  static getPhotoUrl(userId, appendTimestamp = false) {
    const state = StoreInstance && StoreInstance.getState();

    if (state && state.currentUser.userProfileImageUrl && state.currentUser.info.id === userId) {
      return state.currentUser.userProfileImageUrl;
    }

    const baseUrl = PHOTO_PROFILE_URL;
    const imgUrl = `${baseUrl}${userId}.jpg${appendTimestamp ? `?${new Date().getTime()}` : ''}`;

    return imgUrl;
  }

  static isNagaMarketsUser = userInfo => {
    return userInfo.isEU && !userInfo.isFlexible;
  };

  static extractAuthTokens = response => {
    const resData = (response && response.data && response.data.data) || {};

    // TODO: Revisit below properties once we get backend support
    const tokens = {
      token: resData.jwt_token,
      refreshToken: resData.refreshToken,
      tokenExpirationDate: resData.expires_at, //UNIX timestamp format, to get Date() multiply it with 1000
      socketSecret: resData.socket_secret,
    };

    return tokens;
  };

  static extractUserInfo = response => {
    const resData = (response && response.data && response.data.data) || {};

    // TODO: Revisit below properties once we get backend support
    const userInfo = {
      id: resData.user_id,
      username: resData.username,
      first_name: resData.first_name,
      status: resData.user_bo_status,
      platform: resData.platform || PLATFORM,
      language: resData.language || MAIN_LANGUAGES.en.CODE,
      country: resData.country,
      mfaEnabled: resData.mfa_enabled,
      isEU: !!resData.is_eu,
      isFlexible: !!resData.is_flexible,
      email: resData.email,
      emailVerified: !!resData.email_verified,
      phoneVerified: !!resData.phone_verified,
      useNGCForFees: resData.is_exchange_fee_in_ngc,
      intercom_hash: resData.intercom_hash,
    };

    return userInfo;
  };
}

export default UserHelper;
