import { combineReducers } from 'redux';
import reducer from 'redux-form/lib/reducer';
import { i18nState } from 'redux-i18n';

import { ROOT_REDUCER_ACTIONS } from '../config/constants';
import { registration } from '../features/registration/reducers/registration-reducer';
import { infoModal } from '../features/shared-components/info-modal/reducers/info-modal-reducers';
import { loader } from '../features/shared-components/loader/reducers/loader-reducer';
import { refreshToken } from '../features/jwt-token-refresh/reducers/refresh-token-reducer';
import { profile } from '../features/profile/reducers/profile-reducer';
import { inAppMessage } from '../features/in-app-messages/reducers/in-app-message-reducers';
import { currentUser } from '../features/current-user/reducers/current-user-reducer';
import { mediaQuery } from '../features/media-query/reducers/media-query-reducer';
import { wallet } from '../features/wallet/reducers/wallet-reducer';
import { exchange } from '../features/exchange/reducers/exchange-reducer';
import { realTimeService } from '../features/real-time-service/reducers/real-time-service-reducers';
import { nagaRealTimeService } from '../features/real-time-service/reducers/naga-real-time-service-reducers';
import { phoneNumberVerify } from '../features/phone-verification/reducers/phone-verification-reducers';
import { userSettings } from '../features/user-settings/reducers/user-settings-reducers';
import { orders } from '../features/orders/reducers/orders-reducers';
import { prompt } from '../features/shared-components/prompt/reducers/prompt-reducers';
import { highlightedNews } from '../features/highlighted-news/reducers/highlighted-news-reducer';
import { referrals } from '../features/refer-a-friend/reducers/refer-a-friend-reducers';

const appReducer = combineReducers({
  i18nState,
  form: reducer,
  registration,
  infoModal,
  loader,
  refreshToken,
  profile,
  inAppMessage,
  currentUser,
  mediaQuery,
  wallet,
  exchange,
  realTimeService,
  nagaRealTimeService,
  phoneNumberVerify,
  userSettings,
  orders,
  prompt,
  highlightedNews,
  referrals,
});

export const rootReducer = (state, action) => {
  switch (action.type) {
    case ROOT_REDUCER_ACTIONS.LOG_OUT:
      state = undefined;
      state = {
        ...state,
        i18nState: i18nState({ lang: localStorage.getItem('lang') || 'en' }, action),
        currentUser: currentUser({ loggedIn: false, showNotificationBar: true, mfa: {} }, action),
      };
      break;
    default:
      break;
  }
  return appReducer(state, action);
};
