import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';

import { handleDisplayForgotPasswordModal } from '../actions/registration-actions';
import GenericModal from '../../shared-components/generic-modal/generic-modal';
import ForgotPassword from './forgot-password';

class ForgotPasswordModal extends Component {
  closeModal = () => {
    const { handleDisplayForgotPasswordModal } = this.props;
    handleDisplayForgotPasswordModal(false);
  };

  render = () => {
    const { showForgotPasswordModal } = this.props;

    return (
      <GenericModal className="forgot-password-modal" show={showForgotPasswordModal} onHide={this.closeModal}>
        <Modal.Body>
          <div className="forgot-password-modal__close-wrapper">
            <i className="icn icn-simple-remove-big-thin" onClick={this.closeModal} />
          </div>
          <ForgotPassword />
        </Modal.Body>
      </GenericModal>
    );
  };
}

const mapStateToProps = state => ({
  showForgotPasswordModal: state.registration.showForgotPasswordModal,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayForgotPasswordModal: data => {
    dispatch(handleDisplayForgotPasswordModal(data));
  },
});

ForgotPasswordModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
