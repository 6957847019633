import React from 'react';
import NumberFormat from 'react-number-format';

import '../styles/amount.scss';

const Amount = props => {
  const { decimals, prefix, suffix, className, colored, noThousandSeparator } = props;
  if (props.value === undefined) {
    return null;
  }

  const isNegative = props.value < 0;
  const decimalScale = decimals || 2;
  const thousandSeparator = noThousandSeparator !== true ? true : undefined;

  const value = parseFloat(props.value).toCustomFixed(decimalScale);

  // Default value should not be colored
  const defaultValue = parseFloat(0).toCustomFixed(decimalScale);
  const isColored = value === defaultValue ? false : colored;

  return (
    <span className={`amount value-${isColored ? (isNegative ? 'negative' : 'positive') : ''} ${className ? className : ''}`}>
      <NumberFormat value={value} suffix={suffix} prefix={prefix} decimalScale={Number(decimalScale)} allowNegative={true} displayType="text" thousandSeparator={thousandSeparator} />
    </span>
  );
};

export default Amount;
