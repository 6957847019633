import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getMFARecoveryCodes } from '../../user-settings/actions/user-settings-actions';
import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';

import { MFA_STEPS } from '../constants/user-settings-constants';
import SecurityMFA from './mfa/security-mfa';
import MFAAuthenticator from './mfa/mfa-authenticator';
import SecurityChangePassword from './password/security-change-password';
import MFAChanged from './mfa/mfa-changed';

import '../styles/user-settings-security.scss';

class UserSettingsSecurity extends Component {
  state = {
    securityStep: MFA_STEPS.INITIAL,
  };

  handlesecurityStepChanged = mode => {
    this.setState({ securityStep: mode });
  };

  render = () => {
    const { username, mfaEnabled, recoveryCodes, getMFARecoveryCodes, toggleInfoModal } = this.props;
    const { securityStep } = this.state;

    if (securityStep !== MFA_STEPS.INITIAL) {
      return (
        <div className="user-settings-security">
          {securityStep === MFA_STEPS.MFA_ENABLED || securityStep === MFA_STEPS.RECOVERY_CODES ? (
            <MFAChanged
              changeMFAStep={this.handlesecurityStepChanged}
              recoveryCodes={recoveryCodes}
              getMFARecoveryCodes={getMFARecoveryCodes}
              toggleInfoModal={toggleInfoModal}
              username={username}
              securityStep={securityStep}
            />
          ) : (
            <MFAAuthenticator changeMFAStep={this.handlesecurityStepChanged} />
          )}
        </div>
      );
    }

    return (
      <div className="user-settings-security">
        <div>
          <SecurityMFA mfaEnabled={mfaEnabled} changeMFAStep={this.handlesecurityStepChanged} />
        </div>
        <div>
          <SecurityChangePassword />
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    username: state.currentUser.info.username,
    mfaEnabled: state.currentUser.info.mfaEnabled,
    recoveryCodes: state.userSettings.mfa.recoveryCodes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMFARecoveryCodes: () => {
      dispatch(getMFARecoveryCodes());
    },
    toggleInfoModal: (message, type) => {
      dispatch(toggleInfoModal(message, type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsSecurity);
