import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';

import { handleShowPhoneVerifyWarningModal, sendVerificationCode } from '../actions/phone-verification-actions';

import GenericModal from '../../shared-components/generic-modal/generic-modal';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';

import smartphone from '../styles/images/smartphone-red.png';
import arrowRight from '../styles/images/arrow-right.png';

import '../styles/phone-verification-warning.scss';

class PhoneVerificationWarningModal extends Component {
  verifyPhone = () => {
    const { sendVerificationCode, phoneNumber } = this.props;

    sendVerificationCode(phoneNumber);
  };

  closeModal = () => {
    const { handleShowPhoneVerifyWarningModal } = this.props;

    handleShowPhoneVerifyWarningModal(false);
  };

  render = () => {
    const { showPhoneVerificationWarningModal, sendVerificationCodeInProgress } = this.props;
    const { t } = this.context;

    return (
      <GenericModal className="phone-verification-warning-modal" show={showPhoneVerificationWarningModal} onHide={this.closeModal}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="phone-verification-warning-modal__body">
            <div className="phone-verification-warning-modal__body__item">
              <div className="phone-verification-warning-modal__body__item__smartphone">
                <img src={smartphone} alt="Smartphone" />
              </div>
            </div>
            <div className="phone-verification-warning-modal__body__item">
              <span className="phone-verification-warning-modal__body__item__title">{t('VERIFICATION.VERIFY_YOUR_PHONE_NUMBER')}</span>
            </div>
            <div className="phone-verification-warning-modal__body__item">
              <span className="phone-verification-warning-modal__body__item__content">{t('VERIFICATION.IN_ORDER_TO_CREATE_ACCOUNT_VERIFY_PHONE_NUMBER')}</span>
            </div>
            <div className="phone-verification-warning-modal__body__item">
              <Button bsStyle="primary" bsSize="large" disabled={sendVerificationCodeInProgress} onClick={this.verifyPhone}>
                {sendVerificationCodeInProgress ? (
                  <MiniLoader whiteLoader />
                ) : (
                  <span>
                    {this.context.t('VERIFICATION.VERIFY_PHONE')}
                    <img src={arrowRight} alt="Verify phone" className="verify-phone__arrow-right" />
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </GenericModal>
    );
  };
}

PhoneVerificationWarningModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showPhoneVerificationWarningModal: state.phoneNumberVerify.showPhoneVerificationWarningModal,
  sendVerificationCodeInProgress: state.phoneNumberVerify.sendVerificationCodeInProgress,
});

const mapDispatchToProps = dispatch => ({
  handleShowPhoneVerifyWarningModal: data => {
    dispatch(handleShowPhoneVerifyWarningModal(data));
  },
  sendVerificationCode: phoneNumber => {
    dispatch(sendVerificationCode(phoneNumber, false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerificationWarningModal);
