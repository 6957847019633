import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import reduxForm from 'redux-form/lib/reduxForm';
import Field from 'redux-form/lib/Field';
import hasSubmitFailed from 'redux-form/lib/hasSubmitFailed';
import { clearSubmitErrors, setSubmitSucceeded } from 'redux-form/lib/actions';
import { handleShowPhoneVerifyModal, sendVerificationCode } from '../actions/phone-verification-actions';

import PhoneVerificationValidator from '../validators/phone-verification-validators';
import GenericModal from '../../shared-components/generic-modal/generic-modal';
import DefaultInput from '../../shared-components/default-input/components/default-input';

import '../styles/phone-verification.scss';

class PhoneVerification extends Component {
  handleFormSubmit = () => {
    const { verifiedCallback } = this.props;

    this.closeModal();

    if (verifiedCallback) {
      verifiedCallback();
    }
  };

  closeModal = () => {
    const { handleShowPhoneVerifyModal, reset } = this.props;
    reset();
    handleShowPhoneVerifyModal(false);
  };

  resendSms = () => {
    const { sendVerificationCode, phoneNumber, reset } = this.props;
    reset();
    sendVerificationCode(phoneNumber);
  };

  handleCodeChange(event) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);

    if (!isNaN(event.target.value)) {
      if (event.target.value.length === 1) {
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    }
  }

  render = () => {
    const { handleSubmit, showPhoneVerificationModal, phoneVerification, phoneNumber, pristine, asyncValidating, submitFailed } = this.props;
    const { t } = this.context;
    const phoneVerified = phoneVerification.isVerified && phoneVerification.phoneNumber === phoneNumber;

    return (
      <GenericModal className="phone-verification-modal" show={showPhoneVerificationModal} onHide={this.closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="hbgm-modal-body">
          <p className="phone-verification-modal__title text-center"> {t('VERIFICATION.ENTER_VERIFICATION_NUMBER')} </p>
          <p className="phone-verification-modal__text">
            {t('VERIFICATION.PLEASE_ENTER_THE_VERIFICATION_CODE')} ({phoneNumber})
          </p>
          <form onSubmit={handleSubmit(this.handleFormSubmit)} noValidate>
            <div className="phone-verification-modal__body">
              <div className="phone-verification-modal__body__item">
                <div className="phone-verification-box">
                  <div className="phone-verification-box__item">
                    <Field
                      className={`phone-verification-box__item__first ${phoneVerified ? 'phone-verified' : ''}`}
                      name="sms_code_1"
                      type="text"
                      maxLength={1}
                      disabled={phoneVerified}
                      component={DefaultInput}
                      onChange={this.handleCodeChange}
                    />
                    <Field
                      className={`phone-verification-box__item__first ${phoneVerified ? 'phone-verified' : ''}`}
                      name="sms_code_2"
                      type="text"
                      maxLength={1}
                      disabled={phoneVerified}
                      component={DefaultInput}
                      onChange={this.handleCodeChange}
                    />
                    <Field
                      className={`phone-verification-box__item__first ${phoneVerified ? 'phone-verified' : ''}`}
                      name="sms_code_3"
                      type="text"
                      maxLength={1}
                      disabled={phoneVerified}
                      component={DefaultInput}
                      onChange={this.handleCodeChange}
                    />
                    <Field
                      className={`phone-verification-box__item__first ${phoneVerified ? 'phone-verified' : ''}`}
                      name="sms_code_4"
                      type="text"
                      maxLength={1}
                      disabled={phoneVerified}
                      component={DefaultInput}
                      onChange={this.handleCodeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {submitFailed ? <p className="text-center phone-verification-modal__error"> {t('VERIFICATION.VERIFICATION_CODE_INVALID')} </p> : ''}
            <div className="phone-verification-modal__footer">
              <Button bsStyle="primary" type="submit" disabled={!!pristine || !!asyncValidating}>
                {this.context.t(phoneVerified ? 'MODAL.CONTINUE' : 'VERIFICATION.VERIFY')}
              </Button>
              <Button bsStyle="link" disabled={!!asyncValidating} onClick={this.resendSms}>
                {this.context.t('VERIFICATION.RESEND_SMS')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </GenericModal>
    );
  };
}

PhoneVerification.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showPhoneVerificationModal: state.phoneNumberVerify.showPhoneVerificationModal,
  phoneVerification: state.phoneNumberVerify.phoneVerification,
  submitFailed: hasSubmitFailed('phoneVerificationForm')(state),
});

const mapDispatchToProps = dispatch => ({
  handleShowPhoneVerifyModal: data => {
    dispatch(handleShowPhoneVerifyModal(data));
  },
  sendVerificationCode: data => {
    dispatch(sendVerificationCode(data));
  },
});

PhoneVerification = reduxForm({
  form: 'phoneVerificationForm',
  validate: PhoneVerificationValidator.phoneVerificationValidator,
  asyncValidate: PhoneVerificationValidator.phoneVerificationAsyncValidator,
  onChange: (values, dispatch, props) => {
    if (props.submitFailed) {
      dispatch(setSubmitSucceeded('phoneVerificationForm'));
      dispatch(clearSubmitErrors('phoneVerificationForm'));
    }
  },
})(PhoneVerification);

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);
