import React, { Component } from 'react';

import HelperFunctions from '../../../core/helpers/helper-functions';
import LoginForm from './login-form';
import HeaderBar from './header-bar';

import '../styles/login.scss';

class Login extends Component {
  componentDidMount = () => {
    HelperFunctions.generateFingerprint();
  };

  render = () => {
    return (
      <div className="login-wrapper">
        <HeaderBar />
        <LoginForm />
      </div>
    );
  };
}

export default Login;
