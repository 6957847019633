export const CURRENT_USER_ACTIONS = {
  HANDLE_LOGIN_SUCCESS: 'handleLoginSuccess',
  HANDLE_LOGOUT_SUCCESS: 'handleLogoutSuccess',
  HANDLE_CURRENT_ACCOUNT_CHANGED: 'handleCurrentTerminalChanged',
  HANDLE_AUTH_TOKENS_UPDATED: 'handleAuthTokensUpdated',
  HANDLE_USER_INFO_UPDATED: 'handleUserInfoUpdated',
  HANDLE_VERIFICATION_EMAIL_RESENT: 'handleVerificationEmailResent',
  HANDLE_PROFILE_PHOTO_UPDATED: 'handleProfilePhotoUpdated',
  HANDLE_NOTIFICATION_BAR_VISIBILITY: 'handleNotificationBarVisibility',
  HANDLE_USER_MFA_ENABLED: 'handleUserMfaEnabled',
  HANDLE_USER_MFA_DISABLED: 'handleUserMfaDisabled',
};
