import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavLink from 'react-router-dom/NavLink';
import withRouter from 'react-router-dom/withRouter';
import Switch from 'react-router-dom/Switch';
import Select from 'react-select';
import reduce from 'lodash/reduce';
import find from 'lodash/find';

import RouteWithSubRoutes from '../../../core/route-with-subroutes';

import '../styles/user-settings.scss';

class UserSettings extends Component {
  state = {
    navigationItems: [
      {
        id: 0,
        path: '/user-settings/info',
        text: 'USER_SETTINGS.PERSONAL_INFO',
        icon: 'icn-personal-settings',
      },
      {
        id: 1,
        path: '/user-settings/security',
        text: 'USER_SETTINGS.PASSWORD_AND_SECURITY',
        icon: 'icn-security-settings',
      },
      // {
      //   id: 2,
      //   path: '/user-settings/payments',
      //   text: 'USER_SETTINGS.PAYMENTS',
      //   icon: 'icn-payment-setttings',
      // },
    ],
    selectedNavigationItem: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location, history } = nextProps;

    const regex = new RegExp('^/user-settings/?$');

    // There's no better way to emulate sub-route preselection
    // on parent route selection, hence code below
    if (location.pathname.toLowerCase().match(regex)) {
      history.push('/user-settings/info');
    }

    return null;
  }

  componentDidMount = () => {
    const { t } = this.context;
    const { location } = this.props;
    const { navigationItems } = this.state;

    const item = find(navigationItems, item => item.path.startsWith(location.pathname));

    if (item) {
      this.setState({
        selectedNavigationItem: {
          value: item.id,
          label: (
            <NavLink to={item.path} key={item.id}>
              <i className={`icn ${item.icon}`} />
              {t(item.text)}
            </NavLink>
          ),
        },
      });
    }
  };

  onNavigationItemChange = selectedNavigationItem => {
    this.setState({ selectedNavigationItem });
  };

  renderMobileNavigation = () => {
    const { t } = this.context;
    const { selectedNavigationItem, navigationItems } = this.state;

    const customStyles = {
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#3179ff' : 'transparent',
        color: state.isSelected ? 'white' : '#3179ff',
      }),
    };

    const options = reduce(
      navigationItems,
      (result, item, index) => {
        result.push({
          value: index,
          label: (
            <NavLink to={item.path} key={index}>
              <i className={`icn ${item.icon}`} />
              {t(item.text)}
            </NavLink>
          ),
        });
        return result;
      },
      []
    );

    return (
      <div className="user-settings__navigation-mobile">
        <Select classNamePrefix="navigation-select" options={options} value={selectedNavigationItem} styles={customStyles} onChange={this.onNavigationItemChange} isSearchable={false} />
      </div>
    );
  };

  render = () => {
    const { t } = this.context;
    const { routes, isMobile } = this.props;
    const { navigationItems } = this.state;

    return (
      <div className="user-settings">
        {isMobile ? (
          this.renderMobileNavigation()
        ) : (
          <div className="user-settings__navigation">
            <div className="user-settings__navigation-title">{t('USER_SETTINGS.SETTINGS')}</div>
            {navigationItems.map((item, index) => (
              <NavLink to={item.path} className={`user-settings__navigation__item`} activeClassName="active" key={index}>
                <i className={`icn ${item.icon}`} />
                <span>{t(item.text)}</span>
              </NavLink>
            ))}
          </div>
        )}
        {/* this is the way to show nested routes of this parent route*/}
        <div className="user-settings__content">
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </div>
      </div>
    );
  };
}

UserSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isMobile: state.mediaQuery.isMobile,
  };
};

export default withRouter(connect(mapStateToProps, null)(UserSettings));
