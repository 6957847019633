import { NAGA_REAL_TIME_SERVICE_ACTIONS } from '../constants/naga-real-time-service-constants';

import NAGAMQTTService from '../../../core/helpers/naga-mqtt-service';

export const initializeNAGARealTimeService = authTokens => {
  return dispatch => {
    const callbacks = {
      connected: data => dispatch(handleIsNAGARealTimeConnected(data)),
    };

    NAGAMQTTService.connect(authTokens, callbacks);
  };
};

export const disconnectNAGARealTimeService = () => {
  return dispatch => {
    NAGAMQTTService.unsubscribeAll();
    NAGAMQTTService.disconnect(data => dispatch(handleIsNAGARealTimeConnected(data)));
  };
};

export const handleIsNAGARealTimeConnected = data => ({
  type: NAGA_REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_NAGA_RT_CONNECTED,
  data,
});
