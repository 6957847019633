import React, { PureComponent } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';

import { subscribeToRTService, bindRTListener, offRTCallback } from '../../real-time-service/actions/real-time-service-actions';

import { REAL_TIME_CHANNELS } from '../../../config/constants';
import TradingHelper from '../../../core/helpers/trading-helper';
import HelperFunctions from '../../../core/helpers/helper-functions';
import Amount from '../../shared-components/amount/components/amount';

class MarketsCoinItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      displayName: props.displayName,
      currentPrice: props.currentPrice,
      openPrice: props.openPrice,
      decimals: props.decimals,
      colorClass: '',
    };

    this.willUnmount = false;
    this.componentId = null;
  }

  componentDidMount = () => {
    const { isRTAvailable, isRTConnected } = this.props;

    if (isRTAvailable) {
      this.listenForChange();
    }

    if (isRTConnected) {
      this.subscribeToChange();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { isRTConnected } = this.props;
    const { currentPrice } = this.state;

    if (!prevProps.isRTConnected && isRTConnected) {
      this.subscribeToChange();
    }

    if (parseFloat(prevState.currentPrice) < parseFloat(currentPrice)) {
      this.setState({ colorClass: 'animate-color-green' });
    } else if (parseFloat(prevState.currentPrice) > parseFloat(currentPrice)) {
      this.setState({ colorClass: 'animate-color-red' });
    }
  };

  componentWillUnmount = () => {
    const { offRTCallback } = this.props;
    const { name } = this.state;

    this.willUnmount = true;

    offRTCallback(`${REAL_TIME_CHANNELS.TICKER}/${name}`, this.componentId);
  };

  subscribeToChange = () => {
    const { subscribeToRTService } = this.props;
    const { name } = this.state;

    subscribeToRTService(`${REAL_TIME_CHANNELS.TICKER}/${name}`);
  };

  listenForChange = () => {
    const { bindRTListener } = this.props;
    const { name } = this.state;

    this.componentId = HelperFunctions.generateComponentId(name);

    bindRTListener(
      `${REAL_TIME_CHANNELS.TICKER}/${name}`,
      payload => {
        const data = JSON.parse(payload.toString());
        if (!this.willUnmount) {
          this.setState({ currentPrice: data.close_price, openPrice: data.open_price });
        }
      },
      this.componentId
    );
  };

  handleSymbolSelected = () => {
    const { name, history, onSymbolSelectCallback } = this.props;

    if (onSymbolSelectCallback) {
      onSymbolSelectCallback();
    }

    history.push(`/exchange/${name}`);
  };

  render = () => {
    const { selectedPair } = this.props;
    const { name, displayName, currentPrice, openPrice, decimals, colorClass } = this.state;

    if (!name) {
      return null;
    }

    const isSelected = name === selectedPair;

    return (
      <div className={`markets__table__item${isSelected ? ' selected' : ''}`} onClick={this.handleSymbolSelected}>
        <div className="markets__table__item-name">{displayName}</div>
        <div className="markets__table__item-price">
          <Amount value={currentPrice} decimals={decimals} className={colorClass} />
        </div>
        <div className="markets__table__item-change">
          <Amount value={TradingHelper.calculateSymbolTrend(+currentPrice, +openPrice)} colored suffix="%" />
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  isRTAvailable: state.realTimeService.isAvailable,
  isRTConnected: state.realTimeService.isConnected,
});

const mapDispatchToProps = dispatch => ({
  bindRTListener: (topic, callback, componentId) => {
    dispatch(bindRTListener(topic, callback, componentId));
  },
  subscribeToRTService: topic => {
    dispatch(subscribeToRTService(topic));
  },
  offRTCallback: (topic, componentId) => {
    dispatch(offRTCallback(topic, componentId));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketsCoinItem));
