import { LOADER_ACTIONS } from '../constants/loader-constants';

const loaderInitialState = {
  isFetching: false,
  force: false,
};

export const loader = (state = loaderInitialState, payload) => {
  switch (payload.type) {
    case LOADER_ACTIONS.HANDLE_START_FETCHING:
      return { ...state, isFetching: true };
    case LOADER_ACTIONS.HANDLE_END_FETCHING:
      return { ...state, isFetching: false };
    case LOADER_ACTIONS.FORCE_FETCHING:
      return { ...state, isFetching: true, force: true };
    case LOADER_ACTIONS.STOP_FORCE_FETCHING:
      return { ...state, isFetching: false, force: false };
    default:
      return state;
  }
};
