import find from 'lodash/find';
import some from 'lodash/some';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import NavLink from 'react-router-dom/NavLink';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import { StatusCircle } from '../../../shared-components/status-circle/components/status-circle';
import { CONNECTION_STATUS } from '../../../../config/constants';
import { handleDisplayAppPrompt } from '../../../shared-components/prompt/actions/prompt-actions';

import '../styles/main-navigation.scss';

class MainNavigation extends Component {
  state = {
    btnTitle: 'NAV.BALANCE',
    btnTitleValue: 'balance',
    openMenu: false,
    mobileMenuInstance: null,
    showOpenMenuPopover: true,
  };

  componentDidUpdate = prevState => {
    if (!prevState.mobileMenuInstance && this.state.mobileMenuInstance) {
      window.scrollTo(0, 0);
    }
  };

  renderNavigationItems = () => {
    const { items } = this.props;
    const { t } = this.context;

    const _navigationItems = items.map((item, index) => {
      if (item.hidden) {
        return null;
      }
      return (
        <NavLink to={item.path} className={`main-navigation__item`} activeClassName="active" key={index}>
          <div>
            <span className="nav-submenu__text">{t(item.text)}</span>
            {item.customComponent}
          </div>
        </NavLink>
      );
    });

    return _navigationItems;
  };

  getCurrentPageTitle = () => {
    const { location, items } = this.props;
    const { t } = this.context;

    const navItem = find(items, item => location.pathname.indexOf(item.path) > -1);
    if (navItem) return t(navItem.text);
    return '';
  };

  onMenuOpenClick = () => {
    this.setState((prevSate, props) => {
      return { openMenu: !prevSate.openMenu };
    });
  };

  onMenuItemSelected = () => {
    this.setState({ openMenu: false });
  };

  onToggleDropDownMenu = (open, item) => {
    this.setState((prevSate, props) => {
      return { openMenu: open, showOpenMenuPopover: !prevSate.showOpenMenuPopover };
    });
  };

  renderAdditionalNavigationFields = () => {
    const { t } = this.context;
    const { isRTConnected } = this.props;

    return (
      <div className="main-navigation__additional-fields">{t('NAV.CONNECTION_STATUS', { value: <StatusCircle status={isRTConnected ? CONNECTION_STATUS.ONLINE : CONNECTION_STATUS.OFFLINE} /> })}</div>
    );
  };

  renderMobileNav = () => {
    const { t } = this.context;
    const { items, location } = this.props;

    if (!some(items, item => location.pathname.indexOf(item.path) > -1)) {
      return <NavLink to="/exchange">{t('NAV.GO_HOME')}</NavLink>;
    }
    return (
      <div>
        <DropdownButton
          ref={r => {
            if (!this.state.mobileMenuInstance) {
              this.setState({ mobileMenuInstance: r });
            }
          }}
          bsStyle="default"
          title={this.getCurrentPageTitle()}
          id={`dropdown-basic-1`}
          open={this.state.openMenu}
          onToggle={this.onToggleDropDownMenu}
        >
          {items.map((item, index) => {
            if (item.hidden) {
              return null;
            }
            return (
              <li key={index} role="presentation" onClick={this.onMenuItemSelected}>
                <NavLink role="menuitem" to={item.path} activeClassName="active">
                  <div>
                    <span className="nav-submenu__text"> {t(item.text)} </span>
                    {item.customComponent}
                  </div>
                </NavLink>
              </li>
            );
          })}
        </DropdownButton>
      </div>
    );
  };

  render = () => {
    const { className, isSecondary, isMobile } = this.props;
    return (
      <div className={`main-navigation ${className}`}>
        {isMobile ? (
          <div className="main-navigation__items">{!isSecondary ? this.renderMobileNav() : this.renderNavigationItems()}</div>
        ) : (
          <div className="main-navigation__items">{this.renderNavigationItems()}</div>
        )}
        {!isSecondary && this.renderAdditionalNavigationFields()}
      </div>
    );
  };
}

MainNavigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: state.currentUser.loggedIn,
  isMobile: state.mediaQuery.isMobile,
  isRTConnected: state.realTimeService.isConnected,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayAppPrompt: data => {
    dispatch(handleDisplayAppPrompt(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNavigation));
