import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';

import RenderIf from '../../shared-components/render-if/components/render-if';
import { MESSAGE_OPTIONS } from '../constants/in-app-messages-constants';

import '../styles/in-app-message-banner.scss';

const InAppMessageBanner = (props, context) => {
  const { t } = context;

  const closeBanner = () => {
    props.closeToast();
  };

  const performAction = () => {
    let webUrl = props.additional_data.deep_links.web;

    closeBanner();

    if (webUrl.indexOf(MESSAGE_OPTIONS.URL_TOKEN) !== -1) {
      webUrl = MESSAGE_OPTIONS.DEEP_LINKS[webUrl.slice(MESSAGE_OPTIONS.URL_TOKEN.length)];
      props.history.push(webUrl);
    } else {
      window.open(webUrl, '_blank');
    }
  };

  const renderActions = () => {
    const { interactiveAccept, interactiveReject, deep_links } = props.additional_data;
    return (
      <div className={`banner__actions ${!interactiveAccept || !deep_links.web ? 'banner__actions--single' : ''}`}>
        <RenderIf if={interactiveReject}>
          <Button className="banner__actions--reject" onClick={closeBanner}>
            {interactiveReject}
          </Button>
        </RenderIf>
        <RenderIf if={interactiveAccept && deep_links.web}>
          <Button bsStyle="primary" className="banner__actions--accept" onClick={performAction}>
            {interactiveAccept}
          </Button>
        </RenderIf>
      </div>
    );
  };

  const renderThumbnail = () => {
    const { alignment, media } = props.additional_data;
    return (
      <RenderIf if={alignment && alignment !== MESSAGE_OPTIONS.CONTENT.TEXT_ONLY}>
        <div className="banner__thumbnail">
          <div
            className="banner__thumbnail-image"
            style={{
              backgroundImage: `url(${media})`,
            }}
          />
        </div>
      </RenderIf>
    );
  };

  return (
    <div className="banner">
      <div className={`banner__content ${props.additional_data.alignment === MESSAGE_OPTIONS.CONTENT.THUMBNAIL_LEFT ? 'banner__content--reverse' : ''}`}>
        <div className="banner__content-wrapper">
          <i className={`icn banner__icon ${props.type}`} />
          <div>
            <div className="banner__title">{props.title ? t(props.title) : ''}</div>
            <div className="banner__content">{props.message ? t(props.message) : ''}</div>
          </div>
        </div>
        {props.additional_data.media && props.additional_data.mediaType === MESSAGE_OPTIONS.MEDIA_TYPE.image ? renderThumbnail() : null}
      </div>
      {props.additional_data.interactive ? renderActions() : null}
    </div>
  );
};

InAppMessageBanner.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(InAppMessageBanner);
