import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';
import Select, { components } from 'react-select';
import isEmpty from 'lodash/isEmpty';

import { register, facebookRegister, googleRegister, getCountries, detectCountry, handleUserInfoReceived } from '../actions/registration-actions';

import { EXCHANGE_TERMS_URL, EXCHANGE_PRIVACY_URL, PLATFORM, USERNAME_CRITERIA, EMAIL_CRITERIA, PASSWORD_CRITERIA, FIRST_LAST_NAME_CRITERIA } from '../../../config/constants';
import { REGISTRATION_TYPES } from '../constants/registration-constants';
import HelperFunctions from '../../../core/helpers/helper-functions';
import CacheHelper from '../../../core/helpers/cache-helper';
import RegistrationHelper from '../helpers/registration-helper';
import CookiesWrapper from '../../../core/helpers/cookies-wrapper';
import { requiredField } from '../../shared-components/helpers/helpers';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import PasswordInput from '../../shared-components/password-input/components/password-input';

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerType: null,
      countryAutoselected: false,
      userAutoFilled: false,
      countries: [],
      country: null,
      dialingCodes: [],
      dialingCode: null,
      phoneCodeExpanded: false,
    };

    this.phoneCodeRef = React.createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    const { history, loggedIn, registerUserInfo, countries, detectedCountry } = props;

    if (loggedIn) {
      history.push('/exchange');
    }

    if (!state.userAutoFilled && registerUserInfo) {
      props.change('user_name', registerUserInfo.username);
      props.change('email', registerUserInfo.email);
      return { userAutoFilled: true };
    }

    if (!state.countryAutoselected && countries && countries.length && detectedCountry) {
      props.change('country', detectedCountry.code);
      props.change('code', detectedCountry.phone_code);

      return {
        country: RegistrationHelper.getCountryOption(detectedCountry),
        dialingCode: RegistrationHelper.getCodeOption(detectedCountry),
        countryAutoselected: true,
      };
    }

    return null;
  };

  componentWillUnmount = () => {
    const { handleUserInfoReceived, reset } = this.props;

    this.setState({ registerType: null });
    handleUserInfoReceived(null);
    reset();
  };

  componentDidMount = () => {
    const { countries, detectedCountry, getCountries, detectCountry } = this.props;

    if (!countries || countries.length === 0) {
      getCountries();
    }

    if (!detectedCountry) {
      detectCountry();
    }
  };

  componentDidUpdate = prevProps => {
    const { countries } = this.props;
    const { country } = this.state;

    if (!prevProps.countries.length && countries.length) {
      this.setState({ countries: countries.map(country => RegistrationHelper.getCountryOption(country)), dialingCodes: countries.map(country => RegistrationHelper.getCodeOption(country)) });

      if (!country) {
        const savedCountryCode = CookiesWrapper.getCookie('CC');
        const savedCountry = countries.find(country => country.code === savedCountryCode);
        this.handleCountryChange(RegistrationHelper.getCountryOption(savedCountry));
      }
    }
  };

  sendToLogin = () => {
    const { history } = this.props;
    history.push('/login');
  };

  registerWithFacebook = () => {
    const { facebookRegister } = this.props;

    this.setState({ registerType: REGISTRATION_TYPES.FACEBOOK });
    facebookRegister();
  };

  registerWithGoogle = () => {
    const { googleRegister } = this.props;

    this.setState({ registerType: REGISTRATION_TYPES.GOOGLE });
    googleRegister();
  };

  handleFormSubmit = values => {
    const { register, lang } = this.props;
    const { country } = this.state;
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.user_name,
      email: values.email,
      password: HelperFunctions.encodePassword(values.password),
      country: values.country,
      phone_number: `${values.code}${values.phone}`,
      language: lang,
      is_flexible: !isEmpty(country) && country.isEU,
      p_invited_by_code: CacheHelper.getRegistrationUrlParamsByName('p_invited_by_code'),
      p_tracking_code: CacheHelper.getRegistrationUrlParamsByName('p_tracking_code'),
      p_etrass_click_id: CacheHelper.getRegistrationUrlParamsByName('p_etrass_click_id'),
      utm_source: CacheHelper.getRegistrationUrlParamsByName('utm_source'),
      utm_campaign: CacheHelper.getRegistrationUrlParamsByName('utm_campaign'),
      utm_medium: CacheHelper.getRegistrationUrlParamsByName('utm_medium'),
      utm_content: CacheHelper.getRegistrationUrlParamsByName('utm_content'),
      utm_keyword: CacheHelper.getRegistrationUrlParamsByName('utm_keyword'),
      utm_term: CacheHelper.getRegistrationUrlParamsByName('utm_term'),
      aff: CacheHelper.getRegistrationUrlParamsByName('aff'),
      cid: CacheHelper.getRegistrationUrlParamsByName('cid'),
      pid: CacheHelper.getRegistrationUrlParamsByName('pid'),
    };

    const reasonToRegister = CacheHelper.getRegistrationUrlParamsByName('reason');
    const affiliate = CacheHelper.getRegistrationUrlParamsByName('affiliate');
    const reasonFromEtrass = CacheHelper.getRegistrationUrlParamsByName('clickid') ? CacheHelper.getRegistrationUrlParamsByName('aff') : null;

    data.webinar = reasonFromEtrass || reasonToRegister || affiliate || PLATFORM;

    register(data);
  };

  handleCountryChange = country => {
    if (country && country.code) {
      this.props.change('country', country.code);
      this.props.change('code', country.phoneCode);
      this.setState({ country, dialingCode: country });
    }
  };

  handlePhoneCodeChange = country => {
    if (country && country.phoneCode) {
      this.props.change('code', country.phoneCode);
      this.setState({ dialingCode: country }, () => {
        // Focus second part of the phone input after selecting phone code
        this.phoneCodeRef.current.getElementsByTagName('input')[1].focus();
      });
    }
  };

  handleFilterOption = (option, filter) => {
    const re = new RegExp(HelperFunctions.escapeRegExp(filter), 'gi');
    return (option.data.phoneCode && option.data.phoneCode.match(re)) || (option.data.name && option.data.name.match(re));
  };

  openedPhoneCodeMenu = () => {
    this.setState({ phoneCodeExpanded: true });
  };

  closedPhoneCodeMenu = () => {
    this.setState({ phoneCodeExpanded: false });
  };

  renderCountryField = ({ label, meta: { touched, error } }) => {
    const { t } = this.context;
    const { countries } = this.state;
    const hasError = touched && error && !this.state.country.value;

    return (
      <div className="registration-form__country-wrapper">
        {label && <label>{t(label)}</label>}
        <div className="select-and-error">
          <Select
            className={`registration-form__select ${hasError ? 'input-error' : ''}`}
            classNamePrefix="country-select"
            onChange={this.handleCountryChange}
            filterOption={this.handleFilterOption}
            options={countries}
            value={this.state.country}
            placeholder=""
            autocomplete="off"
            clearable={false}
          />
          {hasError && <div className="error">{t('REGISTRATION.COUNTRY_IS_REQUIRED')}</div>}
        </div>
      </div>
    );
  };

  renderPhoneCodeField = () => {
    const { dialingCodes, phoneCodeExpanded } = this.state;

    return (
      <Select
        className={`registration-form__select registration-form__phone-code-wrapper${phoneCodeExpanded ? ' is-open' : ''}`}
        classNamePrefix="phone-select"
        onChange={this.handlePhoneCodeChange}
        onMenuClose={this.closedPhoneCodeMenu}
        onMenuOpen={this.openedPhoneCodeMenu}
        options={dialingCodes}
        filterOption={this.handleFilterOption}
        value={this.state.dialingCode}
        placeholder=""
        components={{ SingleValue: SingleValue }}
        autocomplete="off"
        clearable={false}
      />
    );
  };

  renderPhoneField = () => {
    const { isDisabled } = this.props;
    const { countries } = this.state;
    const phoneCodeSelected = this.state.dialingCode && this.state.dialingCode.phoneCode;
    const validationError = phoneCodeSelected ? null : 'REGISTRATION.PHONE_NUMBER_IS_REQUIRED';

    return (
      <div ref={this.phoneCodeRef} className="registration-form__phone-wrapper">
        <Field disabled={isDisabled} name="code" type="text" component={this.renderPhoneCodeField} props={{ countries, selectedValue: this.state.dialingCode }} />
        <Field id="sejo-boj" disabled={isDisabled} className="phone-number" name="phone" type="text" placeHolder="REGISTRATION.PHONE_NUMBER" customError={validationError} component={DefaultInput} />
      </div>
    );
  };

  render = () => {
    const { handleSubmit, registerInProgress } = this.props;
    const { countries, country } = this.state;
    const { t } = this.context;

    return (
      <div className="registration-form">
        <div className="registration-form__wrapper">
          <div className="registration-form__header">
            <h2 className="registration-form__title">{t('REGISTRATION.CREATE_ACCOUNT')}</h2>
          </div>
          <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} className="registration-form__body" noValidate>
            <div className="registration-form__name">
              <Field disabled={registerInProgress} name="first_name" type="text" placeHolder="REGISTRATION.FIRST_NAME" component={DefaultInput} />
              <Field disabled={registerInProgress} name="last_name" type="text" placeHolder="REGISTRATION.LAST_NAME" component={DefaultInput} />
            </div>
            <Field disabled={registerInProgress} name="user_name" type="text" placeHolder="REGISTRATION.USERNAME" component={DefaultInput} />
            <Field disabled={registerInProgress} name="email" type="text" placeHolder="REGISTRATION.EMAIL" component={DefaultInput} />
            <Field disabled={registerInProgress} name="password" placeHolder="REGISTRATION.PASSWORD" component={PasswordInput} />
            <Field disabled={registerInProgress} name="country" type="select" component={this.renderCountryField} props={{ countries, selectedValue: country }} />
            {this.renderPhoneField()}
            <Button disabled={registerInProgress} type="submit" bsStyle="primary" className="registration-form__sign-up">
              {registerInProgress ? <MiniLoader whiteLoader /> : t('REGISTRATION.SIGN_UP')}
            </Button>
            <div className="registration-form__terms-and-conditions">
              {t('REGISTRATION.AGREE_TO_TERMS_AND_PRIVACY', {
                terms: (
                  <a href={EXCHANGE_TERMS_URL} target="blank" className="btn-link">
                    {t('REGISTRATION.TERMS_AND_CONDITIONS')}
                  </a>
                ),
                privacy: (
                  <a href={EXCHANGE_PRIVACY_URL} target="blank" className="btn-link">
                    {t('REGISTRATION.PRIVACY_POLICY')}
                  </a>
                ),
              })}
            </div>
          </form>
          <div className="registration-form__footer">
            <span>{t('REGISTRATION.ALERADY_HAVE_AN_ACCOUNT')}</span>
            <Button bsStyle="link" disabled={registerInProgress} onClick={this.sendToLogin}>
              {t('REGISTRATION.SIGN_IN')}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

RegisterForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const registerFormValidate = values => {
  const errors = {};

  requiredField(values, errors, 'first_name', 'REGISTRATION.FIRST_NAME_IS_REQUIRED');
  requiredField(values, errors, 'last_name', 'REGISTRATION.LAST_NAME_IS_REQUIRED');
  requiredField(values, errors, 'user_name', 'REGISTRATION.USERNAME_IS_REQUIRED');
  requiredField(values, errors, 'email', 'REGISTRATION.EMAIL_IS_REQUIRED');
  requiredField(values, errors, 'password', 'REGISTRATION.PASSWORD_IS_REQUIRED');
  requiredField(values, errors, 'country', 'REGISTRATION.COUNTRY_IS_REQUIRED');
  requiredField(values, errors, 'phone', 'REGISTRATION.PHONE_NUMBER_IS_REQUIRED');

  if (values.first_name && !FIRST_LAST_NAME_CRITERIA.test(values.first_name)) {
    errors.first_name = 'REGISTRATION.INVALID_ENGLISH_FORMAT';
  }

  if (values.last_name && !FIRST_LAST_NAME_CRITERIA.test(values.last_name)) {
    errors.last_name = 'REGISTRATION.INVALID_ENGLISH_FORMAT';
  }

  if (values.user_name) {
    if (values.user_name.length < 6) {
      errors.user_name = 'REGISTRATION.PLEASE_ENTER_6_OR_MORE_CHARACTERS';
    } else if (!USERNAME_CRITERIA.test(values.user_name)) {
      errors.user_name = 'REGISTRATION.ONLY_ENGLISH_LETTERS_NUMBERS_DASHES';
    }
  }

  if (values.email && !EMAIL_CRITERIA.test(values.email)) {
    errors.email = 'REGISTRATION.ENTERED_EMAIL_NOT_VALID';
  }

  if (values.password && !PASSWORD_CRITERIA.test(values.password)) {
    errors.password = 'REGISTRATION.PASSWORD_CRITERIA_NOT_MET';
  }

  if (values.phone) {
    if (isNaN(values.phone)) {
      errors.phone = 'REGISTRATION.ONLY_DIGITS_ARE_ALLOWED';
    } else if (!values.code) {
      errors.phone = 'REGISTRATION.PHONE_NUMBER_IS_REQUIRED';
    }
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    loggedIn: state.currentUser.loggedIn,
    countries: state.registration.countries,
    registerInProgress: state.registration.registerInProgress,
    registerUserInfo: state.registration.userInfo,
    detectedCountry: state.registration.detectedCountry,
    lang: state.i18nState.lang,
  };
};

const mapDispatchToProps = dispatch => ({
  register: data => {
    dispatch(register(data));
  },
  facebookRegister: () => {
    dispatch(facebookRegister());
  },
  googleRegister: () => {
    dispatch(googleRegister());
  },
  handleUserInfoReceived: data => {
    dispatch(handleUserInfoReceived(data));
  },
  getCountries: () => {
    dispatch(getCountries());
  },
  detectCountry: () => {
    dispatch(detectCountry());
  },
});

RegisterForm = connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

export default withRouter(
  reduxForm({
    form: 'registerForm',
    validate: registerFormValidate,
  })(RegisterForm)
);

const SingleValue = ({ children, ...props }) => {
  return <components.SingleValue {...props}>{props.data.phoneCode}</components.SingleValue>;
};
