import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';

import { USER_STATUS_TYPE, WEB_TRADER_AUTOLOGIN_PAGES } from '../../../config/constants';
import IntercomHelper from '../../../core/helpers/intercom-helper';

import '../styles/user-settings-trading-info.scss';

const UserSettingsTradingInfo = (props, context) => {
  const { t } = context;
  const { emailConfirmed, userStatus, platform, language, country, getAutoLoginUrl } = props;

  const statusEnabled = [<i className="icn icn-circle-check" key={1} />, <div key={2}>{t('USER_SETTINGS.ENABLED')}</div>];

  const upgradeAccount = () => {
    getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE, t: platform, lang: language, country: country, target: '_self' });
  };

  const verifyAccount = () => {
    getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.VERIFY_ACCOUNT, t: platform });
  };

  const showSupport = () => {
    IntercomHelper.openIntercom();
  };

  const getWithdrawalStatus = () => {
    switch (userStatus) {
      case USER_STATUS_TYPE.APPROVED:
        return statusEnabled;
      case USER_STATUS_TYPE.WALLET_ONLY:
      case USER_STATUS_TYPE.DEMO:
        return (
          <Button bsStyle="primary" bsSize="small" onClick={upgradeAccount}>
            {t('USER_SETTINGS.UPGRADE_ACCOUNT')}
          </Button>
        );
      case USER_STATUS_TYPE.PENDING:
      case USER_STATUS_TYPE.WAITING_CY:
        return t('USER_SETTINGS.IN_REVIEW');
      case USER_STATUS_TYPE.NEW:
        return (
          <Button bsStyle="primary" bsSize="small" onClick={verifyAccount}>
            {t('USER_SETTINGS.START_VERIFICATION')}
          </Button>
        );
      case USER_STATUS_TYPE.REVIEWED:
      case USER_STATUS_TYPE.ARCHIVED:
      case USER_STATUS_TYPE.READ_ONLY:
        return (
          <Button bsStyle="primary" bsSize="small" onClick={showSupport}>
            {t('USER_SETTINGS.CONTACT_SUPPORT')}
          </Button>
        );
      default:
        return null;
    }
  };

  const withdrawalStatus = getWithdrawalStatus();

  const tradingItems = [
    {
      title: 'USER_SETTINGS.EXCHANGE_ACCOUNT',
      description: 'USER_SETTINGS.LOGIN_TO_EXCHANGE',
      status: statusEnabled,
    },
    {
      title: 'USER_SETTINGS.DEPOSIT_WITHDRAWAL_OF_CRYPTO_ASSETS',
      description: 'USER_SETTINGS.TRANSFER_ASSETS_TO_AND_FROM_EXCHANGE',
      status: emailConfirmed ? statusEnabled : [<i className="icn icn-alert" key={1} />, <div key={2}>{t('USER_SETTINGS.CONFIRM_YOUR_EMAIL')}</div>],
    },
    {
      title: 'USER_SETTINGS.WITHDRAWALS_OF_ASSETS_ABOVE_2_BTC',
      description: 'USER_SETTINGS.ENABLE_WITHDRAW_ABOVE_2_BTC',
      status: withdrawalStatus,
    },
    {
      title: 'USER_SETTINGS.CREATION_OF_EUR_USD_WALLETS',
      description: ['USER_SETTINGS.DEPOSIT_WIDTRAWALS_TO_EUR_USD_WALLETS', 'USER_SETTINGS.TRANSFER_OF_EUR_USD_TO_FROM_WALLET'],
      status: withdrawalStatus,
    },
  ];

  return (
    <div className="user-settings-trading-info">
      <div>
        <div>{t('USER_SETTINGS.LEVEL')}</div>
        <div>{t('USER_SETTINGS.FEATURES')}</div>
        <div>{t('USER_SETTINGS.STATUS_ACTION')}</div>
      </div>
      {tradingItems.map((item, index) => (
        <div key={index}>
          <div>
            <div className="hidden-xs">{index}</div>
            <div className="visible-xs">
              <span>{t('USER_SETTINGS.LEVEL_NO', { n: index })}</span>
            </div>
          </div>
          <div>
            <div>{t(item.title)}</div>
            {typeof item.description === 'string' ? <div>{t(item.description)}</div> : item.description.map((description, index) => <div key={index}>{t(description)}</div>)}
          </div>
          <div>{item.status}</div>
        </div>
      ))}
    </div>
  );
};

UserSettingsTradingInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(UserSettingsTradingInfo);
