import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Button from 'react-bootstrap/lib/Button';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { placeOrder } from '../../actions/exchange-actions';
import { handleDisplayAppPrompt } from '../../../shared-components/prompt/actions/prompt-actions';
import { getAutoLoginUrl } from '../../../registration/actions/registration-actions';

import { ENTRY_VALUE_TYPE, ORDER_TYPE } from '../../constants/exchange-constants';
import { ORDER_SIDE, USER_STATUS_TYPE, WEB_TRADER_AUTOLOGIN_PAGES } from '../../../../config/constants';
import TradingHelper from '../../../../core/helpers/trading-helper';
import Amount from '../../../shared-components/amount/components/amount';
import Symbol from '../../../shared-components/symbol/symbol';
import RenderIf from '../../../shared-components/render-if/components/render-if';

import InvestmentSection from './investment-section';
import BuySellButton from './buy-sell-button';
import EnterValue from './enter-value';

import '../../styles/open-order.scss';

class OpenOrder extends Component {
  constructor(props) {
    super(props);

    this.wallets = null;
    this.state = {
      isLimitOrder: true,
      originalSize: 0,
      originalPrice: 0,
      size: 0,
      price: 0,
      formValidated: true,
    };
  }

  componentDidMount = () => {
    const { selectedOrder, selectedPair } = this.props;

    //if no order from order book is selected, take current price as default
    if (!!+selectedPair.currentPrice && !+selectedOrder.price) {
      this.setCurrentPriceFromSelectedPair();
    } else if (!!+selectedOrder.price || !!+selectedOrder.amount) {
      this.setCurrentPriceFromSelectedOrder();
    } else {
      this.setDefaultPriceForPairAsCurrentPrice();
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedOrder, selectedPair } = this.props;
    //if no order from order book is selected, take current price as default
    if (prevProps.selectedPair.currentPrice !== selectedPair.currentPrice && !!+selectedPair.currentPrice && !+selectedOrder.price) {
      this.setCurrentPriceFromSelectedPair();
    } else if (prevProps.selectedOrder.price !== selectedOrder.price || prevProps.selectedOrder.amount !== selectedOrder.amount) {
      this.setCurrentPriceFromSelectedOrder();
      //if currenPrice is 0 and no order from order book is selected, take default price for pair
    } else if (prevProps.selectedPair.pair_id !== selectedPair.pair_id || (isEmpty(prevProps.selectedPair.name) && !isEmpty(selectedPair.name))) {
      this.setDefaultPriceForPairAsCurrentPrice();
    }
  };

  setCurrentPriceFromSelectedPair = () => {
    const { selectedPair } = this.props;

    const formattedCurrentPrice = TradingHelper.formatCurrency(
      +selectedPair.currentPrice,
      +selectedPair.min_price,
      +selectedPair.max_price,
      +selectedPair.price_step,
      selectedPair.quote_currency_decimals
    );
    const formattedAsk = TradingHelper.formatCurrency(+selectedPair.ask, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);
    const formattedBid = TradingHelper.formatCurrency(+selectedPair.bid, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);

    this.setState({
      price: formattedCurrentPrice.formatedValue,
      originalPrice: formattedCurrentPrice.value,
      size: selectedPair.min_lot_size.toCustomFixed(selectedPair.base_currency_decimals),
      originalSize: selectedPair.min_lot_size,
      marketAsk: formattedAsk.value,
      marketBid: formattedBid.value,
    });
  };

  setCurrentPriceFromSelectedOrder = () => {
    const { orderSide, selectedOrder, selectedPair } = this.props;

    const formattedPrice = TradingHelper.formatCurrency(+selectedOrder.price, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);
    const calculatedSize =
      orderSide === selectedOrder.type
        ? 0
        : +selectedOrder.amount === 0
        ? selectedPair.min_lot_size
          ? selectedPair.min_lot_size.toCustomFixed(selectedPair.base_currency_decimals)
          : 0
        : selectedOrder.amount.toCustomFixed(selectedPair.base_currency_decimals);

    this.setState(prevState => ({
      price: +selectedOrder.price === 0 ? prevState.price : formattedPrice.formatedValue,
      originalPrice: formattedPrice.value,
      // In case user selected buy order from orderbook we need to fill sell component and vise versa
      size: calculatedSize,
      originalSize: calculatedSize,
    }));
  };

  setDefaultPriceForPairAsCurrentPrice = () => {
    const { selectedPair } = this.props;

    const formattedPrice = TradingHelper.formatCurrency(+selectedPair.min_price, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);
    const formattedAsk = TradingHelper.formatCurrency(+selectedPair.ask, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);
    const formattedBid = TradingHelper.formatCurrency(+selectedPair.bid, +selectedPair.min_price, +selectedPair.max_price, +selectedPair.price_step, selectedPair.quote_currency_decimals);

    this.setState({
      price: formattedPrice.formatedValue,
      originalPrice: formattedPrice.value,
      size: selectedPair.min_lot_size ? selectedPair.min_lot_size.toCustomFixed(selectedPair.base_currency_decimals) : 0,
      originalSize: selectedPair.min_lot_size || 0,
      marketAsk: formattedAsk.value,
      marketBid: formattedBid.value,
    });
  };

  formatRate = (number, digits) => {
    if (number) {
      number = parseFloat(number) || 0;
      return number.toCustomFixed(digits);
    }

    return '';
  };

  getEntryPrice = () => {
    return 0;
  };

  getMinMargin = () => {
    return '';
  };

  getAssetsBalance = () => {
    const { selectedPair, exchangeWallet } = this.props;

    return {
      base: find(exchangeWallet, ['asset_id', selectedPair.base_asset_id]) || {},
      quote: find(exchangeWallet, ['asset_id', selectedPair.quote_asset_id]) || {},
    };
  };

  getBalance = () => {
    const { orderSide } = this.props;

    const assetsBalances = this.getAssetsBalance();

    return orderSide === ORDER_SIDE.BUY ? assetsBalances.quote.free : assetsBalances.base.free;
  };

  getUsersMaxLot = () => {
    const { price } = this.state;

    return +this.getBalance() / +price;
  };

  onValueChanged = (value, type) => {
    const { selectedPair, orderSide } = this.props;

    if (type === ENTRY_VALUE_TYPE.LOTS) {
      const usersMaxLot = orderSide === ORDER_SIDE.BUY ? this.getUsersMaxLot() : +this.getBalance();
      const maxLot = Math.min(usersMaxLot, +selectedPair.max_lot_size);
      let adjustedLot = TradingHelper.formatCurrency(value, selectedPair.min_lot_size, maxLot, selectedPair.lot_step, selectedPair.base_currency_decimals);

      if (adjustedLot.value === 0) {
        adjustedLot.value = adjustedLot.formatedValue = selectedPair.min_lot_size; //In case user doesn't have enough funds we default to min lot needed to trade
      }

      this.setState({ size: adjustedLot.formatedValue, originalSize: adjustedLot.value });
    } else if (type === ENTRY_VALUE_TYPE.PRICE) {
      let adjustedLot = TradingHelper.formatCurrency(value, selectedPair.min_price, selectedPair.max_price, selectedPair.price_step, selectedPair.quote_currency_decimals);

      if (adjustedLot.value === 0) {
        adjustedLot.formatedValue = adjustedLot.value = selectedPair.min_price; //In case user doesn't have enough funds we default to min lot needed to trade
      }

      this.setState({ price: adjustedLot.formatedValue, originalPrice: adjustedLot.value });
    }
  };

  changeValue = (value, type) => {
    if (type === ENTRY_VALUE_TYPE.LOTS) {
      this.setState({ size: value, formValidated: true });
    } else if (type === ENTRY_VALUE_TYPE.PRICE) {
      this.setState({ price: value, formValidated: true });
    }
  };

  validateForm = () => {
    this.setState({ formValidated: true });
  };

  showUpgradeAccountModal = () => {
    const { handleDisplayAppPrompt, userInfo, getAutoLoginUrl } = this.props;

    const promptSettings = {
      show: true,
      title: 'OPEN_TRADE.UPGRADE_MODAL_TITLE',
      description: 'OPEN_TRADE.UPGRADE_MODAL_DESCRIPTION',
      ctaText: 'OPEN_TRADE.UPGRADE_MODAL_CTA',
      submitCallback: () => {
        getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE, t: userInfo.platform, lang: userInfo.language, country: userInfo.country, target: '_self' });
      },
    };

    handleDisplayAppPrompt(promptSettings);
  };

  placeOrder = () => {
    const { userInfo, selectedPair, orderSide, placeOrder } = this.props;
    const { isLimitOrder, price, size } = this.state;

    if (userInfo.status === USER_STATUS_TYPE.DEMO) {
      this.showUpgradeAccountModal();
      return;
    }

    placeOrder({
      pair_id: selectedPair.pair_id,
      side: orderSide,
      type: isLimitOrder ? ORDER_TYPE.LIMIT : ORDER_TYPE.MARKET,
      price: isLimitOrder ? +price : null,
      amount: +size,
    });
  };

  handlePendingSwitchChange = e => {
    this.setState({ isLimitOrder: e });
  };

  handleSignInClick = () => {
    const { history } = this.props;

    history.push('/login');
  };

  handleSignUpClick = () => {
    const { history } = this.props;

    history.push('/register');
  };

  getFeePercentage = isTaker => {
    const { userFees, userInfo } = this.props;
    let fee;

    if (userInfo.useNGCForFees) {
      fee = isTaker ? userFees.taker_fee_ngc : userFees.maker_fee_ngc;
    } else {
      fee = isTaker ? userFees.taker_fee : userFees.maker_fee;
    }

    return (fee * 100).toCustomFixed(4);
  };

  render = () => {
    const { t } = this.context;
    const { selectedPair, orderSide, isMobile, loggedIn } = this.props;
    const { isLimitOrder, originalPrice, originalSize, size, price, marketAsk, marketBid } = this.state;

    const baseCurrency = selectedPair.base_currency;
    const quoteCurrency = selectedPair.quote_currency;

    const assetBalances = this.getAssetsBalance();
    const baseAssetWallet = assetBalances.base;
    const quoteAssetWallet = assetBalances.quote;
    const balance = orderSide === ORDER_SIDE.BUY ? quoteAssetWallet.free : baseAssetWallet.free;
    const takerFee = this.getFeePercentage(true);
    const makerFee = this.getFeePercentage();

    return (
      <div className={`open-order${isMobile ? ' mobile' : ''}`}>
        {!isMobile && (
          <div className="open-order__type">
            <div className={`open-order__type--${orderSide.toLowerCase()}`}>
              {orderSide === ORDER_SIDE.BUY ? t('OPEN_ORDER.BUY_SYMBOL', { symbol: baseCurrency }) : t('OPEN_ORDER.SELL_SYMBOL', { symbol: baseCurrency })}
            </div>
            <div>
              <div>{t('OPEN_ORDER.TAKER_FEE', { fee: takerFee })}</div>
              <div>{t('OPEN_ORDER.MAKER_FEE', { fee: makerFee })}</div>
            </div>
          </div>
        )}

        <div className="open-order__symbol">
          <Symbol symbol={`${baseCurrency}${quoteCurrency}`} />

          <div className="open-order__symbol__info">
            <div className="open-order__symbol__info__name">
              {`${baseCurrency}/${quoteCurrency}`}
              {isMobile && <div className="open-order__symbol__info__fee">{t('OPEN_ORDER.FEE_TAKER_MAKER', { n: takerFee, m: makerFee })}</div>}
            </div>
            <div>
              {orderSide === ORDER_SIDE.BUY ? t('OPEN_ORDER.BALANCE', { symbol: quoteCurrency }) : t('OPEN_ORDER.BALANCE', { symbol: baseCurrency })}
              <RenderIf if={orderSide === ORDER_SIDE.BUY}>
                <Amount value={quoteAssetWallet.free || 0} suffix={quoteCurrency} decimals={quoteAssetWallet.display_digits} colored />
              </RenderIf>
              <RenderIf if={orderSide === ORDER_SIDE.SELL}>
                <Amount value={baseAssetWallet.free || 0} suffix={baseCurrency} decimals={baseAssetWallet.display_digits} colored />
              </RenderIf>
            </div>
          </div>
        </div>

        <div className="open-order__symbol__data">
          <span className="open-order__symbol__data__price-label">{t('OPEN_ORDER.LIVE_PRICE')}</span>
          <Amount value={selectedPair.currentPrice} suffix={quoteCurrency} decimals={selectedPair.quote_currency_decimals} />
        </div>

        <ButtonToolbar bsClass="blue-toggle open-order__limit-market-toggle">
          <ToggleButtonGroup bsClass="blue-toggle__group" type="radio" name="options" value={isLimitOrder} onChange={this.handlePendingSwitchChange}>
            <ToggleButton value={false}>{orderSide === ORDER_SIDE.BUY ? t('OPEN_ORDER.BUY_AT_CURRENT_PRICE') : t('OPEN_ORDER.SELL_AT_CURRENT_PRICE')}</ToggleButton>
            <ToggleButton value={true}>{orderSide === ORDER_SIDE.BUY ? t('OPEN_ORDER.BUY_AT_SPECIFIC_RATE') : t('OPEN_ORDER.SELL_AT_SPECIFIC_RATE')}</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <EnterValue
          className="open-order__entry-price"
          type={ENTRY_VALUE_TYPE.PRICE}
          value={price}
          onValueChanged={this.onValueChanged}
          increment={+selectedPair.price_step}
          changeValue={this.changeValue}
          validationMsg={this.state.entryPriceMessage}
          hideInputElement={!isLimitOrder}
          descText="OPEN_ORDER.TARGET_PRICE"
          currency={quoteCurrency}
        />

        <InvestmentSection
          currency={baseCurrency}
          quoteCurrency={quoteCurrency}
          lotMax={+selectedPair.lot_step}
          lotMin={+selectedPair.min_price}
          balance={+balance || 0}
          quoteIncrement={+selectedPair.lot_step}
          quoteDigits={selectedPair.quote_currency_decimals}
          currencyAmount={size}
          originalSize={+originalSize}
          onValueChanged={this.onValueChanged}
          changeValue={this.changeValue}
          price={+price || 0}
          originalPrice={+originalPrice || 0}
          symbol={baseCurrency}
          orderSide={orderSide}
          isLimitOrder={isLimitOrder}
          livePrice={selectedPair.currentPrice}
          marketAsk={marketAsk}
          marketBid={marketBid}
          descText="OPEN_ORDER.AMOUNT"
        />

        {loggedIn && (
          <BuySellButton
            type={orderSide}
            value={size}
            minMargin={this.getMinMargin()}
            insufficientFunds={false}
            handleClick={this.state.formValidated ? this.placeOrder : this.validateForm}
            isProcessing={false}
            symbol={baseCurrency}
          />
        )}
        {!loggedIn && (
          <div className="open-order__log-in">
            {t('OPEN_ORDER.LOGIN_TO_START_TRADING', {
              login: (
                <Button onClick={this.handleSignInClick} bsStyle="link">
                  {t('OPEN_ORDER.LOG_IN')}
                </Button>
              ),
              createAcc: (
                <Button onClick={this.handleSignUpClick} bsStyle="link">
                  {t('OPEN_ORDER.CREATE_ACCOUNT')}
                </Button>
              ),
            })}
          </div>
        )}
      </div>
    );
  };
}

OpenOrder.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedPair: state.exchange.selectedPair,
  selectedOrder: state.exchange.selectedOrder,
  exchangeWallet: state.wallet.assets,
  loggedIn: state.currentUser.loggedIn,
  userInfo: state.currentUser.info,
  userFees: state.exchange.userFees,
});

const mapDispatchToProps = dispatch => ({
  placeOrder: data => {
    dispatch(placeOrder(data));
  },
  handleDisplayAppPrompt: data => {
    dispatch(handleDisplayAppPrompt(data));
  },
  getAutoLoginUrl: data => {
    dispatch(getAutoLoginUrl(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenOrder));
