import { PROFILE_ACTIONS } from '../constants/profile-constants';

const profileInitialState = {
  userCountry: {},
  userProfile: {},
  userProfilePhoto: '',
  newUserStatus: '',
  showBlockedModalPopup: false,
  showUserStatusNotification: false,
  getUserProfileInProgress: false,
  showUserStatusTooltip: false,
};

export const profile = (state = profileInitialState, payload) => {
  switch (payload.type) {
    case PROFILE_ACTIONS.HANDLE_USER_COUNTRY:
      return {
        ...state,
        userCountry: payload.data,
      };
    case PROFILE_ACTIONS.HANDLE_USER_PROFILE:
      return { ...state, userProfile: payload.data };
    case PROFILE_ACTIONS.HANDLE_USER_PROFILE_PHOTO:
      return { ...state, userProfilePhoto: payload.data };
    case PROFILE_ACTIONS.HANDLE_USER_STATUS_CHANGED:
      return { ...state, newUserStatus: payload.data };
    case PROFILE_ACTIONS.HANDLE_DISPLAY_POPUP_BLOCKED_MODAL:
      return { ...state, showBlockedModalPopup: payload.data };
    case PROFILE_ACTIONS.HANDLE_DISPLAY_USER_STATUS_NOTIFICATION:
      return { ...state, showUserStatusNotification: payload.data };
    case PROFILE_ACTIONS.HANDLE_GET_USER_PROFILE_IN_PROGRESS:
      return { ...state, getUserProfileInProgress: payload.data };
    case PROFILE_ACTIONS.HANDLE_DISPLAY_USER_STATUS_TOOLTIP:
      return { ...state, showUserStatusTooltip: payload.data };
    case PROFILE_ACTIONS.HANDLE_PHONE_NUMBER_VERIFIED:
      return { ...state, userProfile: { ...state.userProfile, phone_number_verified: payload.data } };
    default:
      return state;
  }
};
