export const ORDERS_ACTIONS = {
  HANDLE_ACTIVE_ORDERS_RECEIVED: 'handleActiveOrdersReceived',
  HANDLE_REQUEST_ACTIVE_ORDERS_DATA_IN_PROGRESS: 'handleRequestActiveOrdersDataInProgress',
  HANDLE_GET_EXECUTED_ORDERS_IN_PROGRESS: 'handleGetExecutedOrdersInProgress',
  HANDLE_GET_EXECUTED_ORDERS_RECEIVED: 'handleGetExecutedOrdersReceived',
  HANDLE_HAS_MORE_EXECUTED_ORDERS_TO_LOAD: 'handleHasMoreExecutedOrdersToLoad',
  HANDLE_NEW_ORDER_PLACED: 'handleNewOrderPlacedRTUpdate',
  HANDLE_ORDER_CANCELLED: 'handleOrderCancelledRTUpdate',
  HANDLE_ORDER_MATCHED: 'handleOrderMatched',
};
