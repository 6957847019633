import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';

import { SupportedBrowser } from './supported-browser';
import IntercomHelper from '../../../core/helpers/intercom-helper';

import browserNotSupported from '../styles/images/browser-not-supported.png';
import chrome from '../styles/images/chrome.png';
import safari from '../styles/images/safari.png';
import firefox from '../styles/images/firefox.png';
import opera from '../styles/images/opera.png';
import edge from '../styles/images/edge.png';

import '../styles/browser-not-supported.scss';

class BrowserNotSupported extends Component {
  supportedBrowsers = [
    { id: 'chrome', name: 'Chrome', version: 45, image: chrome },
    { id: 'safari', name: 'Safari', version: 10, image: safari },
    { id: 'firefox', name: 'Firefox', version: 34, image: firefox },
    { id: 'opera', name: 'Opera', version: 32, image: opera },
    { id: 'edge', name: 'Edge', version: 12, image: edge },
  ];

  state = {
    isBrowserSupported: false,
  };

  componentDidMount = () => {
    IntercomHelper.hideIntercom();
    this.setState({ isBrowserSupported: this.isBrowserSupported() });
  };

  isBrowserSupported = () => {
    const userBrowser = detect();
    const name = userBrowser.name.toLowerCase();
    const version = parseInt(userBrowser.version.split('.')[0], 10);
    const supportedBrowser = this.supportedBrowsers.filter(browser => name.indexOf(browser.id) > -1)[0];
    return supportedBrowser ? version > supportedBrowser.version : true;
  };

  render() {
    const { t } = this.context;
    const { isBrowserSupported } = this.state;

    return (
      !isBrowserSupported && (
        <div className="browser-not-supported">
          <img src={browserNotSupported} className="browser-not-supported__image" alt="" />
          <p className="browser-not-supported__title">{t('BROWSER_NOT_SUPPORTED.TITLE')}</p>
          <p className="browser-not-supported__message">{t('BROWSER_NOT_SUPPORTED.MESSAGE')}</p>
          <div className="supported-browsers-list">
            {this.supportedBrowsers.map(browser => {
              return <SupportedBrowser key={browser.name} name={browser.name} version={browser.version} image={browser.image} />;
            })}
          </div>
        </div>
      )
    );
  }
}

BrowserNotSupported.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BrowserNotSupported;
