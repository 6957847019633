import { REFER_A_FRIEND_ACTIONS } from '../constants/refer-a-friend-constants';

const referralsInitialState = {
  users: [],
  getInvitedUsersInProgress: false,
  referralCode: '',
};

export const referrals = (state = referralsInitialState, payload) => {
  switch (payload.type) {
    case REFER_A_FRIEND_ACTIONS.HANDLE_GET_INVITED_USERS_IN_PROGRESS:
      return { ...state, getInvitedUsersInProgress: payload.data };
    case REFER_A_FRIEND_ACTIONS.HANDLE_INVITED_USERS_RECEIVED:
      return { ...state, users: payload.data };
    case REFER_A_FRIEND_ACTIONS.HANDLE_REFERRAL_CODE_RECEIVED:
      return { ...state, referralCode: payload.data };
    default:
      return state;
  }
};
