import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { ORDER_SIDE } from '../../../config/constants';
import Amount from '../../shared-components/amount/components/amount';
import Symbol from '../../shared-components/symbol/symbol';
import TradingHelper from '../../../core/helpers/trading-helper';

import '../styles/order-item.scss';

export const OrderItem = (props, context) => {
  const { t } = context;
  const { item, cancelable, limitNumberOfItems, isMobile, cancelOrderCallback } = props;
  const additinalPaidData = TradingHelper.getAdditionalPairData(item.pair_id);

  if (isEmpty(additinalPaidData)) return null;

  const renderTooltip = title => <Tooltip id="tooltip">{title}</Tooltip>;

  const renderDesktop = () => {
    return (
      <div className={`order-item ${cancelable ? ' open' : 'closed'}`}>
        <div>
          <span className={item.side === ORDER_SIDE.BUY ? 'buy' : 'sell'}>{item.side === ORDER_SIDE.BUY ? t('ORDERS.BUY') : t('ORDERS.SELL')}</span>
        </div>
        <div className="order-item__symbol">
          <Symbol symbol={additinalPaidData.pairBaseName} />
          <span title={additinalPaidData.pairCompleteName}>{additinalPaidData.pairCompleteName}</span>
        </div>
        <div>
          <Amount value={item.price} decimals={additinalPaidData.quoteCurrencyDigits} />
        </div>
        <div>
          <Amount value={item.initial_amount || item.amount} decimals={additinalPaidData.baseCurrencyDigits} />
        </div>
        {cancelable && (
          <div>
            <Amount value={item.initial_amount - item.current_amount} decimals={additinalPaidData.baseCurrencyDigits} />
          </div>
        )}
        {/* <div>
            <Amount value={item.actual_rate} decimals={8} />
          </div> */}
        {!limitNumberOfItems && (
          <div>
            <Amount value={item.price * item.initial_amount || item.price * item.amount} decimals={additinalPaidData.quoteCurrencyDigits} />
          </div>
        )}
        <div>{moment(item.timestamp * 1000).format('DD.MM.YYYY')}</div>
        {!cancelable && <div>{moment(item.timestamp * 1000).format('DD.MM.YYYY')}</div>}
        {cancelable && (
          <div>
            <div className="order-item__actions">
              <OverlayTrigger placement="top" overlay={renderTooltip(t('ORDERS.CANCEL_ORDER'))}>
                <i className="icn icn-box-remove" data-msb={item.msb} data-lsb={item.lsb} onClick={cancelOrderCallback} />
              </OverlayTrigger>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="order-item-mobile">
        <div>
          <div className="order-item__symbol">
            <Symbol symbol={additinalPaidData.pairBaseName} />
            <span>{additinalPaidData.pairCompleteName}</span>
          </div>
          <span className={item.side === ORDER_SIDE.BUY ? 'buy' : 'sell'}>{item.side === ORDER_SIDE.BUY ? t('ORDERS.BUY') : t('ORDERS.SELL')}</span>
        </div>
        <div>
          <div>
            <span>{t('ORDERS.PRICE')}</span>
            <Amount value={item.price} decimals={additinalPaidData.quoteCurrencyDigits} />
          </div>
          <div>
            <span>{t('ORDERS.UNITS_TOTAL')}</span>
            <Amount value={item.initial_amount || item.amount} decimals={additinalPaidData.baseCurrencyDigits} />
          </div>
          {cancelable && (
            <div>
              <span>{t('ORDERS.UNITS_FILLED')}</span>
              <Amount value={item.initial_amount - item.current_amount} decimals={additinalPaidData.baseCurrencyDigits} />
            </div>
          )}
        </div>
        <div>
          {/* <div>
              <span>{t('ORDERS.ACTUAL_RATE')}</span>
              <Amount value={item.actual_rate} decimals={8} />
            </div> */}
          <div>
            <span>{t('ORDERS.ESTIMATED_TOTAL')}</span>
            <Amount value={item.price * item.initial_amount || item.price * item.amount} decimals={additinalPaidData.quoteCurrencyDigits} />
          </div>
          <div>
            <span>{t('ORDERS.OPEN_DATE')}</span>
            <div>{moment(item.timestamp * 1000).format('DD.MM.YYYY')}</div>
          </div>
          {item.timestamp && !cancelable && (
            <div>
              <div>
                <span>{t('ORDERS.CLOSE_DATE')}</span>
                <div>{moment(item.timestamp * 1000).format('DD.MM.YYYY')}</div>
              </div>
            </div>
          )}
        </div>
        {cancelable && (
          <div className="order-item-mobile__cancel">
            <Button onClick={cancelOrderCallback} data-msb={item.msb} data-lsb={item.lsb}>
              {t('ORDERS.CANCEL_ORDER')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

OrderItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
