import axios from 'axios';

export default class OrdersApi {
  static placeOrder = data => {
    return axios({
      method: 'POST',
      url: '/orders/place',
      data,
    });
  };

  static cancelOrder = data => {
    return axios({
      method: 'POST',
      url: '/orders/cancel',
      data,
    });
  };

  static requestActiveOrdersData = () => {
    return axios({
      method: 'POST',
      url: '/orders/get/all',
      data: {
        pair_id: null,
      },
    });
  };

  static getExecutedOrders = data => {
    return axios({
      method: 'GET',
      url: '/orders/history',
      params: {
        pair_id: null,
        page_size: data.pageSize,
        page_num: data.pageNumber,
      },
    });
  };
}
