import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { setLanguage } from 'redux-i18n';

import { MAIN_LANGUAGES, DEFAULT_LANGUAGE, FLAGS_BASE_URL } from '../../../config/constants';
import CacheHelper from '../../../core/helpers/cache-helper';

import NagaLogoIcon from '../styles/images/logo-white.png';

import '../styles/header-bar.scss';

class HeaderBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: localStorage.getItem('lang') || DEFAULT_LANGUAGE,
    };
  }

  handleLanguageChange = e => {
    const { setLanguage } = this.props;
    const { code } = e.currentTarget.dataset;

    this.setState({ selectedLanguage: code });
    setLanguage(code);
    localStorage.setItem('lang', code);
  };

  handleNagaLogoClick = event => {
    event.preventDefault();

    CacheHelper.removeNagaRedirectFlag();
    window.location = '/';
  };

  renderSelectedLanguage = () => {
    const { selectedLanguage } = this.state;
    let flag = MAIN_LANGUAGES[DEFAULT_LANGUAGE].FLAG;
    let text = MAIN_LANGUAGES[DEFAULT_LANGUAGE].TEXT;

    if (!isEmpty(MAIN_LANGUAGES[selectedLanguage])) {
      flag = MAIN_LANGUAGES[selectedLanguage].FLAG;
      text = MAIN_LANGUAGES[selectedLanguage].TEXT;
    }
    return (
      <div>
        <img src={`${FLAGS_BASE_URL}${flag}.png`} alt="flag" />
        {text}
      </div>
    );
  };

  renderMenu = () => {
    const { selectedLanguage } = this.state;

    return values(MAIN_LANGUAGES).map(item => {
      return (
        <MenuItem active={selectedLanguage === item.CODE} key={item.CODE} data-code={item.CODE} onClick={this.handleLanguageChange}>
          <img src={`${FLAGS_BASE_URL}${item.FLAG}.png`} alt="flag" />
          {item.TEXT}
        </MenuItem>
      );
    });
  };

  render = () => {
    return (
      <div className="header-bar">
        <img className="naga-logo" src={NagaLogoIcon} alt="naga-logo" onClick={this.handleNagaLogoClick} />
        <DropdownButton id="login-languages" className="header-bar__languages" title={this.renderSelectedLanguage()} pullRight>
          {this.renderMenu()}
        </DropdownButton>
      </div>
    );
  };
}

HeaderBar.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setLanguage: data => {
    dispatch(setLanguage(data));
  },
});

export default connect(null, mapDispatchToProps)(HeaderBar);
