import { setLanguage } from 'redux-i18n';
import isEmpty from 'lodash/isEmpty';

import { handleAuthTokensUpdated, handleUserInfoUpdated } from '../../current-user/actions/current-user-actions';
import { handleStartFetching, handleEndFetching } from '../../shared-components/loader/actions/loader-actions';
import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';
import { handleIsRealTimeConnected } from '../../real-time-service/actions/real-time-service-actions';
import { disconnectNAGARealTimeService } from '../../real-time-service/actions/naga-real-time-service-actions';

import { FLAGS_BASE_URL, ROOT_REDUCER_ACTIONS, ERROR_CODES, USER_STATUS_TYPE, MAIN_LANGUAGES } from '../../../config/constants';
import { CURRENT_USER_ACTIONS } from '../../current-user/constants/current-user-constants';
import { LOGIN_ACTIONS, REGISTRATION_TYPES } from '../constants/registration-constants';
import authAPI from '../../../api/auth-api';
import registrationAPI from '../../../api/registration-api';
import CacheHelper from '../../../core/helpers/cache-helper';
import MQTTService from '../../../core/helpers/mqtt-service';
import ErrorHelper from '../../../core/helpers/error-helper';
import FacebookHelper from '../../../core/helpers/facebook-helper';
import GoogleHelper from '../../../core/helpers/google-helper';
import UserHelper from '../../../core/helpers/user-helper';
import CookiesWrapper from '../../../core/helpers/cookies-wrapper';

export const signOut = () => {
  return dispatch => {
    CacheHelper.clearCachedData();
    MQTTService.unsubscribeAll();
    MQTTService.disconnect();
    dispatch(disconnectNAGARealTimeService());
    dispatch(clearReduxState());
  };
};

export const login = data => {
  return (dispatch, getState) => {
    dispatch(loginInProgress(true));
    dispatch(handleIsRealTimeConnected(false));
    return authAPI.login(data).then(
      loginResponse => {
        dispatch(handleDisplayReCaptcha(false));
        completeLoginProcess(loginResponse, dispatch, getState);
      },
      err => {
        dispatch(loginInProgress(false));
        dispatch(setRecaptchaResponse(null));
        dispatch(handleResetReCaptcha(true));

        const errorCode = err.response && err.response.data && err.response.data.code;

        if (errorCode === ERROR_CODES.INVALID_LOGIN_COUNT_EXCEEDED) {
          dispatch(handleDisplayReCaptcha(true));
        }

        if (errorCode === ERROR_CODES.INVALID_USERNAME_OR_PASSWORD || errorCode === ERROR_CODES.INVALID_LOGIN_COUNT_EXCEEDED) {
          dispatch(handleInvalidLogin(true));
        } else {
          ErrorHelper.handleErrorStatusCode(err);
        }

        dispatch(increaseNumberOfLoginAttempts());
      }
    );
  };
};

export const createSession = data => {
  return (dispatch, getState) => {
    dispatch(handleInvalidLogin(false));
    dispatch(loginInProgress(true));
    return authAPI.createSession(data).then(
      res => {
        const sessionData = (res.data && res.data.data) || {};
        const authTokens = UserHelper.extractAuthTokens(res);
        const existingUserInfo = getState().currentUser.info;

        const updatedUserInfo = {
          ...existingUserInfo,
          isEU: sessionData.is_eu,
          isFlexible: sessionData.is_flexible,
          emailVerified: sessionData.email_verified,
          phoneVerified: sessionData.phone_verified,
        };

        dispatch(handleUserInfoUpdated(updatedUserInfo));
        dispatch(handleAuthTokensUpdated({ authTokens, persistTokens: true }));
        dispatch(loginInProgress(false));
        dispatch(handleLogin());
      },
      err => {
        dispatch(handleInvalidLogin(true));
        dispatch(loginInProgress(false));
      }
    );
  };
};

const completeLoginProcess = (loginResponse, dispatch, getState) => {
  const userInfo = UserHelper.extractUserInfo(loginResponse);
  dispatch(handleUserInfoUpdated(userInfo));

  // Authentication tokens must be updated after updating user info
  const authTokens = UserHelper.extractAuthTokens(loginResponse);
  dispatch(handleAuthTokensUpdated({ authTokens, persistTokens: false }));

  endLoginActions(getState, dispatch);

  if (!userInfo.mfaEnabled) {
    dispatch(createSession({ device_uuid: CookiesWrapper.getCookie('fingerprint') }));
  }
};

const endLoginActions = (getState, dispatch) => {
  CacheHelper.setNagaRedirectFlag();
  const state = getState();
  dispatch(setLanguage(state.currentUser.info.language));
  dispatch(handleInvalidLogin(false));
  dispatch(handleEndFetching());
  dispatch(registerInProgress(false));
  dispatch(handleDisplayRegistrationModal(false));
  dispatch(handleDisplayLoginModal(false));
  dispatch(loginInProgress(false));
};

export const tokenLogin = data => {
  return (dispatch, getState) => {
    dispatch(loginInProgress(true));
    dispatch(handleIsRealTimeConnected(false));
    return authAPI
      .tokenLogin(data)
      .then(res => {
        const sessionData = (res.data && res.data.data) || {};
        const authTokens = UserHelper.extractAuthTokens(res);
        const userInfo = UserHelper.extractUserInfo(res);

        const updatedUserInfo = {
          ...userInfo,
          isEU: sessionData.is_eu,
          isFlexible: sessionData.is_flexible,
          emailVerified: sessionData.email_verified,
          phoneVerified: sessionData.phone_verified,
        };

        dispatch(handleUserInfoUpdated(updatedUserInfo));
        dispatch(handleAuthTokensUpdated({ authTokens, persistTokens: true }));
        endLoginActions(getState, dispatch);
        dispatch(loginInProgress(false));
        dispatch(handleLogin());
      })
      .catch(error => {
        dispatch(handleEndFetching());
        dispatch(loginInProgress(false));
        window.location.href = '/login';
      })
      .catch(() => {
        window.location.href = '/login';
      });
  };
};

export const facebookLogin = () => {
  return dispatch => {
    dispatch(loginInProgress(true));
    return FacebookHelper.login()
      .then(function(user) {
        if (!user) {
          dispatch(loginInProgress(false));
          return false;
        }
        authAPI
          .facebookLogin(user.id, user.token)
          .then(function(response) {
            performLogin(response, dispatch);
          })
          .catch(function(error) {
            dispatch(loginInProgress(false));
            if (error.response && error.response.status === 450) {
              dispatch(toggleInfoModal('LOGIN.USER_HAS_NOT_REGISTERED_WITH_FACEBOOK', 'error'));
            } else {
              ErrorHelper.handleErrorStatusCode(error);
            }
          });
      })
      .catch(function(error) {
        dispatch(loginInProgress(false));
        if (error.status !== 'unknown') {
          ErrorHelper.handleErrorStatusCode(error);
        }
      });
  };
};

export const googleLogin = () => {
  return dispatch => {
    dispatch(loginInProgress(true));
    return GoogleHelper.login()
      .then(function(user) {
        if (!user) {
          dispatch(loginInProgress(false));
          return false;
        }
        authAPI
          .googleLogin(user.id, user.token)
          .then(function(response) {
            performLogin(response, dispatch);
          })
          .catch(function(error) {
            dispatch(loginInProgress(false));
            if (error.response && error.response.status === 450) {
              dispatch(toggleInfoModal('LOGIN.USER_HAS_NOT_REGISTERED_WITH_GOOGLE', 'error'));
            } else {
              ErrorHelper.handleErrorStatusCode(error);
            }
          });
      })
      .catch(function(error) {
        dispatch(loginInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const socialAutoLogin = loginData => {
  return (dispatch, getState) => {
    if (loginData.type === REGISTRATION_TYPES.FACEBOOK.toLowerCase()) {
      dispatch(handleStartFetching());
      authAPI
        .facebookLogin(loginData.id, loginData.token)
        .then(function(response) {
          performLogin(response, dispatch, false, () => {
            dispatch(handleEndFetching());
          });
        })
        .catch(function(error) {
          dispatch(handleEndFetching());
          if (error.response && error.response.status === 450) {
            dispatch(toggleInfoModal('LOGIN.USER_HAS_NOT_REGISTERED_WITH_FACEBOOK', 'error'));
          } else {
            ErrorHelper.handleErrorStatusCode(error);
          }
        });
    } else if (loginData.type === REGISTRATION_TYPES.GOOGLE.toLowerCase()) {
      dispatch(handleStartFetching());
      authAPI
        .googleLogin(loginData.id, loginData.token)
        .then(function(response) {
          performLogin(response, dispatch, false, () => {
            dispatch(handleEndFetching());
          });
        })
        .catch(function(error) {
          dispatch(handleEndFetching());
          if (error.response && error.response.status === 450) {
            dispatch(toggleInfoModal('LOGIN.USER_HAS_NOT_REGISTERED_WITH_GOOGLE', 'error'));
          } else {
            ErrorHelper.handleErrorStatusCode(error);
          }
        });
    }
  };
};

export const facebookRegister = () => {
  return dispatch => {
    dispatch(registerInProgress(true));
    return FacebookHelper.logout()
      .then(function() {
        return FacebookHelper.login()
          .then(function(user) {
            dispatch(registerInProgress(false));
            dispatch(handleUserInfoReceived(user));
          })
          .catch(function(error) {
            dispatch(registerInProgress(false));
            if (error.status !== 'unknown') {
              ErrorHelper.handleErrorStatusCode(error);
            }
          });
      })
      .catch(function(error) {
        dispatch(registerInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const googleRegister = () => {
  return dispatch => {
    dispatch(registerInProgress(true));
    return GoogleHelper.logout()
      .then(function() {
        return GoogleHelper.login()
          .then(function(user) {
            dispatch(registerInProgress(false));
            dispatch(handleUserInfoReceived(user));
          })
          .catch(function(error) {
            dispatch(registerInProgress(false));
            ErrorHelper.handleErrorStatusCode(error);
          });
      })
      .catch(function(error) {
        dispatch(registerInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const register = data => {
  return dispatch => {
    dispatch(registerInProgress(true));
    return registrationAPI
      .registerAccount(data)
      .then(res => {
        CacheHelper.clearRegistrationUrlParams();
        performLogin(res, dispatch, true);
      })
      .catch(error => {
        dispatch(registerInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const sendResetPasswordEmail = data => {
  return dispatch => {
    dispatch(sendResetPasswordEmailInProgress(true));
    return authAPI
      .sendResetPasswordEmail(data)
      .then(() => {
        dispatch(sendResetPasswordEmailInProgress(false));
        dispatch(sendResetPasswordEmailSuccess(true));
      })
      .catch(error => {
        dispatch(sendResetPasswordEmailInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const resetPassword = data => {
  return (dispatch, getState) => {
    dispatch(resetPasswordInProgress(true));
    return authAPI
      .resetPassword(data)
      .then(res => {
        completeLoginProcess(res, dispatch, getState);
      })
      .catch(error => {
        dispatch(resetPasswordInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const detectCountry = () => {
  return dispatch => {
    return registrationAPI.detectCountry().then(res => {
      const country = (res && res.data && res.data.data) || null;
      if (country) {
        country.icon = `${FLAGS_BASE_URL}${country.code.toLowerCase()}.png`;
      }
      dispatch(handleUserCountryDetected(country));
    });
  };
};

export const getCountries = () => {
  return dispatch => {
    return registrationAPI.getCountries().then(countries => {
      countries.forEach(country => {
        country.icon = `${FLAGS_BASE_URL}${country.code.toLowerCase()}.png`;
      });
      dispatch(handleCountriesReceived(countries));
    });
  };
};

export const getPublicSocketCredentials = () => {
  return dispatch => {
    return authAPI.getPublicSocketCredentials().then(
      res => {
        dispatch(handleUserInfoUpdated({ username: res.data.data.username, language: localStorage.getItem('lang') || MAIN_LANGUAGES.en.CODE }));
        dispatch(handleAuthTokensUpdated({ authTokens: { socketSecret: res.data.data.password } }));
      },
      err => {
        console.error(err);
      }
    );
  };
};

export const getAutoLoginUrl = urlParams => {
  return dispatch => {
    let windowInstance = null;
    if (!urlParams.target || urlParams.target === '_blank') {
      windowInstance = window.open();
    }
    return authAPI.getAutoLoginUrl(urlParams.page, urlParams.dest).then(res => {
      let url = (res.data && res.data.data && res.data.data.url) || null;

      if (url && !isEmpty(urlParams)) {
        let queryString = '';
        for (let key in urlParams) {
          if (key === 'page') continue;
          queryString += `&${key}=${urlParams[key]}`;
        }
        url += queryString;
      }

      dispatch(handleAutoLoginMAUrlReceived(url, windowInstance, urlParams.target));
    });
  };
};

const performLogin = (res, dispatch, isFromRegistrationProcess, loginCallback = () => {}) => {
  const userInfo = { ...UserHelper.extractUserInfo(res) };

  CacheHelper.setNagaRedirectFlag();

  if (isFromRegistrationProcess) {
    userInfo.emailVerified = false;
    userInfo.status = userInfo.status || USER_STATUS_TYPE.WALLET_ONLY;
  }

  dispatch(handleUserInfoUpdated(userInfo));

  const authTokens = UserHelper.extractAuthTokens(res);
  dispatch(handleAuthTokensUpdated({ authTokens, persistTokens: true }));

  loginCallback();

  // This method is curently solely being used by regular registration
  // Session is already created at this point so just proceed with the regular login flow
  dispatch(loginInProgress(false));
  dispatch(handleLogin());
};

export const confirmEmail = data => {
  return (dispatch, getState) => {
    dispatch(handleEmailConfirmationInProgress(true));
    authAPI.confirmEmail(data).then(
      res => {
        const state = getState();

        dispatch(handleEmailConfirmationInProgress(false));

        if (res.data.success && state.currentUser.loggedIn && !state.currentUser.isGuest) {
          const userInfo = state.currentUser.info;
          userInfo.email_confirmed = true;
          dispatch(handleUserInfoUpdated(userInfo));
        }
      },
      err => {
        dispatch(handleEmailConfirmationInProgress(false));
        ErrorHelper.handleErrorStatusCode(err);
      }
    );
  };
};

export const handleEmailConfirmationInProgress = data => ({
  type: LOGIN_ACTIONS.HANDLE_EMAIL_CONFIRMATION_IN_PROGRESS,
  data,
});

export const clearReduxState = () => ({
  type: ROOT_REDUCER_ACTIONS.LOG_OUT,
});

export const setRecaptchaResponse = data => ({
  type: LOGIN_ACTIONS.SET_RECAPTCHA_RESPONSE,
  data: data,
});

export const increaseNumberOfLoginAttempts = () => ({
  type: LOGIN_ACTIONS.INCREASE_NUMBER_OF_LOIGN_ATTEMPTS,
});

export const handleDisplayReCaptcha = data => ({
  type: LOGIN_ACTIONS.HANDLE_DISPLAY_RECAPTCHA,
  data,
});

export const handleInvalidLogin = data => ({
  type: LOGIN_ACTIONS.HANDLE_INVALID_LOGIN,
  data,
});

export const handleLogin = () => ({
  type: CURRENT_USER_ACTIONS.HANDLE_LOGIN_SUCCESS,
  data: true,
});

export const handleLogout = () => ({
  type: CURRENT_USER_ACTIONS.HANDLE_LOGOUT_SUCCESS,
  data: false,
});

export const loginInProgress = data => ({
  type: LOGIN_ACTIONS.LOGIN_IN_PROGRESS,
  data,
});

export const registerInProgress = data => ({
  type: LOGIN_ACTIONS.REGISTER_IN_PROGRESS,
  data,
});

export const handleResetReCaptcha = data => ({
  type: LOGIN_ACTIONS.HANDLE_RESET_RECAPTCHA,
  data,
});

export const handleCountriesReceived = data => ({
  type: LOGIN_ACTIONS.HANDLE_COUNTRIES_RECEIVED,
  data,
});

export const sendResetPasswordEmailInProgress = data => ({
  type: LOGIN_ACTIONS.SEND_RESET_PASSWORD_EMAIL_IN_PROGRESS,
  data,
});

export const sendResetPasswordEmailSuccess = data => ({
  type: LOGIN_ACTIONS.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  data,
});

export const resetPasswordInProgress = data => ({
  type: LOGIN_ACTIONS.RESET_PASSWORD_IN_PROGRESS,
  data,
});

export const handleUserInfoReceived = data => ({
  type: LOGIN_ACTIONS.USER_INFO_RECEIVED,
  data,
});

export const handleUserCountryDetected = data => ({
  type: LOGIN_ACTIONS.USER_COUNTRY_DETECTED,
  data,
});

export const handleDisplayRegistrationModal = data => ({
  type: LOGIN_ACTIONS.HANDLE_DISPLAY_REGISTRATION_MODAL,
  data,
});

export const handleDisplayLoginModal = data => ({
  type: LOGIN_ACTIONS.HANDLE_DISPLAY_LOGIN_MODAL,
  data,
});

export const handleDisplayForgotPasswordModal = data => ({
  type: LOGIN_ACTIONS.HANDLE_DISPLAY_FORGOT_PASSWORD_MODAL,
  data,
});

export const handleAutoLoginMAUrlReceived = (url, windowInstance, target) => ({
  type: LOGIN_ACTIONS.HANDLE_AUTO_LOGIN_MA_URL_RECEIVED,
  data: { url, windowInstance, target },
});
