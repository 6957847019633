import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';

import { ACCEPT_VERSION_HEADER, API_URL, PLATFORM } from '../../../config/constants';

import '../styles/trading-view-chart.scss';

class TradingViewChart extends Component {
  chartWidget = null;

  static defaultProps = {
    containerId: 'tv_chart_container',
    datafeedUrl: `${API_URL}/chart`,
    libraryPath: '/charting_library/',
    autosize: true,
  };

  componentDidMount = () => {
    const { loggedInUserId } = this.props;
    let headers = {};

    headers.platform = PLATFORM;
    headers['accept-version'] = ACCEPT_VERSION_HEADER;

    const widgetOptions = {
      symbol: this.props.pair,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl, headers, 60000),
      container_id: 'tv_chart_container',
      library_path: this.props.libraryPath,
      locale: 'en',
      enabled_features: ['study_templates'],
      disabled_features: ['header_symbol_search', 'header_compare'],
      client_id: 'tradingview.com',
      user_id: loggedInUserId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
    };

    if (this.props.mobile) {
      widgetOptions.preset = 'mobile';
    }

    this.chartWidget = new window.TradingView.widget(widgetOptions);

    this.chartWidget.onChartReady(() => {
      this.chartWidget
        .chart()
        .onSymbolChanged()
        .subscribe(this, pair => {
          if (this.props.pair === pair.name) {
            return;
          }
        });
    });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.pair !== this.props.pair && this.chartWidget._ready) {
      this.chartWidget.setSymbol(this.props.pair, 'D');
    }
  };

  componentWillUnmount = () => {
    if (this.chartWidget && this.chartWidget._ready) {
      this.chartWidget.remove();
    }
  };

  render = () => {
    return (
      <div className="trading-view-chart">
        <div id="tv_chart_container" className={'TVChartContainer'} />
      </div>
    );
  };
}

const mapStateToProps = state => ({
  loggedInUserId: state.currentUser.info.id,
});

export default withRouter(connect(mapStateToProps, null)(TradingViewChart));
