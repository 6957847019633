import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Button from 'react-bootstrap/lib/Button';
import isEmpty from 'lodash/isEmpty';

import { toggleOrderBookModal, getOrderBookItems, handleSymbolChanged } from '../../exchange/actions/exchange-actions';
import { subscribeToRTService, unsubscribeFromRTService } from '../../real-time-service/actions/real-time-service-actions';

import { ORDER_SIDE, REAL_TIME_CHANNELS } from '../../../config/constants';
import HelperFunctions from '../../../core/helpers/helper-functions';
import MediaQueryHelper from '../../../core/helpers/media-query-helper';
import OpenOrder from '../../exchange/components/trading/open-order';
import OrderBookModal from '../../exchange/components/order-book/order-book-modal';

import '../styles/open-order-container.scss';

class OpenOrderContainer extends Component {
  state = { selectedOrderType: null, selectedPair: null };

  componentDidMount = () => {
    const { match, subscribeToRTService, selectedPair, handleSymbolChanged, pairs } = this.props;
    const typeFromUrl = HelperFunctions.getParameterByName('type');
    const pairId = unescape(match.params.pair);

    const orderSide = typeFromUrl ? typeFromUrl.toUpperCase() : ORDER_SIDE.SELL;

    if (isEmpty(selectedPair.name) && !isEmpty(pairs)) {
      handleSymbolChanged(pairId);
    }

    try {
      this.setState({
        selectedOrderType: orderSide,
      });
    } catch (error) {}

    // TODO: We should add symbol validity check down the road
    if (pairId) {
      subscribeToRTService(`${REAL_TIME_CHANNELS.TICKER}/${pairId}`);
    }
  };

  componentDidUpdate = prevProps => {
    const { pairs, selectedPair, match, handleSymbolChanged } = this.props;
    const pairId = unescape(match.params.pair);

    if (isEmpty(prevProps.pairs) && !isEmpty(pairs) && isEmpty(selectedPair.name)) {
      handleSymbolChanged(pairId);
    }
  };

  componentWillUnmount = () => {
    const { unsubscribeFromRTService } = this.props;

    unsubscribeFromRTService(REAL_TIME_CHANNELS.TICKER);
  };

  handleOrderTypeChange = orderSide => {
    this.setState({ selectedOrderType: orderSide });
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  viewOrderBook = () => {
    const { toggleOrderBookModal } = this.props;
    toggleOrderBookModal();
  };

  render = () => {
    const { t } = this.context;
    const { loggedIn, orderBookItems, showOrderBookModal, toggleOrderBookModal, selectedPair, getOrderBookItems, selectedMarket } = this.props;
    const { selectedOrderType } = this.state;

    if (!selectedPair && !selectedMarket && !selectedOrderType) {
      return null;
    }

    const isIphoneX = MediaQueryHelper.isIphoneX();

    const orderBookModalProps = {
      selectedPair,
      selectedMarket,
      showOrderBookModal,
      toggleOrderBookModal,
      orderBookItems,
      getOrderBookItems,
      selectedOrderType,
    };

    return (
      <div className={`open-order-container${loggedIn ? '' : ' guest'}${isIphoneX ? ' iphone-x' : ''}`}>
        <div className="open-order-container__navigation">
          <div className="open-order-container__navigation__body">
            <Button bsStyle="link" bsSize="large" className="open-order-container__navigation__back-btn" onClick={this.goBack}>
              <i className="icn icn-minimal-left-big-thin" />
              {t('OPEN_ORDER.BACK')}
            </Button>
            <Button bsStyle="link" bsSize="large" className="open-order-container__navigation__symbol-info-btn" onClick={this.viewOrderBook}>
              <i className="icn icn-tablet-reader" />
              {t('OPEN_ORDER.VIEW_ORDER_BOOK')}
            </Button>
          </div>
        </div>

        <ButtonToolbar className="open-order-container__buy-sell-toggle">
          <ToggleButtonGroup type="radio" name="options" value={selectedOrderType} onChange={this.handleOrderTypeChange}>
            <ToggleButton value={ORDER_SIDE.BUY}>{t('OPEN_ORDER.BUY')}</ToggleButton>
            <ToggleButton value={ORDER_SIDE.SELL}>{t('OPEN_ORDER.SELL')}</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
        {/* Open order container is being displayed only on mobile devices - hence isMobile flag below */}
        <OpenOrder orderSide={selectedOrderType} isMobile={true} />
        <OrderBookModal {...orderBookModalProps} />
      </div>
    );
  };
}

OpenOrderContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  orderBookItems: state.exchange.orderBook.items,
  showOrderBookModal: state.exchange.showOrderBookModal,
  loggedIn: state.currentUser.loggedIn,
  selectedMarket: state.exchange.market,
  selectedPair: state.exchange.selectedPair,
  pairs: state.exchange.pairs,
});

const mapDispatchToProps = dispatch => ({
  toggleOrderBookModal: () => {
    dispatch(toggleOrderBookModal());
  },
  getOrderBookItems: pair => {
    dispatch(getOrderBookItems(pair));
  },
  subscribeToRTService: topic => {
    dispatch(subscribeToRTService(topic));
  },
  unsubscribeFromRTService: topic => {
    dispatch(unsubscribeFromRTService(topic));
  },
  handleSymbolChanged: data => {
    dispatch(handleSymbolChanged(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenOrderContainer));
