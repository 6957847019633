import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';

import { ORDER_SIDE } from '../../../../config/constants';

import '../../styles/open-order-actions.scss';

const OpenOrderActions = (props, context) => {
  const { selectedPair } = props;

  const openOrder = event => {
    const { history } = props;
    const orderSide = event.currentTarget.dataset.type;

    history.push(`/open-order/${selectedPair.name}?type=${orderSide}`);
  };

  const { t } = context;
  const { isIphoneX } = props;

  return (
    <div className={`open-order-actions${isIphoneX ? ' iphone-x' : ''}`}>
      <Button bsSize="large" data-type={ORDER_SIDE.BUY} onClick={openOrder}>
        {t('OPEN_ORDER.BUY_CURRENCY', { n: selectedPair.base_currency })}
      </Button>
      <Button bsSize="large" data-type={ORDER_SIDE.SELL} onClick={openOrder}>
        {t('OPEN_ORDER.SELL_CURRENCY', { n: selectedPair.base_currency })}
      </Button>
    </div>
  );
};

OpenOrderActions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(OpenOrderActions);
