import find from 'lodash/find';
import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';

import { EXCHANGE_ACTIONS, DEFAULT_SYMBOL, ORDER_BOOK_ACTIONS } from '../constants/exchange-constants';
import TradingHelper from '../../../core/helpers/trading-helper';

const exchangeInitialState = {
  market: DEFAULT_SYMBOL.MARKET,
  pairs: [],
  selectedPair: {
    name: '',
  },
  symbolInfo: null,
  isSubscribed: false,
  fetchingSymbols: false,
  showMarketsModal: false,
  fetchingSymbolInfo: false,
  selectedOrder: {
    price: 0,
    amount: 0,
    type: null,
  },
  orderBook: {
    items: {},
    getOrderBookInProgress: false,
  },
  tradeHistory: {
    items: [],
    hasMoreToLoad: false,
    getTradeHistoryInProgress: false,
    lastLoadedTradeId: null,
  },
  showOrderBookModal: false,
  userSpecificMQTTTopics: [],
  userFees: {
    maker_fee: 0,
    taker_fee: 0,
    maker_fee_ngc: 0,
    taker_fee_ngc: 0,
  },
};

export function exchange(state = exchangeInitialState, payload) {
  switch (payload.type) {
    case EXCHANGE_ACTIONS.HANDLE_FETCHING_SYMBOLS: {
      return { ...state, fetchingSymbols: payload.data };
    }
    case EXCHANGE_ACTIONS.HANDLE_SYMBOLS_RECEIVED: {
      return { ...state, pairs: payload.data };
    }
    case EXCHANGE_ACTIONS.HANDLE_MARKET_CHANGED: {
      return { ...state, market: payload.data };
    }
    case EXCHANGE_ACTIONS.HANDLE_SYMBOL_CHANGED: {
      let selectedPair = {};

      if (state.pairs.length) {
        selectedPair =
          find(state.pairs, pair => {
            return pair.name === payload.data;
          }) || {};
      }

      return { ...state, selectedPair, isSubscribed: false, selectedOrder: exchangeInitialState.selectedOrder };
    }
    case EXCHANGE_ACTIONS.HANDLE_FETCHING_SYMBOL_INFO: {
      return { ...state, fetchingSymbolInfo: payload.data };
    }
    case EXCHANGE_ACTIONS.HANDLE_SYMBOL_INFO_CHANGED: {
      return { ...state, selectedPair: { ...state.selectedPair, ...payload.data } };
    }
    case EXCHANGE_ACTIONS.HANDLE_EXCHANGE_SUBSCRIPTION_CHANGED: {
      return { ...state, isSubscribed: payload.data };
    }
    case EXCHANGE_ACTIONS.TOGGLE_MARKETS_MODAL: {
      return { ...state, showMarketsModal: !state.showMarketsModal };
    }
    case ORDER_BOOK_ACTIONS.HANDLE_ORDER_BOOK_ITEMS_SUCCESS:
      return { ...state, orderBook: { ...state.orderBook, items: payload.data } };
    case ORDER_BOOK_ACTIONS.HANDLE_GET_ORDER_BOOK_ITEMS_IN_PROGRESS:
      return { ...state, orderBook: { ...state.orderBook, getOrderBookInProgress: payload.data } };
    case ORDER_BOOK_ACTIONS.HANDLE_SELECTED_ORDER_BOOK_ITEM:
      return { ...state, selectedOrder: payload.data };
    case ORDER_BOOK_ACTIONS.HANDLE_GET_TRADE_HISTROY_ITEMS_IN_PROGRESS:
      return { ...state, tradeHistory: { ...state.tradeHistory, getTradeHistoryInProgress: payload.data } };
    case ORDER_BOOK_ACTIONS.HANDLE_GET_TRADE_HISTORY_ITEMS_SUCCESS:
      return { ...state, tradeHistory: { ...state.tradeHistory, items: payload.data } };
    case ORDER_BOOK_ACTIONS.HANDLE_HAS_MORE_TRADE_HISTORY_ITEMS_TO_LOAD:
      return { ...state, tradeHistory: { ...state.tradeHistory, hasMoreToLoad: payload.data } };
    case ORDER_BOOK_ACTIONS.HANDLE_RESET_TRADE_HISTORY_ITEMS:
      return { ...state, tradeHistory: { ...state.tradeHistory, items: [] } };
    case EXCHANGE_ACTIONS.TOGGLE_ORDER_BOOK_MODAL:
      return { ...state, showOrderBookModal: !state.showOrderBookModal };
    case EXCHANGE_ACTIONS.HANDLE_USER_SPECIFIC_MQTT_TOPICS:
      return { ...state, userSpecificMQTTTopics: payload.data };
    case ORDER_BOOK_ACTIONS.HANDLE_NEW_TRADE_HISTORY_ITEM_RECEIVED:
      return { ...state, tradeHistory: { ...state.tradeHistory, items: [payload.data, ...state.tradeHistory.items] } };
    case EXCHANGE_ACTIONS.HANDLE_USER_FEES_RECEIVED:
      return { ...state, userFees: payload.data };
    case EXCHANGE_ACTIONS.HANDLE_UPDATE_PAIR_DATA_FROM_TICKER:
      const pairToUpdate = find(state.pairs, f => f.pair_id === payload.data.pairId);
      const { lastDayVolumeInUSD, lastPriceInUSD } = TradingHelper.getPricesInUSD(state.pairs, payload.data.quoteCurrency, payload.data.close_price, payload.data.quote_volume);

      if (
        +pairToUpdate.currentPrice !== +payload.data.close_price ||
        (+pairToUpdate.change24HourPercentage !== +payload.data.current_trend && !isEmpty(payload.data.current_trend)) ||
        +pairToUpdate.ask !== +payload.data.best_ask ||
        +pairToUpdate.bid !== +payload.data.best_bid ||
        +pairToUpdate.quoteVolume !== +payload.data.quote_volume ||
        +pairToUpdate.lastDayVolumeInUSD !== +lastDayVolumeInUSD ||
        +pairToUpdate.lastPriceInUSD !== +lastPriceInUSD
      ) {
        const updatedList = update(state.pairs, {
          [state.pairs.indexOf(pairToUpdate)]: {
            currentPrice: { $set: payload.data.close_price },
            change24HourPercentage: { $set: payload.data.current_trend || pairToUpdate.change24HourPercentage },
            ask: { $set: payload.data.best_ask },
            bid: { $set: payload.data.best_bid },
            quoteVolume: { $set: payload.data.quote_volume },
            lastDayVolumeInUSD: { $set: lastDayVolumeInUSD },
            lastPriceInUSD: { $set: lastPriceInUSD },
          },
        });
        return { ...state, pairs: updatedList };
      }
      return state;
    default:
      return state;
  }
}
