import React from 'react';

import miniLoader from './mini-loader.png';
import whiteLoader from './white-loader.png';
import './mini-loader.scss';

const MiniLoader = props => {
  const loader = props.whiteLoader ? whiteLoader : miniLoader;
  const loaderClass = `mini-loader ${props.className ? ` ${props.className}` : ''}`;

  return <img src={loader} className={loaderClass} alt="loader" />;
};

export default MiniLoader;
