import React from 'react';

import '../styles/toggle-switch.scss';

const ToggleSwitch = props => {
  return (
    <label className={`switch${props.disabled ? ' switch--disabled' : ''}`}>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} />
      <span className="slider round" />
    </label>
  );
};

export default ToggleSwitch;
