import { telephoneAsyncValidation } from '../actions/user-settings-actions';

class UserSettingsFormValidators {
  static asyncValidate = (values, dispatch, props, currentFieldName) => {
    const previousErrors = props.asyncErrors;

    return new Promise((resolve, reject) => {
      dispatch(telephoneAsyncValidation(values, previousErrors, currentFieldName, resolve, reject));
    });
  };
}

export default UserSettingsFormValidators;
