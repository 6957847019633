import { ORDERS_ACTIONS } from '../constants/orders-costants';
import ordersApi from '../../../api/orders-api';

export const requestActiveOrdersData = () => {
  return dispatch => {
    dispatch(handleRequestActiveOrdersDataInProgress(true));
    return ordersApi.requestActiveOrdersData().then(
      res => {
        //request is finished once data is received async, so in progress flag is reset there
      },
      err => {
        dispatch(handleRequestActiveOrdersDataInProgress(false));
      }
    );
  };
};

export const getExecutedOrders = data => {
  return dispatch => {
    dispatch(handleGetExecutedOrdersInProgress(true));

    return ordersApi.getExecutedOrders(data).then(
      res => {
        dispatch(handleGetExecutedOrdersReceived(res.data.data, data.pageNumber));
        res.data.data.length === data.pageSize ? dispatch(handleHasMoreExecutedOrdersToLoad(true)) : dispatch(handleHasMoreExecutedOrdersToLoad(false));
        dispatch(handleGetExecutedOrdersInProgress(false));
      },
      err => {
        dispatch(handleGetExecutedOrdersInProgress(false));
        dispatch(handleHasMoreExecutedOrdersToLoad(false));
      }
    );
  };
};

export const cancelOrder = data => {
  return dispatch => {
    return ordersApi.cancelOrder(data).then(
      res => {
        console.log('CANCELLED!');
      },
      err => {
        console.error('ERROR!');
      }
    );
  };
};

export const handleActiveOrdersReceived = data => ({
  type: ORDERS_ACTIONS.HANDLE_ACTIVE_ORDERS_RECEIVED,
  data,
});

export const handleRequestActiveOrdersDataInProgress = data => ({
  type: ORDERS_ACTIONS.HANDLE_REQUEST_ACTIVE_ORDERS_DATA_IN_PROGRESS,
  data,
});

export const handleGetExecutedOrdersInProgress = data => ({
  type: ORDERS_ACTIONS.HANDLE_GET_EXECUTED_ORDERS_IN_PROGRESS,
  data,
});

export const handleGetExecutedOrdersReceived = (orders, page) => ({
  type: ORDERS_ACTIONS.HANDLE_GET_EXECUTED_ORDERS_RECEIVED,
  data: {
    orders,
    page,
  },
});

export const handleHasMoreExecutedOrdersToLoad = data => ({
  type: ORDERS_ACTIONS.HANDLE_HAS_MORE_EXECUTED_ORDERS_TO_LOAD,
  data,
});

export const handleNewOrderPlacedRTUpdate = data => ({
  type: ORDERS_ACTIONS.HANDLE_NEW_ORDER_PLACED,
  data,
});

export const handleOrderCancelledRTUpdate = data => ({
  type: ORDERS_ACTIONS.HANDLE_ORDER_CANCELLED,
  data,
});

export const handleOrderMatchedRTUpdate = data => ({
  type: ORDERS_ACTIONS.HANDLE_ORDER_MATCHED,
  data,
});
