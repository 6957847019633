import axios from 'axios';

import { AUTOLOGIN_DESTIONATIOS } from '../config/constants';
import CookiesWrapper from '../core/helpers/cookies-wrapper';
import HelperFunctions from '../core/helpers/helper-functions';

class AuthApi {
  static login = data => {
    const loginData = {
      username: data.user_name,
      password: data.password,
      device_uuid: CookiesWrapper.getCookie('fingerprint'),
      request_id: HelperFunctions.generateRequestId(),
      g_recaptcha_response: data.g_recaptcha_response,
    };

    return axios({
      method: 'POST',
      url: '/login',
      data: loginData,
    });
  };

  static createSession = data => {
    return axios({
      method: 'POST',
      url: '/create_session',
      data,
    });
  };

  static getPublicSocketCredentials = () => {
    return axios({
      method: 'GET',
      url: '/public_socket',
    });
  };

  static changePassword(data) {
    return axios({
      method: 'POST',
      url: '/auth/change_password',
      data,
    });
  }

  static getMFAInfo = () => {
    return axios({
      method: 'POST',
      url: '/auth/get_mfa_data',
    });
  };

  static enableMFA = (token, secret) => {
    return axios({
      method: 'POST',
      url: '/auth/enable_mfa',
      data: {
        mfa_secret: secret,
        token,
      },
    });
  };

  static disableMFA = token => {
    return axios({
      method: 'POST',
      url: '/auth/disable_mfa',
      data: { token },
    });
  };

  static getMFARecoveryCodes = () => {
    return axios({
      method: 'POST',
      url: '/auth/new_recovery_codes',
    });
  };

  static resetPassword = data => {
    return axios({
      method: 'POST',
      url: '/password/reset',
      data,
    });
  };

  static tokenLogin = data => {
    return axios({
      method: 'POST',
      url: '/autologin/login',
      data,
    });
  };

  static getAutoLoginUrl = (page = '', dest = AUTOLOGIN_DESTIONATIOS.WEB_TRADER) => {
    return axios({
      method: 'GET',
      url: '/auth/autologin/url',
      params: {
        page,
        dest,
      },
    });
  };

  static sendResetPasswordEmail = data => {
    return axios({
      method: 'POST',
      url: '/password/send_verification_code',
      data,
    });
  };

  static confirmEmail = dataObj => {
    return axios({
      method: 'POST',
      url: '/email/confirm',
      data: {
        email: dataObj.email,
        user_id: dataObj.userId,
      },
      transformRequest: [
        (data, headers) => {
          headers.platform = dataObj.platform;
          headers['Content-Type'] = 'application/json';

          return JSON.stringify(data);
        },
      ],
    });
  };

  static facebookLogin = (facebookId, facebookToken) => {
    return axios({
      method: 'POST',
      url: '/user/login_facebook',
      data: {
        facebook_id: facebookId,
        facebook_token: facebookToken,
        device_uuid: CookiesWrapper.getCookie('fingerprint'),
        get_user_info: true,
      },
    });
  };

  static googleLogin = (googleId, googleToken) => {
    return axios({
      method: 'POST',
      url: '/user/login_google',
      data: {
        google_id: googleId,
        google_token: googleToken,
        device_uuid: CookiesWrapper.getCookie('fingerprint'),
        get_user_info: true,
      },
    });
  };
}

export default AuthApi;
