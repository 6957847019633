const StatusCodes = {
  1006: 'STATUS_CODE.INVALID_CODE_PROVIDED',
  1007: 'STATUS_CODE.MFA_ALREADY_ENABLED',
  1008: 'STATUS_CODE.QR_CODE_COULD_NOT_BE_GENERATED',
  1009: 'STATUS_CODE.FAILED_TO_CHANGE_PASSWORD',
  1012: 'STATUS_CODE.LIMIT_ORDER_NEEDS_A_PRICE_SET',
  1014: 'STATUS_CODE.SPECIFIED_PRICE_TOO_HIGH',
  1015: 'STATUS_CODE.SPECIFIED_PRICE_TOO_LOW',
  1038: 'STATUS_CODE.FAILED_TO_GENERATE_RECOVERY_CODES',
  1039: 'STATUS_CODE.FAILED_TO_SAVE_MFA_CONFIG',
  1040: 'STATUS_CODE.FAILED_TO_VALIDATE_PHONE_NUMBER',
  1041: 'STATUS_CODE.THERE_WAS_A_PROBLEM_WITH_OUR_SMS_PROVIDER',
  1042: 'STATUS_CODE.FAILED_TO_SEND_SMS',
  1043: 'STATUS_CODE.FAILED_TO_VERIFY_PHONE_NUMBER',
  1044: 'STATUS_CODE.FAILED_TO_RETRIEVE_USER_INFORMATION',
  1045: 'STATUS_CODE.FAILED_TO_UPDATE_USER_INFORMATION',
  1046: 'STATUS_CODE.USER_REGISTERED_CREATING_SESSION_FAILED',
  1047: 'STATUS_CODE.USER_REGISTERED_CREATING_SESSION_FAILED',
  1051: 'STATUS_CODE.USERNAME_ALREADY_USED',
  1052: 'STATUS_CODE.EMAIL_ALREADY_USED',
  1053: 'STATUS_CODE.USERNAME_AND_EMAIL_TAKEN',
  1068: 'STATUS_CODE.INVALID_LOGIN_PROVIDED',
  1075: 'STATUS_CODE.EXCHANGE_NOT_OFFERED_TO_YOUR_COUNTRY',
  1076: 'STATUS_CODE.EXCHANGE_NOT_OFFERED_TO_NAGA_MARKET_USERS',
  U0309: 'STATUS_CODE.ENABLE_MFA_MESSAGE',
  U4607: 'STATUS_CODE.CHANGE_REQUEST_HAS_EXPIRED_PLEASE_MAKE_NEW_REQUEST',
  '-1404': 'EXCHANGE_CORE_ERROR.ORDER_NOT_FOUND',
  '-1402': 'EXCHANGE_CORE_ERROR.ORDER_PRICE_BELOW_MINIMUM',
  '-1406': 'EXCHANGE_CORE_ERROR.ORDER_PRICE_ABOVE_MAXIMUM',
  '-1411': 'EXCHANGE_CORE_ERROR.ORDER_AMOUNT_BELOW_MINIMUM',
  '-1413': 'EXCHANGE_CORE_ERROR.ORDER_AMOUNT_ABOVE_MAXIMUM',
  '-1307': 'EXCHANGE_CORE_ERROR.NOT_ENOUGH_QUOTE_ASSET_TO_PLACE_ORDER',
  '-1308': 'EXCHANGE_CORE_ERROR.NOT_ENOUGH_BASE_ASSET_TO_PLACE_ORDER',
};

export default StatusCodes;
