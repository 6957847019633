import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import equals from 'validator/lib/equals';

import { resetPassword } from '../actions/registration-actions';

import { requiredField } from '../../shared-components/helpers/helpers';
import { PASSWORD_CRITERIA } from '../../../config/constants';
import HelperFunctions from '../../../core/helpers/helper-functions';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import HeaderBar from './header-bar';

import '../styles/reset-password.scss';

class ResetPassword extends Component {
  componentDidMount = () => {
    HelperFunctions.generateFingerprint();
  };

  componentDidUpdate = () => {
    const { loggedUserInfo, history, loggedIn } = this.props;

    if (loggedUserInfo && loggedUserInfo.mfaEnabled) {
      history.push('/mfa');
    }

    if (loggedIn) {
      history.push('/exchange');
    }
  };

  handleFormSubmit(values) {
    const { match, resetPassword } = this.props;

    const data = {
      email: decodeURIComponent(match.params.email),
      verification_code: decodeURIComponent(match.params.code),
      password: HelperFunctions.encodePassword(values.password),
      plain_password: values.confirm_password,
    };

    resetPassword(data);
  }

  render() {
    const { handleSubmit, resetPasswordInProgress } = this.props;
    const { t } = this.context;

    return (
      <div className="reset-password-wrapper">
        <HeaderBar />
        <div className="reset-password-form">
          <div className="reset-password-form__wrapper">
            <h2 className="reset-password-form__title">{t('RESET_PASSWORD.ENTER_NEW_PASSWORD')}</h2>
            <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} noValidate>
              <Field name="password" type="password" placeHolder={t('RESET_PASSWORD.NEW_PASSWORD')} component={DefaultInput} />
              <Field name="confirm_password" type="password" placeHolder={t('RESET_PASSWORD.CONFIRM_NEW_PASSWORD')} component={DefaultInput} />
              <div>
                <Button bsStyle="primary" disabled={resetPasswordInProgress} type="submit">
                  {resetPasswordInProgress ? <MiniLoader whiteLoader /> : t('RESET_PASSWORD.CHANGE_PASSWORD')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  resetPasswordInProgress: state.registration.resetPasswordInProgress,
  loggedIn: state.currentUser.loggedIn,
  loggedUserInfo: state.currentUser.info,
});

const mapDispatchToProps = dispatch => ({
  resetPassword: data => {
    return dispatch(resetPassword(data));
  },
});

const validateForm = values => {
  let errors = {};

  requiredField(values, errors, 'password', 'RESET_PASSWORD.PASSWORD_IS_REQUIRED');
  requiredField(values, errors, 'confirm_password', 'RESET_PASSWORD.CONFIRM_PASSWORD_IS_REQUIRED');

  if (values.password && !PASSWORD_CRITERIA.test(values.password)) {
    errors.password = 'RESET_PASSWORD.PASSWORD_CRITERIA_NOT_MET';
  }
  if (values.confirm_password && !PASSWORD_CRITERIA.test(values.confirm_password)) {
    errors.confirm_password = 'RESET_PASSWORD.PASSWORD_CRITERIA_NOT_MET';
  }
  if (isEmpty(errors) && !equals(values.password, values.confirm_password)) {
    errors.password = 'RESET_PASSWORD.PROVIDED_PASSWORDS_DONT_MATCH';
    errors.confirm_password = 'RESET_PASSWORD.PROVIDED_PASSWORDS_DONT_MATCH';
  }

  return errors;
};

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default withRouter(
  reduxForm({
    form: 'resetPasswordForm',
    validate: validateForm,
    destroyOnUnmount: false,
  })(ResetPassword)
);
