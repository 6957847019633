import find from 'lodash/find';

import { HIGHLIGHTED_NEWS_ACTIONS } from '../constants/highlighted-news-constants';

const highlightedInitialState = {
  items: [],
  fetchingHighlightedNews: false,
  selectedNews: {},
};

export const highlightedNews = (state = highlightedInitialState, payload) => {
  switch (payload.type) {
    case HIGHLIGHTED_NEWS_ACTIONS.HANDLE_HIGHLIGHTED_NEWS_SUCCESS:
      return { ...state, items: payload.data };
    case HIGHLIGHTED_NEWS_ACTIONS.HANDLE_FETCHING_HIGHLIGHTED_NEWS:
      return { ...state, fetchingHighlightedNews: payload.data };
    case HIGHLIGHTED_NEWS_ACTIONS.HANDLE_HIGHLIGHTED_NEWS_SELECTED:
      return { ...state, selectedNews: payload.data ? find(state.items, ['id', payload.data]) || {} : {} };
    default:
      return state;
  }
};
