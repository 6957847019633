import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';
import ReCAPTCHA from 'react-google-recaptcha';

import { login, facebookLogin, googleLogin, setRecaptchaResponse, handleResetReCaptcha, handleUserInfoReceived } from '../actions/registration-actions';

import HelperFunctions from '../../../core/helpers/helper-functions';
import { requiredField } from '../../shared-components/helpers/helpers';
import { LOGIN_ATTEMPTS_LIMIT, RE_CAPTCHA_KEY } from '../../../config/constants';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';

import PasswordInput from '../../shared-components/password-input/components/password-input';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const { history, loggedIn, loginUserInfo, loggedUserInfo, loginInProgress, resetReCaptcha, handleResetReCaptcha } = this.props;

    if (loginUserInfo) {
      this.props.change('user_name', loginUserInfo.email);
    }

    if (loggedUserInfo && loggedUserInfo.mfaEnabled) {
      history.push('/mfa');
    }

    if (loggedIn) {
      history.push('/exchange');
    }

    // on every invalid login with recaptcha, recaptcha must be reinitialized
    if ((prevProps.loginInProgress && !loginInProgress && resetReCaptcha) || (!prevProps.resetReCaptcha && resetReCaptcha && !loginInProgress)) {
      this.recaptchaRef.current.reset();
      handleResetReCaptcha(false);
    }
  };

  state = {}; // getDerivedStateFromProps requires state to be defined

  componentWillUnmount = () => {
    const { handleUserInfoReceived, reset } = this.props;
    handleUserInfoReceived(null);
    reset();
  };

  sendToCreateAccount = () => {
    const { history } = this.props;

    history.push('/register');
  };

  handleFormSubmit = values => {
    const { login, loginAttempts, gRecaptchaResponse } = this.props;

    if ((loginAttempts || 0) < LOGIN_ATTEMPTS_LIMIT || (loginAttempts >= LOGIN_ATTEMPTS_LIMIT && gRecaptchaResponse)) {
      const data = {
        user_name: values.user_name,
        password: HelperFunctions.encodePassword(values.password),
        g_recaptcha_response: gRecaptchaResponse,
      };

      login(data);
    }
  };

  loginWithFacebook = () => {
    const { facebookLogin } = this.props;

    facebookLogin();
  };

  loginWithGoogle = () => {
    const { googleLogin } = this.props;

    googleLogin();
  };

  handleForgotPassword = () => {
    const { history } = this.props;

    history.push('/password/forgot');
  };

  onCaptchaCheckboxChange(value) {
    const { setRecaptchaResponse, handleResetReCaptcha } = this.props;
    handleResetReCaptcha(false);
    setRecaptchaResponse(value);
  }

  render = () => {
    const { handleSubmit, invalidLogin, loginAttempts, loginInProgress, displayReCaptcha } = this.props;
    const { t } = this.context;

    return (
      <div className="login-form">
        <div className="login-form__wrapper">
          <div className="login-form__header">
            <h2 className="login-form__title">{t('LOGIN.WELCOME')}</h2>
          </div>
          <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} className="login-form__body" noValidate>
            <Field disabled={loginInProgress} name="user_name" type="text" placeHolder="LOGIN.EMAIL_OR_USERNAME" component={DefaultInput} />
            <Field disabled={loginInProgress} name="password" placeHolder="LOGIN.PASSWORD" component={PasswordInput} />
            {invalidLogin && <div className="alert alert-danger login-form__error">{this.context.t('LOGIN.WRONG_LOGIN_CREDENTIALS')}</div>}
            <div className="re-captcha" style={{ display: loginAttempts >= LOGIN_ATTEMPTS_LIMIT || displayReCaptcha ? 'block' : 'none' }}>
              <ReCAPTCHA ref={this.recaptchaRef} sitekey={RE_CAPTCHA_KEY} onChange={val => this.onCaptchaCheckboxChange(val)} />
            </div>
            <div className="login-form__forgot-password">
              <Button bsStyle="link" disabled={loginInProgress} onClick={this.handleForgotPassword}>
                {t('LOGIN.FORGOT')}
              </Button>
            </div>
            <div className="login-form__submit-container">
              <Button disabled={loginInProgress} type="submit" bsStyle="primary" className="login-form__sign-in">
                {loginInProgress ? <MiniLoader whiteLoader /> : t('LOGIN.SIGN_IN')}
              </Button>
            </div>
          </form>
          <div className="login-form__footer">
            <span>{t('LOGIN.NO_ACCOUNT')}</span>
            <Button bsStyle="link" disabled={loginInProgress} onClick={this.sendToCreateAccount}>
              {t('LOGIN.NO_ACCOUNT_SIGNUP')}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

LoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const loginFormValidate = formProps => {
  const errors = {};

  requiredField(formProps, errors, 'user_name', 'LOGIN.USERNAME_IS_REQURED');
  requiredField(formProps, errors, 'password', 'LOGIN.PASSWORD_IS_REQUIRED');

  return errors;
};

const mapStateToProps = (state, props) => {
  return {
    loggedIn: state.currentUser.loggedIn,
    invalidLogin: state.registration.invalidLogin,
    gRecaptchaResponse: state.registration.gRecaptchaResponse,
    loginAttempts: state.registration.loginAttempts,
    loginInProgress: state.registration.loginInProgress,
    loginUserInfo: state.registration.userInfo,
    displayReCaptcha: state.registration.displayReCaptcha,
    resetReCaptcha: state.registration.resetReCaptcha,
    loggedUserInfo: state.currentUser.info,
  };
};
const mapDispatchToProps = dispatch => ({
  login: data => {
    dispatch(login(data));
  },
  facebookLogin: () => {
    dispatch(facebookLogin());
  },
  googleLogin: () => {
    dispatch(googleLogin());
  },
  handleUserInfoReceived: data => {
    dispatch(handleUserInfoReceived(data));
  },
  setRecaptchaResponse: data => {
    dispatch(setRecaptchaResponse(data));
  },
  handleResetReCaptcha: data => {
    dispatch(handleResetReCaptcha(data));
  },
});

LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default withRouter(
  reduxForm({
    form: 'loginForm',
    validate: loginFormValidate,
  })(LoginForm)
);
