import { REFRESH_TOKEN_ACTIONS } from '../constants/jwt-constants';

export const setRetryCount = path => ({
  type: REFRESH_TOKEN_ACTIONS.SET_RETRY_COUNT,
  path,
});

export const refreshRetryCount = () => ({
  type: REFRESH_TOKEN_ACTIONS.REFRESH_RETRY_COUNT,
});
