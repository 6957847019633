import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';
import isEmpty from 'lodash/isEmpty';

import { getExecutedOrders } from '../actions/orders-actions';

import { ORDERS_TABS } from '../../exchange/constants/exchange-constants';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import NoOrders from './no-orders';
import { OrderItem } from './order-item';

import '../styles/orders.scss';

class ExecutedOrders extends Component {
  state = {
    pageNumber: 0,
    pageSize: 30,
  };

  componentDidMount = () => {
    const { limitNumberOfItems, getExecutedOrders, executedOrders } = this.props;
    const { pageNumber, pageSize } = this.state;

    if (!limitNumberOfItems) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    if (isEmpty(executedOrders)) {
      getExecutedOrders({ pageSize, pageNumber });
      this.handlePageNumberIncrement();
    }
  };

  handleLoadItemsToList = () => {
    const { getExecutedOrders, getExecutedOrdersInProgress } = this.props;
    const { pageNumber, pageSize } = this.state;

    if (!getExecutedOrdersInProgress) {
      getExecutedOrders({ pageSize, pageNumber });
      this.handlePageNumberIncrement();
    }
  };

  handleShowAllOrders = () => {
    const { history } = this.props;

    history.push(`/orders/executed`);
  };

  handlePageNumberIncrement = () => {
    this.setState(prevState => {
      return { pageNumber: prevState.pageNumber + 1 };
    });
  };

  renderShowAllOrders = () => {
    const { t } = this.context;
    const { executedOrders, limitNumberOfItems } = this.props;

    return (
      limitNumberOfItems &&
      executedOrders.length > 5 && (
        <div className="show-all-orders">
          <Button bsStyle="primary" onClick={this.handleShowAllOrders}>
            {t('ORDERS.SHOW_ALL_ORDERS')}
          </Button>
        </div>
      )
    );
  };

  renderItems = isMobile => {
    const { executedOrders, limitNumberOfItems } = this.props;
    const itemsToRender = limitNumberOfItems ? executedOrders.slice(0, 5) : executedOrders;

    return itemsToRender.map(item => {
      return <OrderItem key={item.trade_count} item={item} isMobile={isMobile} />;
    });
  };

  renderDesktop = () => {
    const { t } = this.context;
    const { hasMoreExecutedOrdersToLoad } = this.props;

    return (
      <div>
        <div className="order-item title closed">
          <div />
          <div>{t('ORDERS.MARKET')}</div>
          <div>{t('ORDERS.PRICE')}</div>
          <div>{t('ORDERS.UNITS_TOTAL')}</div>
          <div>{t('ORDERS.ESTIMATED_TOTAL')}</div>
          <div>{t('ORDERS.OPEN_DATE')}</div>
          <div>{t('ORDERS.CLOSE_DATE')}</div>
        </div>
        <InfiniteScroll initialLoad={false} loadMore={this.handleLoadItemsToList} hasMore={hasMoreExecutedOrdersToLoad}>
          {this.renderItems()}
        </InfiniteScroll>
        {this.renderShowAllOrders()}
      </div>
    );
  };

  renderMobile = () => {
    const { hasMoreExecutedOrdersToLoad } = this.props;
    return (
      <div>
        <InfiniteScroll initialLoad={false} loadMore={this.handleLoadItemsToList} hasMore={hasMoreExecutedOrdersToLoad}>
          {this.renderItems(true)}
        </InfiniteScroll>
        {this.renderShowAllOrders()}
      </div>
    );
  };

  render = () => {
    const { isMobile, getExecutedOrdersInProgress, executedOrders, fetchingSymbols } = this.props;

    if ((getExecutedOrdersInProgress && isEmpty(executedOrders)) || fetchingSymbols) {
      return (
        <div className="orders_loader">
          <MiniLoader />
        </div>
      );
    }

    if (isEmpty(executedOrders)) {
      return <NoOrders orderType={ORDERS_TABS.EXECUTED_ORDERS} />;
    }

    return isMobile ? this.renderMobile() : this.renderDesktop();
  };
}

ExecutedOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isMobile: state.mediaQuery.isMobile,
  executedOrders: state.orders.executedOrders,
  getExecutedOrdersInProgress: state.orders.getExecutedOrdersInProgress,
  hasMoreExecutedOrdersToLoad: state.orders.hasMoreExecutedOrdersToLoad,
  fetchingSymbols: state.exchange.fetchingSymbols,
});

const mapDispatchToProps = dispatch => ({
  getExecutedOrders: data => {
    dispatch(getExecutedOrders(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExecutedOrders));
