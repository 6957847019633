import mqtt from 'mqtt';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';

import CookiesWrapper from './cookies-wrapper';
import CacheHelper from './cache-helper';
import { MQTT_ERROR_CODES, MQTT_RETRY_COUNT, NAGA_MQTT_URL } from '../../config/constants';
import HelperFunctions from './helper-functions';

class NAGAMQTTService {
  constructor() {
    this.callbacks = {};
    this.instance = null;
    this.retryCount = 0;
    this.tokens = null;
  }

  connect(tokens, callbacks) {
    if (isEmpty(tokens)) {
      return;
    }

    this.tokens = tokens;

    this.instance = mqtt.connect(NAGA_MQTT_URL, {
      username: CacheHelper.getUserInfo().username,
      password: this.tokens.socketSecret,
      clientId: HelperFunctions.generateUUID(),
    });

    this.callbacks = callbacks;

    this.instance.on('message', (topic, payload) => {
      forEach(this.callbacks, (value, key) => {
        const regexpStr = key.replace(new RegExp('(#)|(\\*)|(\\+)'), str => {
          switch (str) {
            case '#':
              return '.*?';
            case '*':
              return '.*?';
            case '+':
              return '.*';
            default:
              break;
          }
        });
        if (topic.match(regexpStr)) {
          value.forEach(obj => obj.cb(payload));
        }
      });
    });

    this.instance.on('connect', () => {
      if (this.callbacks.connected) {
        this.callbacks.connected(true);
      }
    });

    this.instance.on('error', error => {
      if (error && error.code === MQTT_ERROR_CODES.BAD_USERNAME_PASSWORD) {
        if (!isEmpty(this.tokens) && this.retryCount <= MQTT_RETRY_COUNT) {
          this.retryCount += 1;
          this.instance.options.username = CookiesWrapper.getCookie('fingerprint');
          this.instance.options.password = this.tokens.socketSecret;
        } else {
          this.instance.end(true, () => {
            this.retryCount = 0;
          });
        }
      }
    });
  }

  disconnect = callback => {
    if (this.isConnected()) {
      if (callback) {
        callback(false);
      }
      this.instance.end(true);
    }
  };

  isConnected = () => {
    return this.instance && this.instance.connected;
  };

  on(topic, callback, callbackId = null) {
    if (topic !== 'connect') {
      this.callbacks[topic] = this.callbacks[topic] || [];
      this.callbacks[topic].push({ id: callbackId, cb: callback });
    } else if (this.instance) {
      this.instance.on(topic, callback);
    }
  }

  off(topic, callbackId) {
    if (this.instance) {
      let topicCallbacks = this.callbacks[topic];
      this.callbacks[topic] = reject(topicCallbacks, ['id', callbackId]);

      // In case there is no callbacks for this topic unsubscribe as noone is listening
      if (this.callbacks[topic].length === 0) {
        this.instance.unsubscribe(topic);
      }
    }
  }

  subscribe(topic, callback = () => {}) {
    if (this.isConnected()) {
      this.instance.subscribe(topic, error => {
        if (!error) {
          callback();
        }
      });
    }
  }

  publish(topic, payload) {
    if (this.isConnected()) {
      this.instance.publish(topic, payload);
    }
  }

  unsubscribe(topic, callback) {
    if (this.isConnected()) {
      this.instance.unsubscribe(topic, callback);
      delete this.callbacks[topic];
    }
  }

  unsubscribeAll = () => {
    const topics = Object.keys(this.callbacks);
    topics.forEach(i => this.unsubscribe(i));
  };
}

export default new NAGAMQTTService();
