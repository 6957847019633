import StatusCodes from './status-codes';
import { DEFAULT_ERROR_MESSAGE, IS_PROD_ENV } from '../../config/constants';
import StoreInstance from '../../index';
import { toggleInfoModal } from '../../features/shared-components/info-modal/actions/info-modal-actions';

export default class ErrorHelper {
  static handleErrorStatusCode(error, customErrorMessage) {
    if (!IS_PROD_ENV) {
      console.log('Error happened: ');
      console.dir(error);
    }

    const errorCode = (error && error.response && error.response.data && error.response.data.code) || ''; // get errorCode from response if exists
    const message = StatusCodes[errorCode]; // get friendly error message by errorCode

    const output = message || customErrorMessage || DEFAULT_ERROR_MESSAGE;
    if (error !== 401) {
      StoreInstance.dispatch(toggleInfoModal(output, 'error'));
    }
  }

  static handleNotificationErrorCode(code) {
    const message = StatusCodes[code]; // get friendly error message by errorCode

    const output = message || DEFAULT_ERROR_MESSAGE;

    return output;
  }
}
