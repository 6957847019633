export const EXCHANGE_ACTIONS = {
  HANDLE_MARKET_CHANGED: 'handleMarketChanged',
  HANDLE_SYMBOL_CHANGED: 'handleSymbolChanged',
  HANDLE_FETCHING_SYMBOL_INFO: 'handleFetchingSymbolInfo',
  HANDLE_SYMBOL_INFO_CHANGED: 'handleSymbolInfoChanged',
  HANDLE_EXCHANGE_SUBSCRIPTION_CHANGED: 'handleExchangeSubscriptionChanged',
  HANDLE_FETCHING_SYMBOLS: 'handleFetchingSymbols',
  HANDLE_SYMBOLS_RECEIVED: 'handleSymbolsReceived',
  TOGGLE_MARKETS_MODAL: 'toggleMarketsModal',
  TOGGLE_ORDER_BOOK_MODAL: 'toggleOrderBookModal',
  HANDLE_USER_SPECIFIC_MQTT_TOPICS: 'handleUserSpecificMQTTTopics',
  HANDLE_USER_FEES_RECEIVED: 'handleUserFeesReceived',
  HANDLE_UPDATE_PAIR_DATA_FROM_TICKER: 'handleUpdatePairDataFromTicker',
};

export const ORDERS_TABS = {
  OPEN_ORDERS: 1,
  EXECUTED_ORDERS: 2,
};

export const CHARTS_TABS = {
  PRICE_CHART: 1,
  MARKET_DEPTH: 2,
};

export const MARKET_DEPTH_CHART_COLORS = {
  BID_BACKGROUND: '#e7f5eb',
  BID_BORDER: '#3dc752',
  ASK_BACKGROUND: '#fdeaec',
  ASK_BORDER: '#ee3142',
};

export const THROTTLING_PERIOD = 1000;

export const ORDER_TYPE = {
  MARKET: 'm',
  LIMIT: 'l',
  FOK: 'fok',
  IOC: 'ioc',
  PO: 'po',
};

export const DEFAULT_SYMBOL = {
  PAIR: 'NGCUSD',
  MARKET: 'USD',
};

export const HOLD_BUTTON_TYPE = {
  INCREMENT: 'increment',
  DECREMENT: 'decrement',
};

export const INVESTMENT_TYPE = {
  CURRENCY: 'currency',
  UNITS: 'units',
};

export const ENTRY_VALUE_TYPE = {
  MARGIN: 'margin',
  LOTS: 'lots',
  PRICE: 'price',
};

export const ORDER_BOOK_ACTIONS = {
  HANDLE_ORDER_BOOK_ITEMS_SUCCESS: 'handleGetOrderBookItemsSuccess',
  HANDLE_GET_ORDER_BOOK_ITEMS_IN_PROGRESS: 'handleGetOrderBookItemsInProgress',
  HANDLE_SYMBOL_LOADED: 'handleSymbolLoaded',
  HANDLE_SELECTED_ORDER_BOOK_ITEM: 'handleSeletedOrderBookItem',
  HANDLE_GET_TRADE_HISTORY_ITEMS_SUCCESS: 'handleGetTradeHistoryItemsSuccess',
  HANDLE_GET_TRADE_HISTROY_ITEMS_IN_PROGRESS: 'handleGetTradeHistoryItemsInProgress',
  HANDLE_HAS_MORE_TRADE_HISTORY_ITEMS_TO_LOAD: 'handleHasMoreTradeHistoryItemsToLoad',
  HANDLE_RESET_TRADE_HISTORY_ITEMS: 'handleResetTradeHistoryItems',
  HANDLE_NEW_TRADE_HISTORY_ITEM_RECEIVED: 'handleNewTradeHistoryItemReceived',
};

export const GRAPH_COLOR = {
  BUY: '#EBF9ED',
  SELL: '#FBEDED',
};

export const LOADING_DIRECTIONS = {
  BEFORE: 'before',
  AFTER: 'after',
};

export const ORDER_BOOK_DISPLAY_TYPE = {
  SELL_BUY: 1,
  SELL_SELL: 2,
  BUY_BUY: 3,
};
