import React from 'react';
import ReactDOM from 'react-dom';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';

import Store from './core/store';
import App from './core/App';
import { translations } from './translations';
import initilizeHttpClient from './config/axios-config';

const StoreInstance = Store();

initilizeHttpClient();

const initialLang = localStorage.getItem('lang') || 'en';

ReactDOM.render(
  <Provider store={StoreInstance}>
    <I18n translations={translations} initialLang={initialLang} fallbackLang="en">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18n>
  </Provider>,
  document.getElementById('root')
);

export default StoreInstance;
