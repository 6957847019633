import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import { loadUserProfilePhoto } from '../actions/profile-actions';
import { signOut } from '../../registration/actions/registration-actions';

import { AVATAR_PHOTO_DEFAULT_URL } from '../../../config/constants';
import IntercomHelper from '../../../core/helpers/intercom-helper';

import '../styles/profile-box.scss';

class ProfileBox extends Component {
  userMenu = [
    {
      text: 'USER_MENU.SETTINGS',
      path: '/user-settings',
      className: 'settings',
      iconClass: 'icn-bolt-thin',
    },
    {
      text: 'USER_MENU.SUPPORT',
      path: 'SUPPORT',
      className: 'support',
      iconClass: 'icn-chat-thin',
    },
    {
      text: 'USER_MENU.LOGOUT',
      path: 'LOGOUT',
      className: 'logout',
      iconClass: 'icn-button-power-thin',
    },
  ];

  componentDidMount = () => {
    const { loadUserProfilePhoto, userInfo } = this.props;

    if (userInfo) {
      loadUserProfilePhoto(userInfo.id);
    }
  };

  handleLogout = () => {
    const { signOut, history } = this.props;

    signOut();
    history.push('/login');
  };

  handleRedirect = e => {
    const { history } = this.props;
    const path = e.currentTarget.dataset.path;

    if (path === 'LOGOUT') {
      this.handleLogout();
    } else {
      if (path === 'SUPPORT') {
        IntercomHelper.openIntercom();
      } else {
        history.push(path);
      }
    }
  };

  renderMenu = () => {
    const { t } = this.context;

    return this.userMenu.map(item => {
      return (
        <MenuItem key={item.text} className={item.className} data-path={item.path} data-deeplink={item.deeplink} data-target={item.target} onClick={this.handleRedirect}>
          <div>
            <i className={`icn ${item.iconClass}`} />
            {t(item.text)}
          </div>
        </MenuItem>
      );
    });
  };

  renderProfilePicture = () => {
    const { userProfilePhoto, updatedProfileImage } = this.props;

    return (
      <div
        className="picture"
        style={{
          backgroundImage: `url(${updatedProfileImage || userProfilePhoto}), url(${AVATAR_PHOTO_DEFAULT_URL})`,
        }}
      />
    );
  };

  render = () => {
    return (
      <div className="profile-box-container">
        <DropdownButton title={this.renderProfilePicture()} id="user-menu" noCaret pullRight>
          {this.renderMenu()}
        </DropdownButton>
      </div>
    );
  };
}

ProfileBox.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  signOut: () => {
    dispatch(signOut());
  },
  loadUserProfilePhoto: userId => {
    dispatch(loadUserProfilePhoto(userId));
  },
});

const mapStateToProps = state => ({
  userProfilePhoto: state.profile.userProfilePhoto,
  updatedProfileImage: state.currentUser.userProfileImageUrl,
  userInfo: state.currentUser.info,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileBox));
