import React, { Component, Fragment } from 'react';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import some from 'lodash/some';

import { handleNotificationBarVisibility } from '../../current-user/actions/current-user-actions';
import { getAutoLoginUrl } from '../../registration/actions/registration-actions';
import { handleDisplayAppPrompt } from '../../shared-components/prompt/actions/prompt-actions';

import { WEB_TRADER_AUTOLOGIN_PAGES, UPGRADE_REWORD, USER_STATUS_TYPE } from '../../../config/constants';

import '../styles/notification-bar.scss';

const UPGRADE_DESCRIPTION_POINTS = Array.from({ length: 7 }, (_, i) => i + 1);

class NotificationBar extends Component {
  getDispalyText() {
    const { userInfo, getAutoLoginUrl, handleDisplayAppPrompt } = this.props;

    return {
      mfa: {
        text: 'USER_STATUS.ENABLE_MFA_TEXT',
        title: 'USER_STATUS.ENABLE_MFA_TITLE',
        buttonText: 'USER_STATUS.ENABLE_MFA_BUTTON',
        path: '/user-settings/security',
        className: 'orange',
        buttonStyle: 'warning',
        internalRoute: true,
      },
      upgrade: {
        text: 'USER_STATUS.UPGRADE_TEXT',
        title: 'USER_STATUS.UPGRADE_TITLE',
        buttonText: 'USER_STATUS.UPGRADE_BUTTON',
        path: '',
        className: 'blue-with-background',
        buttonStyle: 'primary',
        internalRoute: false,
        titleParam: 'amount',
        titleParamValue: <span className="blue-text">{UPGRADE_REWORD}</span>,
        textParam: 'amount',
        textParamValue: <span>{UPGRADE_REWORD}</span>,
        callback: () => {
          if (userInfo.isEU && userInfo.isFlexible) {
            const promptSettings = {
              show: true,
              title: 'USER_UPGRADE.UPGRADE_NOTICE_TITLE',
              description: this.renderUpgradeNoticeDescription(),
              ctaText: 'OPEN_TRADE.UPGRADE_MODAL_CTA',
              promptClass: 'account-upgrade-notice',
              submitCallback: () => {
                getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE, lang: userInfo.language, country: userInfo.country, t: userInfo.platform, target: '_self' });
              },
            };

            handleDisplayAppPrompt(promptSettings);
          } else {
            getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE, lang: userInfo.language, country: userInfo.country, t: userInfo.platform, target: '_self' });
          }
        },
      },
    };
  }

  handleOnClick = (path, isInternalrRoute, callback) => {
    const { history } = this.props;

    if (isInternalrRoute) {
      history.push(path);
    } else {
      if (callback) {
        callback();
      }
    }
  };

  remindMeLater = () => {
    const { handleNotificationBarVisibility } = this.props;
    handleNotificationBarVisibility(false);
  };

  isExcludedRoute = () => {
    const { location } = this.props;
    const excludedRoutes = ['/home', '/', '/fees', '/list-your-token', '/confirm-email']; //TODO: refactor this to use Layout prop from route
    const isExcludedRoute = some(excludedRoutes, e => location.pathname === e);
    return isExcludedRoute;
  };

  renderUpgradeNoticeDescription = () => {
    const { t } = this.context;

    return (
      <Fragment>
        <ul>
          {UPGRADE_DESCRIPTION_POINTS.map(point => (
            <li key={point}>{t(`USER_UPGRADE.UPGRADE_POINT_${point}`)}</li>
          ))}
        </ul>
        <div className="account-upgrade-notice__warning">
          <i className="icn icn-alert" /> {t('USER_UPGRADE.RISK_WARNING')}
        </div>
      </Fragment>
    );
  };

  render = () => {
    const { t } = this.context;
    const { userInfo, showNotificationBar, loggedIn } = this.props;
    let settings;

    if (!loggedIn) {
      return null;
    }

    if (showNotificationBar && !this.isExcludedRoute()) {
      if (userInfo.status === USER_STATUS_TYPE.DEMO) {
        settings = 'upgrade';
      } else if (!userInfo.mfaEnabled) {
        settings = 'mfa';
      } else {
        return null;
      }
    } else {
      return null;
    }

    const { text, title, buttonText, path, className, buttonStyle, internalRoute, titleParam, titleParamValue, textParam, textParamValue, callback } = this.getDispalyText()[settings];

    return (
      <div className={`user-status-box ${className}`}>
        <div className="user-status-box__desc">
          <div className="user-status-box__desc-title">{t(title, { [titleParam]: titleParamValue })}</div>
          <div className="user-status-box__desc-text">{t(text, { [textParam]: textParamValue })}</div>
        </div>
        <div className="user-status-box__buttons">
          <Button bsStyle={buttonStyle} onClick={() => this.handleOnClick(path, internalRoute, callback)}>
            {t(buttonText)}
          </Button>
          <Button bsStyle="link" onClick={this.remindMeLater}>
            {t('USER_STATUS.REMIND_ME_LATER')}
          </Button>
        </div>
      </div>
    );
  };
}

NotificationBar.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showNotificationBar: state.currentUser.showNotificationBar,
  userInfo: state.currentUser.info,
  loggedIn: state.currentUser.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  handleNotificationBarVisibility: data => {
    dispatch(handleNotificationBarVisibility(data));
  },
  getAutoLoginUrl: data => {
    dispatch(getAutoLoginUrl(data));
  },
  handleDisplayAppPrompt: data => {
    dispatch(handleDisplayAppPrompt(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationBar));
