import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import { handleDisplayAppPrompt } from '../actions/prompt-actions';

import GenericModal from '../../generic-modal/generic-modal';

import '../styles/prompt.scss';

class Prompt extends Component {
  closeModal = () => {
    const { handleDisplayAppPrompt, closeModalCallback } = this.props;
    handleDisplayAppPrompt(false);

    if (closeModalCallback) {
      closeModalCallback();
    }
  };

  handlePromptSumbit = () => {
    const { submitCallback, preventClose } = this.props;

    submitCallback();

    if (!preventClose) {
      this.closeModal();
    }
  };

  render() {
    const { t } = this.context;
    const { show, title, description, ctaText, submitCallback, promptClass } = this.props;

    return (
      <GenericModal className={`prompt ${promptClass}`} show={show}>
        <Modal.Body>
          <div className="prompt__header">
            <Button className="prompt__header__close-btn" bsStyle="link" onClick={this.closeModal}>
              <i className="icn icn-16 icn-simple-remove-big-thin" />
            </Button>
          </div>
          <div className="prompt__title">{t(title)}</div>
          <div className="prompt__content">
            <div className="prompt__content__message">
              <div>{typeof description === 'string' ? t(description) : description}</div>
            </div>
            {submitCallback && (
              <Button bsStyle="primary" onClick={this.handlePromptSumbit}>
                {t(ctaText)}
              </Button>
            )}
          </div>
        </Modal.Body>
      </GenericModal>
    );
  }
}

Prompt.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  show: state.prompt.show,
  title: state.prompt.title,
  description: state.prompt.description,
  ctaText: state.prompt.ctaText,
  promptClass: state.prompt.promptClass,
  submitCallback: state.prompt.submitCallback,
  closeModalCallback: state.prompt.closeModalCallback,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayAppPrompt: show => {
    dispatch(handleDisplayAppPrompt(show));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Prompt);
