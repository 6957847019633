import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

import { ORDERS_ACTIONS } from '../constants/orders-costants';

const ordersInitialState = {
  activeOrders: [],
  executedOrders: [],
  getExecutedOrdersInProgress: false,
  requestActiveOrdersDataInProgress: false,
  hasMoreExecutedOrdersToLoad: false,
};

export const orders = (state = ordersInitialState, payload) => {
  switch (payload.type) {
    case ORDERS_ACTIONS.HANDLE_ACTIVE_ORDERS_RECEIVED:
      return { ...state, activeOrders: orderBy(payload.data, o => o.timestamp, ['desc']) };
    case ORDERS_ACTIONS.HANDLE_REQUEST_ACTIVE_ORDERS_DATA_IN_PROGRESS:
      return { ...state, requestActiveOrdersDataInProgress: payload.data };
    case ORDERS_ACTIONS.HANDLE_GET_EXECUTED_ORDERS_IN_PROGRESS:
      return { ...state, getExecutedOrdersInProgress: payload.data };
    case ORDERS_ACTIONS.HANDLE_HAS_MORE_EXECUTED_ORDERS_TO_LOAD:
      return { ...state, hasMoreExecutedOrdersToLoad: payload.data };
    case ORDERS_ACTIONS.HANDLE_GET_EXECUTED_ORDERS_RECEIVED:
      return { ...state, executedOrders: state.executedOrders.concat(payload.data.orders) };
    case ORDERS_ACTIONS.HANDLE_NEW_ORDER_PLACED:
      return { ...state, activeOrders: [payload.data, ...state.activeOrders] };
    case ORDERS_ACTIONS.HANDLE_ORDER_CANCELLED:
      return { ...state, activeOrders: filter(state.activeOrders, f => f.order_id !== payload.data.order_id) };
    case ORDERS_ACTIONS.HANDLE_ORDER_MATCHED:
      return {
        ...state,
        activeOrders: filter(state.activeOrders, f => f.order_id !== payload.data.order_id),
        executedOrders: [payload.data, ...state.executedOrders],
      };
    default:
      return state;
  }
};
