import { isIOS } from 'react-device-detect';

import { MAX_MOBILE_WIDTH, MAX_MOBILE_XS_WIDTH, SMALL_DESKTOP_WIDTH } from '../../config/constants';

class MediaQueryHelper {
  constructor() {
    this.mediaQueries = {};
  }

  addMediaQueryListeners = callback => {
    const supportedMediaQueries = this.getSupportedMediaQueries();
    supportedMediaQueries.forEach(mQuery => {
      const mql = window.matchMedia(mQuery.value);
      mql.addListener(this.checkQuery);
      this.mediaQueries[mQuery.value] = { name: mQuery.name, mql, callback };
    });
  };

  removeMediaQueryListeners = () => {
    const supportedMediaQueries = this.getSupportedMediaQueries();
    supportedMediaQueries.forEach(mediaQuery => {
      const cachedMediaQuery = this.mediaQueries[mediaQuery.value];
      if (cachedMediaQuery) {
        cachedMediaQuery.mql.removeListener(this.checkQuery);
      }
    });
  };

  checkQuery = event => {
    const query = this.mediaQueries[event.media];
    if (query && typeof query.callback === 'function') {
      const result = {};
      result[query.name] = event.matches;
      query.callback(result);
    }
  };

  getSupportedMediaQueries = () => {
    return [
      { name: 'mobileXS', value: `(max-width: ${MAX_MOBILE_XS_WIDTH}px)` },
      { name: 'mobile', value: `(max-width: ${MAX_MOBILE_WIDTH}px)` },
      { name: 'desktopXS', value: `(max-width: ${SMALL_DESKTOP_WIDTH}px) and (min-width: ${MAX_MOBILE_WIDTH + 1}px)` },
    ];
  };

  isMobileResolution = () => {
    return window.innerWidth <= MAX_MOBILE_WIDTH;
  };

  isIphoneX = () => {
    const ratio = window.devicePixelRatio || 1;

    const screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    return isIOS && screen.width === 1125 && screen.height === 2436;
  };
}

export default new MediaQueryHelper();
