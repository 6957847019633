import React from 'react';

import '../styles/supported-browser.scss';

export const SupportedBrowser = props => {
  const { name, version, image } = props;

  return (
    <div className="supported-browser">
      <img src={image} alt="" />
      <p className="supported-browser__name">{name}</p>
      <p className="supported-browser__version">{`v${version}+`}</p>
    </div>
  );
};
