import axios from 'axios';
import { setLanguage } from 'redux-i18n';
import isEmpty from 'lodash/isEmpty';

import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';
import { handleUserInfoUpdated, handleUserProfilePhotoUpdated, handleUserMFAEnabled, handleUserMFADisabled } from '../../current-user/actions/current-user-actions';

import { USER_SETTINGS_ACTIONS } from '../constants/user-settings-constants';
import { ACCEPTED_UPLOAD_IMAGE_FILES, MAX_UPLOAD_IMAGE_SIZE } from '../../../config/constants';
import ErrorHelper from '../../../core/helpers/error-helper';
import UserHelper from '../../../core/helpers/user-helper';
import registrationAPI from '../../../api/registration-api';
import UserAPI from '../../../api/user-api';
import AuthAPI from '../../../api/auth-api';

export const getUserProfile = () => {
  return dispatch => {
    dispatch(handleFetchingUserProfile(true));
    return UserAPI.getUserInfo()
      .then(res => {
        dispatch(handleUserProfileReceived(res.data.data));
        dispatch(loadUserProfilePhoto(res.data.data.user_id));
        dispatch(handleFetchingUserProfile(false));
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        dispatch(handleFetchingUserProfile(false));
      });
  };
};

export const changeUserLanguage = language => {
  return (dispatch, getState) => {
    return UserAPI.updateLanguage(language)
      .then(() => {
        const state = getState();
        const userInfo = { ...state.currentUser.info, language };

        dispatch(setLanguage(language));
        dispatch(handleUserInfoUpdated(userInfo));
        dispatch(handleUserLanguageChanged(true));
        dispatch(getUserProfile());
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const updateUserProfile = data => {
  return dispatch => {
    dispatch(handleUpdateUserProfileInProgress(true));
    return UserAPI.updateUserProfile(data)
      .then(() => {
        dispatch(handleUpdateUserProfileInProgress(false));
        dispatch(handleUpdateUserProfileSuccess(true));
        dispatch(getUserProfile());
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        dispatch(handleUpdateUserProfileInProgress(false));
      });
  };
};

export const updateUserNGCFee = useNGCForFees => {
  return (dispatch, getState) => {
    dispatch(handleUpdateUserNGCFeeInProgress(true));
    return UserAPI.updateUserNGCFee(useNGCForFees)
      .then(() => {
        const state = getState();
        const userInfo = { ...state.currentUser.info, useNGCForFees };

        dispatch(handleUserInfoUpdated(userInfo));
        dispatch(handleUpdateUserNGCFeeInProgress(false));
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        dispatch(handleUpdateUserNGCFeeInProgress(false));
      });
  };
};

export const uploadProfileImage = file => {
  return (dispatch, getState) => {
    const state = getState();
    const userInfo = state.currentUser.info;

    if (!file) {
      return;
    }

    if (ACCEPTED_UPLOAD_IMAGE_FILES.indexOf(file.type) === -1) {
      dispatch(toggleInfoModal('USER_SETTINGS.PLEASE_UPLOAD_ONLY_PNG_JPG', 'warning'));
    }

    if (file.size > MAX_UPLOAD_IMAGE_SIZE) {
      dispatch(toggleInfoModal('USER_SETTINGS.YOUR_PROFILE_PICTURE_IS_TOO_BIG', 'warning'));
    } else {
      const fileName = `${userInfo.id}.jpg`;
      const uploadedFile = file;

      dispatch(handleUploadUserProfilePhotoInProgress(true));
      try {
        return UserAPI.getUploadUserFileRoute(fileName, 'profile').then(res => {
          const imageUploadUrl = res && res.data && res.data.data && res.data.data.upload_url;
          return UserAPI.uploadUserFile(file, imageUploadUrl).then(() => {
            return updateUserPhotoUrl(fileName, 'profile', uploadedFile)
              .then(() => {
                UserAPI.saveProfilePhoto(fileName);
                if (imageUploadUrl) {
                  const imageUrl = imageUploadUrl.slice(0, imageUploadUrl.indexOf('?'));
                  dispatch(handleUserProfilePhotoUpdated(`${imageUrl}?${new Date().getTime()}`));
                }
                dispatch(handleUploadUserProfilePhotoInProgress(false));
              })
              .catch(error => {
                dispatch(handleUploadUserProfilePhotoInProgress(false));
                if (axios.isCancel(error)) {
                  ErrorHelper.handleErrorStatusCode(error);
                } else {
                  dispatch(toggleInfoModal('USER_SETTINGS.ERROR_WHILE_UPLOADING_PHOTO', 'error'));
                }
              });
          });
        });
      } catch (error) {
        dispatch(toggleInfoModal('USER_SETTINGS.ERROR_WHILE_UPLOADING_PHOTO', 'error'));
        dispatch(handleUploadUserProfilePhotoInProgress(false));
      }
    }
  };
};

export const telephoneAsyncValidation = (values, previousErrors, currentFieldName, resolve, reject) => {
  return dispatch => {
    dispatch(handleValidatePhoneNumberInProgress(true));
    return registrationAPI
      .validatePhoneNumber(values.phone_number)
      .then(response => {
        dispatch(handleValidatePhoneNumberInProgress(false));
        if (response && response.data && !response.data.data.isValid) {
          const fieldName = 'phone_number';
          reject(Object.assign({}, previousErrors, { [fieldName]: 'USER_SETTINGS.INVALID_PHONE_FORMAT' }));
        } else {
          const prevErrors = previousErrors;
          if (prevErrors && prevErrors.phone_number) delete prevErrors.phone_number;

          if (prevErrors && !isEmpty(prevErrors)) {
            reject(Object.assign({}, prevErrors));
          } else {
            resolve();
          }
        }
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
        dispatch(handleValidatePhoneNumberInProgress(false));
        reject({ telephone: 'SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN' });
      });
  };
};

const updateUserPhotoUrl = (fileName, imageType, file) => {
  let imageUploadUrl;
  return UserAPI.getUploadUserFileRoute(fileName, imageType).then(res => {
    imageUploadUrl = res && res.data && res.data.data && res.data.data.upload_url;
    return UserAPI.uploadUserFile(file, imageUploadUrl);
  });
};

export const loadUserProfilePhoto = userId => {
  return dispatch => {
    const url = UserHelper.getPhotoUrl(userId, true);
    dispatch(handleUserProfilePhotoLoaded(url));
  };
};

export const changePassword = data => {
  return dispatch => {
    dispatch(handleChangePasswordInProgress(true));
    return AuthAPI.changePassword(data)
      .then(res => {
        dispatch(handleChangePasswordInProgress(false));
        if (res.data && res.data.data && res.data.data.changed_password) {
          dispatch(toggleInfoModal('USER_SETTINGS.YOUR_PASSWORD_IS_SUCCESSFULLY_CHANGED', 'success'));
        }
      })
      .catch(error => {
        dispatch(handleChangePasswordInProgress(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const getMFAInfo = () => {
  return dispatch => {
    return AuthAPI.getMFAInfo()
      .then(res => {
        if (res.data.data) {
          dispatch(handleMFAInfoReceived(res.data.data));
        }
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const enableMFA = (token, secret) => {
  return dispatch => {
    return AuthAPI.enableMFA(token, secret)
      .then(res => {
        if (res.data.data) {
          dispatch(handleMFAEnabled(res.data.data));
          dispatch(handleUserMFAEnabled(res.data.data));
          dispatch(toggleInfoModal('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION_ENABLED', 'success'));
        }
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const disableMFA = token => {
  return dispatch => {
    return AuthAPI.disableMFA(token)
      .then(res => {
        if (res.data.data) {
          dispatch(handleMFADisabled(res.data.data));
          dispatch(handleUserMFADisabled(res.data.data));
          dispatch(toggleInfoModal('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION_DISABLED', 'success'));
        }
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const getMFARecoveryCodes = () => {
  return dispatch => {
    return AuthAPI.getMFARecoveryCodes()
      .then(res => {
        if (res.data && res.data.data && res.data.data.recovery_codes) {
          dispatch(handleMFARecoveryCodesReceived(res.data.data.recovery_codes));
          dispatch(toggleInfoModal('USER_SETTINGS.MFA_RECOVERY_CODES_GENERATED', 'success'));
        }
      })
      .catch(error => {
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const handleValidatePhoneNumberInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_VALIDATE_PHONE_NUMBER_IN_PROGRESS,
  data,
});

export const handleUploadUserProfilePhotoInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_UPLOAD_USER_PROFILE_PHOTO_IN_PROGRESS,
  data,
});

export const handleUpdateUserProfileInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_UPDATE_USER_PROFILE_IN_PROGRESS,
  data,
});

export const handleUpdateUserProfileSuccess = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_UPDATE_USER_PROFILE_SUCCESS,
  data,
});

export const handleUserLanguageChanged = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_USER_LANGUAGE_CHANGED,
  data,
});

export const handlePhoneNumberConfirmation = (phoneNumberConfirmed, phoneNumber) => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_PHONE_NUMBER_CONFIRMATION,
  data: { phoneNumberConfirmed, phoneNumber },
});

export const handleChangePhoneNumberInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.CHANGE_PHONE_NUMBER_IN_PROGRESS,
  data,
});

export const handleUserProfileReceived = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_USER_PROFILE_RECEIVED,
  data,
});

export const handleUserProfilePhotoLoaded = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_USER_PROFILE_PHOTO_LOADED,
  data,
});

export const handleFetchingUserProfile = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_FETCHING_USER_PROFILE,
  data,
});

export const handleChangePasswordInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_CHANGE_PASSWORD_IN_PROGRESS,
  data,
});

export const handleMFAInfoReceived = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_MFA_INFO_RECEIVED,
  data,
});

export const handleMFARecoveryCodesReceived = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_MFA_RECOVERY_CODES_RECEIVED,
  data,
});

export const handleMFAEnabled = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_MFA_ENABLED,
  data,
});

export const handleMFADisabled = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_MFA_DISABLED,
  data,
});

export const handleUpdateUserNGCFeeInProgress = data => ({
  type: USER_SETTINGS_ACTIONS.HANDLE_UPDATE_NGC_FEE_IN_PROGRESS,
  data,
});
