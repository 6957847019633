import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { bindRTListener } from '../../../real-time-service/actions/real-time-service-actions';

import { REAL_TIME_CHANNELS } from '../../../../config/constants';
import { MARKET_DEPTH_CHART_COLORS } from '../../constants/exchange-constants';
import MiniLoader from '../../../shared-components/mini-loader/mini-loader';

import '../../styles/market-depth-chart.scss';

class MarketDepthChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      symbol: props.symbol,
      marketDepth: props.orderBook,
      prices: [],
      totalAmounts: [],
    };
    this.willUnmount = false;
  }

  componentDidMount = () => {
    const { orderBook } = this.props;
    if (!isEmpty(orderBook)) {
      this.initializeData();
    }
  };

  componentDidUpdate = prevProps => {
    const { orderBook, fetchingData } = this.props;

    if (prevProps.fetchingData && !fetchingData && !isEmpty(orderBook)) {
      this.initializeData();
    }
  };

  componentWillUnmount = () => {
    this.willUnmount = true;
  };

  initializeData = () => {
    const { symbol, orderBook, bindRTListener } = this.props;

    const result = this.calculateMarketDepth(orderBook);
    this.setState({ symbol, marketDepth: orderBook, prices: result.prices, totalAmounts: result.totalAmounts }, () => {
      // We need to rebind callback as it's deleted at this point
      bindRTListener(`${REAL_TIME_CHANNELS.LEVEL2}/${symbol}`, payload => {
        if (!this.willUnmount) {
          const data = JSON.parse(payload.toString());

          const bids = data.bids.map(a => ({ price: a[0], amount: a[1] }));
          const asks = data.asks.map(a => ({ price: a[0], amount: a[1] }));

          const depth = { bids, asks };
          const updates = this.calculateMarketDepth(depth);

          this.setState({ marketDepth: depth, ...updates });
        }
      });
    });
  };

  calculateMarketDepth = data => {
    if (!data.bids || !data.asks) {
      return { prices: null, totalAmounts: null };
    }

    let bids = [];
    if (data.bids.length) {
      bids = this.calculateMarketDepthList(data.bids.slice(), true);
    }

    let asks = [];
    if (data.asks.length) {
      asks = this.calculateMarketDepthList(data.asks.slice());
    }

    return {
      prices: bids.map(bid => bid.price).concat(asks.map(ask => ask.price)),
      totalAmounts: [
        bids.map(bid => bid.amount).concat(Array(asks.length).fill(null)),
        Array(bids.length)
          .fill(null)
          .concat(asks.map(ask => ask.amount)),
      ],
    };
  };

  calculateMarketDepthList(list, reversed = false) {
    let amountSum = 0;

    // Sort list as it might be unsorted at this point
    list.sort((x1, x2) => (reversed ? x2.price - x1.price : x1.price - x2.price));

    list = list.map(item => {
      amountSum += item.amount;

      return {
        price: item.price,
        amount: amountSum,
      };
    });

    if (reversed) {
      list.sort((x1, x2) => x1.price - x2.price);
    }

    return list;
  }

  render = () => {
    const { t } = this.context;
    const { baseCurrency, quoteCurrency, fetchingData } = this.props;
    const { prices, totalAmounts } = this.state;

    return (
      <div className="market-depth-chart">
        {(() => {
          if (fetchingData) {
            return <MiniLoader />;
          } else if (!fetchingData && (!prices || !prices.length)) {
            return <span>{t('EXCHANGE.NO_DATA')}</span>;
          }
          return (
            <Line
              data={{
                labels: prices,
                datasets: [
                  {
                    label: 'bid',
                    lineTension: 0,
                    data: totalAmounts[0],
                    fill: true,
                    pointRadius: 0,
                    backgroundColor: MARKET_DEPTH_CHART_COLORS.BID_BACKGROUND,
                    borderColor: MARKET_DEPTH_CHART_COLORS.BID_BORDER,
                    borderWidth: 1,
                  },
                  {
                    label: 'ask',
                    lineTension: 0,
                    data: totalAmounts[1],
                    fill: true,
                    pointRadius: 0,
                    backgroundColor: MARKET_DEPTH_CHART_COLORS.ASK_BACKGROUND,
                    borderColor: MARKET_DEPTH_CHART_COLORS.ASK_BORDER,
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                animation: {
                  duration: 0,
                  onComplete: () => {
                    if (this.hoveredItem) {
                      const event = new MouseEvent('mousemove', {
                        screenX: this.hoveredItem.screenX,
                        screenY: this.hoveredItem.screenY,
                        clientX: this.hoveredItem.clientX,
                        clientY: this.hoveredItem.clientY,
                        ctrlKey: this.hoveredItem.ctrlKey,
                        shiftKey: this.hoveredItem.shiftKey,
                        altKey: this.hoveredItem.altKey,
                      });
                      this.hoveredItem.target.dispatchEvent(event);
                    }
                  },
                },
                elements: {
                  line: {
                    tension: 0,
                  },
                },
                hover: {
                  intersect: false,
                },
                onHover: (event, activeElements) => {
                  this.hoveredItem = activeElements.length ? event : null;
                },
                maintainAspectRatio: false,
                tooltips: {
                  intersect: false,
                  caretPadding: 6,
                  mode: 'index',
                  position: 'nearest',
                  footerFontStyle: 'normal',
                  displayColors: false,
                  callbacks: {
                    title: tooltipItems => {
                      return `${tooltipItems[0].datasetIndex === 0 ? t('EXCHANGE.BID_PRICE') : t('EXCHANGE.ASK_PRICE')}: ${tooltipItems[0].xLabel}`;
                    },
                    label: tooltipItem => {
                      return `${t('EXCHANGE.MARKET_DEPTH_VOLUME', { n: baseCurrency })}: ${parseFloat(tooltipItem.yLabel).toCustomFixed(8)}`;
                    },
                    footer: tooltipItems => {
                      return `${t('EXCHANGE.MARKET_DEPTH_VOLUME', { n: quoteCurrency })}: ${(parseFloat(tooltipItems[0].xLabel) * tooltipItems[0].yLabel).toCustomFixed(8)}`;
                    },
                  },
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        autoSkipPadding: 50,
                        padding: 10,
                        maxRotation: 0,
                      },
                    },
                  ],
                },
              }}
              legend={{ display: false }}
            />
          );
        })()}
      </div>
    );
  };
}

MarketDepthChart.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    fetchingData: state.exchange.orderBook.getOrderBookInProgress,
    orderBook: state.exchange.orderBook.items,
    symbol: state.exchange.selectedPair.name,
    baseCurrency: state.exchange.selectedPair.base_currency,
    quoteCurrency: state.exchange.selectedPair.quote_currency,
  };
};

const mapDispatchToProps = dispatch => ({
  bindRTListener: (topic, callback) => {
    dispatch(bindRTListener(topic, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketDepthChart);
