import React, { Component } from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class PrivateRoute extends Component {
  renderRoute = props => {
    const { t } = this.context;
    const { component: Component, routes, loggedIn, requiresAuth, actionCallback, dispatch } = this.props;

    if (!loggedIn && requiresAuth) {
      if (actionCallback) {
        actionCallback(t, props.history, dispatch);
        props.history.goBack();
        return null;
      }
      return (
        <Redirect
          to={{
            pathname: '/home',
            search: (props.location && props.location.search) || null,
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} routes={routes} />;
  };

  render = () => {
    const { component: Component, loggedIn, routes, ...rest } = this.props;
    return <Route {...rest} render={props => this.renderRoute(props)} />;
  };
}

PrivateRoute.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    loggedIn: state.currentUser.loggedIn,
    userInfo: state.currentUser.info,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
