import axios from 'axios';

class ExchangeApi {
  static getAssets = () => {
    return axios({
      method: 'GET',
      url: '/assets',
    });
  };

  static getPairs = () => {
    return axios({
      method: 'GET',
      url: '/pairs',
    });
  };

  static getMarketDepth = pair => {
    return axios({
      method: 'GET',
      url: `/orderbookfull`,
      params: {
        pair: pair,
      },
    });
  };

  static getMarketPricesData = data => {
    return axios({
      method: 'POST',
      url: '/market/data',
      data: {
        pairs: data,
      },
    });
  };

  static getTradeHistory = data => {
    return axios({
      method: 'GET',
      url: `/last_trades`,
      params: {
        pair: data.pair,
      },
    });
  };
}

export default ExchangeApi;
