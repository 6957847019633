import HelperFunctions from '../../core/helpers/helper-functions.js';
import { FACEBOOK_APP_ID, EMAIL_CRITERIA } from '../../config/constants';

class FacebookHelper {
  constants = {
    GRAPH_API_VERSION: 'v3.0',
    SDK_JS_URL: 'https://connect.facebook.net/en_US/sdk.js',
    SCOPE_PARAMS: 'public_profile,email,user_birthday',
    FIELDS: 'id,email,first_name,last_name,birthday',
  };

  initialize = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: this.constants.GRAPH_API_VERSION,
      });
      window.FB.AppEvents.logPageView();
    };

    // Load the SDK asynchronously
    ((d, s, id) => {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = this.constants.SDK_JS_URL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  login = () => {
    const promise = new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          if (response.status !== 'connected') {
            if (!response.status) {
              // User closed FB login modal
              resolve();
            } else {
              reject(response);
            }
          } else {
            resolve(this.getUserData(response.authResponse.userID));
          }
        },
        {
          scope: this.constants.SCOPE_PARAMS,
        }
      );
    });

    return promise;
  };

  logout = () => {
    const promise = new Promise((resolve, reject) => {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          window.FB.logout(res => {
            if (!res || res.error) {
              reject(res);
            } else {
              resolve(res);
            }
          });
        } else {
          resolve(response);
        }
      });
    });

    return promise;
  };

  getUserData = userId => {
    const promise = new Promise((resolve, reject) => {
      window.FB.api(userId + `/?fields=${this.constants.FIELDS}`, [this.constants.SCOPE_PARAMS], user => {
        if (!user) {
          reject(user);
        } else {
          user.username = HelperFunctions.removeDiacritics(`${user.first_name}${user.last_name}`.replace(/\s+/g, '')); // Remove empty spaces
          user.email = EMAIL_CRITERIA.test(user.email) ? user.email : ''; // Check email validity
          user.token = window.FB.getAccessToken();
          resolve(user);
        }
      });
    });

    return promise;
  };
}

export default new FacebookHelper();
