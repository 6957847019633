import axios from 'axios';

import { PLATFORM, TRADER_API_URL } from '../config/constants';
import CookiesWrapper from '../core/helpers/cookies-wrapper';

class RegistrationApi {
  static registerAccount(data) {
    const payload = { ...data, device_uuid: CookiesWrapper.getCookie('fingerprint') };

    return axios({
      method: 'post',
      url: '/register',
      data: payload,
      transformRequest: [
        function(data, headers) {
          headers.platform = PLATFORM;
          headers['Content-Type'] = 'application/json';

          return JSON.stringify(data);
        },
      ],
    });
  }

  static getCountries = () => {
    return axios({
      method: 'get',
      url: '/country/all',
      baseURL: TRADER_API_URL,
      transformRequest: [
        function(data, headers) {
          delete headers['request_id'];
        },
      ],
    }).then(response => (response.data.data ? response.data.data : []));
  };

  static detectCountry = () => {
    return axios({
      method: 'get',
      url: '/country/detect',
    });
  };

  static validatePhoneNumber = phoneNumber => {
    return axios({
      method: 'POST',
      url: '/sms/lookup',
      baseURL: TRADER_API_URL,
      data: { phone_number: phoneNumber },
      transformRequest: [
        function(data, headers) {
          headers['Content-Type'] = 'application/json';
          delete headers['request_id'];
          return JSON.stringify(data);
        },
      ],
    });
  };

  static verifyAndSavePhoneNumber = (phone, code) => {
    return axios({
      url: '/sms/verify_and_save',
      method: 'POST',
      data: {
        phone_number: phone,
        code,
      },
    });
  };

  static sendVerificationCode = phone => {
    return axios({
      url: '/sms/send',
      method: 'POST',
      data: {
        phone_number: phone,
      },
    }).then(response => (response.data.data ? response.data.data : []));
  };

  static resendConfirmationEmail() {
    return axios({
      method: 'post',
      url: 'user/registration/resend_email',
    });
  }
}

export default RegistrationApi;
