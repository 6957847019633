import isEmpty from 'lodash/isEmpty';

import { CURRENT_USER_ACTIONS } from '../constants/current-user-constants';
import CacheHelper from '../../../core/helpers/cache-helper';

const authTokensFromCache = CacheHelper.getAuthTokens();
const currentUserInitialState = {
  loggedIn: !isEmpty(authTokensFromCache),
  authTokens: authTokensFromCache,
  info: CacheHelper.getUserInfo(),
  verificationEmailResent: false,
  userProfileImageUrl: '',
  showNotificationBar: true,
};

export function currentUser(state = currentUserInitialState, payload) {
  switch (payload.type) {
    case CURRENT_USER_ACTIONS.HANDLE_LOGIN_SUCCESS:
      return { ...state, loggedIn: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_LOGOUT_SUCCESS:
      return { ...state, loggedIn: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_AUTH_TOKENS_UPDATED:
      if (payload.data.persistTokens) {
        CacheHelper.setAuthTokens(payload.data.authTokens);
      }
      return { ...state, authTokens: payload.data.authTokens };
    case CURRENT_USER_ACTIONS.HANDLE_USER_INFO_UPDATED:
      CacheHelper.setUserInfo(payload.data);
      return { ...state, info: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_VERIFICATION_EMAIL_RESENT:
      return { ...state, verificationEmailResent: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_PROFILE_PHOTO_UPDATED:
      return { ...state, userProfileImageUrl: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_NOTIFICATION_BAR_VISIBILITY:
      return { ...state, showNotificationBar: payload.data };
    case CURRENT_USER_ACTIONS.HANDLE_USER_MFA_ENABLED: {
      const authTokens = { ...state.authTokens, token: payload.data.jwt_token };
      CacheHelper.setAuthTokens(authTokens);
      CacheHelper.setUserInfo({ ...state.info, mfaEnabled: true });

      return { ...state, info: { ...state.info, mfaEnabled: true }, authTokens };
    }
    case CURRENT_USER_ACTIONS.HANDLE_USER_MFA_DISABLED: {
      const authTokens = { ...state.authTokens, token: payload.data.jwt_token };
      CacheHelper.setAuthTokens(authTokens);
      CacheHelper.setUserInfo({ ...state.info, mfaEnabled: false });

      return { ...state, info: { ...state.info, mfaEnabled: false }, authTokens };
    }
    default:
      return state;
  }
}
