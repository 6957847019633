import React, { Component } from 'react';

import HelperFunctions from '../../../core/helpers/helper-functions';
import RegisterForm from './register-form';
import AuthorizationPanel from './header-bar';

import '../styles/registration.scss';

class Register extends Component {
  componentDidMount = () => {
    HelperFunctions.generateFingerprint();
  };

  render = () => {
    return (
      <div className="registration-wrapper">
        <AuthorizationPanel />
        <RegisterForm />
      </div>
    );
  };
}

export default Register;
