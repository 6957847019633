import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import ModalHelper from '../../../core/helpers/modal-helper';

const GenericModal = props => {
  const handleBackgroundScrollStop = shouldStop => {
    if (props.allowbackgroundscroll !== 'true') {
      if (shouldStop) {
        ModalHelper.handleAddScrollStop();
      } else {
        ModalHelper.handleRemoveScrollStop();
      }
    }
  };

  const onEnterModal = () => {
    handleBackgroundScrollStop(true);

    if (props.dontcenter !== 'true') {
      ModalHelper.handleModalCentering(props.className);
    }

    if (props.onEnter) {
      props.onEnter();
    }
  };

  const onExitModal = () => {
    handleBackgroundScrollStop(false);

    if (props.onExit) {
      props.onExit();
    }
  };

  const onHideModal = () => {
    handleBackgroundScrollStop(false);

    if (props.onHide) {
      props.onHide();
    }
  };

  return <Modal {...props} onEnter={onEnterModal} onExit={onExitModal} onHide={onHideModal} />;
};

export default GenericModal;
