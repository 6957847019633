import axios from 'axios';

export default class PortfolioAPI {
  static getExchangePortfolio = () => {
    return axios({
      method: 'GET',
      url: '/portfolio/exchange',
    });
  };

  static getExchangePortfolioCache = () => {
    return axios({
      method: 'GET',
      url: '/portfolio/exchange/cache',
    });
  };

  static getWalletPortfolio = () => {
    return axios({
      method: 'GET',
      url: '/portfolio/wallet',
    });
  };

  static transferFundsToWallet = (terminalId, amount, currencyCode, token) => {
    return axios({
      method: 'POST',
      url: '/portfolio/transfer/wallet',
      data: {
        terminal_id: terminalId,
        amount,
        currency_code: currencyCode,
        token,
      },
    });
  };
}
