import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';
import find from 'lodash/find';

import { handleDisplayAppPrompt } from '../../shared-components/prompt/actions/prompt-actions';
import { getAutoLoginUrl } from '../../registration/actions/registration-actions';
import { handleDisplayWithdrawModal, handleDisplayDepositModal, handleSelectedWalletCurrency } from '../actions/wallet-actions';

import { WALLET_ICONS_URL, AUTOLOGIN_DESTIONATIOS, WEB_TRADER_AUTOLOGIN_PAGES } from '../../../config/constants';
import { WALLET_ACTIONS } from '../constants/wallet-constants';
import TradingHelper from '../../../core/helpers/trading-helper';
import Amount from '../../shared-components/amount/components/amount';

import '../styles/wallet-currency-item.scss';

class WalletCurrencyItem extends Component {
  handleButtonPressed = event => {
    const action = event.currentTarget.dataset.action;

    this.onActionPerformed(+action);
  };

  onActionPerformed = actionType => {
    const { t } = this.context;
    const { loggedIn, item, history, handleDisplayWithdrawModal, handleDisplayDepositModal, handleSelectedWalletCurrency, handleDisplayAppPrompt } = this.props;

    if (loggedIn) {
      handleSelectedWalletCurrency(item);
      if (actionType === WALLET_ACTIONS.DEPOSIT) {
        if (TradingHelper.isFiatCurrency(item.asset_group)) {
          this.showLoginToWalletMessage(item.symbol);
        } else {
          handleDisplayDepositModal(true);
        }
      } else if (actionType === WALLET_ACTIONS.WITHDRAW) {
        if (item.free && +item.free) {
          handleDisplayWithdrawModal(true);
        } else {
          this.showNoFundsAvailableMessage(item.symbol);
        }
      }
    } else {
      const promptSettings = {
        show: true,
        title: t('LOGIN.SIGN_IN_OR_CREATE_ACCOUNT'),
        description: t('LOGIN.SIGN_IN_OR_REGISTER_NOTE'),
        ctaText: 'LOGIN.SIGN_IN_OR_CREATE_ACCOUNT',
        submitCallback: () => {
          history.push('/login');
        },
      };

      handleDisplayAppPrompt(promptSettings);
    }
  };

  getCurrencyRate = item => {
    const { currencies } = this.props;
    let currencyRate;

    if (currencies.crypto && currencies.crypto.length && !TradingHelper.isFiatCurrency(item.asset_group)) {
      const currency = find(currencies.crypto, ['currency', item.symbol]);

      if (currency) {
        currencyRate = +currency.currency_rate;
      }
    }

    return currencyRate;
  };

  showNoFundsAvailableMessage = symbol => {
    const { t } = this.context;
    const { handleDisplayAppPrompt } = this.props;

    const promptSettings = {
      show: true,
      title: t('WALLET.WITHDRAW_CURRENCY_TO_WALLET', { currency: symbol }),
      description: 'WALLET.NO_AVAILABLE_BALANCE',
    };

    handleDisplayAppPrompt(promptSettings);
  };

  showLoginToWalletMessage = symbol => {
    const { t } = this.context;
    const { currencies, handleDisplayAppPrompt, getAutoLoginUrl } = this.props;
    const urlParams = { page: WEB_TRADER_AUTOLOGIN_PAGES.WALLET, dest: AUTOLOGIN_DESTIONATIOS.WEB_TRADER };

    const currency = find(currencies.fiat, ['currency', symbol]);

    if (currency) {
      urlParams.terminal_id = currency.terminal_id;
    }

    const promptSettings = {
      show: true,
      title: t('WALLET.DEPOSIT_CURRENCY', { n: symbol }),
      description: [<div key="1">{t('WALLET.FIAT_DEPOSIT_NOTE1', { n: symbol })}</div>, <br key="2" />, <div key="3">{t('WALLET.FIAT_DEPOSIT_NOTE2', { n: symbol })}</div>],
      ctaText: 'WALLET.OPEN_NAGA_WALLET',
      submitCallback: () => {
        getAutoLoginUrl(urlParams);
      },
    };

    handleDisplayAppPrompt(promptSettings);
  };

  renderDesktop = () => {
    const { t } = this.context;
    const { item, desktopXS } = this.props;
    const currencyRate = this.getCurrencyRate(item);

    return (
      <div className="wallet-currency-item">
        <div className="wallet-currency-item__data">
          <div className="wallet-currency-item__data__item">
            <div className="wallet-currency-item__data__item__image">
              <img src={`${WALLET_ICONS_URL}${item.symbol.toLowerCase()}.png`} alt={item.symbol.toLowerCase()} />
            </div>
            <div title={item.name} className="wallet-currency-item__data__item-currency">
              <span>{item.symbol}</span>
              <span>{item.name}</span>
            </div>
          </div>
          <div className="wallet-currency-item__data__item">
            <Amount value={item.total || 0} decimals={item.display_digits} />
          </div>
          <div className="wallet-currency-item__data__item">
            <Amount value={item.locked || 0} decimals={item.display_digits} />
          </div>
          <div className="wallet-currency-item__data__item">
            <Amount value={item.free || 0} decimals={item.display_digits} />
          </div>
          {!TradingHelper.isFiatCurrency(item.asset_group) && !desktopXS && (
            <div className="wallet-currency-item__data__item">
              <Amount value={item.total && currencyRate ? +item.total / currencyRate : 0} decimals="2" prefix="$" />
            </div>
          )}
        </div>
        <div className="wallet-currency-item__actions">
          <Button data-action={WALLET_ACTIONS.DEPOSIT} onClick={this.handleButtonPressed}>
            {t('WALLET.DEPOSIT')}
          </Button>
          <Button data-action={WALLET_ACTIONS.WITHDRAW} onClick={this.handleButtonPressed} disabled={!parseFloat(item.free)}>
            {t('WALLET.WITHDRAW')}
          </Button>
        </div>
      </div>
    );
  };

  renderMobile = () => {
    const { t } = this.context;
    const { item } = this.props;
    const currencyRate = this.getCurrencyRate(item);

    return (
      <div className="wallet-currency-item mobile">
        <div className="wallet-currency-item__data__item">
          <div className="wallet-currency-item__data__item__image">
            <img src={`${WALLET_ICONS_URL}${item.symbol.toLowerCase()}.png`} alt={item.symbol.toLowerCase()} />
          </div>
          <div>
            <span title={item.name} className="wallet-currency-item__data__item-currency">
              {item.symbol} - {item.name}
            </span>
            <div className="wallet-currency-item__data__item-desc">
              {t('WALLET_COLUMNS.AVAILABLE_BALANCE')}: <Amount value={item.free || 0} decimals={item.display_digits} colored />
            </div>
          </div>
        </div>
        <div className="wallet-currency-item__data__item__body">
          <div className="wallet-currency-item__data__item__body__total">
            <div className="wallet-currency-item__data__item__body__total-title">{t('WALLET_COLUMNS.TOTAL_VALUE')}</div>
            <div className="wallet-currency-item__data__item__body__total-value">
              <Amount value={item.total || 0} decimals={item.display_digits} />
            </div>
          </div>
          <div className="wallet-currency-item__data__item__body__in-order">
            <div className="wallet-currency-item__data__item__body__in-order-title">{t('WALLET_COLUMNS.IN_ORDERS')}</div>
            <div className="wallet-currency-item__data__item__body__in-order-value">
              <Amount value={item.locked || 0} decimals={item.display_digits} />
            </div>
          </div>
          {!TradingHelper.isFiatCurrency(item.asset_group) && (
            <div className="wallet-currency-item__data__item__body__est-value">
              <div className="wallet-currency-item__data__item__body__est-value-title">{t('WALLET_COLUMNS.EST_USD_VALUE')}</div>
              <div className="wallet-currency-item__data__item__body__est-value-value">
                <Amount value={item.total && currencyRate ? +item.total / currencyRate : 0} decimals="2" prefix="$" />
              </div>
            </div>
          )}
        </div>
        <div className="wallet-currency-item__data__item__buttons">
          <Button data-action={WALLET_ACTIONS.DEPOSIT} onClick={this.handleButtonPressed}>
            {t('WALLET.DEPOSIT')}
          </Button>
          <Button data-action={WALLET_ACTIONS.WITHDRAW} onClick={this.handleButtonPressed} disabled={!parseFloat(item.free)}>
            {t('WALLET.WITHDRAW')}
          </Button>
        </div>
      </div>
    );
  };

  render = () => {
    const { isMobile } = this.props;
    return isMobile ? this.renderMobile() : this.renderDesktop();
  };
}

WalletCurrencyItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isMobile: state.mediaQuery.isMobile,
  desktopXS: state.mediaQuery.desktopXS,
  currencies: state.wallet.currencies,
  loggedIn: state.currentUser.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayWithdrawModal: data => {
    dispatch(handleDisplayWithdrawModal(data));
  },
  handleDisplayDepositModal: data => {
    dispatch(handleDisplayDepositModal(data));
  },
  handleSelectedWalletCurrency: data => {
    dispatch(handleSelectedWalletCurrency(data));
  },
  handleDisplayAppPrompt: data => {
    dispatch(handleDisplayAppPrompt(data));
  },
  getAutoLoginUrl: urlParams => {
    dispatch(getAutoLoginUrl(urlParams));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletCurrencyItem));
