import { NAGA_REAL_TIME_SERVICE_ACTIONS } from '../constants/naga-real-time-service-constants';

const nagaRealTimeServiceInitialState = {
  isConnected: false,
};

export const nagaRealTimeService = (state = nagaRealTimeServiceInitialState, payload) => {
  switch (payload.type) {
    case NAGA_REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_NAGA_RT_CONNECTED:
      return { ...state, isConnected: payload.data };
    default:
      return state;
  }
};
