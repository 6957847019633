import { USER_SETTINGS_ACTIONS } from '../constants/user-settings-constants';

const settingsInitialState = {
  userProfile: {},
  fetchingUserProfile: false,
  userProfilePhoto: '',
  languageChanged: false,
  updateUserProfileInProgress: false,
  updateUserProfileSuccess: false,
  uploadUserProfilePhotoInProgress: false,
  validatePhoneNumberInProgress: false,
  changePhoneNumberInProgress: false,
  changePasswordInProgress: false,
  updateUserNGCFeeInProgress: false,
  mfa: {
    qrData: null,
    qrMime: null,
    secret: null,
    recoveryCodes: [],
  },
};

export const userSettings = (state = settingsInitialState, payload) => {
  switch (payload.type) {
    case USER_SETTINGS_ACTIONS.HANDLE_USER_PROFILE_RECEIVED:
      return { ...state, userProfile: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_FETCHING_USER_PROFILE:
      return { ...state, fetchingUserProfile: payload.data, profile: payload.data ? {} : state.profile };
    case USER_SETTINGS_ACTIONS.HANDLE_USER_LANGUAGE_CHANGED:
      return { ...state, languageChanged: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_UPDATE_USER_PROFILE_IN_PROGRESS:
      return { ...state, updateUserProfileInProgress: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_UPDATE_USER_PROFILE_SUCCESS:
      return { ...state, updateUserProfileSuccess: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_UPLOAD_USER_PROFILE_PHOTO_IN_PROGRESS:
      return { ...state, uploadUserProfilePhotoInProgress: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_VALIDATE_PHONE_NUMBER_IN_PROGRESS:
      return { ...state, validatePhoneNumberInProgress: payload.data };
    case USER_SETTINGS_ACTIONS.CHANGE_PHONE_NUMBER_IN_PROGRESS:
      return { ...state, changePhoneNumberInProgress: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_USER_PROFILE_PHOTO_LOADED:
      return { ...state, userProfilePhoto: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_CHANGE_PASSWORD_IN_PROGRESS:
      return { ...state, changePasswordInProgress: payload.data };
    case USER_SETTINGS_ACTIONS.HANDLE_MFA_INFO_RECEIVED:
      return {
        ...state,
        mfa: {
          qrData: payload.data.qr_data,
          secret: payload.data.mfa_secret,
          qrMime: payload.data.mime,
          recoveryCodes: [],
        },
      };
    case USER_SETTINGS_ACTIONS.HANDLE_MFA_RECOVERY_CODES_RECEIVED:
      return { ...state, mfa: { ...state.mfa, recoveryCodes: payload.data } };
    case USER_SETTINGS_ACTIONS.HANDLE_MFA_ENABLED:
      return { ...state, mfa: { ...state.mfa, recoveryCodes: payload.data.recovery_codes } };
    case USER_SETTINGS_ACTIONS.HANDLE_MFA_DISABLED:
      return { ...state, mfa: { qrData: null, qrMime: null, secret: null, recoveryCodes: [] } };
    case USER_SETTINGS_ACTIONS.HANDLE_UPDATE_NGC_FEE_IN_PROGRESS:
      return { ...state, updateUserNGCFeeInProgress: payload.data };
    default:
      return state;
  }
};
