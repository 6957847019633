import { PROMPT_ACTIONS } from '../constants/prompt-constants';

const initialPromptState = {
  show: false,
  title: '',
  description: '',
  ctaText: '',
  promptClass: '',
  submitCallback: null,
  closeModalCallback: null,
};

export const prompt = (state = initialPromptState, payload) => {
  switch (payload.type) {
    case PROMPT_ACTIONS.HANDLE_DISPLAY_APP_PROMPT:
      if (!payload.data.show) {
        return { ...initialPromptState };
      }

      return {
        ...state,
        show: payload.data.show,
        title: payload.data.title,
        description: payload.data.description,
        ctaText: payload.data.ctaText,
        promptClass: payload.data.promptClass,
        submitCallback: payload.data.submitCallback,
        closeModalCallback: payload.data.closeModalCallback,
      };
    default:
      return state;
  }
};
