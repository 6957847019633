import { PHONE_VERIFICATION_ACTIONS } from '../constants/phone-verification-constants';

const phoneVerifyInitialState = {
  showPhoneVerificationModal: false,
  showPhoneVerificationWarningModal: false,
  phoneVerification: {},
  sendVerificationCodeInProgress: false,
};

export const phoneNumberVerify = (state = phoneVerifyInitialState, payload) => {
  switch (payload.type) {
    case PHONE_VERIFICATION_ACTIONS.HANDLE_SHOW_PHONE_VERIFY_MODAL:
      return { ...state, showPhoneVerificationModal: payload.data };
    case PHONE_VERIFICATION_ACTIONS.HANDLE_PHONE_VERIFICATION:
      return { ...state, phoneVerification: payload.data };
    case PHONE_VERIFICATION_ACTIONS.HANDLE_SHOW_PHONE_VERIFY_WARNING_MODAL:
      return { ...state, showPhoneVerificationWarningModal: payload.data };
    case PHONE_VERIFICATION_ACTIONS.HANDLE_SEND_VERIFICATION_CODE_IN_PROGRESS:
      return { ...state, sendVerificationCodeInProgress: payload.data };
    default:
      return state;
  }
};
