import find from 'lodash/find';

import registrationAPI from '../../../api/registration-api';
import userAPI from '../../../api/user-api';
import { PROFILE_ACTIONS } from '../constants/profile-constants';
import UserHelper from '../../../core/helpers/user-helper';

export const getUserCountryData = countryCode => {
  return dispatch => {
    return registrationAPI.getCountries().then(res => {
      const userCountry = find(res, country => country.code === countryCode);
      dispatch(handleUserCountry(userCountry));
    });
  };
};

export const getUserProfile = () => {
  return dispatch => {
    dispatch(handleGetUserProfileInProgress(true));
    return userAPI
      .getUserInfo()
      .then(res => {
        dispatch(handleUserProfile(res.data.data));
        dispatch(getUserCountryData(res.data.data.country));
        dispatch(loadUserProfilePhoto(res.data.data.user_id));
        dispatch(handleGetUserProfileInProgress(false));
      })
      .catch(error => {
        dispatch(handleGetUserProfileInProgress(false));
      });
  };
};

export const loadUserProfilePhoto = userId => {
  return dispatch => {
    const url = UserHelper.getPhotoUrl(userId, true);
    dispatch(handleUserProfilePhoto(url));
  };
};

export const handleUserCountry = data => ({
  type: PROFILE_ACTIONS.HANDLE_USER_COUNTRY,
  data,
});

export const handleUserProfile = data => ({
  type: PROFILE_ACTIONS.HANDLE_USER_PROFILE,
  data,
});

export const handleUserProfilePhoto = data => ({
  type: PROFILE_ACTIONS.HANDLE_USER_PROFILE_PHOTO,
  data,
});

export const handleUserStatusChanged = data => ({
  type: PROFILE_ACTIONS.HANDLE_USER_STATUS_CHANGED,
  data,
});

export const handleDisplayPopupBlockedModal = data => ({
  type: PROFILE_ACTIONS.HANDLE_DISPLAY_POPUP_BLOCKED_MODAL,
  data,
});

export const handleDisplaySwitchTraderModal = data => ({
  type: PROFILE_ACTIONS.HANDLE_DISPLAY_SWITCH_TRADER_MODAL,
  data,
});

export const handleDisplayUserStatusNotification = data => ({
  type: PROFILE_ACTIONS.HANDLE_DISPLAY_USER_STATUS_NOTIFICATION,
  data,
});

export const handleGetUserProfileInProgress = data => ({
  type: PROFILE_ACTIONS.HANDLE_GET_USER_PROFILE_IN_PROGRESS,
  data,
});

export const handleDisplayUserStatusTooltip = data => ({
  type: PROFILE_ACTIONS.HANDLE_DISPLAY_USER_STATUS_TOOLTIP,
  data,
});

export const handlePhoneNumberVerified = data => ({
  type: PROFILE_ACTIONS.HANDLE_PHONE_NUMBER_VERIFIED,
  data,
});
