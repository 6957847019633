export const PROFILE_ACTIONS = {
  HANDLE_USER_COUNTRY: 'handleUserCountry',
  HANDLE_USER_PROFILE: 'handleUserProfile',
  HANDLE_USER_PROFILE_PHOTO: 'handleUserProfilePhoto',
  HANDLE_USER_STATUS_CHANGED: 'handleUserStatusChanged',
  HANDLE_DISPLAY_POPUP_BLOCKED_MODAL: 'handleDisplayPopupBlockedModal',
  HANDLE_DISPLAY_USER_STATUS_NOTIFICATION: 'handleDisplayUserStatusNotification',
  HANDLE_GET_USER_PROFILE_IN_PROGRESS: 'handleGetUserProfileInProgress',
  HANDLE_DISPLAY_USER_STATUS_TOOLTIP: 'handleDisplayUserStatusTooltip',
  HANDLE_PHONE_NUMBER_VERIFIED: 'handlePhoneNumberVerified',
};
