import UserApi from '../../../api/user-api';
import { showBanner } from '../../in-app-messages/actions/in-app-message-actions';
import { MESSAGE_OPTIONS } from '../../in-app-messages/constants/in-app-messages-constants';
import { REFER_A_FRIEND_ACTIONS } from '../constants/refer-a-friend-constants';

export const sendInviteLink = data => {
  return dispatch => {
    return UserApi.sendInviteLink(data).then(
      () => {
        dispatch(showBanner('REFER_FRIEND.EMAIL_SENT'));
      },
      () => {
        dispatch(showBanner('REFER_FRIEND.EMAIL_FAILURE', '', MESSAGE_OPTIONS.TYPE.ERROR));
      }
    );
  };
};

export const getAllInvitedUsers = () => {
  return dispatch => {
    dispatch(handleGetInvitedUsersInProgress(true));
    return UserApi.getInvitedUsers().then(
      res => {
        dispatch(handleInvitedUsersReceived(res.data.data.referrals));
        dispatch(handleReferralCodeReceived(res.data.data.invite_code));
        dispatch(handleGetInvitedUsersInProgress(false));
      },
      () => {
        dispatch(handleGetInvitedUsersInProgress(false));
      }
    );
  };
};

export const handleGetInvitedUsersInProgress = data => ({
  type: REFER_A_FRIEND_ACTIONS.HANDLE_GET_INVITED_USERS_IN_PROGRESS,
  data,
});

export const handleInvitedUsersReceived = data => ({
  type: REFER_A_FRIEND_ACTIONS.HANDLE_INVITED_USERS_RECEIVED,
  data,
});

export const handleReferralCodeReceived = data => ({
  type: REFER_A_FRIEND_ACTIONS.HANDLE_REFERRAL_CODE_RECEIVED,
  data,
});
