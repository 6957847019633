import isEmpty from 'lodash/isEmpty';

import { IS_PROD_ENV } from '../../../config/constants';
import { IN_APP_MESSAGES_ACTIONS, MESSAGE_OPTIONS } from '../constants/in-app-messages-constants';
import MQTTService from '../../../core/helpers/mqtt-service';

export const subscribeToInAppMessages = () => {
  return (dispatch, getState) => {
    const state = getState();
    const currentTerminal = state.currentUser.currentTerminal;
    const userInfo = state.currentUser.info;

    if (!isEmpty(userInfo) && !isEmpty(currentTerminal)) {
      MQTTService.subscribe(`user_notifications/${userInfo.id}`);

      MQTTService.on(`user_notifications/${userInfo.id}`, message => {
        try {
          let payload = null;

          try {
            payload = JSON.parse(message.toString());
          } catch (err) {}

          if (!IS_PROD_ENV) {
            console.log(payload);
          }

          if (payload) {
            dispatch(showMessage(payload));
          }
        } catch (error) {
          console.log(error);
          console.log(message.toString());
        }
      });
    }
  };
};

export const showMessage = message => ({
  type: IN_APP_MESSAGES_ACTIONS.HANDLE_MESSAGE_RECEIVED,
  message,
});

export const showBanner = (message, title, type = MESSAGE_OPTIONS.TYPE.SUCCESS) => ({
  type: IN_APP_MESSAGES_ACTIONS.HANDLE_MESSAGE_RECEIVED,
  message: { message, title, type, additional_data: { displayType: MESSAGE_OPTIONS.DISPLAY_TYPE.BANNER, autoClose: MESSAGE_OPTIONS.AUTO_CLOSE_PERIOD } },
});

export const toggleInAppMessageModal = () => ({
  type: IN_APP_MESSAGES_ACTIONS.HANDLE_TOGGLE_IN_APP_MESSAGE_MODAL,
});
