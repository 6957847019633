import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import promise from 'promise';

import { setRetryCount } from '../features/jwt-token-refresh/actions/refresh-token-actions';
import { handleEndFetching } from '../features/shared-components/loader/actions/loader-actions';

import { ACCEPT_VERSION_HEADER, API_URL, TOKEN_STRATEGY, PLATFORM, TRADER_API_URL } from './constants';
import StoreInstance from '../index';
import CacheHelper from '../core/helpers/cache-helper';
import HelperFunctions from '../core/helpers/helper-functions';
import { handleAuthTokensUpdated } from '../features/current-user/actions/current-user-actions';
import { signOut } from '../features/registration/actions/registration-actions';
import { handleDisplayAppPrompt } from '../features/shared-components/prompt/actions/prompt-actions';
import CookiesWrapper from '../core/helpers/cookies-wrapper';

const initilizeHttpClient = () => {
  const instance = axios.create();
  instance.defaults.headers.common = {};
  axios.defaults.baseURL = API_URL;

  const blockPageScroll = () => {
    if (document.body.className.indexOf('loader-block-scroll') === -1) {
      document.body.className += ' loader-block-scroll';
    }
  };

  const unblockPageScroll = () => {
    document.body.className = document.body.className.split('loader-block-scroll').join('');
  };

  const handleSessionExpired = () => {
    StoreInstance.dispatch(signOut());
    window.location.href = '/login';
  };

  axios.interceptors.request.use(
    config => {
      const state = StoreInstance.getState();

      blockPageScroll();

      // if request url is towards V2 send appropriate headers
      if (((config.url.startsWith('http') && config.url.indexOf(API_URL) > -1) || config.url.startsWith('/')) && config.method !== 'OPTIONS') {
        const tokens = state.currentUser.authTokens;

        if (!isEmpty(tokens)) {
          config.headers.authorization = `${TOKEN_STRATEGY} ${tokens.token}`;
        }

        config.headers.platform = config.headers.platform || PLATFORM;
        config.headers['accept-version'] = ACCEPT_VERSION_HEADER;
        config.headers.request_id = HelperFunctions.generateRequestId();
        if (config.baseURL !== TRADER_API_URL) {
          config.headers.device_uuid = CookiesWrapper.getCookie('fingerprint');
        }
      }

      return config;
    },
    error => {
      unblockPageScroll();
      StoreInstance.dispatch(handleEndFetching());
      return promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      unblockPageScroll();
      return response;
    },
    error => {
      unblockPageScroll();
      StoreInstance.dispatch(handleEndFetching());

      // retry until new jwt token is received
      if (error) {
        const store = StoreInstance.getState();

        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if (error && error.response && error.response.status === 401) {
          const promptSettings = {
            show: true,
            title: 'SESSION_EXPIRED.TITLE',
            description: 'SESSION_EXPIRED.MESSAGE',
            ctaText: 'SESSION_EXPIRED.BUTTON_TEXT',
            submitCallback: handleSessionExpired,
            closeModalCallback: handleSessionExpired,
          };

          //if new tokens were not provided, logout
          //in case auto-login route were hit, ignore session timeout
          if ((!error.response.data || !error.response.data.jwt_token || !error.response.data.socket_secret) && store.currentUser.authTokens.token && window.location.pathname !== '/auth/token') {
            CacheHelper.clearCachedData();
            StoreInstance.dispatch(handleDisplayAppPrompt(promptSettings));
            return Promise.reject(error);
          }

          const currentRetryCount = store.refreshToken.retryCount;
          const newTokens = {
            token: error.response.data.jwt_token,
            socketSecret: error.response.data.socket_secret,
          };

          if (!isEmpty(currentRetryCount)) {
            if (currentRetryCount[error.config.url] > 5) {
              CacheHelper.clearCachedData();
              if (store.currentUser.authTokens.token) {
                StoreInstance.dispatch(handleDisplayAppPrompt(promptSettings));
              }
              return Promise.reject(error);
            }
          }

          StoreInstance.dispatch(handleAuthTokensUpdated({ authTokens: newTokens, persistTokens: true }));
          StoreInstance.dispatch(setRetryCount(error.config.url));

          return axios(error.config);
        }

        if (error && error.response && error.response.status === 451) {
          CacheHelper.clearCachedData();
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default initilizeHttpClient;
