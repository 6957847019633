import forEach from 'lodash/forEach';

import { DEFAULT_ACCOUNT_NAME, NON_EU_ACCOUNT_NAME } from '../../../config/constants';
import { WALLET_REDUX_ACTIONS } from '../constants/wallet-constants';
import PortfolioAPI from '../../../api/portfolio-api';
import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';
import ErrorHelper from '../../../core/helpers/error-helper';

export const transferFundsToWallet = (terminalId, amount, currencyCode, token) => {
  return dispatch => {
    dispatch(handleTransferringFundsToWallet(true));
    return PortfolioAPI.transferFundsToWallet(terminalId, amount, currencyCode, token)
      .then(res => {
        if (res.data && res.data.data && (res.data.data.Valid || res.data.data.success)) {
          dispatch(handleDisplayWithdrawModal(false));
        } else {
          dispatch(toggleInfoModal('WALLET.WITHDRAWAL_TO_WALLET_ERROR', 'error'));
        }
        dispatch(handleTransferringFundsToWallet(false));
      })
      .catch(error => {
        dispatch(handleTransferringFundsToWallet(false));
        ErrorHelper.handleErrorStatusCode(error);
      });
  };
};

export const getUserWalletPortfolio = () => {
  return (dispatch, getState) => {
    dispatch(handleFetchingUserWalletInformation(true));
    return PortfolioAPI.getWalletPortfolio().then(
      res => {
        let currencies = (res.data && res.data.data) || { crypto: [], fiat: [] };
        if (currencies.fiat && currencies.fiat.length) {
          const userInfo = getState().currentUser.info;
          forEach(currencies.fiat, currency => {
            currency.accountName = `${getAccountName(userInfo)} - ${currency.terminal_id} {WALLET.BALANCE} ${parseFloat(currency.balance).toCustomFixed(2)} ${currency.currency}`;
          });
        }
        dispatch(handleCurrenciesReceived(currencies));
        dispatch(handleFetchingUserWalletInformation(false));
      },
      () => {
        dispatch(handleFetchingUserWalletInformation(false));
      }
    );
  };
};

const getAccountName = userInfo => {
  if (userInfo.isEU) {
    return DEFAULT_ACCOUNT_NAME;
  }
  return NON_EU_ACCOUNT_NAME;
};

export const handleSelectedWalletCurrency = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_SELECTED_WALLET_CURRENCY,
  data,
});

export const handleAssetsReceived = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_ASSETS_RECEIVED,
  data,
});

export const updateAssetsData = data => ({
  type: WALLET_REDUX_ACTIONS.UPDATE_ASSETS_DATA,
  data,
});

export const handleCurrenciesReceived = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_CURRENCIES_RECEIVED,
  data,
});

export const handleFetchingUserWalletInformation = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_FETCHING_USER_WALLET_INFORMATION,
  data,
});

export const handleTransferringFundsToWallet = data => ({
  type: WALLET_REDUX_ACTIONS.TRANSFERRING_FUNDS_TO_WALLET,
  data,
});

export const handleUpdateAssetBalance = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_UPDATE_ASSET_BALANCE,
  data,
});

export const handleDisplayWithdrawModal = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_DISPLAY_WITHDRAW_MODAL,
  data,
});

export const handleDisplayDepositModal = data => ({
  type: WALLET_REDUX_ACTIONS.HANDLE_DISPLAY_DEPOSIT_MODAL,
  data,
});
