import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import { isIOS } from 'react-device-detect';
import isEmpty from 'lodash/isEmpty';

import { subscribeToRTService, unsubscribeFromRTService } from '../../../features/real-time-service/actions/real-time-service-actions';
import { handleMarketChanged, handleSymbolChanged, getOrderBookItems, getUserFees } from '../actions/exchange-actions';

import { REAL_TIME_CHANNELS } from '../../../config/constants';
import { ORDERS_TABS, CHARTS_TABS, DEFAULT_SYMBOL } from '../constants/exchange-constants';
import MediaQueryHelper from '../../../core/helpers/media-query-helper';
import ExchangeOrders from './orders/exchange-orders';
import ExchangeOpenOrder from './orders/exchange-open-order';
import MarketDepth from './market-depth/market-depth';
import OrderBook from './order-book/order-book';
import TradeHistory from './trade-history/trade-history';
import TradingViewChart from './trading-view-chart';
import Markets from './markets';
import SymbolInfo from './symbol-info';
import ExchangeActions from './exchange-actions';
import OrderBookAndTradeHistory from './order-book-and-trade-history';

import '../styles/exchange.scss';

class Exchange extends Component {
  state = {
    selectedOrdersTab: ORDERS_TABS.OPEN_ORDERS,
    selectedChartsTab: CHARTS_TABS.PRICE_CHART,
  };

  constructor(props) {
    super(props);

    //scroll to the top of the page
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  static getDerivedStateFromProps(nextProps) {
    const { location, history, selectedPair } = nextProps;

    // There's no better way to emulate sub-route preselection
    // on parent route selection, hence code below
    if (['/exchange', '/'].indexOf(location.pathname.toLowerCase()) > -1) {
      history.push(`/exchange/${selectedPair.name || DEFAULT_SYMBOL.PAIR}`);
    }

    return null;
  }

  componentDidMount = () => {
    const { selectedPair, match, handleSymbolChanged, orderBookItems, getOrderBookItems, pairs, isRTConnected, selectedMarket, handleMarketChanged, loggedIn, getUserFees } = this.props;

    let pair = match.params.pair && encodeURIComponent(match.params.pair);

    // Get previously selected symbol, if any
    if (!match.params.pair && selectedPair.name) {
      pair = selectedPair.name;
    }

    // Update store with latest values
    if (pair && pair !== selectedPair.name && !isEmpty(pairs)) {
      handleSymbolChanged(pair);
    }

    if (isEmpty(orderBookItems) && (!isEmpty(match.params.pair) || !isEmpty(selectedPair.name))) {
      getOrderBookItems(selectedPair.name || pair);
    }

    if (!isEmpty(selectedPair.quote_currency) && selectedPair.quote_currency !== selectedMarket) {
      handleMarketChanged(selectedPair.quote_currency);
    }

    if (isRTConnected) {
      this.subscribeToSymbolChanges(selectedPair.name);
    }

    if (loggedIn) {
      getUserFees();
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedPair, match, isRTConnected, orderBookItems, handleSymbolChanged, getOrderBookItems, pairs, selectedMarket, handleMarketChanged } = this.props;
    let pair = match.params.pair;
    if ((isEmpty(selectedPair.name) || pair !== selectedPair.name) && !isEmpty(pairs)) {
      let prevSelectedPair = prevProps.match.params.pair;

      pair = encodeURIComponent(pair);
      prevSelectedPair = encodeURIComponent(prevSelectedPair);

      handleSymbolChanged(pair);

      this.unsubscribeFromSymbolChanges(prevSelectedPair);
      this.subscribeToSymbolChanges(pair);

      if (isEmpty(orderBookItems) || (!isEmpty(orderBookItems) && !isEmpty(pair) && !isEmpty(selectedPair.name) && pair !== selectedPair.name)) {
        getOrderBookItems(pair);
      }
    }

    if (!isEmpty(selectedPair.quote_currency) && selectedPair.quote_currency !== selectedMarket && prevProps.selectedPair.name !== selectedPair.name) {
      handleMarketChanged(selectedPair.quote_currency);
    }

    if (!prevProps.isRTConnected && isRTConnected) {
      this.subscribeToSymbolChanges(selectedPair.name);
    }
  };

  componentWillUnmount = () => {
    const { selectedPair } = this.props;

    this.unsubscribeFromSymbolChanges(selectedPair.name);
  };

  subscribeToSymbolChanges = pair => {
    if (!isEmpty(pair)) {
      const { subscribeToRTService } = this.props;
      const topics = [
        `${REAL_TIME_CHANNELS.ORDER_CANCELLED}/${pair}`,
        `${REAL_TIME_CHANNELS.ORDER_MATCHED}/${pair}`,
        `${REAL_TIME_CHANNELS.ORDER_PLACED}/${pair}`,
        `${REAL_TIME_CHANNELS.LEVEL2}/${pair}`,
        `${REAL_TIME_CHANNELS.TICKER}/${pair}`,
      ];

      subscribeToRTService(topics);
    }
  };

  unsubscribeFromSymbolChanges = pair => {
    const { unsubscribeFromRTService } = this.props;
    const topics = [
      `${REAL_TIME_CHANNELS.ORDER_CANCELLED}/${pair}`,
      `${REAL_TIME_CHANNELS.ORDER_MATCHED}/${pair}`,
      `${REAL_TIME_CHANNELS.ORDER_PLACED}/${pair}`,
      `${REAL_TIME_CHANNELS.LEVEL2}/${pair}`,
    ];

    unsubscribeFromRTService(topics);
  };

  handleOrdersTabSelected = value => {
    this.setState({ selectedOrdersTab: value });
  };

  handleChartsTabSelected = value => {
    this.setState({ selectedChartsTab: value });
  };

  render = () => {
    const { t } = this.context;
    const { loggedIn, selectedPair, isMobile } = this.props;
    const { selectedChartsTab } = this.state;

    const isIphoneX = MediaQueryHelper.isIphoneX();

    return (
      <div className={`exchange${isIphoneX ? ' iphone-x' : ''}`}>
        <div className="exchange__top">
          <div className="exchange__left">
            <div className="exchange__left__overview">
              <SymbolInfo />
            </div>
            <div className="exchange__left__main">
              {!isMobile && (
                <div>
                  <OrderBook />
                </div>
              )}
              <div>
                <div className="exchange__left__chart">
                  <div className="exchange__left__orders__tabs-container">
                    <ButtonToolbar className="tabs-toggle">
                      <ToggleButtonGroup type="radio" name="options" value={this.state.selectedChartsTab} onChange={this.handleChartsTabSelected}>
                        <ToggleButton value={CHARTS_TABS.PRICE_CHART}>{t('EXCHAGE_CHARTS.PRICE_CHART')}</ToggleButton>
                        <ToggleButton value={CHARTS_TABS.MARKET_DEPTH}>{t('EXCHAGE_CHARTS.MARKET_DEPTH')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                  <div>{selectedChartsTab === CHARTS_TABS.PRICE_CHART && selectedPair.name && <TradingViewChart pair={selectedPair.name} />}</div>
                  <div>{selectedChartsTab === CHARTS_TABS.MARKET_DEPTH && <MarketDepth />}</div>
                </div>
                <ExchangeOpenOrder />
              </div>
            </div>
          </div>
          <div className="exchange__right">
            <div className="exchange__right__markets hidden-xs">
              <Markets />
            </div>
            {!isMobile && (
              <div className="exchange__right__trade-history">
                <div className="exchange__right__trade-history__title">{t('EXCHAGE_STATISTICS.TRADE_HISTORY')}</div>
                <TradeHistory />
              </div>
            )}
          </div>
        </div>
        <div className={`exchange__bottom${isIOS ? ' is-ios' : ''}`}>
          {isMobile && <OrderBookAndTradeHistory />}
          {!isMobile && <ExchangeOrders />}
        </div>
        <ExchangeActions loggedIn={loggedIn} isIphoneX={isIphoneX} />
      </div>
    );
  };
}

Exchange.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedPair: state.exchange.selectedPair,
  selectedMarket: state.exchange.market,
  orderBookItems: state.exchange.orderBook.items,
  isRTConnected: state.realTimeService.isConnected,
  loggedIn: state.currentUser.loggedIn,
  pairs: state.exchange.pairs,
  isMobile: state.mediaQuery.isMobile,
});

const mapDispatchToProps = dispatch => ({
  handleSymbolChanged: symbol => {
    dispatch(handleSymbolChanged(symbol));
  },
  handleMarketChanged: market => {
    dispatch(handleMarketChanged(market));
  },
  getOrderBookItems: pair => {
    dispatch(getOrderBookItems(pair));
  },
  subscribeToRTService: topics => {
    dispatch(subscribeToRTService(topics));
  },
  unsubscribeFromRTService: topics => {
    dispatch(unsubscribeFromRTService(topics));
  },
  getUserFees: () => {
    dispatch(getUserFees());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
