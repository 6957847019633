import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';

import { MFA_STEPS } from '../../constants/user-settings-constants';

import '../../styles/security-mfa.scss';

const SecurityMFA = (props, context) => {
  const { t } = context;
  const { mfaEnabled, changeMFAStep } = props;

  const showAuthenticator = () => {
    changeMFAStep(MFA_STEPS.ENTER_KEY);
  };

  const showRecoveryCodes = () => {
    changeMFAStep(MFA_STEPS.RECOVERY_CODES);
  };

  return (
    <div className="settings-mfa">
      <div className="settings-mfa__title">{t('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION')}</div>
      <p className="settings-mfa__description">{t('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION_DESCRIPTION')}</p>
      <div className="settings-mfa__subtitle">{t('USER_SETTINGS.AUTHENTICATOR_APP')}</div>
      <p className="settings-mfa__description">{t('USER_SETTINGS.AUTHENTICATOR_APP_DESCRIPTION')}</p>
      <div className="settings-mfa__actions">
        {mfaEnabled ? (
          <div>
            <Button className="settings-mfa__disable-btn" onClick={showAuthenticator}>
              {t(`USER_SETTINGS.DISABLE_TWO_FACTOR_AUTHENTICATION`)}
            </Button>
            <Button bsStyle="link" onClick={showRecoveryCodes}>
              {t(`USER_SETTINGS.GET_RECOVERY_CODES`)}
            </Button>
          </div>
        ) : (
          <Button bsStyle="primary" onClick={showAuthenticator}>
            {t(`USER_SETTINGS.ENABLE_TWO_FACTOR_AUTHENTICATION`)}
          </Button>
        )}
      </div>
    </div>
  );
};

SecurityMFA.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SecurityMFA;
