import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import { connect } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import { handleMarketChanged } from '../actions/exchange-actions';

import { MARKETS } from '../../../config/constants';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import MarketsCoinItem from './markets-coin-item';

import '../styles/markets.scss';

class Markets extends Component {
  constructor(props) {
    super(props);
    const { pairs, selectedMarket } = props;

    this.state = {
      selectedTab: find(MARKETS, item => item.name === selectedMarket).value,
      searchText: '',
      filteredPairs: !isEmpty(pairs) ? this.getFilteredPairs(pairs, selectedMarket) : [],
      fetchingPairs: isEmpty(pairs),
    };
  }

  componentDidUpdate = prevProps => {
    const { pairs, selectedMarket, fetchingPairs } = this.props;

    if (isEmpty(prevProps.pairs) && !isEmpty(pairs)) {
      const marketPairs = this.getFilteredPairs(pairs, selectedMarket);

      this.setState({ filteredPairs: marketPairs });
    }

    if (prevProps.fetchingPairs && !fetchingPairs) {
      this.setState({ fetchingPairs });
    }

    if (prevProps.selectedMarket !== selectedMarket) {
      const marketPairs = this.getFilteredPairs(pairs, selectedMarket);

      this.setState({ filteredPairs: marketPairs, selectedTab: find(MARKETS, item => item.name === selectedMarket).value });
    }
  };

  handleOnTabChange = value => {
    const { handleMarketChanged } = this.props;
    this.setState({ selectedTab: value });

    handleMarketChanged(find(MARKETS, item => item.value === value).name);
  };

  handleOnSearchChange = e => {
    this.setState({ searchText: e.target.value });
  };

  getPairIds = pairs => {
    const { selectedPairName } = this.props;

    return reduce(
      pairs,
      (result, pair) => {
        if (pair.name !== selectedPairName) {
          result.push(pair.name);
        }
        return result;
      },
      []
    );
  };

  getFilteredPairs = (pairs, market) => {
    return filter(pairs, pair => pair.quote_currency === market);
  };

  renderTabs = () => {
    return (
      <ButtonToolbar bsClass="blue-toggle">
        <ToggleButtonGroup bsClass="blue-toggle__group" type="radio" name="options" value={this.state.selectedTab} onChange={this.handleOnTabChange}>
          {MARKETS.map(item => {
            return (
              <ToggleButton key={item.value} value={item.value}>
                {item.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </ButtonToolbar>
    );
  };

  renderPairs = () => {
    const { t } = this.context;
    const { selectedPairName, onSymbolSelectCallback } = this.props;
    const { filteredPairs, searchText, fetchingPairs } = this.state;

    const filteredItems = (!isEmpty(filteredPairs) && filter(filteredPairs, pair => pair.display_name.indexOf(searchText.toUpperCase()) > -1 || !searchText)) || [];

    if (fetchingPairs) {
      return (
        <div className="markets__table__item-empty">
          <MiniLoader />
        </div>
      );
    }

    if (!filteredItems || !filteredItems.length) {
      return <div className="markets__table__item-empty">{t('EXCHAGE_MARKETS.NO_SYMBOLS_FOUND')}</div>;
    }

    return filteredItems.map(item => {
      return (
        <MarketsCoinItem
          key={item.display_name}
          name={item.name}
          displayName={item.display_name}
          currentPrice={item.currentPrice}
          openPrice={item.openPrice}
          decimals={item.quote_currency_decimals}
          selectedPair={selectedPairName}
          onSymbolSelectCallback={onSymbolSelectCallback}
        />
      );
    });
  };

  render = () => {
    const { t } = this.context;
    return (
      <div className="markets">
        <div className="markets__header">
          <div className="markets__header__tabs">{this.renderTabs()}</div>
          <div>
            <input type="text" className="markets__header__search" placeholder={t('EXCHAGE_MARKETS.MARKETS_SEARCH')} onChange={this.handleOnSearchChange} />
          </div>
        </div>
        <div className="markets__table">
          <div className="markets__table__item title">
            <div className="markets__table__item-name">{t('EXCHAGE_MARKETS.MARKETS_COIN')}</div>
            <div className="markets__table__item-price">{t('EXCHAGE_MARKETS.MARKETS_PRICE')}</div>
            <div className="markets__table__item-change">{t('EXCHAGE_MARKETS.MARKETS_CHANGE')}</div>
          </div>
          <div id="coins_container" className="markets__table__body">
            {this.renderPairs()}
          </div>
        </div>
      </div>
    );
  };
}

Markets.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedMarket: state.exchange.market,
    selectedPairName: state.exchange.selectedPair.name,
    pairs: state.exchange.pairs,
    fetchingPairs: state.exchange.fetchingSymbols,
  };
};

const mapDispatchToProps = dispatch => ({
  handleMarketChanged: market => {
    dispatch(handleMarketChanged(market));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Markets);
