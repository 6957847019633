import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Button from 'react-bootstrap/lib/Button';
import isEmpty from 'lodash/isEmpty';

import { changePassword } from '../../../user-settings/actions/user-settings-actions';

import { PASSWORD_CRITERIA } from '../../../../config/constants';
import HelperFunctions from '../../../../core/helpers/helper-functions';
import { requiredField } from '../../../shared-components/helpers/helpers';
import PasswordInput from '../../../shared-components/password-input/components/password-input';

import '../../styles/security-change-password.scss';

class SecurityChangePassword extends Component {
  passwordCriteria = ['USER_SETTINGS.PASSWORD_LENGTH_CRITERIA', 'USER_SETTINGS.PASSWORD_CHARACTER_TYPES_CRITERIA', 'USER_SETTINGS.PASSWORD_NO_SPACE_CRITERIA'];

  componentWillUnmount = () => {
    this.resetPasswordForm();
  };

  handleFormSubmit(values) {
    const { changePassword } = this.props;

    const data = {
      old_password: HelperFunctions.encodePassword(values.password),
      new_password: HelperFunctions.encodePassword(values.new_password),
    };

    changePassword(data);
  }

  resetPasswordForm = () => {
    const { reset } = this.props;

    reset();
  };

  render = () => {
    const { t } = this.context;
    const { handleSubmit, changePasswordInProgress } = this.props;

    return (
      <div className="change-password">
        <div className="change-password__title">{t('USER_SETTINGS.CHANGE_YOUR_PASSWORD')}</div>
        <p className="change-password__description">{t('USER_SETTINGS.CHANGE_PASSWORD_DESCRIPTION')}</p>
        <div className="change-password__container">
          <div className="change-password__container__left">
            <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} noValidate>
              <Field name="password" type="password" placeHolder={`${t('USER_SETTINGS.CURRENT_PASSWORD')}*`} label={t('USER_SETTINGS.CURRENT_PASSWORD')} component={PasswordInput} />
              <Field name="new_password" type="password" placeHolder={`${t('USER_SETTINGS.NEW_PASSWORD')}*`} label={t('USER_SETTINGS.NEW_PASSWORD')} component={PasswordInput} />
              <div className="change-password__actions">
                <Button bsStyle="link" onClick={this.resetPasswordForm}>
                  {t('USER_SETTINGS.CLEAR')}
                </Button>
                <Button type="submit" bsStyle="primary" disabled={changePasswordInProgress}>
                  {t('USER_SETTINGS.SAVE_CHANGES')}
                </Button>
              </div>
            </form>
          </div>
          <div className="change-password__container__right hidden-xs">
            <div className="change-password__criteria-title">{t('USER_SETTINGS.YOUR_PASSWORD')}</div>
            {this.passwordCriteria.map((criteria, index) => (
              <div className="change-password__criteria-item" key={index}>
                {t(criteria)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
}

SecurityChangePassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    changePasswordInProgress: state.userSettings.changePasswordInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  changePassword: data => {
    dispatch(changePassword(data));
  },
});

const validateForm = values => {
  let errors = {};

  requiredField(values, errors, 'password', 'RESET_PASSWORD.PASSWORD_IS_REQUIRED');
  requiredField(values, errors, 'new_password', 'RESET_PASSWORD.CONFIRM_PASSWORD_IS_REQUIRED');

  if (values.password && !PASSWORD_CRITERIA.test(values.password)) {
    errors.password = 'USER_SETTINGS.PASSWORD_CRITERIA_NOT_MET';
  }
  if (values.new_password && !PASSWORD_CRITERIA.test(values.new_password)) {
    errors.new_password = 'USER_SETTINGS.PASSWORD_CRITERIA_NOT_MET';
  }

  if (isEmpty(errors) && values.password.toLowerCase() === values.new_password.toLowerCase()) {
    errors.new_password = 'USER_SETTINGS.NEW_PASSWORD_CANNOT_BE_THE_SAME_AS_CURRENT_ONE';
  }

  return errors;
};

SecurityChangePassword = connect(mapStateToProps, mapDispatchToProps)(SecurityChangePassword);

export default reduxForm({
  form: 'changePasswordForm',
  validate: validateForm,
  destroyOnUnmount: false,
})(SecurityChangePassword);
