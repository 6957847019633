import { INFO_MODAL_ACTIONS } from '../constants/info-modal-constants';

/* @Reducers */
const initialState = {
  showInfoModal: false,
  message: '',
  callback: null,
  redirectUrl: null,
  type: '', // success, error, info, warning
  ctaText: null,
  ctaCallback: null,
};

export const infoModal = (state = initialState, action) => {
  switch (action.type) {
    case INFO_MODAL_ACTIONS.TOGGLE_INFO_MODAL: {
      if (state.showInfoModal) {
        return {
          ...state,
          showInfoModal: false,
          callback: null,
          redirectUrl: null,
          ctaText: null,
          ctaCallback: null,
          translationParameters: null,
        };
      }
      return {
        ...state,
        showInfoModal: true,
        message: action.data.message,
        type: action.data.type,
        callback: action.data.callback,
        redirectUrl: action.data.redirectUrl,
        ctaText: action.data.ctaText,
        ctaCallback: action.data.ctaCallback,
        translationParameters: action.data.translationParameters,
      };
    }
    default:
      return state;
  }
};
