import { verifyAndSavePhoneNumber } from '../actions/phone-verification-actions';
import { requiredField } from '../../shared-components/helpers/helpers';

class PhoneVerificationValidator {
  static phoneVerificationValidator = formProps => {
    const errors = {};

    requiredField(formProps, errors, 'sms_code_1', ' ');
    requiredField(formProps, errors, 'sms_code_2', ' ');
    requiredField(formProps, errors, 'sms_code_3', ' ');
    requiredField(formProps, errors, 'sms_code_4', ' ');

    return errors;
  };

  static phoneVerificationAsyncValidator = (values, dispatch, props) => {
    return new Promise((resolve, reject) => {
      // In case phone is already verified, prevent validating it again
      // as this will cause the verification code to be invalid
      const isPhoneVerified = props.phoneVerification.isVerified && props.phoneVerification.phoneNumber === props.phoneNumber;
      if (isPhoneVerified || !values.sms_code_1 || !values.sms_code_2 || !values.sms_code_3 || !values.sms_code_4) {
        return resolve();
      }
      const smsCode = values.sms_code_1 + values.sms_code_2 + values.sms_code_3 + values.sms_code_4;
      dispatch(verifyAndSavePhoneNumber(props.phoneNumber, smsCode, reject, resolve));
    });
  };
}

export default PhoneVerificationValidator;
