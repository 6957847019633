import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GenericModal from '../../shared-components/generic-modal/generic-modal';
import { handleDisplayPopupBlockedModal } from '../../profile/actions/profile-actions';
import RenderIf from '../../shared-components/render-if/components/render-if';

import '../styles/blocked-popup-modal.scss';

class BlockedPopupModal extends Component {
  closeModal = () => {
    const { handleDisplayPopupBlockedModal } = this.props;

    handleDisplayPopupBlockedModal(false);
  };

  render = () => {
    const { showBlockedModalPopup } = this.props;
    const { t } = this.context;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
      <GenericModal className="blocked-popup-modal" show={showBlockedModalPopup} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('POPUP_BLOCKED.POPUP_BLOCKED_TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="description">
              {t('POPUP_BLOCKED.POPUP_BLOCKED_EXPLANATION')}
              <RenderIf if={isSafari}>
                <div>{t('POPUP_BLOCKED.POPUP_BLOCKED_EXPLANATION_SAFARI')}</div>
              </RenderIf>
            </div>
            <Button className="button" onClick={this.closeModal}>
              {t('POPUP_BLOCKED.POPUP_BLOCKED_OK')}
            </Button>
          </div>
        </Modal.Body>
      </GenericModal>
    );
  };
}

BlockedPopupModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showBlockedModalPopup: state.profile.showBlockedModalPopup,
});
const mapDispatchToProps = disptach => ({
  handleDisplayPopupBlockedModal: data => {
    disptach(handleDisplayPopupBlockedModal(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockedPopupModal);
