import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { subscribeToRTService, bindRTListener, offRTCallback } from '../../../features/real-time-service/actions/real-time-service-actions';
import { toggleMarketsModal, handleSymbolInfoChanged } from '../actions/exchange-actions';

import { NAGA_GUARD_URL, REAL_TIME_CHANNELS } from '../../../config/constants';
import TradingHelper from '../../../core/helpers/trading-helper';
import HelperFunctions from '../../../core/helpers/helper-functions';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import Amount from '../../shared-components/amount/components/amount';
import Symbol from '../../shared-components/symbol/symbol';
import MarketsModal from './markets-modal';

import '../styles/symbol-info.scss';

class SymbolInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pairInfo: props.pairs.length ? this.getSelectedPairInfo() : null,
      loading: !props.pairs.length,
      colorClass: '',
    };

    this.isListenerAttached = false;
    this.componentId = null;
  }

  componentDidMount = () => {
    const { isConnected } = this.props;
    if (isConnected) {
      this.listenForSymbolChanges();
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedPair, pairs, fetchingPairs, isConnected, isMobile, showMarketsModal, toggleMarketsModal, subscribeToRTService, offRTCallback } = this.props;

    if (prevProps.fetchingPairs && !fetchingPairs) {
      const selectedPairInfo = this.getSelectedPairInfo();
      this.setState({ pairInfo: selectedPairInfo, loading: false });

      if (isConnected && selectedPairInfo && !this.isListenerAttached) {
        this.listenForSymbolChanges();
      }
    }

    if (!prevProps.isConnected && isConnected) {
      if (this.componentId) {
        offRTCallback(`${REAL_TIME_CHANNELS.TICKER}/${prevProps.selectedPair.name}`, this.componentId);
      }
      this.listenForSymbolChanges();
    }

    if (prevProps.selectedPair.name !== selectedPair.name) {
      if (pairs.length) {
        this.setState({ pairInfo: this.getSelectedPairInfo(), loading: false });
      }

      if (!isEmpty(prevProps.selectedPair.name)) {
        offRTCallback(`${REAL_TIME_CHANNELS.TICKER}/${prevProps.selectedPair.name}`, this.componentId);
        this.isListenerAttached = false;
      }

      subscribeToRTService(`${REAL_TIME_CHANNELS.TICKER}/${selectedPair.name}`);

      if (!this.isListenerAttached && isConnected) {
        this.listenForSymbolChanges();
      }
    }

    if (prevProps.isMobile && !isMobile && showMarketsModal) {
      toggleMarketsModal();
    }

    if (parseFloat(prevProps.selectedPair.currentPrice) < parseFloat(selectedPair.currentPrice)) {
      this.setState({ colorClass: 'animate-color-green' });
    } else if (parseFloat(prevProps.selectedPair.currentPrice) > parseFloat(selectedPair.currentPrice)) {
      this.setState({ colorClass: 'animate-color-red' });
    }
  };

  componentWillUnmount = () => {
    const { selectedPair, offRTCallback } = this.props;

    offRTCallback(`${REAL_TIME_CHANNELS.TICKER}/${selectedPair.name}`, this.componentId);
    this.isListenerAttached = false;
  };

  listenForSymbolChanges = () => {
    const { selectedPair, handleSymbolInfoChanged, bindRTListener } = this.props;

    if (!isEmpty(selectedPair) && selectedPair.name) {
      this.componentId = HelperFunctions.generateComponentId(`symbol_info_${selectedPair.name}`);

      bindRTListener(
        `${REAL_TIME_CHANNELS.TICKER}/${selectedPair.name}`,
        payload => {
          const data = JSON.parse(payload.toString());

          if (
            isEmpty(this.state.pairInfo) ||
            data.pair_name !== this.state.pairInfo.name ||
            +data.close_price !== +this.state.pairInfo.currentPrice ||
            +data.open_price !== +this.state.pairInfo.openPrice ||
            +data.best_bid !== +this.state.pairInfo.bid ||
            +data.best_ask !== +this.state.pairInfo.ask
          ) {
            this.setState({ pairInfo: { ...this.state.pairInfo, currentPrice: data.close_price, openPrice: data.open_price, bid: data.best_bid, ask: data.best_ask } });

            const pairInfo = {
              currentPrice: data.close_price,
              openPrice: data.open_price,
              bid: data.best_bid,
              ask: data.best_ask,
            };

            handleSymbolInfoChanged(pairInfo);
          }
        },
        this.componentId
      );
      this.isListenerAttached = true;
    }
  };

  showExistingMarkets = () => {
    const { toggleMarketsModal } = this.props;

    toggleMarketsModal();
  };

  getSelectedPairInfo = () => {
    const { pairs, selectedPair } = this.props;

    return find(pairs, pair => pair.name === selectedPair.name);
  };

  openNagaGuard = () => {
    const { selectedPair } = this.props;

    window.open(`${NAGA_GUARD_URL}coin/${selectedPair.base_currency}`, '_blank');
  };

  renderDesktopUI = () => {
    const { t } = this.context;
    const { selectedPair } = this.props;
    const { pairInfo, colorClass } = this.state;
    const isCryptoQuoteCurrency = !TradingHelper.isFiatCurrency(selectedPair.quote_currency);

    return (
      <div className={`symbol-info${isCryptoQuoteCurrency ? ' crypto-quote' : ''}`}>
        <div>
          <div className="symbol-info__symbol">
            <Symbol symbol={selectedPair.name} />
            <div>
              <div className="symbol-info__symbol__name">{selectedPair.base_currency_name}</div>
              <div className="symbol-info__symbol__pair">{selectedPair.display_name}</div>
            </div>
          </div>
          <div className="symbol-info__stats">
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.LAST_PRICE')}</div>
              <div>
                <Amount value={pairInfo.currentPrice} suffix={selectedPair.quote_currency} decimals={selectedPair.quote_currency_decimals} className={colorClass} />
              </div>
            </div>
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.ONE_DAY_CHANGE')}</div>
              <div className="symbol-info__day-change">
                <Amount value={pairInfo.currentPrice - pairInfo.openPrice} suffix={selectedPair.quote_currency} decimals={selectedPair.quote_currency_decimals} colored />
                <Amount value={TradingHelper.calculateSymbolTrend(pairInfo.currentPrice, pairInfo.openPrice)} suffix="%" colored />
              </div>
            </div>
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.BID')}</div>
              <div>
                <Amount value={pairInfo.bid} decimals={selectedPair.quote_currency_decimals} />
              </div>
            </div>
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.ASK')}</div>
              <div>
                <Amount value={pairInfo.ask} decimals={selectedPair.quote_currency_decimals} />
              </div>
            </div>
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.24H_VOLUME')}</div>
              <div>
                <Amount value={pairInfo.baseVolume} suffix={selectedPair.base_currency} decimals={selectedPair.base_currency_decimals} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMobileUI = () => {
    const { t } = this.context;
    const { selectedPair } = this.props;
    const { pairInfo } = this.state;

    return (
      <div className="symbol-info mobile">
        <div className="symbol-info__symbol">
          <Symbol symbol={selectedPair.name} />
          <div>
            <div className="symbol-info__symbol__name" onClick={this.showExistingMarkets}>
              {selectedPair.display_name} <i className="icn icn-small-down" />
            </div>
            <div className="symbol-info__symbol__pair">
              <Amount value={pairInfo.currentPrice} suffix={selectedPair.quote_currency} decimals={selectedPair.quote_currency_decimals} />
            </div>
          </div>
        </div>
        <div className="symbol-info__details">
          <div>
            <div className="symbol-info__label">{t('EXCHANGE.ONE_DAY_CHANGE')}</div>
            <div className="symbol-info__day-change">
              <Amount value={pairInfo.currentPrice - pairInfo.openPrice} suffix={selectedPair.quote_currency} decimals={selectedPair.quote_currency_decimals} colored />
              <Amount value={TradingHelper.calculateSymbolTrend(pairInfo.currentPrice, pairInfo.openPrice)} suffix="%" colored />
            </div>
          </div>
          <div className="symbol-info__details__high-low">
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.BID')}</div>
              <Amount value={pairInfo.bid} decimals={selectedPair.quote_currency_decimals} />
            </div>
            <div>
              <div className="symbol-info__label">{t('EXCHANGE.ASK')}</div>
              <Amount value={pairInfo.ask} decimals={selectedPair.quote_currency_decimals} />
            </div>
          </div>
        </div>
        <MarketsModal />
      </div>
    );
  };

  render = () => {
    const { t } = this.context;
    const { isMobile } = this.props;
    const { pairInfo, loading } = this.state;

    if (loading) {
      return (
        <div className="symbol-info loading">
          <MiniLoader />
        </div>
      );
    }

    if (!pairInfo) {
      return <div className="symbol-info no-data">{t('EXCHANGE.NO_DATA')}</div>;
    }

    return isMobile ? this.renderMobileUI() : this.renderDesktopUI();
  };
}

SymbolInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    pairs: state.exchange.pairs,
    fetchingPairs: state.exchange.fetchingSymbols,
    showMarketsModal: state.exchange.showMarketsModal,
    isMobile: state.mediaQuery.isMobile,
    isRTAvailable: state.realTimeService.isAvailable,
    isConnected: state.realTimeService.isConnected,
    selectedPair: state.exchange.selectedPair,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleMarketsModal: () => {
    dispatch(toggleMarketsModal());
  },
  handleSymbolInfoChanged: data => {
    dispatch(handleSymbolInfoChanged(data));
  },
  bindRTListener: (topic, callback, componentId) => {
    dispatch(bindRTListener(topic, callback, componentId));
  },
  subscribeToRTService: topic => {
    dispatch(subscribeToRTService(topic));
  },
  offRTCallback: (topic, componentId) => {
    dispatch(offRTCallback(topic, componentId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SymbolInfo);
