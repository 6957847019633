export const USER_SETTINGS_ACTIONS = {
  HANDLE_USER_LANGUAGE_CHANGED: 'handleUserLanguageChanged',
  HANDLE_UPDATE_USER_PROFILE_IN_PROGRESS: 'handleUpdateUserProfileInProgress',
  HANDLE_UPDATE_USER_PROFILE_SUCCESS: 'handleUpdateUserProfileSuccess',
  HANDLE_UPLOAD_USER_PROFILE_PHOTO_IN_PROGRESS: 'handleUploadUserProfilePhotoInProgress',
  HANDLE_VALIDATE_PHONE_NUMBER_IN_PROGRESS: 'handleValidatePhoneNumberInProgress',
  HANDLE_PHONE_NUMBER_CONFIRMATION: 'handlePhoneNumberConfirmation',
  CHANGE_PHONE_NUMBER_IN_PROGRESS: 'handleChangePhoneNumberInProgress',
  HANDLE_USER_PROFILE_RECEIVED: 'handleUserProfileReceived',
  HANDLE_USER_PROFILE_PHOTO_LOADED: 'handleUserProfilePhotoLoaded',
  HANDLE_FETCHING_USER_PROFILE: 'handleFetchingUserProfile',
  HANDLE_CHANGE_PASSWORD_IN_PROGRESS: 'handleChangePasswordInProgress',
  HANDLE_MFA_INFO_RECEIVED: 'handleMfaInfoReceived',
  HANDLE_MFA_ENABLED: 'handleMfaEnabled',
  HANDLE_MFA_DISABLED: 'handleMfaDisabled',
  HANDLE_MFA_RECOVERY_CODES_RECEIVED: 'handleMfaRecoveryCodesReceived',
  HANDLE_UPDATE_NGC_FEE_IN_PROGRESS: 'handleUpdateUserNGCFeeInProgress',
};

export const MFA_STEPS = {
  INITIAL: 'initial',
  ENTER_KEY: 'enterKey',
  MFA_ENABLED: 'mfaEnabled',
  RECOVERY_CODES: 'recoveryCodes',
};
