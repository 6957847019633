import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import isEmpty from 'lodash/isEmpty';

import { ORDERS_TABS } from '../../exchange/constants/exchange-constants';
import { OrderItem } from './order-item';
import NoOrders from './no-orders';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import { cancelOrder } from '../actions/orders-actions';

import '../styles/orders.scss';

class OpenOrders extends Component {
  state = {
    loadedItems: [],
  };

  componentDidMount = () => {
    const { limitNumberOfItems, activeOrders } = this.props;

    if (!limitNumberOfItems) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    this.setState({ loadedItems: limitNumberOfItems ? activeOrders.slice(0, 5) : activeOrders.slice(0, 30) });
  };

  componentDidUpdate = prevProps => {
    const { activeOrders, limitNumberOfItems } = this.props;

    if (prevProps.activeOrders.length !== activeOrders.length) {
      if (activeOrders.length) {
        this.setState({ loadedItems: limitNumberOfItems ? activeOrders.slice(0, 5) : activeOrders.slice(0, 30) });
      } else {
        this.setState({ loadedItems: [] });
      }
    }
  };

  renderItems = isMobile => {
    const { limitNumberOfItems } = this.props;

    return this.state.loadedItems.map(item => {
      return <OrderItem key={item.order_id} item={item} cancelable={true} isMobile={isMobile} limitNumberOfItems={limitNumberOfItems} cancelOrderCallback={this.handleCancelOrder} />;
    });
  };

  handleShowAllOrders = () => {
    const { history } = this.props;

    history.push(`/orders/open`);
  };

  handleLoadItemsToList = () => {
    const { limitNumberOfItems, activeOrders } = this.props;

    if (!limitNumberOfItems) {
      this.setState(state => {
        return { loadedItems: state.loadedItems.concat(activeOrders.slice(state.loadedItems.length, state.loadedItems.length + 10)) };
      });
    }
  };

  handleCancelOrder = e => {
    const { cancelOrder } = this.props;
    const msb = e.currentTarget.dataset.msb;
    const lsb = e.currentTarget.dataset.lsb;

    cancelOrder({ msb, lsb });
  };

  renderShowAllOrders = () => {
    const { t } = this.context;
    const { activeOrders, limitNumberOfItems } = this.props;
    const { loadedItems } = this.state;

    return (
      limitNumberOfItems &&
      loadedItems.length < activeOrders.length && (
        <div className="show-all-orders">
          <Button bsStyle="primary" onClick={this.handleShowAllOrders}>
            {t('ORDERS.SHOW_ALL_ORDERS')}
          </Button>
        </div>
      )
    );
  };

  renderDesktop = () => {
    const { t } = this.context;
    const { limitNumberOfItems, activeOrders } = this.props;

    return (
      <div>
        <div className="order-item open title">
          <div />
          <div>{t('ORDERS.MARKET')}</div>
          <div>{t('ORDERS.PRICE')}</div>
          <div>{t('ORDERS.UNITS_TOTAL')}</div>
          <div>{t('ORDERS.UNITS_FILLED')}</div>
          {/* <div>{t('ORDERS.ACTUAL_RATE')}</div> */}
          {!limitNumberOfItems && <div>{t('ORDERS.ESTIMATED_TOTAL')}</div>}
          <div>{t('ORDERS.OPEN_DATE')}</div>
          <div />
        </div>
        <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.handleLoadItemsToList} hasMore={this.state.loadedItems.length < activeOrders.length}>
          {this.renderItems()}
        </InfiniteScroll>
        {this.renderShowAllOrders()}
      </div>
    );
  };

  renderMobile = () => {
    const { activeOrders } = this.props;

    return (
      <div>
        <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.handleLoadItemsToList} hasMore={this.state.loadedItems.length < activeOrders.length}>
          {this.renderItems(true)}
        </InfiniteScroll>
        {this.renderShowAllOrders()}
      </div>
    );
  };

  render = () => {
    const { isMobile, activeOrders, requestActiveOrdersDataInProgress } = this.props;

    if (requestActiveOrdersDataInProgress && isEmpty(activeOrders)) {
      return (
        <div className="orders_loader">
          <MiniLoader />
        </div>
      );
    }

    if (isEmpty(activeOrders)) {
      return <NoOrders orderType={ORDERS_TABS.OPEN_ORDERS} />;
    }

    return isMobile ? this.renderMobile() : this.renderDesktop();
  };
}

OpenOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isMobile: state.mediaQuery.isMobile,
  desktopXS: state.mediaQuery.desktopXS,
  activeOrders: state.orders.activeOrders,
  requestActiveOrdersDataInProgress: state.orders.requestActiveOrdersDataInProgress,
});

const mapDispatchToProps = dispatch => ({
  cancelOrder: data => {
    dispatch(cancelOrder(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenOrders));
