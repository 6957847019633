export const CURRENCY_TYPES = {
  FIAT: 1,
  CRYPTO: 2,
};

export const WALLET_COLUMNS = {
  FIAT: ['WALLET_COLUMNS.NAME_AND_CODE', 'WALLET_COLUMNS.TOTAL_VALUE', 'WALLET_COLUMNS.IN_ORDERS', 'WALLET_COLUMNS.AVAILABLE_BALANCE'],
  CRYPTO: ['WALLET_COLUMNS.NAME_AND_CODE', 'WALLET_COLUMNS.TOTAL_VALUE', 'WALLET_COLUMNS.IN_ORDERS', 'WALLET_COLUMNS.AVAILABLE_BALANCE', 'WALLET_COLUMNS.EST_USD_VALUE'],
};

export const WALLET_ACTIONS = {
  DEPOSIT: 1,
  WITHDRAW: 2,
  TRADE: 3,
};

export const WALLET_REDUX_ACTIONS = {
  HANDLE_SELECTED_WALLET_CURRENCY: 'handleSelectedWalletCurrency',
  HANDLE_ASSETS_RECEIVED: 'handleAssetsReceived',
  HANDLE_CURRENCIES_RECEIVED: 'handleCurrenciesReceived',
  HANDLE_UPDATE_ASSET_BALANCE: 'handleUpdateAssetBalance',
  UPDATE_ASSETS_DATA: 'updateAssetsData',
  TRANSFERRING_FUNDS_TO_WALLET: 'transferringFundsToWallet',
  HANDLE_DISPLAY_WITHDRAW_MODAL: 'handleDisplayWithdrawModal',
  HANDLE_DISPLAY_DEPOSIT_MODAL: 'handleDisplayDepositModal',
  HANDLE_FETCHING_USER_WALLET_INFORMATION: 'handleFetchingUserWalletInformation',
};
