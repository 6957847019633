import React from 'react';
import Route from 'react-router-dom/Route';

import PrivateRoutes from './private-routes';

const RouteWithSubRoutes = route => {
  if (route.requiresAuth) {
    return <PrivateRoutes {...route} />;
  } else {
    return (
      <Route
        path={route.path}
        render={props => {
          if (route.layoutComponent) {
            const layoutProps = route.layoutProps ? { ...route.layoutProps, ...props } : props;
            return (
              <route.layoutComponent {...layoutProps}>
                <route.component {...props} routes={route.routes} />
              </route.layoutComponent>
            );
          } else {
            // pass the sub-routes down to keep nesting
            return <route.component {...props} routes={route.routes} />;
          }
        }}
      />
    );
  }
};

export default RouteWithSubRoutes;
