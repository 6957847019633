import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import InAppMessageBanner from '../components/in-app-message-banner';
import InAppMessageModal from '../components/in-app-message-modal';
import { MESSAGE_OPTIONS } from '../constants/in-app-messages-constants';

class InAppMessage extends Component {
  render() {
    const { showMessage } = this.props;

    if (showMessage) {
      const { message } = this.props;

      if (message.additional_data.displayType === MESSAGE_OPTIONS.DISPLAY_TYPE.BANNER) {
        const toastPosition = message.additional_data.position || MESSAGE_OPTIONS.SCREEN_POSITION.top;

        toast(<InAppMessageBanner {...message} />, { position: `${toastPosition}-right`, closeButton: false, autoClose: message.additional_data.autoClose, progressClassName: 'banner-blue-bar' });
      }
    }

    return <InAppMessageModal />;
  }
}

const mapStateToProps = state => ({
  message: state.inAppMessage.messageObj,
  showMessage: state.inAppMessage.showMessage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InAppMessage);
