import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CURRENCY_TYPES, WALLET_COLUMNS } from '../constants/wallet-constants';
import WalletCurrencyItem from './wallet-currency-item';

import '../styles/wallet-currency-type.scss';

class WalletCurrencyType extends Component {
  state = {
    activeItem: '',
  };

  renderColumnTitles = () => {
    const { t } = this.context;
    const { type, isMobile, desktopXS } = this.props;
    let columns = type === CURRENCY_TYPES.FIAT ? WALLET_COLUMNS.FIAT : WALLET_COLUMNS.CRYPTO;

    if (type === CURRENCY_TYPES.CRYPTO && !isMobile && desktopXS) {
      columns = WALLET_COLUMNS.CRYPTO.slice(0, -1);
    }

    return columns.map((item, index) => {
      return (
        <div key={index} className="wallet-category__column-titles__item">
          {t(item)}
        </div>
      );
    });
  };

  handleItemSelect = key => {
    this.setState({ activeItem: key });
  };

  renderItems = () => {
    const { t } = this.context;
    const { items } = this.props;

    return items.length ? (
      items.map(item => {
        return <WalletCurrencyItem key={item.asset_id} item={item} />;
      })
    ) : (
      <div className="wallet-category__no-data">{t('WALLET.NO_CURRENCIES_FOUND')}</div>
    );
  };

  render = () => {
    const { t } = this.context;
    const { title } = this.props;
    return (
      <div className="wallet-category">
        <div className="wallet-category__title">{t(title)}</div>
        <div className="wallet-category__column-titles hidden-xs">
          <div className="wallet-category__column-titles__text">{this.renderColumnTitles()}</div>
        </div>
        {this.renderItems()}
      </div>
    );
  };
}

WalletCurrencyType.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WalletCurrencyType;
