import { CURRENT_USER_ACTIONS } from '../constants/current-user-constants';
import RegistrationAPI from '../../../api/registration-api';

export const handleResendVerificationEmail = () => {
  return dispatch => {
    return RegistrationAPI.resendConfirmationEmail()
      .then(success => {
        dispatch(handleVerificationEmailResent(success.data.data.success));
      })
      .catch(() => {
        dispatch(handleVerificationEmailResent(false));
      });
  };
};

export const handleVerificationEmailResent = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_VERIFICATION_EMAIL_RESENT,
  data,
});

export const handleAuthTokensUpdated = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_AUTH_TOKENS_UPDATED,
  data,
});

export const handleUserInfoUpdated = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_USER_INFO_UPDATED,
  data,
});

export const handleUserProfilePhotoUpdated = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_PROFILE_PHOTO_UPDATED,
  data,
});

export const handleNotificationBarVisibility = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_NOTIFICATION_BAR_VISIBILITY,
  data,
});

export const handleUserMFAEnabled = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_USER_MFA_ENABLED,
  data,
});

export const handleUserMFADisabled = data => ({
  type: CURRENT_USER_ACTIONS.HANDLE_USER_MFA_DISABLED,
  data,
});
