import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';

import { MFA_STEPS } from '../../constants/user-settings-constants';

import '../../styles/mfa-changed.scss';

const MFAChanged = (props, context) => {
  const { t } = context;
  const { recoveryCodes, username, securityStep, changeMFAStep, toggleInfoModal, getMFARecoveryCodes } = props;

  const recoveryCodesAvailable = !isEmpty(recoveryCodes);

  const goBack = () => {
    changeMFAStep(MFA_STEPS.INITIAL);
  };

  const showCopiedModal = () => {
    toggleInfoModal('USER_SETTINGS.COPIED_TO_CLIPBOARD', 'success');
  };

  const generateRecoveryCodes = () => {
    getMFARecoveryCodes();
  };

  return (
    <div className="mfa-changed">
      <div className="mfa-changed__back">
        <Button bsStyle="link" bsSize="large" onClick={goBack}>
          <i className="icn icn-minimal-left-big-thin" />
          {t('USER_SETTINGS.PASSWORD_AND_SECURITY')}
        </Button>
      </div>
      {securityStep === MFA_STEPS.RECOVERY_CODES ? (
        <div className="mfa-changed__title">{t('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION_BACKUP_CODES')}</div>
      ) : (
        <div className="mfa-changed__title">
          <i className="icn icn-check-small-thin" />
          {t('USER_SETTINGS.TWO_FACTOR_AUTHENTICATION_ENABLED')}
        </div>
      )}
      <div className="mfa-changed__account">
        {t('USER_SETTINGS.ACCOUNT')} <span>{username}</span>
      </div>
      <div className="mfa-changed__description">{t('USER_SETTINGS.KEEP_BACKUP_CODES_SAFE')}</div>
      <div className="mfa-changed__codes">
        <div className="mfa-changed__codes__title">{t('USER_SETTINGS.BACKUP_CODES')}</div>
        <div className="mfa-changed__codes__list">
          {recoveryCodesAvailable ? recoveryCodes.map((code, index) => <span key={index}>{code}</span>) : <div>{t('USER_SETTINGS.NO_CODES_AVAILABLE')}</div>}
        </div>
        <div className="mfa-changed__copy">
          <CopyToClipboard text={recoveryCodes.join('\n')} onCopy={showCopiedModal}>
            <Button bsStyle="primary" disabled={!recoveryCodesAvailable}>
              {t('USER_SETTINGS.COPY_CODES')}
            </Button>
          </CopyToClipboard>
          <Button bsStyle="link" className="mfa-changed__generate-codes" onClick={generateRecoveryCodes}>
            {t('USER_SETTINGS.GENERATE_NEW_RECOVERY_CODES')}
          </Button>
        </div>
      </div>
      <div className="mfa-changed__note">
        {t('USER_SETTINGS.NOTE')}
        <div>{t('USER_SETTINGS.BACKUP_CODE_CAN_BE_USED_ONCE')}</div>
        <div>{t('USER_SETTINGS.RECOVERY_CODES_NOTE')}</div>
      </div>
    </div>
  );
};

MFAChanged.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MFAChanged;
