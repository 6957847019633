import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import Switch from 'react-router-dom/Switch';

import RouteWithSubRoutes from '../../../core/route-with-subroutes';
import MainNavigation from '../../layout/navigation/components/navigation';

import '../styles/orders.scss';

class Orders extends Component {
  state = {};

  navigationItems = [
    {
      path: '/orders/open',
      text: 'EXCHAGE_ORDERS.OPEN_ORDERS',
    },
    {
      path: '/orders/executed',
      text: 'EXCHAGE_ORDERS.EXECUTED_ORDERS',
    },
  ];

  static getDerivedStateFromProps(nextProps) {
    const { location, history } = nextProps;
    const regex = new RegExp('^/orders/?$');

    // There's no better way to emulate sub-route preselection
    // on parent route selection, hence code below
    if (location.pathname.toLowerCase().match(regex)) {
      history.push('/orders/open');
    }

    return null;
  }

  render = () => {
    const { routes } = this.props;

    return (
      <div className="orders">
        <div className="orders__navigation">
          <MainNavigation items={this.navigationItems} isSecondary={true} />
        </div>
        {/* this is the way to show nested routes of this parent route*/}
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
      </div>
    );
  };
}

Orders.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(Orders);
