import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';

import TradeHistory from './trade-history/trade-history';
import OrderBook from './order-book/order-book';

import '../styles/order-book-and-trade-history.scss';

class OrderBookAndTradeHistory extends Component {
  state = {
    selectedTab: 1,
  };

  handleTabSelected = value => {
    this.setState({ selectedTab: value });
  };

  render = () => {
    const { t } = this.context;

    const showOrderBook = this.state.selectedTab === 1;

    return (
      <div className="order-book-and-trade-history">
        <ButtonToolbar className="tabs-toggle">
          <ToggleButtonGroup type="radio" name="options" value={this.state.selectedTab} onChange={this.handleTabSelected}>
            <ToggleButton value={1}>{t('EXCHAGE_STATISTICS.ORDER_BOOK')}</ToggleButton>
            <ToggleButton value={2}>{t('EXCHAGE_STATISTICS.TRADE_HISTORY')}</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
        <OrderBook className={`${showOrderBook ? '' : ' hidden'}`} />
        <TradeHistory className={`${showOrderBook ? ' hidden' : ''}`} isSelected={!showOrderBook} />
      </div>
    );
  };
}

OrderBookAndTradeHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pairName: state.exchange.selectedPair.name,
});

export default connect(mapStateToProps, null)(OrderBookAndTradeHistory);
