import React, { Component } from 'react';

import { SYMBOL_BASE_URL } from '../../../config/constants';

import './symbol.scss';

class Symbol extends Component {
  handleClick = () => {
    const { handleClick } = this.props;
    if (handleClick) {
      handleClick();
    }
  };

  render() {
    const { symbol, size, symbolImage } = this.props;
    const image = symbolImage ? symbolImage : SYMBOL_BASE_URL + encodeURIComponent(symbol) + '.png';
    return <div className={`symbol ${size || ''}`} style={{ backgroundImage: `url(${image})` }} onClick={this.handleClick} />;
  }
}

export default Symbol;
