import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ORDERS_TABS } from '../../exchange/constants/exchange-constants';

import '../styles/orders.scss';

class NoOrders extends Component {
  render = () => {
    const { orderType } = this.props;
    const { t } = this.context;

    return (
      <div className="orders__empty">
        <div>{t('EXCHAGE_ORDERS.NO_ORDERS', { type: orderType === ORDERS_TABS.OPEN_ORDERS ? t('EXCHAGE_ORDERS.NO_OPEN_ORDERS') : t('EXCHAGE_ORDERS.NO_EXECUTED_ORDERS') })}</div>
      </div>
    );
  };
}

NoOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NoOrders;
