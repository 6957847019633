import { LOGIN_ACTIONS } from '../constants/registration-constants';

const registrationInitalState = {
  invalidLogin: false,
  gRecaptchaResponse: null,
  loginAttempts: 0,
  loginInProgress: false,
  displayReCaptcha: false,
  resetReCaptcha: false,
  countries: [],
  registerInProgress: false,
  resetPasswordInProgress: false,
  passwordResetEmailSent: false,
  sendResetPasswordEmailInProgress: false,
  userInfo: null,
  detectedCountry: null,
  showRegisterModal: false,
  showLoginModal: false,
  showForgotPasswordModal: false,
  autoLoginMAUrl: null,
  autoLoginWindow: null,
  autoLoginURLTarget: null,
  emailConfirmationInProgress: false,
};

export const registration = (state = registrationInitalState, payload) => {
  switch (payload.type) {
    case LOGIN_ACTIONS.HANDLE_INVALID_LOGIN:
      return { ...state, invalidLogin: payload.data };
    case LOGIN_ACTIONS.SET_RECAPTCHA_RESPONSE:
      return { ...state, gRecaptchaResponse: payload.data };
    case LOGIN_ACTIONS.LOGIN_IN_PROGRESS:
      return { ...state, loginInProgress: payload.data };
    case LOGIN_ACTIONS.INCREASE_NUMBER_OF_LOIGN_ATTEMPTS:
      return { ...state, loginAttempts: state.loginAttempts + 1 };
    case LOGIN_ACTIONS.HANDLE_DISPLAY_RECAPTCHA:
      return { ...state, displayReCaptcha: payload.data };
    case LOGIN_ACTIONS.HANDLE_RESET_RECAPTCHA:
      return { ...state, resetReCaptcha: payload.data };
    case LOGIN_ACTIONS.HANDLE_COUNTRIES_RECEIVED:
      return { ...state, countries: payload.data };
    case LOGIN_ACTIONS.REGISTER_IN_PROGRESS:
      return { ...state, registerInProgress: payload.data };
    case LOGIN_ACTIONS.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, passwordResetEmailSent: payload.data };
    case LOGIN_ACTIONS.SEND_RESET_PASSWORD_EMAIL_IN_PROGRESS:
      return { ...state, sendResetPasswordEmailInProgress: payload.data };
    case LOGIN_ACTIONS.RESET_PASSWORD_IN_PROGRESS:
      return { ...state, resetPasswordInProgress: payload.data };
    case LOGIN_ACTIONS.USER_INFO_RECEIVED:
      return { ...state, userInfo: payload.data };
    case LOGIN_ACTIONS.USER_COUNTRY_DETECTED:
      return { ...state, detectedCountry: payload.data };
    case LOGIN_ACTIONS.HANDLE_DISPLAY_REGISTRATION_MODAL:
      return { ...state, showRegisterModal: payload.data };
    case LOGIN_ACTIONS.HANDLE_DISPLAY_LOGIN_MODAL:
      return { ...state, showLoginModal: payload.data };
    case LOGIN_ACTIONS.HANDLE_DISPLAY_FORGOT_PASSWORD_MODAL:
      return { ...state, showForgotPasswordModal: payload.data };
    case LOGIN_ACTIONS.HANDLE_EMAIL_CONFIRMATION_IN_PROGRESS:
      return { ...state, emailConfirmationInProgress: payload.data };
    case LOGIN_ACTIONS.HANDLE_AUTO_LOGIN_MA_URL_RECEIVED:
      return { ...state, autoLoginMAUrl: payload.data.url, autoLoginWindow: payload.data.windowInstance, autoLoginURLTarget: payload.data.target };
    default:
      return state;
  }
};
