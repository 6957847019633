import React from 'react';

import MarketDepthChart from './market-depth-chart';

import '../../styles/market-depth.scss';

const MarketDepth = () => {
  return (
    <div className="market-depth">
      <div className="market-depth__chart">
        <MarketDepthChart />
      </div>
    </div>
  );
};

export default MarketDepth;
