import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import ReCAPTCHA from 'react-google-recaptcha';

import { sendResetPasswordEmail, sendResetPasswordEmailSuccess, setRecaptchaResponse, handleResetReCaptcha } from '../actions/registration-actions';
import { requiredField } from '../../shared-components/helpers/helpers';
import { EMAIL_CRITERIA, RE_CAPTCHA_KEY } from '../../../config/constants';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import HeaderBar from './header-bar';

import EnvelopeIcon from '../styles/images/envelope.png';

import '../styles/forgot-password.scss';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.recaptchaInstance = React.createRef();
    this.state = {
      email: null,
    };
  }

  componentWillUnmount = () => {
    const { sendResetPasswordEmailSuccess, reset } = this.props;

    this.setState({ email: null });
    sendResetPasswordEmailSuccess(false);
    reset();
  };

  sendToLogin = () => {
    const { history } = this.props;

    history.push('/login');
  };

  handleRediretToHome = () => {
    const { history } = this.props;

    history.push('/');
  };

  resendEmail = () => {
    const { gRecaptchaResponse, sendResetPasswordEmail } = this.props;
    const { email } = this.state;

    const data = {
      login: email,
      g_recaptcha_response: gRecaptchaResponse,
    };

    return sendResetPasswordEmail(data);
  };

  handleFormSubmit = values => {
    const { gRecaptchaResponse, sendResetPasswordEmail } = this.props;

    const data = {
      login: values.email,
      g_recaptcha_response: gRecaptchaResponse,
    };

    this.setState({ email: values.email });

    return sendResetPasswordEmail(data);
  };

  onCaptchaCheckboxChange(value) {
    const { setRecaptchaResponse, handleResetReCaptcha } = this.props;
    handleResetReCaptcha(false);
    setRecaptchaResponse(value);
  }

  renderForgotPassword = () => {
    const { t } = this.context;
    const { handleSubmit, emailSendingInProgress, gRecaptchaResponse } = this.props;

    return (
      <div className="forgot-password-form__wrapper">
        <h2 className="forgot-password-form__title">{t('FORGOT_PASSWORD.FORGOT_PASSWORD')}</h2>
        <div className="forgot-password-form__subtitle">{t('FORGOT_PASSWORD.ENTER_YOUR_EMAIL_ADDRESS')}</div>
        <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} noValidate>
          <Field name="email" type="email" placeHolder={t('FORGOT_PASSWORD.EMAIL')} component={DefaultInput} />
          <ReCAPTCHA ref={this.recaptchaInstance} sitekey={RE_CAPTCHA_KEY} onChange={val => this.onCaptchaCheckboxChange(val)} />

          <Button bsStyle="primary" className="send-reset-link-btn" disabled={emailSendingInProgress || !gRecaptchaResponse} type="submit">
            {emailSendingInProgress ? <MiniLoader whiteLoader /> : t('FORGOT_PASSWORD.SEND_RESET_LINK')}
          </Button>
        </form>
        <div className="forgot-password-form__back-to-login">
          <span className="forgot-password-form__back-to-login__message">{t('FORGOT_PASSWORD.REMEMBERED_YOUR_PASSWORD')}</span>
          <Button bsStyle="link" onClick={this.sendToLogin} disabled={emailSendingInProgress}>
            {t('FORGOT_PASSWORD.SIGN_IN')}
          </Button>
        </div>
      </div>
    );
  };

  renderEmailSentConfirmation = () => {
    const { t } = this.context;
    const { emailSendingInProgress } = this.props;
    const { email } = this.state;

    return (
      <div className="forgot-password-form__wrapper confirmation">
        <img className="forgot-password-form__envelope-img" src={EnvelopeIcon} alt="Envelope" />
        <h2 className="forgot-password-form__title">{t('FORGOT_PASSWORD.CHECK_YOUR_INBOX')}</h2>
        <div className="forgot-password-form__subtitle">{t('FORGOT_PASSWORD.PASSWORD_RESET_INSTRUCTIONS_SENT', { email })}</div>
        <div className="forgot-password-form__reset">
          <div className="forgot-password-form__reset__message">{t('FORGOT_PASSWORD.DIDNT_GET_PASSWORD_INSTRUCTIONS_EMAIL')}</div>
          <Button bsStyle="link" onClick={this.resendEmail} disabled={emailSendingInProgress}>
            {t('FORGOT_PASSWORD.RESEND_EMAIL')}
          </Button>
          <Button bsStyle="link" onClick={this.handleRediretToHome} disabled={emailSendingInProgress}>
            {t('FORGOT_PASSWORD.BACK_TO_HOME')}
          </Button>
        </div>
      </div>
    );
  };

  render = () => {
    const { showEmailSentConfirmation } = this.props;

    return (
      <div className="forgot-password-wrapper">
        <HeaderBar />
        <div className="forgot-password-form">{showEmailSentConfirmation ? this.renderEmailSentConfirmation() : this.renderForgotPassword()}</div>
      </div>
    );
  };
}

ForgotPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showEmailSentConfirmation: state.registration.passwordResetEmailSent,
  emailSendingInProgress: state.registration.sendResetPasswordEmailInProgress,
  gRecaptchaResponse: state.registration.gRecaptchaResponse,
});

const mapDispatchToProps = dispatch => ({
  sendResetPasswordEmail: data => {
    dispatch(sendResetPasswordEmail(data));
  },
  sendResetPasswordEmailSuccess: data => {
    dispatch(sendResetPasswordEmailSuccess(data));
  },
  setRecaptchaResponse: data => {
    dispatch(setRecaptchaResponse(data));
  },
  handleResetReCaptcha: data => {
    dispatch(handleResetReCaptcha(data));
  },
});

const validateForm = formProps => {
  let errors = {};

  requiredField(formProps, errors, 'email', 'FORGOT_PASSWORD.EMAIL_IS_REQUIRED');

  if (!!formProps.email && !EMAIL_CRITERIA.test(formProps.email)) {
    errors.email = 'FORGOT_PASSWORD.INVALID_EMAIL_FORMAT';
  }

  return errors;
};

ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

export default withRouter(
  reduxForm({
    form: 'forgotPasswordForm',
    validate: validateForm,
    destroyOnUnmount: false,
  })(ForgotPassword)
);
