import { isIOS } from 'react-device-detect';

export default class ModalHelper {
  static IOS_STOP_SCROLL_CLASS = 'modal-open-ios';
  static MODAL_CENTER_CLASS = 'modal-dialog-centered';

  static handleRemoveScrollStop = () => {
    // There can be multiple modals shown on the screen
    // hence remove the class solely if there's single modal displayed
    if (isIOS && document.querySelectorAll('.modal-dialog').length === 1) {
      document.body.className = document.body.className.split(ModalHelper.IOS_STOP_SCROLL_CLASS).join('');
    }
  };

  static handleAddScrollStop = () => {
    if (isIOS && !document.body.className.includes(ModalHelper.IOS_STOP_SCROLL_CLASS)) {
      document.body.className = document.body.className ? `${document.body.className} ${ModalHelper.IOS_STOP_SCROLL_CLASS}` : ModalHelper.IOS_STOP_SCROLL_CLASS;
    }
  };

  static handleModalCentering = modalClass => {
    const modals = document.getElementsByClassName(modalClass);

    if (modals.length === 1) {
      const modalDialog = modals[0].getElementsByClassName('modal-dialog')[0];
      const bodyRect = document.body.getBoundingClientRect();
      const modalDialogRect = modalDialog.getBoundingClientRect();

      if (bodyRect.height - modalDialogRect.height > 10) {
        modalDialog.className = `${modalDialog.className || ''} ${ModalHelper.MODAL_CENTER_CLASS}`;
      }
    }
  };
}
