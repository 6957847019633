import moment from 'moment';
import 'moment/locale/en-ie';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/hu';
import 'moment/locale/cs';

class MomentLocalizationHelper {
  static isInitialized = false;

  static setLocale(language, t) {
    const customLocalization = MomentLocalizationHelper.getCustomLocalization(t);
    // If locale doesn't exist, we use defineLocale method. If it exist it must be imported, thats why we're importing 'moment/min/locales'.
    // Imported locale's translations are overriden using updateLocale method. Setter method 'locale' returns currently set locale.
    // If we try to set non-defined locale, momentjs will stick to default - 'en' locale.
    if (moment.locale(language) === language) {
      moment.updateLocale(language, customLocalization);
    } else {
      moment.defineLocale(language, customLocalization);
    }
    MomentLocalizationHelper.isInitialized = true;
  }

  static getCustomLocalization(t) {
    return {
      relativeTime: {
        future: t('MOMENT.IN'),
        past: t('MOMENT.AGO'),
        s: t('MOMENT.SEC'),
        m: t('MOMENT.MINUTE'),
        mm: t('MOMENT.MINUTES'),
        h: t('MOMENT.HOUR'),
        hh: t('MOMENT.HOURS'),
        d: t('MOMENT.DAY'),
        dd: t('MOMENT.DAYS'),
        M: t('MOMENT.MONTH'),
        MM: t('MOMENT.MONTHS'),
        y: t('MOMENT.YEAR'),
        yy: t('MOMENT.YEARS'),
      },
    };
  }
}

export default MomentLocalizationHelper;
