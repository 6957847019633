import isNumeric from 'validator/lib/isNumeric';

// Helpers
export const propertyInObject = (obj, property) => {
  if (obj[property]) {
    return true;
  }
  return false;
};

export const requiredField = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!propertyInObject(formValues, field)) {
    error[field] = message;
  }
  return error;
};

export const changeField = (formProps, field, value) => {
  formProps.dispatch(formProps.change(field, value));
};

// Field Normalizers

export const onlyNumber = limit => {
  return (value, previousValue) => {
    if (!limit) {
      return isNumeric(value) || value === '' ? value : previousValue;
    }
    return (value.length <= limit && isNumeric(value)) || value === '' ? value : previousValue;
  };
};

export const minValue = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (parseInt(formValues[field], 10) < parseInt(min, 10)) {
    error[field] = message;
  }
  return error;
};
