import axios from 'axios';

class UserApi {
  static getUserInfo = () => {
    return axios({
      method: 'GET',
      url: '/user/info',
    });
  };

  static updateLanguage = lang => {
    return axios({
      method: 'PUT',
      url: '/user/update',
      data: {
        language: lang,
      },
    });
  };

  static getUserSpecificMQTTTopics = () => {
    return axios({
      method: 'GET',
      url: '/user/socket_topics',
    });
  };

  static getUserFees = () => {
    return axios({
      method: 'GET',
      url: '/user/fees',
    });
  };

  static updateUserNGCFee = useNGCForFees => {
    return axios({
      method: 'POST',
      url: '/user/exchange_fee_in_ngc',
      data: {
        to_ngc: useNGCForFees,
      },
    });
  };

  static sendInviteLink = data => {
    return axios({
      method: 'POST',
      url: '/wallet/refer_friend_email',
      data: {
        email: data.email,
      },
    });
  };

  static getInvitedUsers = () => {
    return axios({
      method: 'GET',
      url: '/wallet/user_referrals',
    });
  };
}

export default UserApi;
