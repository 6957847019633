import React from 'react';

import { CONNECTION_STATUS } from '../../../../config/constants';

import '../styles/status-circle.scss';

export const StatusCircle = props => {
  const { status } = props;
  return <label className={`status-circle ${status === CONNECTION_STATUS.ONLINE ? 'green' : 'red'}`} />;
};
