import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Button from 'react-bootstrap/lib/Button';

import HeaderBar from './header-bar';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import { requiredField } from '../../shared-components/helpers/helpers';
import { createSession, signOut } from '../actions/registration-actions';
import CookiesWrapper from '../../../core/helpers/cookies-wrapper';

import '../styles/mfa-validation.scss';

class MFAValidation extends Component {
  componentDidUpdate = prev => {
    const { loggedIn, history } = this.props;

    if (loggedIn) {
      history.push('/exchange');
    }
  };

  handleGoBackClick = () => {
    const { history, signOut } = this.props;

    signOut();
    history.push('/login');
  };

  handleFormSubmit = values => {
    const { createSession } = this.props;
    const data = {
      device_uuid: CookiesWrapper.getCookie('fingerprint'),
      token: values.mfa_code,
    };

    createSession(data);
  };

  render = () => {
    const { t } = this.context;
    const { handleSubmit, loginInProgress, invalidLogin } = this.props;

    return (
      <div className="mfa">
        <div className="mfa-wrapper">
          <HeaderBar />
          <div className="mfa-wrapper__content">
            <div className="mfa-wrapper__content__header-container">
              <div className="mfa-wrapper__content__header-container__title">{t('MFA.MFA_TITLE')}</div>
              <div className="mfa-wrapper__content__header-container__description">{t('MFA.MFA_CODE_DESCRIPTION')}</div>
            </div>
            <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} noValidate>
              <Field disabled={loginInProgress} name="mfa_code" type="text" placeHolder="MFA.VERIFICATION_CODE" component={DefaultInput} />
              {invalidLogin && <div className="mfa-wrapper__content__error">{this.context.t('MFA.WRONG_MFA_CODE')}</div>}
              <Button disabled={loginInProgress} bsStyle="primary" type="submit">
                {t('MFA.CONTINUE')}
              </Button>
            </form>
            <Button bsStyle="link" className="mfa-wrapper__content__back" onClick={this.handleGoBackClick}>
              {t('MFA.GO_BACK')}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

MFAValidation.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mfaFormValidate = formProps => {
  const errors = {};

  requiredField(formProps, errors, 'mfa_code', 'MFA.MFA_CODE_REQUIRED');

  return errors;
};

const mapStateToProps = state => ({
  loginInProgress: state.registration.loginInProgress,
  invalidLogin: state.registration.invalidLogin,
  loggedIn: state.currentUser.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  createSession: data => {
    dispatch(createSession(data));
  },
  signOut: () => {
    dispatch(signOut());
  },
});

MFAValidation = connect(mapStateToProps, mapDispatchToProps)(MFAValidation);

export default withRouter(
  reduxForm({
    form: 'mfaForm',
    validate: mfaFormValidate,
  })(MFAValidation)
);
