import React from 'react';
import find from 'lodash/find';

import { MAIN_LANGUAGES, FLAGS_BASE_URL } from '../../../config/constants';

class UserSettingsHelper {
  static getLanguage = language => {
    const usersLanguage = find(MAIN_LANGUAGES, lang => lang.CODE === language);
    const availableLanguage = usersLanguage || MAIN_LANGUAGES.en;

    return UserSettingsHelper.getLanguageOption(availableLanguage);
  };

  static getLanguageOption = language => {
    const availableLanguage = language || MAIN_LANGUAGES.en;

    return {
      value: language.CODE,
      label: (
        <div>
          <img className="country-flag" src={`${FLAGS_BASE_URL}${availableLanguage.FLAG}.png`} alt="Country" />
          {language.TEXT}
        </div>
      ),
    };
  };
}

export default UserSettingsHelper;
