import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import Modal from 'react-bootstrap/lib/Modal';
import isEmpty from 'lodash/isEmpty';

import GenericModal from '../../../shared-components/generic-modal/generic-modal';
import OrderBook from '../../../exchange/components/order-book/order-book';

import '../../styles/order-book/order-book-modal.scss';

class OrderBookModal extends Component {
  onModalShow = () => {
    const { selectedPair, orderBookItems, getOrderBookItems } = this.props;
    if (isEmpty(orderBookItems)) {
      getOrderBookItems(selectedPair.name);
    }
  };

  closeModal = () => {
    const { toggleOrderBookModal } = this.props;

    toggleOrderBookModal();
  };

  render() {
    const { t } = this.context;
    const { selectedPair, selectedMarket, showOrderBookModal, toggleOrderBookModal, selectedOrderType } = this.props;

    return (
      <GenericModal className={`order-book-modal${isIOS ? ' ios' : ''}`} show={showOrderBookModal} onShow={this.onModalShow} onHide={this.closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('OPEN_ORDER.ORDER_BOOK')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderBook selectedPair={selectedPair} selectedMarket={selectedMarket} onItemSelected={toggleOrderBookModal} orderSide={selectedOrderType} />
        </Modal.Body>
      </GenericModal>
    );
  }
}

OrderBookModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OrderBookModal;
