import Login from '../features/registration/components/login';
import Register from '../features/registration/components/register';
import ForgotPassword from '../features/registration/components/forgot-password';
import ResetPassword from '../features/registration/components/reset-password';
import SocialAutoLogin from '../features/registration/components/social-auto-login';
import AutoLoginViaToken from '../features/auto-login/auto-login-via-token.jsx';
import VerfiyEmail from '../features/verify-email/components/verify-email';
import Exchange from '../features/exchange/components/exchange';
import Wallet from '../features/wallet/components/wallet';
import Orders from '../features/orders/components/orders';
import OpenOrderContainer from '../features/orders/components/open-order-container';
import OpenOrders from '../features/orders/components/open-orders';
import ExecutedOrders from '../features/orders/components/executed-orders';
import UserSettings from '../features/user-settings/components/user-settings';
import UserSettingsInfo from '../features/user-settings/components/user-settings-info';
import UserSettingsSecurity from '../features/user-settings/components/user-settings-security';
import UserSettingsPayments from '../features/user-settings/components/user-settings-payments';
import { NoMatch } from '../features/404/components/404';
import MFAValidation from '../features/registration/components/mfa-validation';
import ReferAFriend from '../features/refer-a-friend/components/refer-a-friend';
import { handleDisplayAppPrompt } from '../features/shared-components/prompt/actions/prompt-actions';
import ConfirmEmail from '../features/confirm-email/components/confirm-email';

const routes = [
  {
    path: '/',
    component: Exchange,
    exact: true,
    layoutClasses: 'custom-layout',
  },
  {
    path: '/login',
    component: Login,
    layoutClasses: 'override-layout',
  },
  {
    path: '/mfa',
    component: MFAValidation,
    layoutClasses: 'override-layout',
  },
  {
    path: '/autologin/social',
    component: SocialAutoLogin,
    layoutClasses: 'override-layout',
  },
  {
    path: '/auth/token',
    component: AutoLoginViaToken,
    layoutClasses: 'override-layout',
  },
  {
    path: '/register',
    component: Register,
    layoutClasses: 'override-layout',
  },
  {
    path: '/password/forgot',
    component: ForgotPassword,
    layoutClasses: 'override-layout',
  },
  {
    path: '/passwordrecovery/:email/:code',
    component: ResetPassword,
    layoutClasses: 'override-layout',
  },
  {
    path: '/verify-email',
    component: VerfiyEmail,
    requiresAuth: true,
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
    layoutClasses: 'override-layout',
  },
  {
    path: '/exchange/:pair?',
    component: Exchange,
    layoutClasses: 'custom-layout',
  },
  {
    path: '/wallet',
    component: Wallet,
  },
  {
    path: '/orders',
    component: Orders,
    routes: [
      {
        path: '/orders/open',
        component: OpenOrders,
      },
      {
        path: '/orders/executed',
        component: ExecutedOrders,
      },
    ],
  },
  {
    path: '/open-order/:pair',
    component: OpenOrderContainer,
  },
  {
    path: '/user-settings',
    component: UserSettings,
    requiresAuth: true,
    routes: [
      {
        path: '/user-settings/info',
        requiresAuth: true,
        component: UserSettingsInfo,
      },
      {
        path: '/user-settings/security',
        requiresAuth: true,
        component: UserSettingsSecurity,
      },
      {
        path: '/user-settings/payments',
        requiresAuth: true,
        component: UserSettingsPayments,
      },
    ],
  },
  {
    path: '/refer-a-friend',
    component: ReferAFriend,
    requiresAuth: true,
    actionCallback: (t, history, dispatch) => {
      const promptSettings = {
        show: true,
        title: t('LOGIN.SIGN_IN_OR_CREATE_ACCOUNT'),
        description: t('LOGIN.SIGN_IN_OR_REGISTER_NOTE'),
        ctaText: 'LOGIN.SIGN_IN_OR_CREATE_ACCOUNT',
        submitCallback: () => {
          history.push('/login');
        },
      };

      dispatch(handleDisplayAppPrompt(promptSettings));
    },
  },
  {
    component: NoMatch,
  },
];

export default routes;
