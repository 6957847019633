import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HelperFunctions from '../../core/helpers/helper-functions';
import CookiesWrapper from '../../core/helpers/cookies-wrapper';
import AppLoader from '../shared-components/loader/components/loader';
import { tokenLogin } from '../registration/actions/registration-actions';

class AutoLoginViaToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetPage: HelperFunctions.getParameterByName('page'),
    };
  }

  componentDidMount() {
    const { tokenLogin } = this.props;
    const token = HelperFunctions.getParameterByName('token');
    const userId = HelperFunctions.getParameterByName('user_id');

    HelperFunctions.generateFingerprint(() => {
      const data = {
        user_id: +userId,
        token: decodeURIComponent(token),
        device_uuid: CookiesWrapper.getCookie('fingerprint'),
      };

      tokenLogin(data);
    });
  }

  componentDidUpdate = () => {
    const { history, loggedIn } = this.props;

    if (loggedIn) {
      history.push('/exchange/NGCUSD');
    }
  };

  render() {
    const { t } = this.context;

    return (
      <div>
        <AppLoader message={t('LOGIN.LOGGING_IN_PLEASE_WAIT')} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.currentUser.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  tokenLogin: data => {
    dispatch(tokenLogin(data));
  },
});

AutoLoginViaToken.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoLoginViaToken));
