import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MiniLoader from '../../mini-loader/mini-loader';

import '../styles/default-input.scss';

class DefaultInput extends Component {
  render = () => {
    const {
      input,
      readOnlyInput,
      placeHolder,
      type,
      label,
      labelTranslationValues,
      loaderExtended,
      customError,
      errorTranslationValues,
      disabled,
      className,
      imageExtended,
      textExtended,
      children,
      extendedPartClass,
      minLength,
      maxLength,
      meta: { touched, error, asyncValidating },
    } = this.props;

    const inputHasError = touched && (error || customError);

    Object.assign(input, inputHasError ? { className: 'input-error' } : null, minLength ? { minLength } : null, maxLength ? { maxLength } : null);

    return (
      <div className={`default-input ${className || ''}`}>
        {label ? <label>{this.context.t(label, labelTranslationValues)}</label> : ''}
        <div className={asyncValidating ? 'input-and-error async-validating' : 'input-and-error'}>
          <input type={type} readOnly={readOnlyInput} placeholder={this.context.t(placeHolder)} {...input} autoCorrect="off" autoCapitalize="none" disabled={disabled} />
          {inputHasError && <div className="error">{this.context.t(error || customError, errorTranslationValues)}</div>}
          <span className={`extended-part ${extendedPartClass}`}>
            {(() => {
              if (textExtended) return <span>{textExtended}</span>;
              else if (imageExtended) return <img src={imageExtended} alt="inputImage" />;
              else if (loaderExtended) return <MiniLoader />;
              else if (children) return children;
              else return null;
            })()}
          </span>
        </div>
      </div>
    );
  };
}

DefaultInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(state => ({
  lang: state.i18nState.lang,
}))(DefaultInput);
