import { REAL_TIME_SERVICE_ACTIONS } from '../constants/real-time-service-constants';

const realTimeServiceInitialState = {
  isAvailable: false,
  isConnected: false,
  subscriptionsFinished: false,
};

export const realTimeService = (state = realTimeServiceInitialState, payload) => {
  switch (payload.type) {
    case REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_RT_CONNECTED:
      return { ...state, isConnected: payload.data };
    case REAL_TIME_SERVICE_ACTIONS.HANDLE_IS_RT_AVAILABLE:
      return { ...state, isAvailable: payload.data };
    case REAL_TIME_SERVICE_ACTIONS.HANDLE_SUBSCRIPTIONS_FINISHED:
      return { ...state, subscriptionsFinished: payload.data };
    default:
      return state;
  }
};
