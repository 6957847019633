import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import maxBy from 'lodash/maxBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import withRouter from 'react-router-dom/withRouter';

import { bindRTListener, offRTCallback } from '../../../real-time-service/actions/real-time-service-actions';
import { handleSeletedOrderBookItem } from '../../actions/exchange-actions';

import { ORDER_SIDE, REAL_TIME_CHANNELS, ACCOUNT_CURRENCIES } from '../../../../config/constants';
import { ORDER_BOOK_DISPLAY_TYPE } from '../../constants/exchange-constants';
import HelperFunctions from '../../../../core/helpers/helper-functions';
import MiniLoader from '../../../shared-components/mini-loader/mini-loader';
import Amount from '../../../shared-components/amount/components/amount';
import OrderBookItem from './order-book-item';

import '../../styles/order-book/order-book.scss';

class OrderBook extends Component {
  constructor(props) {
    super(props);
    const { orderSide } = this.props;

    this.state = {
      loadedItemsBuy: [],
      loadedItemsSell: [],
      maxTotalBidsItem: {},
      maxTotalAsksItem: {},
      colorClass: '',
      selectedUI: orderSide ? (orderSide === ORDER_SIDE.SELL ? ORDER_BOOK_DISPLAY_TYPE.SELL_SELL : ORDER_BOOK_DISPLAY_TYPE.BUY_BUY) : ORDER_BOOK_DISPLAY_TYPE.SELL_BUY,
    };

    this.componentId = null;
    this.componentMounted = null;
  }
  componentDidMount = () => {
    this.componentMounted = true;
  };

  componentWillMount = () => {
    const { orderBookItems } = this.props;

    if (!isEmpty(orderBookItems)) {
      this.initializeData(orderBookItems);
    }
  };

  componentDidUpdate = prevProps => {
    const { orderBookItems, getOrderBookItemsInProgress, selectedPair } = this.props;

    if ((prevProps.getOrderBookItemsInProgress && !getOrderBookItemsInProgress && !isEmpty(orderBookItems)) || (isEmpty(prevProps.orderBookItems) && !isEmpty(orderBookItems))) {
      this.initializeData(orderBookItems);
    }

    if (prevProps.selectedPair.name !== selectedPair.name) {
      this.bindToPublicData();
    }

    if (parseFloat(prevProps.selectedPair.currentPrice) < parseFloat(selectedPair.currentPrice)) {
      this.setState({ colorClass: 'animate-color-green' });
    } else if (parseFloat(prevProps.selectedPair.currentPrice) > parseFloat(selectedPair.currentPrice)) {
      this.setState({ colorClass: 'animate-color-red' });
    }
  };

  componentWillUnmount = () => {
    const { offRTCallback, selectedPair } = this.props;

    this.componentMounted = false;
    offRTCallback(`${REAL_TIME_CHANNELS.LEVEL2}/${selectedPair.name}`, this.componentId);
  };

  getMaxTotalItemFromData = data => {
    return maxBy(data, m => parseFloat(m.price) * parseFloat(m.amount)); //get max total by multiplying price and amount
  };

  initializeData = orderBookItems => {
    this.setState({
      loadedItemsBuy: orderBy(orderBookItems.bids, o => o.price, ['desc']) || [],
      loadedItemsSell: orderBy(orderBookItems.asks, o => o.price, ['desc']) || [],
      //in order to perform correct drawing of charts for order book items, these calculations need to be performed.
      maxTotalBidsItem: this.getMaxTotalItemFromData(orderBookItems.bids) || { price: 1, amount: 1 },
      maxTotalAsksItem: this.getMaxTotalItemFromData(orderBookItems.asks) || { price: 1, amount: 1 },
    });
  };

  bindToPublicData = () => {
    const { bindRTListener, selectedPair } = this.props;

    if (!isEmpty(selectedPair) && !isEmpty(selectedPair.name)) {
      this.componentId = HelperFunctions.generateComponentId(`order_book_${selectedPair.name}`);

      bindRTListener(
        `${REAL_TIME_CHANNELS.LEVEL2}/${selectedPair.name}`,
        message => {
          const payload = JSON.parse(message);

          const bids = payload.bids.map(a => {
            return { price: a[0], amount: a[1] };
          });
          const asks = payload.asks.map(a => {
            return { price: a[0], amount: a[1] };
          });

          if (this.componentMounted) {
            this.setState({
              loadedItemsBuy: orderBy(bids, o => o.price, ['desc']) || [],
              loadedItemsSell: orderBy(asks, o => o.price, ['desc']) || [],
              //in order to perform correct drawing of charts for order book items, these calculations need to be performed.
              maxTotalBidsItem: this.getMaxTotalItemFromData(bids) || { price: 1, amount: 1 },
              maxTotalAsksItem: this.getMaxTotalItemFromData(asks) || { price: 1, amount: 1 },
            });
          }
        },
        this.componentId
      );
    }
  };

  throttleRequest = (fn, delay) => {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      return fn(...args);
    };
  };

  handleOrderBookItemPriceSelected = e => {
    this.persistSelectedOrder(e.currentTarget.dataset);
  };

  handleOrderBookItemAmountSelected = e => {
    this.persistSelectedOrder(e.currentTarget.dataset);
  };

  handleSelectedUIClick = e => {
    const id = e.currentTarget.dataset.id;

    this.setState({ selectedUI: id });
  };

  persistSelectedOrder = dataset => {
    const { handleSeletedOrderBookItem, onItemSelected } = this.props;
    const { loadedItemsSell, loadedItemsBuy } = this.state;

    let selectedItem = {
      price: dataset.price,
      type: dataset.type,
      amount: 0,
    };

    //amount of selected order is calculated based on price, all orders that has lower price than selcted
    //are included in the calculation of amount
    if (dataset.amount) {
      const list = dataset.type === ORDER_SIDE.BUY ? loadedItemsBuy : loadedItemsSell;
      const filteredList = filter(list, a => a.price <= dataset.price); //select all order book items that has the same or lower price than selected
      const totalAmount = sumBy(filteredList, s => s.amount); //sum all amounts
      selectedItem.amount = String(totalAmount);
    }

    handleSeletedOrderBookItem(selectedItem);

    if (onItemSelected) {
      onItemSelected();
    }
  };

  renderOrderBookItems = type => {
    const { selectedPair } = this.props;
    const { loadedItemsSell, loadedItemsBuy, maxTotalAsksItem, maxTotalBidsItem, selectedUI } = this.state;
    const items = type === ORDER_SIDE.SELL ? loadedItemsSell : loadedItemsBuy;
    const maxTotal = type === ORDER_SIDE.SELL ? maxTotalAsksItem : maxTotalBidsItem;

    const orderBookItems = items.map(item => {
      return (
        <OrderBookItem
          key={`${item.price}-${item.orderId}`}
          item={item}
          type={type}
          maxTotal={maxTotal.price * maxTotal.amount}
          onPriceClick={this.handleOrderBookItemPriceSelected}
          onAmountClick={this.handleOrderBookItemAmountSelected}
          pair={selectedPair}
        />
      );
    });
    const orderBookItemsSize = items.length;
    //26 is the max number of items that can be displayed under Order Book tab
    if (orderBookItemsSize < 26) {
      for (let i = 0; i < 26 - orderBookItemsSize; i++) {
        if (type === ORDER_SIDE.BUY) {
          orderBookItems.push(<OrderBookItem key={i} isPlaceHolder={true} type={type} />);
        } else if (type === ORDER_SIDE.SELL) {
          orderBookItems.unshift(<OrderBookItem key={i} isPlaceHolder={true} type={type} />);
        }
      }
    }

    //if order book combined view is selected
    if (Number(selectedUI) === ORDER_BOOK_DISPLAY_TYPE.SELL_BUY) {
      //for bid orders select first 12 items (ordered desc by price)
      if (type === ORDER_SIDE.BUY) {
        return orderBookItems.slice(0, 12);
      }
      //for ask orders select last 11 items (ordered desc by price)
      return orderBookItems.slice(orderBookItems.length - 12, orderBookItems.length);
    }
    return orderBookItems;
  };

  render = () => {
    const { t } = this.context;
    const { getOrderBookItemsInProgress, selectedPair, className, fetchingSymbols } = this.props;
    const { selectedUI, loadedItemsSell, loadedItemsBuy, colorClass } = this.state;
    const selecteUIConverted = Number(selectedUI);

    if (getOrderBookItemsInProgress || fetchingSymbols) {
      return (
        <div className={`order-book ${className || ''}`}>
          <div className="order-book__loader">
            <MiniLoader />
          </div>
        </div>
      );
    }

    if (isEmpty(loadedItemsSell) && isEmpty(loadedItemsBuy)) {
      return (
        <div className={`order-book ${className || ''}`}>
          <div className="order-book__no-data-title">{t('EXCHAGE_STATISTICS.ORDER_BOOK')}</div>
          <div className="order-book__no-data">{t('EXCHANGE.ORDER_BOOK_NO_DATA')}</div>
        </div>
      );
    }

    return (
      <div className={`order-book ${className || ''}`}>
        <div className="order-book__show-option">
          <div
            className={`order-book__show-option__buy-sell ${selecteUIConverted === ORDER_BOOK_DISPLAY_TYPE.SELL_BUY && 'selected-book-option'}`}
            data-id={ORDER_BOOK_DISPLAY_TYPE.SELL_BUY}
            onClick={this.handleSelectedUIClick}
          />
          <div
            className={`order-book__show-option__sell-sell ${selecteUIConverted === ORDER_BOOK_DISPLAY_TYPE.SELL_SELL && 'selected-book-option'}`}
            data-id={ORDER_BOOK_DISPLAY_TYPE.SELL_SELL}
            onClick={this.handleSelectedUIClick}
          />
          <div
            className={`order-book__show-option__buy-buy ${selecteUIConverted === ORDER_BOOK_DISPLAY_TYPE.BUY_BUY && 'selected-book-option'}`}
            data-id={ORDER_BOOK_DISPLAY_TYPE.BUY_BUY}
            onClick={this.handleSelectedUIClick}
          />
        </div>
        <div className="order-book__title">
          <div>{t('EXCHANGE.ORDER_BOOK_ITEM_PRICE', { value: selectedPair.quote_currency })}</div>
          <div>{t('EXCHANGE.ORDER_BOOK_ITEM_AMOUNT', { value: selectedPair.base_currency })}</div>
          <div>{t('EXCHANGE.ORDER_BOOK_ITEM_TOTAL', { value: selectedPair.quote_currency })}</div>
        </div>
        <div
          id="sell-container"
          className={`order-book__sell-container ${selecteUIConverted === ORDER_BOOK_DISPLAY_TYPE.BUY_BUY && 'order-book__sell-container-hide'} ${selecteUIConverted ===
            ORDER_BOOK_DISPLAY_TYPE.SELL_SELL && 'order-book__sell-container-expand'}`}
        >
          {this.renderOrderBookItems(ORDER_SIDE.SELL)}
        </div>
        <div className={`order-book__separator ${selecteUIConverted !== ORDER_BOOK_DISPLAY_TYPE.SELL_BUY && 'order-book__separator-hide'}`}>
          <Amount value={selectedPair.currentPrice} decimals={selectedPair.quote_currency_decimals} suffix={selectedPair.quote_currency} className={colorClass} />
          {false && selectedPair.quote_currency !== ACCOUNT_CURRENCIES.usd.value && <Amount value={selectedPair.currentPrice} prefix=" ($ " suffix=")" />}
        </div>
        <div
          id="buy-container"
          className={`order-book__buy-container ${selecteUIConverted === ORDER_BOOK_DISPLAY_TYPE.SELL_SELL && 'order-book__buy-container-hide'} ${selecteUIConverted ===
            ORDER_BOOK_DISPLAY_TYPE.BUY_BUY && 'order-book__buy-container-expand'}`}
        >
          {this.renderOrderBookItems(ORDER_SIDE.BUY)}
        </div>
      </div>
    );
  };
}

OrderBook.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  orderBookItems: state.exchange.orderBook.items,
  getOrderBookItemsInProgress: state.exchange.orderBook.getOrderBookInProgress,
  selectedPair: state.exchange.selectedPair,
  fetchingSymbols: state.exchange.fetchingSymbols,
});

const mapDispatchToProps = dispatch => ({
  handleSeletedOrderBookItem: data => {
    dispatch(handleSeletedOrderBookItem(data));
  },
  bindRTListener: (topic, callback, pair) => {
    dispatch(bindRTListener(topic, callback, pair));
  },
  offRTCallback: (topic, componentId) => {
    dispatch(offRTCallback(topic, componentId));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderBook));
