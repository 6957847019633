import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import withRouter from 'react-router-dom/withRouter';

import { handleResendVerificationEmail } from '../../current-user/actions/current-user-actions';

import RenderIf from '../../shared-components/render-if/components/render-if';

import '../styles/verify-email.scss';

class VerifyEmail extends Component {
  handleResendEmailClick = () => {
    const { handleResendVerificationEmail } = this.props;

    handleResendVerificationEmail();
  };

  handleStartTradingClick = () => {
    const { history } = this.props;

    history.push('/market-watch');
  };

  renderGoTrading = () => {
    const { t } = this.context;
    return (
      <div className="verify-email__success">
        {t('VERIFY_EMAIL.VERIFIED_SUCCESSFULLY')}
        <Button bsStyle="primary" onClick={this.handleStartTradingClick}>
          {t('VERIFY_EMAIL.GO_TRADING')}
        </Button>
      </div>
    );
  };

  renderVerifyEmail = () => {
    const { t } = this.context;
    const { verificationEmailResent, userInfo } = this.props;
    return (
      <div>
        <div className="verify-email__description">
          <div className="verify-email__description__icon" />
          <div className="verify-email__description__title">{t('VERIFY_EMAIL.TITLE')}</div>
          <div>
            <p>{t('VERIFY_EMAIL.VERIFY_TO_USE_NAGA_FEATURES')}</p>
            <p>{t('VERIFY_EMAIL.EMAIL_SENT_TO_ADDRESS', { email: userInfo.email })}</p>
          </div>
          <p>{t('VERIFY_EMAIL.FOLLOW_INSTRUCTIONS')}</p>
        </div>
        <RenderIf if={!verificationEmailResent}>
          <div className="verify-email__actions">
            <div>{t('VERIFY_EMAIL.EMAIL_NOT_RECEIVED')}</div>
          </div>
          <Button bsStyle="link" className="verify-email__actions__resend" onClick={this.handleResendEmailClick}>
            {t('VERIFY_EMAIL.RESEND')}
          </Button>
        </RenderIf>
        <RenderIf if={verificationEmailResent}>
          <div className="verify-email__actions">
            <div className="verify-email__actions__icon" />
            <div>{t('VERIFY_EMAIL.EMAIL_HAS_BEEN_RESENT')}</div>
          </div>
        </RenderIf>
      </div>
    );
  };

  render = () => {
    const { emailConfirmed } = this.props;

    return <div className="verify-email">{emailConfirmed ? this.renderGoTrading() : this.renderVerifyEmail()}</div>;
  };
}

VerifyEmail.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  emailConfirmed: state.currentUser.info.emailVerified,
  userInfo: state.currentUser.info,
  verificationEmailResent: state.currentUser.verificationEmailResent,
});

const mapDispatchToProps = dispatch => ({
  handleResendVerificationEmail: () => {
    dispatch(handleResendVerificationEmail());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));
