import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';

import { ORDERS_TABS } from '../../constants/exchange-constants';
import OpenOrders from '../../../orders/components/open-orders';
import ExecutedOrders from '../../../orders/components/executed-orders';

import '../../styles/exchange-orders.scss';

class ExchangeOrders extends Component {
  state = {
    selectedOrdersTab: ORDERS_TABS.OPEN_ORDERS,
  };

  handleOrdersTabSelected = value => {
    this.setState({ selectedOrdersTab: value });
  };

  render = () => {
    const { t } = this.context;
    const { isMobile, className } = this.props;
    const { selectedOrdersTab } = this.state;

    if (isMobile) {
      return null;
    }

    return (
      <div className={`exchange-orders ${className || ''}`}>
        <div className="exchange-orders__tabs-container">
          <ButtonToolbar className="tabs-toggle">
            <ToggleButtonGroup type="radio" name="options" value={selectedOrdersTab} onChange={this.handleOrdersTabSelected}>
              <ToggleButton value={ORDERS_TABS.OPEN_ORDERS}>{t('EXCHAGE_ORDERS.OPEN_ORDERS')}</ToggleButton>
              <ToggleButton value={ORDERS_TABS.EXECUTED_ORDERS}>{t('EXCHAGE_ORDERS.EXECUTED_ORDERS')}</ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </div>
        {selectedOrdersTab === ORDERS_TABS.OPEN_ORDERS ? <OpenOrders limitNumberOfItems={true} /> : <ExecutedOrders limitNumberOfItems={true} />}
      </div>
    );
  };
}

ExchangeOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isMobile: state.mediaQuery.isMobile,
});

export default connect(mapStateToProps, null)(ExchangeOrders);
