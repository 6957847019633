import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HOLD_BUTTON_TYPE } from '../../constants/exchange-constants';

import '../../styles/enter-value.scss';

class EnterValue extends Component {
  interval = null;
  val = 0;

  constructor(props) {
    super(props);

    this.state = {};
  }

  onKeyPress = e => {
    const charCode = e.which || e.keyCode;
    const value = e.target.value;

    if (charCode === 45) {
      if (value !== '') e.preventDefault();
    } else if (charCode === 46) {
      if (value.indexOf('.') > -1) e.preventDefault();
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  start = (e, type) => {
    const { value } = this.props;
    this.val = +value;

    if (type === HOLD_BUTTON_TYPE.DECREMENT) {
      this.val -= this.props.increment;
    } else if (type === HOLD_BUTTON_TYPE.INCREMENT) {
      this.val += this.props.increment;
    }

    this.props.onValueChanged(this.val, this.props.type);
  };

  end = (e, type) => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  clickNHold = (e, type) => {
    const { value } = this.props;
    this.val = value;

    this.interval = setInterval(() => {
      if (type === HOLD_BUTTON_TYPE.DECREMENT) {
        this.val -= this.props.increment;
      } else if (type === HOLD_BUTTON_TYPE.INCREMENT) {
        this.val += this.props.increment;
      }

      this.props.onValueChanged(this.val, this.props.type);
    }, 200);
  };

  handleChange = e => {
    this.props.changeValue(e.target.value, this.props.type);
  };

  updateValue = e => {
    const val = parseFloat(e.target.value) || 0;
    this.props.onValueChanged(val, this.props.type);
  };

  render = () => {
    const { t } = this.context;
    const { hideInputElement, descText, currency, value, className } = this.props;

    return (
      <div className={className}>
        <div className="enter-value">
          <div className="enter-value__text">{t(descText)}</div>
          <div className="enter-value__input">
            {!hideInputElement ? (
              <div className="enter-value__input__container">
                <input type="text" value={value || ''} onChange={this.handleChange} onBlur={this.updateValue} onKeyPress={this.onKeyPress} />
                <span className="enter-value__input__container-currency">{currency}</span>
              </div>
            ) : (
              <div className="enter-value__input-at-price">{t('OPEN_ORDER.AT_MARKET_PRICE')}</div>
            )}
          </div>
        </div>

        {this.props.validationMsg && <div className="enter-value-validation">{this.props.validationMsg}</div>}
      </div>
    );
  };
}

EnterValue.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EnterValue;
