import React, { Component } from 'react';
import moment from 'moment';

import Amount from '../../../shared-components/amount/components/amount';
import { ORDER_SIDE } from '../../../../config/constants';

import '../../styles/trade-history/trade-history-item.scss';

export default class TradeHistoryItem extends Component {
  render = () => {
    const { item, pair } = this.props;
    return (
      <div className="trade-history-item">
        <div className="trade-history-item__price">
          <Amount value={item.amount} decimals={pair.base_currency_decimals} />
        </div>
        <div className={`trade-history-item__amount ${item.side.toLowerCase()}`}>
          <i className={`icn ${item.side === ORDER_SIDE.BUY ? 'icn-tail-triangle-up' : 'icn-tail-triangle-down'}`} />
          <Amount value={item.price} decimals={pair.quote_currency_decimals} />
        </div>
        <div className="trade-history-item__time">{moment(item.timestamp * 1000).format('h:mm:ss A')}</div>
      </div>
    );
  };
}
