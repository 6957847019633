import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';
import moment from 'moment';

import TradeHistoryItem from './trade-history-item';
import { getTradeHistoryItems, handleResetTradeHistoryItems, handleNewTradeHistoryItemReceived } from '../../actions/exchange-actions';
import { LOADING_DIRECTIONS } from '../../constants/exchange-constants';
import MiniLoader from '../../../shared-components/mini-loader/mini-loader';
import { REAL_TIME_CHANNELS } from '../../../../config/constants';
import { bindRTListener, offRTCallback } from '../../../real-time-service/actions/real-time-service-actions';

import '../../styles/trade-history/trade-history.scss';

class TradeHistory extends Component {
  componentId = Math.floor(Math.random() * 1001);

  componentDidMount = () => {
    const { selectedPair, tradeHistoryItems, getTradeHistoryItemsInPogress } = this.props;

    if (isEmpty(tradeHistoryItems) && !getTradeHistoryItemsInPogress && !isEmpty(selectedPair.name)) {
      this.getTradeHistoryItems();
      this.listenForNewCompletedTrades();
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedPair, handleResetTradeHistoryItems } = this.props;

    if (prevProps.selectedPair.name !== selectedPair.name) {
      //stop listening for new trade history items for previous pair
      if (!isEmpty(prevProps.selectedPair.name)) {
        this.stopListeningOnChange(prevProps.selectedPair.name);
      }

      //delete all items from redux if pair is changed
      handleResetTradeHistoryItems();
      this.getTradeHistoryItems();

      this.listenForNewCompletedTrades();
    }
  };

  componentWillUnmount = () => {
    const { selectedPair } = this.props;
    this.stopListeningOnChange(selectedPair.name);
  };

  renderHistoryItems = () => {
    const { tradeHistoryItems, selectedPair } = this.props;

    return tradeHistoryItems.map(item => {
      return <TradeHistoryItem key={`${item.trade_count}_${item.trade_msb}`} item={item} pair={selectedPair} />;
    });
  };

  handleLoadMoreItems = () => {
    this.getTradeHistoryItems(LOADING_DIRECTIONS.AFTER);
  };

  listenForNewCompletedTrades = () => {
    const { selectedPair, bindRTListener, handleNewTradeHistoryItemReceived } = this.props;

    bindRTListener(
      `${REAL_TIME_CHANNELS.ORDER_MATCHED}/${selectedPair.name}`,
      message => {
        const payload = JSON.parse(message);

        handleNewTradeHistoryItemReceived({
          price: payload.price,
          amount: payload.trade_amount,
          side: payload.side,
          timestamp: payload.timestamp,
          trade_count: payload.trade_id,
          trade_msb: new Date().getTime(), //used for key purposes only
        });
      },
      this.componentId
    );
  };

  stopListeningOnChange = pairName => {
    const { offRTCallback } = this.props;
    offRTCallback(`${REAL_TIME_CHANNELS.ORDER_MATCHED}/${pairName}`, this.componentId);
  };

  getTradeHistoryItems = direction => {
    const { getTradeHistoryItems, selectedPair, lastLoadedTradeId, match, getTradeHistoryItemsInPogress } = this.props;
    if (!getTradeHistoryItemsInPogress) {
      const data = {
        pair: match.params.pair || selectedPair.name,
        direction: direction,
        limit: 60,
        from: lastLoadedTradeId,
      };

      getTradeHistoryItems(data);
    }
  };

  renderHistoryHeader = () => {
    const { t } = this.context;
    const { selectedPair, className } = this.props;

    let utcOffset = moment().utcOffset() / 60;
    if (utcOffset === 0) {
      utcOffset = '';
    } else if (utcOffset > 0) {
      utcOffset = `+${utcOffset}`;
    }

    return (
      <div className={`trade-history-item item-title ${className || ''}`}>
        <div className="trade-history-item__price">{t('EXCHANGE_TRADE_HISTORY.AMOUNT', { n: selectedPair.base_currency })}</div>
        <div className="trade-history-item__amount">{t('EXCHANGE_TRADE_HISTORY.PRICE', { n: selectedPair.quote_currency })}</div>
        <div className="trade-history-item__time">{t('EXCHANGE_TRADE_HISTORY.TIME', { n: `UTC${utcOffset}` })}</div>
      </div>
    );
  };

  render = () => {
    const { t } = this.context;
    const { getTradeHistoryItemsInPogress, tradeHistoryItems, className } = this.props;

    if (getTradeHistoryItemsInPogress && isEmpty(tradeHistoryItems)) {
      return (
        <div className={`trade-history__loader ${className || ''}`}>
          <MiniLoader />
        </div>
      );
    }

    if (isEmpty(tradeHistoryItems)) {
      return <div className={`trade-history__no-data ${className || ''}`}>{t('EXCHANGE.ORDER_BOOK_NO_DATA')}</div>;
    }

    return (
      <div>
        {this.renderHistoryHeader()}
        <div className={`trade-history ${className || ''}`}>{this.renderHistoryItems()}</div>
      </div>
    );
  };
}

TradeHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getTradeHistoryItems: data => {
    dispatch(getTradeHistoryItems(data));
  },
  handleResetTradeHistoryItems: () => {
    dispatch(handleResetTradeHistoryItems());
  },
  bindRTListener: (topic, callback, id) => {
    dispatch(bindRTListener(topic, callback, id));
  },
  offRTCallback: (topic, id) => {
    dispatch(offRTCallback(topic, id));
  },
  handleNewTradeHistoryItemReceived: data => {
    dispatch(handleNewTradeHistoryItemReceived(data));
  },
});

const mapStateToProps = state => ({
  getTradeHistoryItemsInPogress: state.exchange.tradeHistory.getTradeHistoryInProgress,
  tradeHistoryItems: state.exchange.tradeHistory.items,
  hasMoreTradeItemsToLoad: state.exchange.tradeHistory.hasMoreToLoad,
  lastLoadedTradeId: state.exchange.tradeHistory.lastLoadedTradeId,
  selectedPair: state.exchange.selectedPair,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TradeHistory));
