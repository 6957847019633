import en from './languages/en.json';
import de from './languages/de.json';
import es from './languages/es.json';
import it from './languages/it.json';
import pl from './languages/pl.json';
import cz from './languages/cz.json';
import hu from './languages/hu.json';

export const translations = {
  en,
  de,
  es,
  it,
  pl,
  cz,
  hu,
  options: {
    suppress_warnings: true,
  },
};
