import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { toggleInfoModal } from '../../shared-components/info-modal/actions/info-modal-actions';
import { getAutoLoginUrl } from '../../registration/actions/registration-actions';
import { handleDisplayDepositModal, transferFundsToWallet } from '../actions/wallet-actions';

import { AUTOLOGIN_DESTIONATIOS, WEB_TRADER_AUTOLOGIN_PAGES } from '../../../config/constants';
import GenericModal from '../../shared-components/generic-modal/generic-modal';

import '../styles/deposit-modal.scss';

class DepositModal extends Component {
  state = { selectedCrypto: {} };

  componentDidUpdate = prevProps => {
    const { selectedCurrency, currencies } = this.props;
    if (prevProps.selectedCurrency.symbol !== selectedCurrency.symbol && currencies.crypto && currencies.crypto.length) {
      const currency = find(currencies.crypto, ['currency', selectedCurrency.symbol]);
      if (currency) {
        this.setState({ selectedCrypto: currency });
      }
    }
  };

  handleModalOpen = () => {
    const { currencies, selectedCurrency } = this.props;

    this.setState(() => {
      if (currencies.crypto && currencies.crypto.length) {
        const currency = find(currencies.crypto, ['currency', selectedCurrency.symbol]);
        if (currency) {
          return { selectedCrypto: currency };
        }
      }
      return { selectedCrypto: '' };
    });
  };

  handleModalClose = () => {
    const { handleDisplayDepositModal } = this.props;

    handleDisplayDepositModal(false);
  };

  getQrCodeContent = () => {
    const { selectedCrypto } = this.state;

    if (!isEmpty(selectedCrypto)) {
      return selectedCrypto.destination_tag ? `${selectedCrypto.address || ''}?dt=${selectedCrypto.destination_tag || ''}` : selectedCrypto.address || '';
    }

    return '';
  };

  showCopySuccessMessage = () => {
    const { toggleInfoModal } = this.props;

    toggleInfoModal('WALLET.COPIED_TO_CLIPBOARD', 'success');
  };

  handleOpenNagaWallet = () => {
    const { getAutoLoginUrl } = this.props;
    const { selectedCrypto } = this.state;

    getAutoLoginUrl({ terminal_id: selectedCrypto.terminal_id, page: WEB_TRADER_AUTOLOGIN_PAGES.WALLET, dest: AUTOLOGIN_DESTIONATIOS.WEB_TRADER });

    this.handleModalClose();
  };

  render = () => {
    const { t } = this.context;
    const { showDepositModal, selectedCurrency } = this.props;
    const { selectedCrypto } = this.state;

    if (isEmpty(selectedCurrency)) {
      return null;
    }

    return (
      <GenericModal className="deposit-modal" show={showDepositModal} onEnter={this.handleModalOpen} onHide={this.handleModalClose}>
        <Modal.Header>
          <div>
            <span>{t('WALLET.DEPOSIT_CURRENCY', { n: selectedCurrency.name })}</span>
          </div>
          <i className="icn icn-simple-remove-big-thin" onClick={this.handleModalClose} />
        </Modal.Header>
        <Modal.Body>
          <div className={`deposit-modal__primary${selectedCrypto.destination_tag ? ' with-tag' : ''}`}>
            <div className="deposit-modal__qr-code">
              <div>
                <QRCode value={this.getQrCodeContent()} size={120} />
              </div>
            </div>
            <div>
              <div className="deposit-modal__address">
                <div>{t('WALLET.YOUR_ADDRESS')}</div>
                <div className="deposit-modal__primary__address">
                  <input type="text" value={selectedCrypto.address || ''} disabled="disabled" />
                  <CopyToClipboard text={selectedCrypto.address || ''} onCopy={this.showCopySuccessMessage}>
                    <Button bsStyle="primary">
                      <i className="icn icn-ungroup-big-thin" />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="deposit-modal__text deposit-modal__note">
                <p>{t('WALLET.CRYPTO_DEPOSIT_NOTE1', { n: selectedCurrency.symbol })}</p>
                <p>{t('WALLET.CRYPTO_DEPOSIT_NOTE2', { n: selectedCurrency.symbol })}</p>
              </div>
            </div>
          </div>
          {selectedCrypto.destination_tag && (
            <div className="deposit-modal__additional">
              <div className="deposit-modal__text">{t('WALLET.DESTINATION_TAG')}</div>
              <div className="deposit-modal__additional__code">
                <div className="deposit-modal__primary__address">
                  <input type="text" value={selectedCrypto.destination_tag || ''} disabled="disabled" />
                  <CopyToClipboard text={selectedCrypto.destination_tag || ''} onCopy={this.showCopySuccessMessage}>
                    <Button bsStyle="primary">
                      <i className="icn icn-ungroup-big-thin" />
                    </Button>
                  </CopyToClipboard>
                </div>
                <div>
                  <div>{t('WALLET.INCLUDE_THE_DESTINATION_TAG')}</div>
                  <div>{t('WALLET.CODE_IDENTIFIES_THE_DEPOSIT_WITH_ACCOUNT')}</div>
                </div>
              </div>
              <div className="deposit-modal__text">{t('WALLET.RIPPLE_NOTE')}</div>
            </div>
          )}
          <div className="deposit-modal__action-button">
            <Button bsStyle="primary" onClick={this.handleOpenNagaWallet}>
              {t('WALLET.OPEN_NAGA_WALLET')}
            </Button>
          </div>
        </Modal.Body>
      </GenericModal>
    );
  };
}

DepositModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showDepositModal: state.wallet.showDepositModal,
  selectedCurrency: state.wallet.selectedCurrency,
  currencies: state.wallet.currencies,
});

const mapDispatchToProps = dispatch => ({
  handleDisplayDepositModal: data => {
    dispatch(handleDisplayDepositModal(data));
  },
  transferFundsToWallet: (terminalId, amount, currencyCode, token) => {
    dispatch(transferFundsToWallet(terminalId, amount, currencyCode, token));
  },
  toggleInfoModal: (message, type) => {
    dispatch(toggleInfoModal(message, type));
  },
  getAutoLoginUrl: urlParams => {
    dispatch(getAutoLoginUrl(urlParams));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositModal);
