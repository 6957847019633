import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import Big from 'big.js';

import StoreInstance from '../../index';
import { CURRENCIES, CURRENCY_SYMBOL_POSITION, ASSET_GROUP } from '../../config/constants';
import HelperFunctions from './helper-functions';

export default class TradingHelper {
  static calculateSymbolTrend(closeValue, openValue) {
    if (closeValue === 0) {
      return 0;
    }

    return ((closeValue - openValue) / openValue) * 100;
  }

  static isFiatCurrency = assetGroup => {
    return assetGroup === ASSET_GROUP.FIAT;
  };

  static getCurrencySymbol = (asset, position) => {
    switch (position) {
      case CURRENCY_SYMBOL_POSITION.PREFIX:
        return asset && TradingHelper.isFiatCurrency(asset.asset_group) ? CURRENCIES[asset.symbol.toLowerCase()].symbol : '';
      case CURRENCY_SYMBOL_POSITION.SUFFIX:
        return asset && !TradingHelper.isFiatCurrency(asset.asset_group) ? asset.symbol : '';
      default:
        break;
    }
  };

  static getPricesInUSD = (pairs, quoteCurrency, lastPrice, volume) => {
    const quoteCurrencyUSDPair = find(pairs, f => f.base_currency === quoteCurrency && f.quote_currency === CURRENCIES.usd.value);
    let lastDayVolumeInUSD = 0;
    let lastPriceInUSD = 0;

    if (quoteCurrency === CURRENCIES.usd.value) {
      lastDayVolumeInUSD = volume;
      lastPriceInUSD = lastPrice;
    } else if (quoteCurrency === CURRENCIES.eur.value) {
      const btcusd = find(pairs, f => f.quote_currency === CURRENCIES.usd.value && f.base_currency === 'BTC');
      const btceur = find(pairs, f => f.quote_currency === CURRENCIES.eur.value && f.base_currency === 'BTC');
      const eurusdPrice = btcusd.currentPrice / btceur.currentPrice;

      lastDayVolumeInUSD = +eurusdPrice * +volume;
      lastPriceInUSD = +eurusdPrice * +lastPrice;
    } else if (quoteCurrency === CURRENCIES.eth.value) {
      const ngcusd = find(pairs, f => f.quote_currency === CURRENCIES.usd.value && f.base_currency === CURRENCIES.ngc.value);
      const ngceth = find(pairs, f => f.quote_currency === CURRENCIES.eth.value && f.base_currency === CURRENCIES.ngc.value);
      const ethusdPrice = ngcusd.currentPrice / ngceth.currentPrice;

      lastDayVolumeInUSD = +ethusdPrice * +volume;
      lastPriceInUSD = +ethusdPrice * +lastPrice;
    } else if (quoteCurrencyUSDPair) {
      lastDayVolumeInUSD = +quoteCurrencyUSDPair.currentPrice * +volume;
      lastPriceInUSD = +quoteCurrencyUSDPair.currentPrice * +lastPrice;
    }

    return {
      lastDayVolumeInUSD,
      lastPriceInUSD,
    };
  };

  static formatCurrency(selectedLot, lotMin, lotMax, lotStep, digits) {
    if (!lotMin || !lotMax) {
      //This scenario is only happening when there are issues on backend, but we need to handle it so users doesn't see NaN
      return { formatedValue: '0'.toCustomFixed(digits), value: 0 };
    }

    let adjustedLot = new Big(selectedLot || 0)
      .div(+lotStep || 1)
      .round()
      .times(+lotStep || 1);
    //let adjustedLot = Math.round(selectedLot / lotStep) * lotStep;

    if (adjustedLot.lt(+lotMin || 0) || +lotMin > +lotMax) {
      adjustedLot = selectedLot = lotMin;
    } else if (adjustedLot.gt(+lotMax || 0)) {
      adjustedLot = selectedLot = +lotMax;
    }

    return { formatedValue: adjustedLot.toString().toCustomFixed(digits), value: selectedLot };
  }

  static getAdditionalPairData = pair_id => {
    const state = StoreInstance.getState();
    const pairs = state.exchange.pairs;
    const assets = state.wallet.assets;

    const selectedPair = find(pairs, p => p.pair_id === pair_id);
    const baseCurrency = (selectedPair && find(assets, a => a.asset_id === selectedPair.base_asset_id)) || {};
    const quoteCurrency = (selectedPair && find(assets, a => a.asset_id === selectedPair.quote_asset_id)) || {};

    if (isEmpty(baseCurrency) || isEmpty(quoteCurrency)) {
      return {};
    }

    return {
      baseCurrencyName: baseCurrency.name,
      baseCurrencyDigits: HelperFunctions.countDecimals(+selectedPair.lot_step),
      quoteCurrencyName: quoteCurrency.name,
      quoteCurrencyDigits: HelperFunctions.countDecimals(+selectedPair.price_step),
      pairCompleteName: selectedPair.display_name,
      pairBaseName: selectedPair.name,
    };
  };
}
