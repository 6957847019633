import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import PropTypes from 'prop-types';

import RenderIf from '../../shared-components/render-if/components/render-if';
import GenericModal from '../../shared-components/generic-modal/generic-modal';
import { toggleInAppMessageModal } from '../actions/in-app-message-actions';
import { MESSAGE_OPTIONS } from '../constants/in-app-messages-constants';

import '../styles/in-app-message-modal.scss';

class InAppMessageModal extends Component {
  performAction = () => {
    let webUrl = this.props.message.additional_data.deep_links.web;

    this.closeModal();

    if (webUrl.indexOf(MESSAGE_OPTIONS.URL_TOKEN) !== -1) {
      webUrl = MESSAGE_OPTIONS.DEEP_LINKS[webUrl.slice(MESSAGE_OPTIONS.URL_TOKEN.length)];
      this.props.history.push(webUrl);
    } else {
      window.open(webUrl, '_blank');
    }
  };

  closeModal = () => {
    const { toggleInAppMessageModal } = this.props;
    toggleInAppMessageModal();
  };

  renderActions = () => {
    const {
      additional_data: { interactive, interactiveReject, interactiveAccept, deep_links },
    } = this.props.message;
    const showAcceptButton = interactive && interactiveAccept && deep_links.web;

    return (
      <div className={`message-modal__actions ${!showAcceptButton || !interactive ? 'message-modal__actions--single' : ''}`}>
        <RenderIf if={!interactiveReject || !interactive}>
          <Button className="message-modal__actions--reject" onClick={this.closeModal}>
            {this.context.t('MODAL.CLOSE')}
          </Button>
        </RenderIf>
        <RenderIf if={interactiveReject && interactive}>
          <Button className="message-modal__actions--reject" onClick={this.closeModal}>
            {interactiveReject}
          </Button>
        </RenderIf>
        <RenderIf if={showAcceptButton}>
          <Button bsStyle="primary" className="message-modal__actions--accept" onClick={this.performAction}>
            {interactiveAccept}
          </Button>
        </RenderIf>
      </div>
    );
  };

  renderMedia = () => {
    const { media, mediaType, alignment } = this.props.message.additional_data;
    const youTubeUrl = 'https://www.youtube.com/'; // YouTube prevents playing video in HTML video tag, one needs to use iframe instead

    return (
      <RenderIf if={alignment && alignment !== MESSAGE_OPTIONS.CONTENT.TEXT_ONLY}>
        <div className="message-modal__media">
          <RenderIf if={mediaType === MESSAGE_OPTIONS.MEDIA_TYPE.image}>
            <div className="message-modal__media-image" style={{ backgroundImage: `url(${media})` }} />
          </RenderIf>
          <RenderIf if={mediaType === MESSAGE_OPTIONS.MEDIA_TYPE.video}>
            {media.indexOf(youTubeUrl) === 0 ? (
              <iframe width="100%" height="196" title="YouTube video" className="message-modal__media-youtube-video" allowFullScreen="allowFullScreen" frameBorder="0" src={media} />
            ) : (
              <video className="message-modal__media-video" controls>
                <source src={media} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </RenderIf>
        </div>
      </RenderIf>
    );
  };

  render() {
    const { showMessage } = this.props;
    const { title, message } = this.props.message;
    const { media, displayType, position, alignment } = this.props.message.additional_data;

    const showModal = showMessage && displayType !== MESSAGE_OPTIONS.DISPLAY_TYPE.BANNER;
    const modalPosition = MESSAGE_OPTIONS.SCREEN_POSITION[position];
    const contentOrder = MESSAGE_OPTIONS.CONTENT_ORDER[alignment];

    return (
      <GenericModal className={`message-modal ${modalPosition ? `message-modal--${modalPosition}` : ''}`} show={showModal}>
        <Modal.Body className="message-modal__body">
          <div className={`message-modal__content ${contentOrder ? `message-modal__content--${contentOrder}` : ''}`}>
            <div className="message-modal__title">{title}</div>
            <div className="message-modal__description">{message}</div>
            {media ? this.renderMedia() : null}
          </div>
          {this.renderActions()}
        </Modal.Body>
      </GenericModal>
    );
  }
}

InAppMessageModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  message: state.inAppMessage.messageObj,
  showMessage: state.inAppMessage.showMessage,
});

const mapDispatchToProps = dispatch => ({
  toggleInAppMessageModal: () => {
    dispatch(toggleInAppMessageModal());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InAppMessageModal));
