import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Button from 'react-bootstrap/lib/Button';
import filter from 'lodash/filter';
import each from 'lodash/each';
import find from 'lodash/find';

import { getAutoLoginUrl } from '../../registration/actions/registration-actions';
import { getUserWalletPortfolio } from '../actions/wallet-actions';
import { handleDisplayAppPrompt } from '../../shared-components/prompt/actions/prompt-actions';

import { USER_STATUS_TYPE, ASSET_GROUP, WEB_TRADER_AUTOLOGIN_PAGES, AUTOLOGIN_DESTIONATIOS } from '../../../config/constants';
import { CURRENCY_TYPES } from '../constants/wallet-constants';
import Amount from '../../shared-components/amount/components/amount';
import WalletCurrencyType from './wallet-currency-type';
import WithdrawModal from './withdraw-modal';
import DepositModal from './deposit-modal';

import AccountsImg from '../styles/images/accounts.png';

import '../styles/wallet.scss';

const UPGRADE_DESCRIPTION_POINTS = Array.from({ length: 7 }, (_, i) => i + 1);

class Wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };
    this.walletPortfolioLoaded = false;
  }

  componentDidMount = () => {
    const { getUserWalletPortfolio, loggedIn } = this.props;

    if (loggedIn) {
      getUserWalletPortfolio();
      this.walletPortfolioLoaded = true;
    }
  };

  componentDidUpdate = prevProps => {
    const { loggedIn, getUserWalletPortfolio } = this.props;

    if (loggedIn && !this.walletPortfolioLoaded) {
      getUserWalletPortfolio();
      this.walletPortfolioLoaded = true;
    }
  };

  handleOnSearchChange = e => {
    this.setState({ searchText: e.target.value });
  };

  handleOpenTradingAccount = () => {
    const { loggedIn, userInfo, getAutoLoginUrl, handleDisplayAppPrompt } = this.props;
    const page = userInfo.status === USER_STATUS_TYPE.WALLET_ONLY ? WEB_TRADER_AUTOLOGIN_PAGES.ACCOUNT_SUMMARY : WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE;

    if (loggedIn) {
      if (page === WEB_TRADER_AUTOLOGIN_PAGES.UPGRADE && userInfo.isEU && userInfo.isFlexible) {
        const promptSettings = {
          show: true,
          title: 'USER_UPGRADE.UPGRADE_NOTICE_TITLE',
          description: this.renderUpgradeNoticeDescription(),
          ctaText: 'OPEN_TRADE.UPGRADE_MODAL_CTA',
          promptClass: 'account-upgrade-notice',
          submitCallback: () => {
            getAutoLoginUrl({ page, t: userInfo.platform, lang: userInfo.language, country: userInfo.country, target: '_self' });
          },
        };

        handleDisplayAppPrompt(promptSettings);
      } else {
        getAutoLoginUrl({ page, t: userInfo.platform, lang: userInfo.language, country: userInfo.country });
      }
    } else {
      this.showSignInOrRegisterPrompt();
    }
  };

  handleBuyCryptoWithCreditCard = () => {
    const { loggedIn, getAutoLoginUrl } = this.props;

    if (loggedIn) {
      getAutoLoginUrl({ page: WEB_TRADER_AUTOLOGIN_PAGES.WALLET, dest: AUTOLOGIN_DESTIONATIOS.WEB_TRADER });
    } else {
      this.showSignInOrRegisterPrompt();
    }
  };

  calculateTotalAvailableWalletAmount = () => {
    const { assets, currencies } = this.props;
    let usdTotal = 0;
    let btcTotal = 0;

    if (assets && assets.length) {
      let currencyItems = currencies.fiat ? currencies.fiat.slice() : [];
      currencyItems = currencies.crypto ? currencyItems.concat(currencies.crypto.slice()) : currencyItems;

      each(assets, asset => {
        if (asset.total) {
          const currencyItem = find(currencyItems, ['currency', asset.symbol]);
          if (currencyItem && currencyItem.currency_rate) {
            usdTotal += asset.total / +currencyItem.currency_rate;
          }
        }
      });

      if (usdTotal) {
        const btcCurrency = find(currencyItems, ['currency', 'BTC']);
        if (btcCurrency && btcCurrency.currency_rate) {
          btcTotal = usdTotal * +btcCurrency.currency_rate;
        }
      }
    }

    return { usdTotal, btcTotal };
  };

  showSignInOrRegisterPrompt = () => {
    const { t } = this.context;
    const { history, handleDisplayAppPrompt } = this.props;

    const promptSettings = {
      show: true,
      title: t('LOGIN.SIGN_IN_OR_CREATE_ACCOUNT'),
      description: t('LOGIN.SIGN_IN_OR_REGISTER_NOTE'),
      ctaText: 'LOGIN.SIGN_IN_OR_CREATE_ACCOUNT',
      submitCallback: () => {
        history.push('/login');
      },
    };

    handleDisplayAppPrompt(promptSettings);
  };

  renderCurrencyGroups = hasFiatAccounts => {
    const { assets, isMobile, desktopXS } = this.props;
    const { searchText } = this.state;
    let groupedAssets = [
      {
        type: CURRENCY_TYPES.CRYPTO,
        title: 'WALLET.CRYPTO',
        items: filter(assets, f => f.asset_group === ASSET_GROUP.CRYPTO),
      },
    ];

    if (hasFiatAccounts) {
      const fiatAssets = [
        {
          type: CURRENCY_TYPES.FIAT,
          title: 'WALLET.FIAT',
          items: filter(assets, f => f.asset_group === ASSET_GROUP.FIAT),
        },
      ];
      groupedAssets = fiatAssets.concat(groupedAssets);
    }

    each(groupedAssets, asset => {
      const allItems = asset.items.concat();
      asset.items = filter(allItems, item => item.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 || item.symbol.toUpperCase().indexOf(searchText.toUpperCase()) > -1 || !searchText) || [];
    });

    return groupedAssets.map(item => {
      return <WalletCurrencyType key={item.type} items={item.items} type={item.type} title={item.title} isMobile={isMobile} desktopXS={desktopXS} />;
    });
  };

  renderUpgradeNoticeDescription = () => {
    const { t } = this.context;

    return (
      <Fragment>
        <ul>
          {UPGRADE_DESCRIPTION_POINTS.map(point => (
            <li key={point}>{t(`USER_UPGRADE.UPGRADE_POINT_${point}`)}</li>
          ))}
        </ul>
        <div className="account-upgrade-notice__warning">
          <i className="icn icn-alert" /> {t('USER_UPGRADE.RISK_WARNING')}
        </div>
      </Fragment>
    );
  };

  renderWalletHeader = () => {
    const { t } = this.context;
    const { isMobile } = this.props;

    const { usdTotal, btcTotal } = this.calculateTotalAvailableWalletAmount();

    return (
      <div className={`wallet__header${isMobile ? ' mobile' : ''}`}>
        <div className="wallet__header__search">
          <input type="text" placeholder={t('WALLET.SEARCH_FOR_CRYPTO_OR_FIAT')} onChange={this.handleOnSearchChange} />
          <i className="icn icn-zoom" />
        </div>
        <div className="wallet__header__info">
          <Button onClick={this.handleBuyCryptoWithCreditCard}>{t('WALLET.BUY_CRYPTO_WITH_CREDIT_CARD')}</Button>
          <div>
            <div>{t('WALLET.ESTIMATED_VALUE')}</div>
            <div>
              <Amount value={btcTotal} decimals={8} suffix="BTC" /> / <Amount value={usdTotal} decimals={2} prefix="$" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCreateAccountBanner = () => {
    const { t } = this.context;
    return (
      <div className="wallet__create-account">
        <div>
          <div className="wallet__create-account__heading-text">{t('WALLET.GET_A_TRADING_ACCOUNT')}</div>
          <div className="wallet__create-account__title">{t('WALLET.BENEFIT_FROM_EUR_USD_ACCOUNT')}</div>
          <div className="wallet__create-account__note">
            <p>{t('WALLET.OPEN_ACCOUNT_NOTE1')}</p>
            <p>{t('WALLET.DID_YOU_KNOW')}</p>
            {t('WALLET.OPEN_ACCOUNT_NOTE2')}
          </div>
          <Button bsStyle="primary" onClick={this.handleOpenTradingAccount}>
            {t('WALLET.OPEN_A_TRADING_ACCOUNT')}
          </Button>
        </div>
        <div className="hidden-xs">
          <img src={AccountsImg} className="Illustration" alt="Trading account" />
        </div>
      </div>
    );
  };

  render = () => {
    const { userInfo } = this.props;
    const hasFiatAccounts = userInfo.status !== USER_STATUS_TYPE.DEMO && userInfo.status !== USER_STATUS_TYPE.WALLET_ONLY;

    return (
      <div className="wallet">
        {this.renderWalletHeader()}
        {!hasFiatAccounts && this.renderCreateAccountBanner()}
        {this.renderCurrencyGroups(hasFiatAccounts)}
        <WithdrawModal />
        <DepositModal />
      </div>
    );
  };
}

Wallet.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  assets: state.wallet.assets,
  currencies: state.wallet.currencies,
  userInfo: state.currentUser.info,
  loggedIn: state.currentUser.loggedIn,
  isMobile: state.mediaQuery.isMobile,
  desktopXS: state.mediaQuery.desktopXS,
});

const mapDispatchToProps = dispatch => ({
  getUserWalletPortfolio: () => {
    dispatch(getUserWalletPortfolio());
  },
  getAutoLoginUrl: urlParams => {
    dispatch(getAutoLoginUrl(urlParams));
  },
  handleDisplayAppPrompt: data => {
    dispatch(handleDisplayAppPrompt(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
