import { INTERCOM_APP_ID } from '../../config/constants';

class IntercomHelper {
  static initialize(options) {
    let intercomSettings = {
      app_id: INTERCOM_APP_ID,
    };

    intercomSettings = {
      ...intercomSettings,
      name: options.first_name || options.username,
      email: options.email,
      user_id: options.id,
      user_hash: options.intercom_hash,
    };

    window.Intercom('boot', intercomSettings);
    window.Intercom('onHide', () => {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    });
  }

  static hideIntercom() {
    window.Intercom && window.Intercom('hide');
  }

  static showIntercom() {
    window.Intercom && window.Intercom('show');
  }

  static setLocale(lang) {
    window.Intercom && window.Intercom('update', { language_override: lang });
  }

  static openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
      IntercomHelper.showIntercom();
    }
  };

  static logout() {
    window.Intercom && window.Intercom('shutdown');
  }
}

export default IntercomHelper;
