import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';

import { toggleMarketsModal } from '../actions/exchange-actions';

import GenericModal from '../../shared-components/generic-modal/generic-modal';
import Markets from './markets';

import '../styles/markets-modal.scss';

class MarketsModal extends Component {
  closeModal = () => {
    const { toggleMarketsModal } = this.props;

    toggleMarketsModal();
  };

  render() {
    const { showMarketsModal } = this.props;

    return (
      <GenericModal className="markets-modal" show={showMarketsModal} allowbackgroundscroll="true">
        <Modal.Body>
          <i className="icn icn-simple-remove-big-thin" onClick={this.closeModal} />
          <Markets onSymbolSelectCallback={this.closeModal} />
        </Modal.Body>
      </GenericModal>
    );
  }
}

const mapStateToProps = state => ({
  showMarketsModal: state.exchange.showMarketsModal,
});

const mapDispatchToProps = dispatch => ({
  toggleMarketsModal: () => {
    dispatch(toggleMarketsModal());
  },
});

MarketsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketsModal);
