import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { setLanguage } from 'redux-i18n';
import Button from 'react-bootstrap/lib/Button';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';

import { sendVerificationCode } from '../../phone-verification/actions/phone-verification-actions.js';
import { handleShowPhoneVerifyWarningModal } from '../../phone-verification/actions/phone-verification-actions';
import { handleUserInfoUpdated } from '../../current-user/actions/current-user-actions';
import { getUserProfile } from '../../profile/actions/profile-actions';
import { updateUserProfile, changeUserLanguage, uploadProfileImage, handleChangePhoneNumberInProgress, updateUserNGCFee } from '../actions/user-settings-actions';
import { getAutoLoginUrl } from '../../registration/actions/registration-actions';

import { MAIN_LANGUAGES } from '../../../config/constants';
import UserSettingsHelper from '../helpers/user-settings-helper';
import IntercomHelper from '../../../core/helpers/intercom-helper';
// import ShortUserDetails from '../../shared-components/short-user-details/components/short-user-details';
import DefaultInput from '../../shared-components/default-input/components/default-input';
import MiniLoader from '../../shared-components/mini-loader/mini-loader';
import RenderIf from '../../shared-components/render-if/components/render-if';
import ToggleSwitch from '../../shared-components/toggle-switch/components/toggle-switch';
import FormValidator from '../validators/user-settings-form-validators';
import PhoneVerificationModal from '../../phone-verification/components/phone-verification';
import PhoneVerificationWarningModal from '../../phone-verification/components/phone-verification-warning';
import UserSettingsTradingInfo from './user-settings-trading-info';

import verified from '../styles/images/verified.png';
import notVerified from '../styles/images/not-verified.png';

import '../styles/user-settings-info.scss';

class PhoneNumber extends Component {
  changePhoneNumber = () => {
    const { handleChangePhoneNumberInProgress } = this.props;
    handleChangePhoneNumberInProgress(true);
  };

  cancel = () => {
    const { handleChangePhoneNumberInProgress, cancelCallback } = this.props;
    handleChangePhoneNumberInProgress(false);
    cancelCallback();
  };

  save = () => {
    this.props.verifyCallback();
  };

  render = () => {
    const {
      input,
      readOnlyInput,
      placeHolder,
      label,
      labelTranslationValues,
      loaderExtended,
      customError,
      errorTranslationValues,
      disabled,
      className,
      imageExtended,
      textExtended,
      extendedPartClass,
      minLength,
      maxLength,
      phoneNumberConfirmed,
      noInitialValue,
      initialValue,
      changePhoneNumberInProgress,
      sendVerificationCodeInProgress,
      meta: { touched, error, asyncValidating },
    } = this.props;
    const { t } = this.context;

    const inputHasError = touched && (error || customError);

    Object.assign(input, inputHasError ? { className: 'input-error' } : null, minLength ? { minLength } : null, maxLength ? { maxLength } : null);

    return (
      <div className={`default-input ${className || ''}`}>
        {label ? <label>{this.context.t(label, labelTranslationValues)}</label> : ''}
        <div className={asyncValidating ? 'input-and-error async-validating' : 'input-and-error'}>
          <input
            type="text"
            readOnly={readOnlyInput}
            placeholder={noInitialValue && !touched ? t('USER_SETTINGS.YOU_DID_NOT_ADD_YOUR_PHONE_NUMBER') : placeHolder}
            {...input}
            autoCorrect="off"
            autoCapitalize="none"
            disabled={disabled || !changePhoneNumberInProgress}
          />
          <RenderIf if={phoneNumberConfirmed && !changePhoneNumberInProgress}>
            <div className="input-validation-box verified">
              {t('USER_SETTINGS.VERIFIED')} <img src={verified} alt="alt" />
            </div>
          </RenderIf>
          <RenderIf if={!noInitialValue && !changePhoneNumberInProgress && !phoneNumberConfirmed}>
            <div className="input-validation-box not-verified">
              {t('USER_SETTINGS.NOT_VERIFIED')} <img src={notVerified} alt="alt" />
            </div>
          </RenderIf>
          {inputHasError && <div className="error">{this.context.t(error || customError, errorTranslationValues)}</div>}
          <span className={`extended-part ${extendedPartClass}`}>
            {(() => {
              if (textExtended) return <span>{textExtended}</span>;
              else if (imageExtended) return <img src={imageExtended} alt="inputImage" />;
              else if (loaderExtended) return <MiniLoader />;
              else return null;
            })()}
          </span>
        </div>
        <RenderIf if={noInitialValue && !touched && !changePhoneNumberInProgress}>
          <Button type="button" bsStyle="link" onClick={this.changePhoneNumber}>
            {t('USER_SETTINGS.ADD_PHONE_NUMBER')}
          </Button>
        </RenderIf>
        <RenderIf if={!noInitialValue && !changePhoneNumberInProgress && !phoneNumberConfirmed}>
          <div>
            <Button bsStyle="primary" type="button" disabled={sendVerificationCodeInProgress} className="verify-phone-button" onClick={this.save}>
              {t('USER_SETTINGS.VERIFY')}
              {sendVerificationCodeInProgress ? <MiniLoader whiteLoader /> : ''}
            </Button>
            <Button type="button" bsStyle="link" onClick={this.changePhoneNumber}>
              {t('USER_SETTINGS.CHANGE_PHONE_NUMBER')}
            </Button>
          </div>
        </RenderIf>
        <RenderIf if={changePhoneNumberInProgress}>
          <div>
            <Button type="button" onClick={this.cancel}>
              {t('USER_SETTINGS.CANCEL')}
            </Button>
            <Button
              bsStyle="primary"
              type="button"
              className="verify-phone-button"
              disabled={initialValue === input.value || sendVerificationCodeInProgress || !!asyncValidating || !!error}
              onClick={this.save}
            >
              {t('USER_SETTINGS.SAVE')}
              {sendVerificationCodeInProgress ? <MiniLoader whiteLoader /> : ''}
            </Button>
          </div>
        </RenderIf>
      </div>
    );
  };
}

PhoneNumber.contextTypes = {
  t: PropTypes.func.isRequired,
};

class UserSettingsInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: UserSettingsHelper.getLanguageOption(MAIN_LANGUAGES[props.userInfo.language || 'en']),
      profilePhotoChanged: false,
    };
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    if (!isEmpty(nextProps.userProfile) && isEmpty(state.selectedLanguage)) {
      return { ...state, selectedLanguage: UserSettingsInfo.getLanguage(nextProps.userProfile.language) };
    }

    return null;
  };

  componentDidMount = () => {
    const { userProfile, getUserProfile } = this.props;

    getUserProfile();

    if (!isEmpty(userProfile)) {
      this.setState({
        selectedLanguage: UserSettingsHelper.getLanguage(userProfile.language),
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { userInfo, userProfileImageUrl } = this.props;

    if (prevProps.userProfileImageUrl !== userProfileImageUrl) {
      this.setState({ profilePhotoChanged: true });
    } else {
      // No need to rerender user avatar all the time
      if (this.state.profilePhotoChanged) {
        this.setState({ profilePhotoChanged: false });
      }
    }

    if (prevProps.userInfo.language !== userInfo.language) {
      this.setState({ selectedLanguage: UserSettingsHelper.getLanguageOption(MAIN_LANGUAGES[userInfo.language]) });
    }
  };

  displayPhoneVerifyModal = () => {
    const { sendVerificationCode, phoneNumber } = this.props;

    sendVerificationCode(phoneNumber);
  };

  reinitializePhoneNumber = () => {
    const { userProfile, change } = this.props;

    change('phone_number', userProfile.phone_number);
  };

  handleLanguageChanged = langObj => {
    const { changeUserLanguage } = this.props;

    changeUserLanguage(langObj.value);

    IntercomHelper.setLocale(langObj.value);
  };

  uploadProfileImage = event => {
    const { uploadProfileImage } = this.props;
    const file = event.target.files[0];

    uploadProfileImage(file);
  };

  handleUploadImage = () => {
    this.userPhotoUpload.click();
  };

  openIntercom = () => {
    IntercomHelper.openIntercom();
  };

  togglePayFeeInNGC = () => {
    const { userInfo, updateUserNGCFee, updateUserNGCFeeInProgress } = this.props;

    if (!updateUserNGCFeeInProgress) {
      updateUserNGCFee(!userInfo.useNGCForFees);
    }
  };

  renderLanguageOptions = () => {
    let languages = [];

    for (const key of Object.keys(MAIN_LANGUAGES)) {
      languages.push(UserSettingsHelper.getLanguageOption(MAIN_LANGUAGES[key]));
    }

    return languages;
  };

  render = () => {
    const { t } = this.context;
    const {
      userProfile,
      userInfo,
      fetchingUserProfile,
      getAutoLoginUrl,
      updateUserProfileInProgress,
      // uploadUserProfilePhotoInProgress,
      validatePhoneNumberInProgress,
      phoneNumber,
      phoneNumberConfirmed,
      changePhoneNumberInProgress,
      handleChangePhoneNumberInProgress,
      sendVerificationCodeInProgress,
      updateUserNGCFeeInProgress,
    } = this.props;
    const { selectedLanguage } = this.state;
    // const { profilePhotoChanged } = this.state;

    if (fetchingUserProfile && isEmpty(userProfile)) {
      return <MiniLoader className="user-settings-info__loader" />;
    }

    return (
      <div className="user-info">
        <div className="user-settings-info">
          <div className="user-settings-info__title">{t('USER_SETTINGS.USER_INFORMATION')}</div>
          {/* <div className="user-settings-info__profile-image">
          <div>{t('USER_SETTINGS.PROFILE_PICTURE')}</div>
          <ShortUserDetails userId={userInfo.id} appendTimeStamp={true} reloadAvatar={profilePhotoChanged}>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={input => {
                this.userPhotoUpload = input;
              }}
              accept="image/*"
              onChange={e => this.uploadProfileImage(e)}
            />

            <Button onClick={this.handleUploadImage} className="user-settings-info__upload-new-photo" disabled={uploadUserProfilePhotoInProgress}>
              {uploadUserProfilePhotoInProgress ? <MiniLoader /> : t('USER_SETTINGS.UPLOAD_NEW_PHOTO')}
            </Button>
          </ShortUserDetails>
        </div> */}
          <form>
            <div className="user-settings-info__fields">
              <div>
                <div>{t('USER_SETTINGS.USERNAME')}</div>
                <div>
                  <Field disabled={true} placeHolder="USER_SETTINGS.USERNAME" name="username" type="text" component={DefaultInput} />
                </div>
              </div>
              <div>
                <div className="user-settings-info__fields__label-box">
                  {t('USER_SETTINGS.EMAIL')}
                  <div className="contact-us">
                    {t('USER_SETTINGS.WANT_TO_CHANGE_EMAIL')}
                    <Button bsStyle="link" bsSize="small" className="contact-us__button" onClick={this.openIntercom}>
                      {t('USER_SETTINGS.CONTACT_US')}
                    </Button>
                  </div>
                </div>
                <div>
                  <Field disabled={true} placeHolder="USER_SETTINGS.EMAIL" name="email" type="text" component={DefaultInput} />
                </div>
              </div>
            </div>
            <div className="user-settings-info__fields">
              <div>
                <div>{t('USER_SETTINGS.PHONE')}</div>
                <div>
                  <Field
                    disabled={updateUserProfileInProgress}
                    className="phone-verification-input"
                    name="phone_number"
                    type="text"
                    component={PhoneNumber}
                    verifyCallback={this.displayPhoneVerifyModal}
                    cancelCallback={this.reinitializePhoneNumber}
                    changePhoneNumberInProgress={changePhoneNumberInProgress}
                    handleChangePhoneNumberInProgress={handleChangePhoneNumberInProgress}
                    noInitialValue={!userProfile.phone_number}
                    initialValue={userProfile.phone_number}
                    placeHolder={t('USER_SETTINGS.PHONE')}
                    phoneNumberConfirmed={phoneNumberConfirmed}
                    loaderExtended={validatePhoneNumberInProgress}
                    sendVerificationCodeInProgress={sendVerificationCodeInProgress}
                  />
                </div>
              </div>
              <div>
                <div>{t('USER_SETTINGS.LANGUAGE')}</div>
                <div>
                  <Select
                    className="language-select"
                    classNamePrefix="language-select"
                    name="languages"
                    options={this.renderLanguageOptions()}
                    value={selectedLanguage}
                    onChange={this.handleLanguageChanged}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="user-settings-info__title">{t('USER_SETTINGS.TRADING_FEE')}</div>
          <div className="user-settings-info__fee">
            {t('USER_SETTINGS.USE_NGC_TO_PAY_FOR_FEES', { n: <span className="user-settings-info__fee__discount">{t('USER_SETTINGS.10_PERCENT_DISCOUNT')}</span> })}
            <ToggleSwitch checked={userInfo.useNGCForFees} onChange={this.togglePayFeeInNGC} disabled={updateUserNGCFeeInProgress} />
          </div>
          <PhoneVerificationModal phoneNumber={phoneNumber} />
          <PhoneVerificationWarningModal phoneNumber={phoneNumber} />
        </div>

        <div className="user-info__trading">
          <div className="user-settings-info__title">{t('USER_SETTINGS.TRADING_INFORMATION')}</div>
          <UserSettingsTradingInfo
            emailConfirmed={userProfile.email_verified}
            userStatus={userProfile.user_bo_status}
            platform={userInfo.platform}
            language={userInfo.language}
            country={userInfo.country}
            getAutoLoginUrl={getAutoLoginUrl}
          />
        </div>
      </div>
    );
  };
}

UserSettingsInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

UserSettingsInfo = reduxForm({
  form: 'userSettingsForm',
  validate: FormValidator.validateForm,
  asyncValidate: FormValidator.asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ['phone_number'],
})(UserSettingsInfo);

const selector = formValueSelector('userSettingsForm');

const mapStateToProps = state => ({
  userProfile: state.profile.userProfile,
  initialValues: state.profile.userProfile, // Redux form initial values
  fetchingUserProfile: state.userSettings.fetchingUserProfile,
  updateUserProfileInProgress: state.userSettings.updateUserProfileInProgress,
  uploadUserProfilePhotoInProgress: state.userSettings.uploadUserProfilePhotoInProgress,
  phoneNumber: selector(state, 'phone_number'),
  validatePhoneNumberInProgress: state.userSettings.validatePhoneNumberInProgress,
  updateUserNGCFeeInProgress: state.userSettings.updateUserNGCFeeInProgress,
  getUserProfileInProgress: state.profile.getUserProfileInProgress,
  userInfo: state.currentUser.info,
  userProfileImageUrl: state.currentUser.userProfileImageUrl,
  phoneNumberConfirmed: state.profile.userProfile.phone_number_verified,
  changePhoneNumberInProgress: state.userSettings.changePhoneNumberInProgress,
  sendVerificationCodeInProgress: state.phoneNumberVerify.sendVerificationCodeInProgress,
});

const mapDispatchToProps = dispatch => ({
  updateUserProfile: data => {
    dispatch(updateUserProfile(data));
  },
  changeUserLanguage: data => {
    dispatch(changeUserLanguage(data));
  },
  uploadProfileImage: data => {
    dispatch(uploadProfileImage(data));
  },
  handleShowPhoneVerifyWarningModal: data => {
    dispatch(handleShowPhoneVerifyWarningModal(data));
  },
  setLanguage: data => {
    dispatch(setLanguage(data));
  },
  getUserProfile: () => {
    dispatch(getUserProfile());
  },
  handleUserInfoUpdated: data => {
    dispatch(handleUserInfoUpdated(data));
  },
  sendVerificationCode: phoneNumber => {
    dispatch(sendVerificationCode(phoneNumber, false));
  },
  handleChangePhoneNumberInProgress: data => {
    dispatch(handleChangePhoneNumberInProgress(data));
  },
  updateUserNGCFee: useFeesInNGC => {
    dispatch(updateUserNGCFee(useFeesInNGC));
  },
  getAutoLoginUrl: urlParams => {
    dispatch(getAutoLoginUrl(urlParams));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsInfo);
