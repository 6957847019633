import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { MOMENT_FORMATS, CURRENCY_SYMBOL_POSITION } from '../../../config/constants';
import Amount from '../../shared-components/amount/components/amount';
import TradingHelper from '../../../core/helpers/trading-helper';

import '../styles/refered-friend-item.scss';

const ReferredFriendItem = (props, context) => {
  const { t } = context;
  const { isTitleRow, item, assets, isMobile } = props;

  const [bonusCurrencyAsset, fundedCurrencyAsset] = useMemo(() => {
    const bonusAsset = (item && item.bonus_currency && assets.find(asset => asset.symbol === item.bonus_currency)) || null;
    const fundedAsset = (item && item.funded_currency && assets.find(asset => asset.symbol === item.funded_currency)) || null;

    return [bonusAsset, fundedAsset];
  }, [item, assets]);

  const bonusCurrencyPrefix = item && TradingHelper.getCurrencySymbol(bonusCurrencyAsset, CURRENCY_SYMBOL_POSITION.PREFIX);
  const bonusCurrencySuffix = item && TradingHelper.getCurrencySymbol(bonusCurrencyAsset, CURRENCY_SYMBOL_POSITION.SUFFIX);
  const fundCurrencyPrefix = item && TradingHelper.getCurrencySymbol(fundedCurrencyAsset, CURRENCY_SYMBOL_POSITION.PREFIX);
  const fundCurrencySuffix = item && TradingHelper.getCurrencySymbol(fundedCurrencyAsset, CURRENCY_SYMBOL_POSITION.PREFIX);

  const renderTitleRow = () => {
    return (
      <div className="refered-friend-item title">
        <div className="refered-friend-item__column">{t('REFER_FRIEND.USERNAME')}</div>
        <div className="refered-friend-item__column">{t('REFER_FRIEND.DATE_JOINED')}</div>
        <div className="refered-friend-item__column">{t('REFER_FRIEND.NGC_BOUGHT')}</div>
        <div className="refered-friend-item__column">{t('REFER_FRIEND.YOUR_BONUS')}</div>
        <div className="refered-friend-item__column">{t('REFER_FRIEND.STATUS')}</div>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className="refered-friend-item">
        <div className="refered-friend-item__column">{item.user_name}</div>
        <div className="refered-friend-item__column">{moment(item.registration_date).format(MOMENT_FORMATS.DATE.LONG_SLASH_SEPARATED)}</div>
        <div className="refered-friend-item__column">
          {!!item.funded_amount ? (
            <Amount value={item.funded_amount} prefix={fundCurrencyPrefix} suffix={fundCurrencySuffix} decimals={fundedCurrencyAsset && fundedCurrencyAsset.display_digits} />
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="refered-friend-item__column">
          {!!item.bonus_amount ? (
            <Amount value={item.bonus_amount} prefix={bonusCurrencyPrefix} suffix={bonusCurrencySuffix} decimals={bonusCurrencySuffix && bonusCurrencySuffix.display_digits} />
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="refered-friend-item__column">{!!item.earning_date ? t('REFER_FRIEND.REFERRALS_STATUS') : '-'}</div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div className="refered-friend-item">
        <div className="refered-friend-item__data">
          <div>
            <div>{t('REFER_FRIEND.USERNAME')}</div>
            <div>{item.user_name}</div>
          </div>
          <div>
            <div>{t('REFER_FRIEND.DATE_JOINED')}</div>
            <div>{moment(item.registration_date).format(MOMENT_FORMATS.DATE.LONG_SLASH_SEPARATED)}</div>
          </div>
        </div>
        <div className="refered-friend-item__data">
          <div>
            <div>{t('REFER_FRIEND.NGC_BOUGHT')}</div>
            <div>
              {!!item.funded_amount ? (
                <Amount value={item.funded_amount} prefix={fundCurrencyPrefix} suffix={fundCurrencySuffix} decimals={fundedCurrencyAsset && fundedCurrencyAsset.display_digits} />
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div>
            <div>{t('REFER_FRIEND.YOUR_BONUS')}</div>
            <div>
              {!!item.bonus_amount ? (
                <Amount value={item.bonus_amount} prefix={bonusCurrencyPrefix} suffix={bonusCurrencySuffix} decimals={bonusCurrencySuffix && bonusCurrencySuffix.display_digits} />
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div>
            <div>{t('REFER_FRIEND.STATUS')}</div>
            <div>{!!item.earning_date ? t('REFER_FRIEND.REFERRALS_STATUS') : '-'}</div>
          </div>
        </div>
      </div>
    );
  };

  if (isTitleRow) {
    if (!isMobile) {
      return renderTitleRow();
    }
    return null;
  }

  return isMobile ? renderMobileView() : renderDesktopView();
};

ReferredFriendItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  assets: state.wallet.assets,
});

export default connect(mapStateToProps, null)(ReferredFriendItem);
