import { REFRESH_TOKEN_ACTIONS } from '../constants/jwt-constants';

const refreshTokenInitialState = {
  newTokenRequestInProgress: false,
  retryCount: {},
};

export const refreshToken = (state = refreshTokenInitialState, payload) => {
  switch (payload.type) {
    case REFRESH_TOKEN_ACTIONS.SET_RETRY_COUNT:
      const retryPathCounter = state.retryCount[payload.path] ? state.retryCount[payload.path] + 1 : 1;
      const retryCount = { ...state.retryCount };
      retryCount[payload.path] = retryPathCounter;
      return {
        ...state,
        retryCount,
      };
    case REFRESH_TOKEN_ACTIONS.REFRESH_RETRY_COUNT:
      return { ...state, retryCount: {} };
    default:
      return state;
  }
};
