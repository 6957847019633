export const IS_PROD_ENV = process.env.REACT_APP_ENV === 'production';
export const MAGIC_SALT = '$2a$10$0.o/S/w/i/p/e/S/A/L/T/';
const devApiUrl = 'https://ex-api.sxdev.io';
const prodApiUrl = 'https://api.nagax.com';
const NAGA_MARKETS_URL_DEV = 'https://sxdevmarkets.com';
const NAGA_GLOBAL_URL_DEV = 'https://sxdev-global.com';
const NAGA_MARKETS_URL_PROD = 'https://nagamarkets.com';
const NAGA_GLOBAL_URL_PROD = 'https://naga-global.com';
export const NAGA_MARKETS_URL = IS_PROD_ENV ? NAGA_MARKETS_URL_PROD : NAGA_MARKETS_URL_DEV;
export const NAGA_GLOBAL_URL = IS_PROD_ENV ? NAGA_GLOBAL_URL_PROD : NAGA_GLOBAL_URL_DEV;
export const API_URL = IS_PROD_ENV ? prodApiUrl : devApiUrl;
const TRADER_API_URL_PROD = 'https://api-v2.swipestox.com';
const TRADER_API_URL_DEV = 'https://api-v2.sxdev.io';
export const TRADER_API_URL = IS_PROD_ENV ? TRADER_API_URL_PROD : TRADER_API_URL_DEV;
export const NAGA_GUARD_URL = 'https://naga-guard.com/';

export const PLATFORM = 'web-exchange';
export const DEFAULT_ROUTE = '/exchange';
export const TOKEN_STRATEGY = 'JWT';
export const ACCEPT_VERSION_HEADER = '1.*';
export const ACCEPT_VERSION_TWO_HEADER = '2.*';
export const AVATAR_PHOTO_DEFAULT_URL = 'https://d2f911aicdllsf.cloudfront.net/avatar_photos/default-avatar.png';
const USER_IMAGES_REPO_PROD = 'https://d2f911aicdllsf.cloudfront.net';
const USER_IMAGES_REPO_DEV = 'https://s3.eu-central-1.amazonaws.com/mobileswipestox';
export const PROFILE_PHOTO_BASE_URL = `${IS_PROD_ENV ? USER_IMAGES_REPO_PROD : USER_IMAGES_REPO_DEV}/profile_photos/`;
export const SYMBOL_BASE_URL = 'https://s3-eu-west-1.amazonaws.com/sx-mobile/exchange_pairs/';
export const SYMBOL_TYPES_BASE_URL = 'https://d2f911aicdllsf.cloudfront.net/symbol_types/';
export const VARIOUS_IMAGES_URL = 'https://content.swipestox.com/wt_images/';
export const RANDOM_STRING_CHARS = 'abcdefghijklmnopqrstuvwxyz0123456789';
export const DEFAULT_ERROR_MESSAGE = 'SOMETHING_WENT_WRONG_PLEASE_CONTACT_SUPPORT';
const mqttUrlDev = 'wss://ex-mqtt.sxdev.io:443/mqtt';
const mqttUrlProd = 'wss://mqtt.nagax.com:443/mqtt';
export const MQTT_URL = IS_PROD_ENV ? mqttUrlProd : mqttUrlDev;
const nagaMqttUrlDev = 'wss://mqtt-k8s.sxdev.io:443/mqtt';
const nagaMqttUrlProd = 'wss://mqtt-k8s.naga.com:443/mqtt';
export const NAGA_MQTT_URL = IS_PROD_ENV ? nagaMqttUrlProd : nagaMqttUrlDev;
export const LOGIN_ATTEMPTS_LIMIT = 3;
export const RE_CAPTCHA_KEY = '6LdowFcUAAAAAO8iMJeHAqyzyOkX8XNL8bd2pi27';
const devBrokerId = 14;
const prodBrokerId = 24;
export const SUPPORTED_BROKER_ID = IS_PROD_ENV ? prodBrokerId : devBrokerId;

export const EXCHANGE_TERMS_URL = 'https://docsend.com/view/s96i95i';
export const EXCHANGE_PRIVACY_URL = 'https://docsend.com/view/n3pvwd6';
export const FLAGS_BASE_URL = 'https://d2f911aicdllsf.cloudfront.net/country_flags/';
const FACEBOOK_APP_ID_DEV = '1585398601765171';
const FACEBOOK_APP_ID_PROD = '1441811402790559';
export const FACEBOOK_APP_ID = IS_PROD_ENV ? FACEBOOK_APP_ID_PROD : FACEBOOK_APP_ID_DEV;
export const GOOGLE_APP_ID = '95159300025-7m22pohd9u8nb2gfeq0g79jqjpp7tsjq';
const TIMELINE_IMAGE_BASE_URL_PROD = 'https://d2f911aicdllsf.cloudfront.net/user_timeline/';
const TIMELINE_IMAGE_BASE_URL_DEV = 'https://s3.eu-central-1.amazonaws.com/mobileswipestox/user_timeline/';
export const TIMELINE_IMAGE_BASE_URL = IS_PROD_ENV ? TIMELINE_IMAGE_BASE_URL_PROD : TIMELINE_IMAGE_BASE_URL_DEV;

const NEWS_IMAGES_URL_PROD = 'https://d2f911aicdllsf.cloudfront.net/news_images/';
const NEWS_IMAGES_URL_DEV = 'https://s3.eu-central-1.amazonaws.com/mobileswipestox/news_images/';
export const NEWS_IMAGES_URL = IS_PROD_ENV ? NEWS_IMAGES_URL_PROD : NEWS_IMAGES_URL_DEV;

const NEWS_VIDEOS_URL_PROD = 'https://dg9mh26qjblnw.cloudfront.net/news_videos/';
const NEWS_VIDEOS_URL_DEV = 'https://dmvecvvbp50iw.cloudfront.net/news_videos/';
export const NEWS_VIDEOS_URL = IS_PROD_ENV ? NEWS_VIDEOS_URL_PROD : NEWS_VIDEOS_URL_DEV;

export const REAL_TIME_CHANNELS = {
  LEVEL2: 'l2update',
  TICKER: 'ticker',
  HEARTBEAT: 'heartbeat',
  ORDER_PLACED: 'exchange/order/place', // add pair at the end
  ORDER_CANCELLED: 'exchange/order/cancel', // add pair at the end
  ORDER_MATCHED: 'exchange/order/match', // add pair at the end
};

export const TERMINAL_TYPES = {
  d: {
    value: 'D',
    text: 'Demo',
  },
  r: {
    value: 'R',
    text: 'Live',
  },
};

export const ACCOUNT_CURRENCIES = {
  usd: {
    value: 'USD',
    symbol: '$',
  },
  eur: {
    value: 'EUR',
    symbol: '€',
  },
  ngc: {
    value: 'NGC',
    symbol: 'NGC',
  },
};

export const CURRENCIES = {
  ...ACCOUNT_CURRENCIES,
  gbp: {
    value: 'GBP',
    symbol: '£',
  },
  aud: {
    value: 'AUD',
    symbol: 'A$',
  },
  jpy: {
    value: 'JPY',
    symbol: '¥',
  },
  cny: {
    value: 'CNY',
    symbol: '¥',
  },
  rub: {
    value: 'RUB',
    symbol: '₽',
  },
  clp: {
    value: 'CLP',
    symbol: 'Ch$',
  },
  eth: {
    value: 'ETH',
    symbol: 'ETH',
  },
};

export const USER_STATUS_TYPE = {
  APPROVED: 'A',
  NEW: 'N',
  DEMO: 'D',
  READ_ONLY: 'RO',
  PENDING: 'P',
  REVIEWED: 'R',
  WAITING_CY: 'W',
  ARCHIVED: 'X',
  WALLET_ONLY: 'WO',
};

const USER_MEDIA_NEW_REPO_DEV = 'https://s3-eu-west-1.amazonaws.com/sxphotoresized';
const USER_MEDIA_NEW_REPO_PROD = 'https://s3-eu-west-1.amazonaws.com/swipestoxphotoresized';
export const PHOTO_PROFILE_URL = `${IS_PROD_ENV ? USER_MEDIA_NEW_REPO_PROD : USER_MEDIA_NEW_REPO_DEV}/profile/thumbnail/`;

export const WALLET_ICONS_URL = 'https://content.swipestox.com/wallet/';

export const MOMENT_FORMATS = {
  DATETIME: {
    SHORT_SLASH_SEPARATED: 'DD/MM/YY, HH:mm',
    LONG_MONTH_COMMA_SEPARATED: 'D. MMM YYYY, HH:mm',
    DEFAULT: 'DD.MM.YYYY HH:mm',
  },
  DATE: {
    SHORT_SLASH_SEPARATED: 'DD/MM/YY',
    LONG_SLASH_SEPARATED: 'DD/MM/YYYY',
    DEFAULT: 'DD.MM.YYYY',
  },
  TIME: {
    DEFAULT: 'HH:mm',
  },
};

export const MAX_MOBILE_WIDTH = 768;
export const MAX_MOBILE_XS_WIDTH = 400;
export const MIN_DESKTOP_WIDTH = 769;
export const SMALL_DESKTOP_WIDTH = 1000;
export const SMALL_CHART_DATA_CACHE_DURATION_IN_DAYS = 1;
export const POLLING = {
  DEFAULT_POLL_INTERVAL: 500,
  RETRY_COUNT: 5,
};

export const MQTT_RETRY_COUNT = 5;
export const MQTT_ERROR_CODES = {
  BAD_USERNAME_PASSWORD: 4,
};

export const ROOT_REDUCER_ACTIONS = {
  LOG_OUT: 'logOut',
};

export const DEFAULT_LANGUAGE = 'en';
export const MAIN_LANGUAGES = {
  en: {
    CODE: 'en',
    TEXT: 'English',
    FLAG: 'gb',
  },
  es: {
    CODE: 'es',
    TEXT: 'Español',
    FLAG: 'es',
  },
  de: {
    CODE: 'de',
    TEXT: 'Deutsch',
    FLAG: 'de',
  },
  pl: {
    CODE: 'pl',
    TEXT: 'Polski',
    FLAG: 'pl',
  },
  cz: {
    CODE: 'cz',
    TEXT: 'Česky',
    FLAG: 'cz',
  },
  it: {
    CODE: 'it',
    TEXT: 'Italiano',
    FLAG: 'it',
  },
  hu: {
    CODE: 'hu',
    TEXT: 'Magyar',
    FLAG: 'hu',
  },
  pt: {
    CODE: 'pt',
    TEXT: 'Português',
    FLAG: 'pt',
  },
  ms: {
    CODE: 'ms',
    TEXT: 'Malay',
    FLAG: 'my',
  },
  sk: {
    CODE: 'sk',
    TEXT: 'Slovenský',
    FLAG: 'sk',
  },
  hi: {
    CODE: 'hi',
    TEXT: 'हिन्दी',
    FLAG: 'in',
  },
  ur: {
    CODE: 'ur',
    TEXT: 'اردو',
    FLAG: 'pk',
  },
};

export const FIRST_LAST_NAME_CRITERIA = /^[A-Za-z0-9\s]+$/;
export const USERNAME_CRITERIA = /(?!^[0-9]*$)^[A-Za-z]+((([.]|[-]|[_]|)[a-zA-Z0-9]+)?)$/;
export const EMAIL_CRITERIA = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;
export const PASSWORD_CRITERIA = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,15}$/;
export const MFA_CODE_CRITERIA = /^[0-9]{6}$/;
export const URL_REGEX = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^"'\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/gi;

export const WARNING_STATUSES = [USER_STATUS_TYPE.DEMO, USER_STATUS_TYPE.NEW, USER_STATUS_TYPE.PENDING, USER_STATUS_TYPE.REVIEWED, USER_STATUS_TYPE.READ_ONLY];

export const AUTO_LOGIN_TOKEN_EXPIRATION = 15 * 60 * 1000;
export const MA_TOKEN_AUTO_LOGIN_DONE = 'MaTokenAutoLoginDone';

export const ORDER_SIDE = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const MARKETS = [
  {
    name: 'USD',
    value: 1,
  },
  {
    name: 'EUR',
    value: 2,
  },
  {
    name: 'GBP',
    value: 3,
  },
  {
    name: 'BTC',
    value: 4,
  },
  {
    name: 'ETH',
    value: 5,
  },
  {
    name: 'NGC',
    value: 6,
  },
];

export const DB_BOOLEAN = {
  TRUE: 'Y',
  FALSE: 'N',
};

export const ACCEPTED_UPLOAD_IMAGE_FILES = ['image/jpeg', 'image/jpg', 'image/png'];
export const MAX_UPLOAD_IMAGE_SIZE = 2000000; //2MB
export const WS_COMMANDS = {
  CHECK_TIMESTAMP: 1,
  GET_USER_INFO: 256,
  GET_ORDER_BY_UUID: 4096,
  GET_ORDER_BY_PAIR_OR_ALL: 8192,
  CANCEL_ORDER_BY_UUID: 20480,
  CANCEL_ORDER_BY_PAIR_OR_ALL: 24576,
  PLACE_ORDER: 36864,
};

export const DEFAULT_ACCOUNT_NAME = 'NAGA Markets Ltd.';
export const NON_EU_ACCOUNT_NAME = 'NAGA Capital Ltd.';

export const WEB_TRADER_AUTOLOGIN_PAGES = {
  WALLET: 'wallet',
  UPGRADE: 'upgrade',
  ACCOUNT_SUMMARY: 'account-summary',
  VERIFY_ACCOUNT: 'verification',
};

export const AUTOLOGIN_DESTIONATIOS = {
  WEB_TRADER: 'web',
};

export const ERROR_CODES = {
  INVALID_USERNAME_OR_PASSWORD: 1057,
  INVALID_LOGIN_COUNT_EXCEEDED: 1077,
};

export const LIST_TOKEN_FORM_URL = 'https://docs.google.com/forms/d/1ZmOTLp2QvyU5RXa9IL5TG3Og5G-kIMhDLOXNTeOR39I/edit?ts=5bdc6fb2';

export const CONNECTION_STATUS = {
  ONLINE: 1,
  OFFLINE: 0,
};

export const INTERCOM_APP_ID = 'uzsmlojy';

export const CURRENCY_SYMBOL_POSITION = {
  PREFIX: 1,
  SUFFIX: 2,
};

export const UPGRADE_REWORD = '0.001 BTC';

export const ASSET_GROUP = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
};

export const COOKIES_MAX_AGE = 365 * 24 * 60 * 60; //one year
