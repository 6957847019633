import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Big from 'big.js';

import { INVESTMENT_TYPE, ENTRY_VALUE_TYPE } from '../../constants/exchange-constants';
import { ORDER_SIDE } from '../../../../config/constants';
import EnterValue from './enter-value';

import '../../styles/investment-section.scss';

class InvestmentSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSection: INVESTMENT_TYPE.CURRENCY,
      amountPercentage: 0,
    };
  }

  componentDidUpdate = prevProps => {
    const { isLimitOrder, price } = this.props;

    if (prevProps.isLimitOrder !== isLimitOrder || prevProps.price !== price) {
      this.setState({ amountPercentage: 0 });
    }
  };

  handleInvestmentChange = type => {
    this.setState({ selectedSection: type });
  };

  handlePercentageAmountChanged = value => {
    this.setState({ amountPercentage: value }, () => {
      this.calculateAmount(this.state.amountPercentage);
    });
  };

  calculateAmount = value => {
    const { onValueChanged, balance, orderSide, livePrice, price, isLimitOrder } = this.props;

    if (balance > 0) {
      if (orderSide === ORDER_SIDE.SELL) {
        const calculatedValue = new Big(balance).times(value).toString(); //+balance * +value; //
        onValueChanged(calculatedValue, ENTRY_VALUE_TYPE.LOTS);
      } else {
        if (isLimitOrder) {
          const calculatedValue = price
            ? new Big(balance)
                .times(value)
                .div(price)
                .toString()
            : 0; //+balance * +value / +price ; //
          onValueChanged(calculatedValue, ENTRY_VALUE_TYPE.LOTS);
        } else {
          const calculatedValue = new Big(balance)
            .times(value)
            .div(livePrice)
            .toString(); //+balance * +value / +livePrice; //
          onValueChanged(calculatedValue, ENTRY_VALUE_TYPE.LOTS);
        }
      }
    }
  };

  getInvestmentExplanation = () => {
    const { t } = this.context;
    const { orderSide, symbol, balance, originalPrice, quoteDigits, quoteCurrency, originalSize, isLimitOrder, marketAsk, marketBid } = this.props;

    let explanation = '';

    if (balance === 0) {
      explanation = t('OPEN_ORDER.NOT_ENOUGH_FUNDS_TO_TRADE');
    } else if (orderSide === ORDER_SIDE.BUY) {
      const price = isLimitOrder ? originalPrice : marketAsk;
      const percent = (((originalSize * price) / balance) * 100).toCustomFixed(4) + '%';
      const balanceSpend = (originalSize * price).toCustomFixed(quoteDigits);
      explanation = t('OPEN_ORDER.YOU_WILL_SPEND', { amount: `${balanceSpend}${quoteCurrency}`, percent: percent });
    } else if (orderSide === ORDER_SIDE.SELL) {
      const price = isLimitOrder ? originalPrice : marketBid;
      const percent = ((originalSize / balance) * 100).toCustomFixed(4) + '%';
      const balanceReceived = (originalSize * price).toCustomFixed(quoteDigits);
      explanation = t('OPEN_ORDER.YOU_WILL_RECEIVE', { amount: `${balanceReceived}${quoteCurrency}`, percent: percent, symbol: symbol });
    }

    return explanation;
  };

  render = () => {
    const { t } = this.context;
    const { descText, currency, orderSide } = this.props;
    const { amountPercentage } = this.state;

    return (
      <div className="investment-section">
        <EnterValue
          type={ENTRY_VALUE_TYPE.LOTS}
          value={this.props.currencyAmount}
          increment={this.props.quoteIncrement}
          onValueChanged={this.props.onValueChanged}
          changeValue={this.props.changeValue}
          hideInputElement={false}
          descText={descText}
          currency={currency}
        />
        <div className="investment-section__toggle">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name="options" value={amountPercentage} onChange={this.handlePercentageAmountChanged}>
              <ToggleButton value={0.25}>25%</ToggleButton>
              <ToggleButton value={0.5}>50%</ToggleButton>
              <ToggleButton value={0.75}>75%</ToggleButton>
              <ToggleButton value={orderSide === ORDER_SIDE.SELL ? 1 : 0.98}>{t('OPEN_ORDER.MAX').toUpperCase()}</ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </div>
        <div className="investment-explanation">{this.getInvestmentExplanation()}</div>
      </div>
    );
  };
}

InvestmentSection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InvestmentSection;
