import { MAX_MOBILE_WIDTH, MAX_MOBILE_XS_WIDTH, SMALL_DESKTOP_WIDTH } from '../../../config/constants';
import { MEDIA_QUERY_ACTIONS } from '../constants/media-query-constants';

const mediaQueryInitialState = {
  isMobile: window.innerWidth <= MAX_MOBILE_WIDTH,
  isMobileXS: window.innerWidth <= MAX_MOBILE_XS_WIDTH,
  desktopXS: window.innerWidth <= SMALL_DESKTOP_WIDTH,
};

export const mediaQuery = (state = mediaQueryInitialState, payload) => {
  switch (payload.type) {
    case MEDIA_QUERY_ACTIONS.HANDLE_MEDIA_QUERY_RESULT:
      const stateInstance = state;
      if (payload.data.mobile === true && !state.isMobile) {
        stateInstance.isMobile = true;
      } else if (payload.data.mobile === false && state.isMobile) {
        stateInstance.isMobile = false;
      } else if (payload.data.mobileXS === true && !state.isMobileXS) {
        stateInstance.isMobileXS = true;
      } else if (payload.data.mobileXS === false && state.isMobileXS) {
        stateInstance.isMobileXS = false;
      }
      stateInstance.desktopXS = payload.data.desktopXS;
      return { ...stateInstance };
    default:
      return state;
  }
};
