import React, { Component } from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';

import ProfileBox from '../../../profile/components/profile-box';

import '../../styles/layout.scss';
import '../styles/header.scss';

class Header extends Component {
  nagivateToSearchResults = () => {
    const { history } = this.props;

    history.push('/search-results');
  };

  navigateToHome = () => {
    const { history } = this.props;

    history.push('/');
  };

  render = () => {
    const { loggedIn } = this.props;

    return (
      <header className="nav-bar">
        <div>
          <Navbar.Brand>
            <div onClick={this.navigateToHome} className={`navbar-brand-logo full ${loggedIn && 'hide-logo'}`} />
            <div onClick={this.navigateToHome} className={`navbar-brand-logo short ${loggedIn && 'show-logo'}`} />
          </Navbar.Brand>
          {loggedIn && (
            <div className="nav-bar__profile">
              <ProfileBox />
            </div>
          )}
        </div>
      </header>
    );
  };
}

Header.contextTypes = {
  t: PropsTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: state.currentUser.loggedIn,
});

export default withRouter(connect(mapStateToProps, null)(Header));
