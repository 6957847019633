export const LOGIN_ACTIONS = {
  HANDLE_INVALID_LOGIN: 'handleInvalidLogin',
  SET_RECAPTCHA_RESPONSE: 'setgRecaptchaResponse',
  INCREASE_NUMBER_OF_LOIGN_ATTEMPTS: 'increaseNumberOfLoginAttempts',
  LOGIN_IN_PROGRESS: 'loginInProgress',
  HANDLE_DISPLAY_RECAPTCHA: 'handleDisplayReCaptcha',
  HANDLE_RESET_RECAPTCHA: 'handleResetReCaptcha',
  HANDLE_COUNTRIES_RECEIVED: 'handleCountriesReceived',
  REGISTER_IN_PROGRESS: 'registerInProgress',
  SEND_RESET_PASSWORD_EMAIL_SUCCESS: 'sendResetPasswordEmailSuccess',
  SEND_RESET_PASSWORD_EMAIL_IN_PROGRESS: 'sendResetPasswordEmailInProgress',
  RESET_PASSWORD_IN_PROGRESS: 'resetPasswordInProgress',
  USER_INFO_RECEIVED: 'userInfoReceived',
  USER_COUNTRY_DETECTED: 'userCountryDetected',
  HANDLE_DISPLAY_REGISTRATION_MODAL: 'handleDisplayRegistrationModal',
  HANDLE_DISPLAY_LOGIN_MODAL: 'handleDisplayLoginModal',
  HANDLE_DISPLAY_FORGOT_PASSWORD_MODAL: 'handleDisplayForgotPasswordModal',
  HANDLE_AUTO_LOGIN_MA_URL_RECEIVED: 'handleAutoLoginMAUrlReceived',
  HANDLE_EMAIL_CONFIRMATION_IN_PROGRESS: 'handleEmailConfirmationInProgress',
};

export const REGISTRATION_TYPES = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};
