import HelperFunctions from '../../core/helpers/helper-functions.js';
import { EMAIL_CRITERIA, GOOGLE_APP_ID } from '../../config/constants';

class GoogleHelper {
  initialize = () => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: `${GOOGLE_APP_ID}.apps.googleusercontent.com`,
        cookiepolicy: 'single_host_origin',
      });
    });
  };

  login = () => {
    const promise = new Promise((resolve, reject) => {
      const auth2 = window.gapi.auth2.getAuthInstance();

      if (!auth2.isSignedIn.get()) {
        auth2.signIn().then(
          response => {
            const user = this.extractUserData(response);
            resolve(user);
          },
          errorResponse => {
            if (errorResponse.error === 'popup_closed_by_user') {
              resolve();
            } else {
              reject(errorResponse);
            }
          }
        );
      } else {
        const currentUser = auth2.currentUser.get();
        const user = this.extractUserData(currentUser);
        resolve(user);
      }
    });

    return promise;
  };

  logout = () => {
    const promise = new Promise((resolve, reject) => {
      const auth2 = window.gapi.auth2.getAuthInstance();

      if (auth2.isSignedIn.get()) {
        auth2.disconnect().then(
          () => {
            auth2.signOut().then(
              () => {
                resolve();
              },
              error => {
                reject(error);
              }
            );
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve();
      }
    });

    return promise;
  };

  extractUserData = googleUser => {
    const profile = googleUser.getBasicProfile();
    const auth = googleUser.getAuthResponse();

    const user = {
      username: HelperFunctions.removeDiacritics(profile.getName().replace(/\s+/g, '')), // Remove empty spaces
      email: EMAIL_CRITERIA.test(profile.getEmail()) ? profile.getEmail() : '', // Check email validity
      id: googleUser.getId(),
      token: auth.id_token,
    };

    return user;
  };
}

export default new GoogleHelper();
