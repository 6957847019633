import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import { HeadingMedium, ParagraphLarge } from '@my-swipestox/components';

import { getMFAInfo, enableMFA, disableMFA } from '../../../user-settings/actions/user-settings-actions';

import { MFA_CODE_CRITERIA } from '../../../../config/constants';
import { MFA_STEPS } from '../../constants/user-settings-constants';
import DefaultInput from '../../../shared-components/default-input/components/default-input';
import { requiredField } from '../../../shared-components/helpers/helpers';

import '../../styles/mfa-authenticator.scss';

class MFAAuthenticator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
    };
  }

  componentDidMount = () => {
    const { mfaEnabled, getMFAInfo } = this.props;

    if (!mfaEnabled) {
      getMFAInfo();
    }
  };

  componentDidUpdate = prevProps => {
    const { changeMFAStep, mfaEnabled } = this.props;

    if (!prevProps.mfaEnabled && mfaEnabled) {
      changeMFAStep(MFA_STEPS.MFA_ENABLED);
    } else if (prevProps.mfaEnabled && !mfaEnabled) {
      changeMFAStep(MFA_STEPS.INITIAL);
    }
  };

  componentWillUnmount = () => {
    this.resetPasswordForm();
  };

  goBack = () => {
    const { changeMFAStep } = this.props;

    changeMFAStep(MFA_STEPS.INITIAL);
  };

  onAuthCodeChanged = event => {
    this.setState({ code: event.target.value });
  };

  handleFormSubmit(values) {
    const { mfaEnabled, secret, enableMFA, disableMFA } = this.props;

    if (mfaEnabled) {
      disableMFA(values.code);
    } else {
      enableMFA(values.code, secret);
    }
  }

  resetPasswordForm = () => {
    const { reset } = this.props;

    reset();
  };

  render = () => {
    const { t } = this.context;
    const { mfaEnabled, secret, qrData, qrMime, handleSubmit } = this.props;

    return (
      <div className="mfa-authenticator">
        <div className="mfa-authenticator__back">
          <Button bsStyle="link" bsSize="large" onClick={this.goBack}>
            <i className="icn icn-minimal-left-big-thin" />
            {t('USER_SETTINGS.BACK')}
          </Button>
        </div>
        <HeadingMedium>{t(`${mfaEnabled ? 'USER_SETTINGS.DISABLE_TWO_FACTOR_AUTHENTICATION' : 'USER_SETTINGS.ENABLE_TWO_FACTOR_AUTHENTICATION'}`)}</HeadingMedium>
        <ParagraphLarge mt="25px">{t(`${mfaEnabled ? 'USER_SETTINGS.SECURITY_VERIFICATION' : 'USER_SETTINGS.CONNECT_YOUR_APP'}`)}</ParagraphLarge>
        <p className="mfa-authenticator__description mfa-apps">
          {t(`${mfaEnabled ? 'USER_SETTINGS.ENTER_THE_6_DIGIT_GENERATED_CODE' : 'USER_SETTINGS.CONNECT_YOUR_APP_DESCRIPTION'}`, { n: <span>Google Authenticator</span>, m: <span>Duo</span> })}
        </p>
        <div>{!mfaEnabled && qrData && qrMime && <img src={`${qrMime}${qrData}`} alt="QR Code" />}</div>
        <div className="mfa-authenticator__form">
          <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} noValidate>
            <Field name="code" type="text" placeHolder={t('USER_SETTINGS.ENTER_AUTHENTICATION_CODE')} component={DefaultInput} />
            <Button bsStyle="primary" type="submit">
              {t('USER_SETTINGS.SUBMIT')}
            </Button>
          </form>
        </div>
        {!mfaEnabled && secret && (
          <div className="mfa-authenticator__code">
            <span>{t('USER_SETTINGS.BACKUP_2_STEP_KEY')}</span>
            <span>{secret}</span>
          </div>
        )}
        <div className="mfa-authenticator__footer">{t(`${mfaEnabled ? 'USER_SETTINGS.MFA_DISABLE_DISCLAIMER' : 'USER_SETTINGS.MFA_DISCLAIMER'}`)}</div>
      </div>
    );
  };
}

MFAAuthenticator.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    mfaEnabled: state.currentUser.info.mfaEnabled,
    qrData: state.userSettings.mfa.qrData,
    qrMime: state.userSettings.mfa.qrMime,
    secret: state.userSettings.mfa.secret,
  };
};

const mapDispatchToProps = dispatch => ({
  getMFAInfo: () => {
    dispatch(getMFAInfo());
  },
  enableMFA: (token, secret) => {
    dispatch(enableMFA(token, secret));
  },
  disableMFA: token => {
    dispatch(disableMFA(token));
  },
});

MFAAuthenticator = connect(mapStateToProps, mapDispatchToProps)(MFAAuthenticator);

const validateForm = values => {
  const errors = {};

  requiredField(values, errors, 'code', 'USER_SETTINGS.AUTHENTICATION_CODE_INVALID');

  if (!errors.length && !MFA_CODE_CRITERIA.test(values.code)) {
    errors.code = 'USER_SETTINGS.AUTHENTICATION_CODE_DIGITS_ERROR';
  }

  return errors;
};

export default reduxForm({
  form: 'mfaForm',
  validate: validateForm,
  destroyOnUnmount: false,
})(MFAAuthenticator);
