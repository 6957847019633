import { LOADER_ACTIONS } from '../constants/loader-constants';

export const handleStartFetching = () => ({
  type: LOADER_ACTIONS.HANDLE_START_FETCHING,
});

export const handleEndFetching = () => ({
  type: LOADER_ACTIONS.HANDLE_END_FETCHING,
});

export const forceFetching = () => ({
  type: LOADER_ACTIONS.FORCE_FETCHING,
});

export const stopForceFetching = () => ({
  type: LOADER_ACTIONS.STOP_FORCE_FETCHING,
});
