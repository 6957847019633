import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderIf from '../../../shared-components/render-if/components/render-if';

import nagaImg from '../../../../core/styles/images/naga-logo-white.png';
import '../styles/loader.scss';

class AppLoader extends Component {
  render = () => {
    const { isFetching, message } = this.props;

    return (
      <RenderIf if={isFetching}>
        <div className="loading-container">
          <div className="loading">
            <div className="spinner-wrapper">
              <div className="loader-inner ball-clip-rotate" />
              <div className="ss-logo">
                <img src={nagaImg} alt="nagaLogo" />
              </div>
            </div>
            <label>{message}</label>
          </div>
        </div>
      </RenderIf>
    );
  };
}

AppLoader.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetching: state.loader.isFetching,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
