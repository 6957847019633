import React, { Component } from 'react';

import DefaultInput from '../../default-input/components/default-input';
import ShowHidePassword from './show-hide-password';

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = { inputType: 'password' };
  }

  handleToggleVisibility = () => {
    const newInputType = this.state.inputType === 'text' ? 'password' : 'text';
    this.setState({ inputType: newInputType });
  };

  render() {
    return (
      <DefaultInput {...this.props} type={this.state.inputType} className="password-input">
        <ShowHidePassword clickHandler={this.handleToggleVisibility} show={this.state.inputType === 'text'} />
      </DefaultInput>
    );
  }
}

export default PasswordInput;
