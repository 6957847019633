import padEnd from 'lodash/padEnd';

const toCustomFixed = (value, decimals) => {
  let customValue = value;
  if (value.toString().indexOf('e') > -1) {
    customValue = parseFloat(value).toFixed(decimals);
  }
  const splitedNumber = String(customValue).split('.');
  if (splitedNumber.length > 1) {
    return `${splitedNumber[0]}.${padEnd(splitedNumber[1].substring(0, decimals), decimals, '0')}`;
  }
  //if number is integer, add zeros as decimals
  return `${customValue}.${padEnd('0', decimals, '0')}`;
};

/*eslint-disable no-extend-native*/
Number.prototype.toCustomFixed = function(decimals) {
  return toCustomFixed(this, decimals);
};

String.prototype.toCustomFixed = function(decimals) {
  return toCustomFixed(this, decimals);
};
